import { useEffect, useState } from 'react'

// React router dom
import { Link, useLocation, useHistory } from "react-router-dom";

// Styled components
import styled, { keyframes, css } from 'styled-components'

// Bootstrap
import Container from 'react-bootstrap/Container'

// React responsive
import MediaQuery, { useMediaQuery } from 'react-responsive'

// Animated
import { Animated } from "react-animated-css";

// Images
import CloudxierLogo from '../assets/images/logo/cloudxier_logo.png'
import CloudxierLogoDark from '../assets/images/logo/cloudxier_logo_dark.png'
import MagnifyIcon from '../assets/images/icons/magnify_icon.png'
import MagnifyIconDark from '../assets/images/icons/magnify_icon_dark.png'
import SearchArrowIcon from '../assets/images/icons/search_icon_light.png'

// Icons
import AppointmentIcon from '../assets/images/icons/products_icon/AppointmentIcon';
import CompanyProfileIcon from '../assets/images/icons/products_icon/CompanyProfileIcon';
import EcommerceIcon from '../assets/images/icons/products_icon/EcommerceIcon';

// Components
import RoundButton from './buttons/RoundButton'
import HeaderDropdown from './dropdowns/HeaderDropdown'
import KeyboardArrowDownIcon from '../assets/images/icons/KeyboardArrowDownIcon';

// Hamburger react
import { Squash as Hamburger } from 'hamburger-react'

function Header({ toggleCollapsableMenu, isOpenMenu }) {
  const smallScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 1200px)' })

  const [windowScrollPercentage, setWindowScrollPercentage] = useState(0)

  const [animateHeader, setAnimateHeader] = useState(false)

  const [animateSearchBar, setAnimateSearchBar] = useState(false)

  const [searchInput, setSearchInput] = useState('')

  const [ourGroupMenuSelected, setOurGroupMenuSelected] = useState(false)
  const [servicesMenuSelected, setServicesMenuSelected] = useState(false)
  const [productsMenuSelected, setProductsMenuSelected] = useState(false)

  const screenLocation = useLocation()

  const history = useHistory()

  // Play header transition on page load
  useEffect(() => {
    setAnimateHeader(true)
  }, [])

  // Listen on keydown effect on load
  useEffect(() => {
    window.addEventListener('keydown', handleKeydown)
    return function cleanUp() {
      window.removeEventListener('keydown', handleKeydown)
    }
  })

  // Listen on window scroll on load
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return function cleanUp() {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  // Handle keydown function
  function handleKeydown({ keyCode }) {
    switch (keyCode) {
      case 27:
        setSearchInput('')
        setAnimateSearchBar(false)
      default:
        return
    }
  }

  // Handle click logo
  function handleClickLogo() {
    toggleCollapsableMenu(false)
    if (screenLocation.pathname === '/') {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    } else {
      window.open('/', "_self")
    }
  }

  // Change header background color on scroll
  function handleScroll() {
    let scrollPercentage = window.pageYOffset / (document.body.offsetHeight - window.innerHeight)
    setWindowScrollPercentage(scrollPercentage)
  }

  // Change dropdown text color on specific location
  function changeDropdownTxtColor() {
    switch (screenLocation.pathname) {
      case '/about':
        return 'black'
      case '/expertise':
        return 'black'
      case '/projects':
        return 'black'
      case '/projects/details':
        return 'black'
      case '/contact-us':
        return 'black'
      case '/blog':
        return 'black'
      case `/blog/details/${screenLocation.pathname.replace('/blog/details/', '')}`:
        return 'black'
      case '/privacy-policy':
        return 'black'
      case '/sustainability-policy':
        return 'black'
      case '/enviromental-policy':
        return 'black'
      case '/services':
        return 'black'
      case '/tp-terms':
        return 'black'
      case '/tp-privacy-policy':
        return 'black'
      case '/our-services/custom-software-development':
        return 'black'
      case '/our-services/mobile-app-development':
        return 'black'
      case '/our-services/api-development-integration':
        return 'black'
      case '/our-services/cloud-computing-consultation':
        return 'black'
      case '/our-services/dedicated-tech-team':
        return 'black'
      case '/our-services/cto-as-service':
        return 'black'
      case '/our-services/ui-ux-design-planning':
        return 'black'
      case '/our-services/devops-consulting':
        return 'black'
      case '/':
        return 'white'
      default:
        return 'black'
    }
  }

  // Change text color on specific location
  function changeTextColor() {
    switch (screenLocation.pathname) {
      case '/about':
        return 'black'
      case '/expertise':
        return 'black'
      case '/projects':
        return 'black'
      case '/projects/details':
        return 'black'
      case '/contact-us':
        return 'black'
      case '/blog':
        return 'black'
      case `/blog/details/${screenLocation.pathname.replace('/blog/details/', '')}`:
        return 'black'
      case '/privacy-policy':
        return 'black'
      case '/sustainability-policy':
        return 'black'
      case '/enviromental-policy':
        return 'black'
      case '/services':
        return 'black'
      case '/tp-terms':
        return 'black'
      case '/tp-privacy-policy':
        return 'black'
      case '/our-services/custom-software-development':
        return 'black'
      case '/our-services/mobile-app-development':
        return 'black'
      case '/our-services/api-development-integration':
        return 'black'
      case '/our-services/cloud-computing-consultation':
        return 'black'
      case '/our-services/dedicated-tech-team':
        return 'black'
      case '/our-services/cto-as-service':
        return 'black'
      case '/our-services/ui-ux-design-planning':
        return 'black'
      case '/our-services/devops-consulting':
        return 'black'
      case '/':
        return 'white'
      default:
        return 'white'
    }
  }

  // Change header logo on specific location
  function changeHeaderLogo() {
    switch (screenLocation.pathname) {
      case '/about':
        if (isOpenMenu) {
          return CloudxierLogo
        } else {
          return CloudxierLogoDark
        }
      case '/expertise':
        if (isOpenMenu) {
          return CloudxierLogo
        } else {
          return CloudxierLogoDark
        }
      case '/projects':
        if (isOpenMenu) {
          return CloudxierLogo
        } else {
          return CloudxierLogoDark
        }
      case '/projects/details':
        if (isOpenMenu) {
          return CloudxierLogo
        } else {
          return CloudxierLogoDark
        }
      case '/contact-us':
        if (isOpenMenu) {
          return CloudxierLogo
        } else {
          return CloudxierLogoDark
        }
      case '/blog':
        if (isOpenMenu) {
          return CloudxierLogo
        } else {
          return CloudxierLogoDark
        }
      case `/blog/details/${screenLocation.pathname.replace('/blog/details/', '')}`:
        if (isOpenMenu) {
          return CloudxierLogo
        } else {
          return CloudxierLogoDark
        }
      case '/privacy-policy':
        if (isOpenMenu) {
          return CloudxierLogo
        } else {
          return CloudxierLogoDark
        }
      case '/sustainability-policy':
        if (isOpenMenu) {
          return CloudxierLogo
        } else {
          return CloudxierLogoDark
        }
      case '/enviromental-policy':
        if (isOpenMenu) {
          return CloudxierLogo
        } else {
          return CloudxierLogoDark
        }
      case '/services':
        if (isOpenMenu) {
          return CloudxierLogo
        } else {
          return CloudxierLogoDark
        }
      case '/tp-terms':
        if (isOpenMenu) {
          return CloudxierLogo
        } else {
          return CloudxierLogoDark
        }
      case '/tp-privacy-policy':
        if (isOpenMenu) {
          return CloudxierLogo
        } else {
          return CloudxierLogoDark
        }
      case '/our-services/custom-software-development':
        if (isOpenMenu) {
          return CloudxierLogo
        } else {
          return CloudxierLogoDark
        }
      case '/our-services/mobile-app-development':
        if (isOpenMenu) {
          return CloudxierLogo
        } else {
          return CloudxierLogoDark
        }
      case '/our-services/api-development-integration':
        if (isOpenMenu) {
          return CloudxierLogo
        } else {
          return CloudxierLogoDark
        }
      case '/our-services/cloud-computing-consultation':
        if (isOpenMenu) {
          return CloudxierLogo
        } else {
          return CloudxierLogoDark
        }
      case '/our-services/dedicated-tech-team':
        if (isOpenMenu) {
          return CloudxierLogo
        } else {
          return CloudxierLogoDark
        }
      case '/our-services/cto-as-service':
        if (isOpenMenu) {
          return CloudxierLogo
        } else {
          return CloudxierLogoDark
        }
      case '/our-services/ui-ux-design-planning':
        if (isOpenMenu) {
          return CloudxierLogo
        } else {
          return CloudxierLogoDark
        }
      case '/our-services/devops-consulting':
        if (isOpenMenu) {
          return CloudxierLogo
        } else {
          return CloudxierLogoDark
        }
      case '/':
        return CloudxierLogo
      default:
        return CloudxierLogo
    }
  }

  // Change magnifier icon on specific location
  function changeMagnifierIcon() {
    switch (screenLocation.pathname) {
      case '/about':
        return MagnifyIconDark
      case '/expertise':
        return MagnifyIconDark
      case '/projects':
        return MagnifyIconDark
      case '/projects/details':
        return MagnifyIconDark
      case '/contact-us':
        return MagnifyIconDark
      case '/blog':
        return MagnifyIconDark
      case `/blog/details/${screenLocation.pathname.replace('/blog/details/', '')}`:
        return MagnifyIconDark
      case '/sustainability-policy':
        return MagnifyIconDark
      case '/enviromental-policy':
        return MagnifyIconDark
      case '/services':
        return MagnifyIconDark
      case '/tp-terms':
        return MagnifyIconDark
      case '/tp-privacy-policy':
        return MagnifyIconDark
      case '/our-services/custom-software-development':
        return MagnifyIconDark
      case '/our-services/mobile-app-development':
        return MagnifyIconDark
      case '/our-services/api-development-integration':
        return MagnifyIconDark
      case '/our-services/cloud-computing-consultation':
        return MagnifyIconDark
      case '/our-services/dedicated-tech-team':
        return MagnifyIconDark
      case '/our-services/cto-as-service':
        return MagnifyIconDark
      case '/our-services/ui-ux-design-planning':
        return MagnifyIconDark
      case '/our-services/devops-consulting':
        return MagnifyIconDark
      case '/':
        return MagnifyIcon
      default:
        return MagnifyIcon
    }
  }

  // Change dropdown background color on specific location
  function changeDropdownBgColor() {
    switch (screenLocation.pathname) {
      case '/about':
        return 'white'
      case '/expertise':
        return 'white'
      case '/projects':
        return 'white'
      case '/projects/details':
        return 'white'
      case '/contact-us':
        return 'white'
      case '/blog':
        return 'white'
      case `/blog/details/${screenLocation.pathname.replace('/blog/details/', '')}`:
        return 'white'
      case '/privacy-policy':
        return 'white'
      case '/sustainability-policy':
        return 'white'
      case '/enviromental-policy':
        return 'white'
      case '/services':
        return 'white'
      case '/tp-terms':
        return 'white'
      case '/tp-privacy-policy':
        return 'white'
      case '/our-services/custom-software-development':
        return 'white'
      case '/our-services/mobile-app-development':
        return 'white'
      case '/our-services/api-development-integration':
        return 'white'
      case '/our-services/cloud-computing-consultation':
        return 'white'
      case '/our-services/dedicated-tech-team':
        return 'white'
      case '/our-services/cto-as-service':
        return 'white'
      case '/our-services/ui-ux-design-planning':
        return 'white'
      case '/our-services/devops-consulting':
        return 'white'
      case '/':
        return 'black'
      default:
        return 'white'
    }
  }

  // Change header button background color on specific location
  function changeHeaderBtnBgColor() {
    switch (screenLocation.pathname) {
      case '/about':
        return '#4C39FF'
      case '/expertise':
        return '#4C39FF'
      case '/projects':
        return '#4C39FF'
      case '/projects/details':
        return '#4C39FF'
      case '/contact-us':
        return '#4C39FF'
      case '/blog':
        return '#4C39FF'
      case `/blog/details/${screenLocation.pathname.replace('/blog/details/', '')}`:
        return '#4C39FF'
      case '/privacy-policy':
        return '#4C39FF'
      case '/sustainability-policy':
        return '#4C39FF'
      case '/enviromental-policy':
        return '#4C39FF'
      case '/services':
        return '#4C39FF'
      case '/tp-terms':
        return '#4C39FF'
      case '/tp-privacy-policy':
        return '#4C39FF'
      case '/our-services/custom-software-development':
        return '#4C39FF'
      case '/our-services/mobile-app-development':
        return '#4C39FF'
      case '/our-services/api-development-integration':
        return '#4C39FF'
      case '/our-services/cloud-computing-consultation':
        return '#4C39FF'
      case '/our-services/dedicated-tech-team':
        return '#4C39FF'
      case '/our-services/cto-as-service':
        return '#4C39FF'
      case '/our-services/ui-ux-design-planning':
        return '#4C39FF'
      case '/our-services/devops-consulting':
        return '#4C39FF'
      case '/':
        return 'transparent'
      default:
        return 'transparent'
    }
  }

  // Change background color based on specific location
  function changeBackgroundColor() {
    switch (screenLocation.pathname) {
      case '/about':
        return 'white'
      case '/expertise':
        return 'white'
      case '/projects':
        return 'white'
      case '/projects/details':
        return 'white'
      case '/contact-us':
        return 'white'
      case '/blog':
        return 'white'
      case `/blog/details/${screenLocation.pathname.replace('/blog/details/', '')}`:
        return 'white'
      case '/privacy-policy':
        return 'white'
      case '/sustainability-policy':
        return 'white'
      case '/enviromental-policy':
        return 'white'
      case '/services':
        return 'white'
      case '/tp-terms':
        return 'white'
      case '/tp-privacy-policy':
        return 'white'
      case '/our-services/custom-software-development':
        return 'white'
      case '/our-services/mobile-app-development':
        return 'white'
      case '/our-services/api-development-integration':
        return 'white'
      case '/our-services/cloud-computing-consultation':
        return 'white'
      case '/our-services/dedicated-tech-team':
        return 'white'
      case '/our-services/cto-as-service':
        return 'white'
      case '/our-services/ui-ux-design-planning':
        return 'white'
      case '/our-services/devops-consulting':
        return 'white'
      case '/':
        return 'black'
      default:
        return 'transparent'
    }
  }

  // Stop header scroll animation on specific location
  function stopScrollAnimation() {
    switch (screenLocation.pathname) {
      case '/about':
        return 'none'
      case '/expertise':
        return 'none'
      case '/projects':
        return 'none'
      case '/projects/details':
        return 'none'
      case '/contact-us':
        return 'none'
      case '/blog':
        return 'none'
      case `/blog/details/${screenLocation.pathname.replace('/blog/details/', '')}`:
        return 'none'
      case '/privacy-policy':
        return 'none'
      case '/sustainability-policy':
        return 'none'
      case '/enviromental-policy':
        return 'none'
      case '/services':
        return 'none'
      case '/tp-terms':
        return 'none'
      case '/tp-privacy-policy':
        return 'none'
      case '/our-services/custom-software-development':
        return 'none'
      case '/our-services/mobile-app-development':
        return 'none'
      case '/our-services/api-development-integration':
        return 'none'
      case '/our-services/cloud-computing-consultation':
        return 'none'
      case '/our-services/dedicated-tech-team':
        return 'none'
      case '/our-services/cto-as-service':
        return 'none'
      case '/our-services/ui-ux-design-planning':
        return 'none'
      case '/our-services/devops-consulting':
        return 'none'
      case '/':
        return 'none'
      // return css`${changeHeaderColor} 1s linear`
      default:
        return css`${changeHeaderColor} 1s linear`
    }
  }

  // Change hamburger icon color on specific location
  function changeHamburgerColor() {
    switch (screenLocation.pathname) {
      case '/about':
        if (isOpenMenu) {
          return 'white'
        } else {
          return 'black'
        }
      case '/expertise':
        if (isOpenMenu) {
          return 'white'
        } else {
          return 'black'
        }
      case '/projects':
        if (isOpenMenu) {
          return 'white'
        } else {
          return 'black'
        }
      case '/projects/details':
        if (isOpenMenu) {
          return 'white'
        } else {
          return 'black'
        }
      case '/contact-us':
        if (isOpenMenu) {
          return 'white'
        } else {
          return 'black'
        }
      case '/blog':
        if (isOpenMenu) {
          return 'white'
        } else {
          return 'black'
        }
      case `/blog/details/${screenLocation.pathname.replace('/blog/details/', '')}`:
        if (isOpenMenu) {
          return 'white'
        } else {
          return 'black'
        }
      case '/privacy-policy':
        if (isOpenMenu) {
          return 'white'
        } else {
          return 'black'
        }
      case '/sustainability-policy':
        if (isOpenMenu) {
          return 'white'
        } else {
          return 'black'
        }
      case '/enviromental-policy':
        if (isOpenMenu) {
          return 'white'
        } else {
          return 'black'
        }
      case '/services':
        if (isOpenMenu) {
          return 'white'
        } else {
          return 'black'
        }
      case '/tp-terms':
        if (isOpenMenu) {
          return 'white'
        } else {
          return 'black'
        }
      case '/tp-privacy-policy':
        if (isOpenMenu) {
          return 'white'
        } else {
          return 'black'
        }
      case '/our-services/custom-software-development':
        if (isOpenMenu) {
          return 'white'
        } else {
          return 'black'
        }
      case '/our-services/mobile-app-development':
        if (isOpenMenu) {
          return 'white'
        } else {
          return 'black'
        }
      case '/our-services/api-development-integration':
        if (isOpenMenu) {
          return 'white'
        } else {
          return 'black'
        }
      case '/our-services/cloud-computing-consultation':
        if (isOpenMenu) {
          return 'white'
        } else {
          return 'black'
        }
      case '/our-services/dedicated-tech-team':
        if (isOpenMenu) {
          return 'white'
        } else {
          return 'black'
        }
      case '/our-services/cto-as-service':
        if (isOpenMenu) {
          return 'white'
        } else {
          return 'black'
        }
      case '/our-services/ui-ux-design-planning':
        if (isOpenMenu) {
          return 'white'
        } else {
          return 'black'
        }
      case '/our-services/devops-consulting':
        if (isOpenMenu) {
          return 'white'
        } else {
          return 'black'
        }
      case '/':
        return 'white'
      default:
        return 'white'
    }
  }

  return (
    <HeaderContainer
      animateHeader={animateHeader}
      windowScrollPercentage={windowScrollPercentage}
      stopScrollAnimation={stopScrollAnimation}
      changeBackgroundColor={changeBackgroundColor}
      isOpenMenu={isOpenMenu}
      mediumScreen={mediumScreen}>
      <Animated animationIn="slideInDown" animationInDuration={2000} isVisible={true}>
        <Container style={{ padding: smallScreen ? '10px 5vw' : mediumScreen ? '10px 5vw' : 0 }}>
          <FlexContainer>
            <LogoContainer mediumScreen={mediumScreen} onClick={() => handleClickLogo()}>
              <Logo src={changeHeaderLogo()} />
            </LogoContainer>
            <NavbarContainer>
              {/* Render full width navigations on large screen */}
              <MediaQuery minWidth={1280}>
                <NavItem
                  onMouseEnter={() => setServicesMenuSelected(true)}
                  onMouseLeave={() => setServicesMenuSelected(false)}
                  animateSearchBar={animateSearchBar}
                  changeTextColor={changeTextColor}
                >
                  <LinkContainer style={{ cursor: 'pointer', fontWeight: servicesMenuSelected ? "bold" : "normal" }} screenLocation={screenLocation}>
                    Services
                    &nbsp;
                    <KeyboardArrowDownIcon fill={screenLocation.pathname === '/' ? 'white' : 'black'} transform={servicesMenuSelected ? 'rotate(0)' : 'rotate(180)'} />
                  </LinkContainer>
                  <HeaderDropdown
                    servicesMenuSelected={servicesMenuSelected}
                    isOpen={servicesMenuSelected}
                    closeDropdown={() => setServicesMenuSelected(false)}
                    backgroundColor={changeDropdownBgColor()}
                    textColor={changeDropdownTxtColor()}
                    stopScrollAnimation={stopScrollAnimation}
                    windowScrollPercentage={windowScrollPercentage}
                    isExternalUrl={false}
                    list={
                      [
                        {
                          title: "Custom Software Development",
                          path: "/our-services/custom-software-development"
                        },
                        {
                          title: "Mobile App Development",
                          path: "/our-services/mobile-app-development"
                        },
                        {
                          title: "API Development and Integration",
                          path: "/our-services/api-development-integration"
                        },
                        {
                          title: "Cloud Computing and Consultation",
                          path: "/our-services/cloud-computing-consultation"
                        },
                        {
                          title: "Dedicated Tech Team",
                          path: "/our-services/dedicated-tech-team"
                        },
                        {
                          title: "CTO as a Service",
                          path: "/our-services/cto-as-service"
                        },
                        {
                          title: "UI/UX Design and Planning",
                          path: "/our-services/ui-ux-design-planning"
                        },
                        {
                          title: "DevOps Consulting",
                          path: "/our-services/devops-consulting"
                        }
                      ]
                    } />
                </NavItem>
                <NavItem
                  animateSearchBar={animateSearchBar}
                  changeTextColor={changeTextColor}
                  onMouseEnter={() => setProductsMenuSelected(true)}
                  onMouseLeave={() => setProductsMenuSelected(false)}
                >
                  <LinkContainer style={{ cursor: 'pointer', fontWeight: productsMenuSelected ? "bold" : "normal" }} screenLocation={screenLocation}>
                    Products
                    &nbsp;
                    <KeyboardArrowDownIcon fill={screenLocation.pathname === '/' ? 'white' : 'black'} transform={productsMenuSelected ? 'rotate(0)' : 'rotate(180)'} />
                  </LinkContainer>
                  <HeaderDropdown
                    isOpen={productsMenuSelected}
                    closeDropdown={() => setProductsMenuSelected(false)}
                    backgroundColor={changeDropdownBgColor()}
                    textColor={changeDropdownTxtColor()}
                    stopScrollAnimation={stopScrollAnimation}
                    windowScrollPercentage={windowScrollPercentage}
                    isExternalUrl={true}
                    list={
                      [
                        {
                          title: (<b>E-Commerce & Inventory</b>),
                          description: "Professionally designed e-commerce website to grow your online store.",
                          icon: <EcommerceIcon width={40} height={40} fill={screenLocation.pathname === '/' ? 'white' : '#8000CF'} />,
                          path: "https://maplexxon.com/product/ecommerce-website"
                        },
                        {
                          title: (<b>Appointment and Scheduling Management</b>),
                          description: "Manage appointments and help your customers book services easily.",
                          icon: <AppointmentIcon width={40} height={40} fill={screenLocation.pathname === '/' ? 'white' : '#8000CF'} />,
                          path: "https://maplexxon.com/product/appointment-and-scheduling-management"
                        },
                        {
                          title: (<b>Premium Company Profile Site</b>),
                          description: "Present a professional website for your company or own brand.",
                          icon: <CompanyProfileIcon width={40} height={40} fill={screenLocation.pathname === '/' ? 'white' : '#8000CF'} />,
                          path: "https://maplexxon.com/product/company-profile-site"
                        }
                      ]
                    } />
                </NavItem>
                <NavItem animateSearchBar={animateSearchBar} changeTextColor={changeTextColor} >
                  <LinkContainer screenLocation={screenLocation}>
                    <Link to="/projects" style={{ color: changeTextColor(), textDecoration: "none" }}>
                      Projects
                    </Link>
                  </LinkContainer>
                </NavItem>
                <NavItem animateSearchBar={animateSearchBar} changeTextColor={changeTextColor}>
                  <LinkContainer screenLocation={screenLocation}>
                    <Link to="/blog" style={{ color: changeTextColor(), textDecoration: "none" }}>
                      Blog
                    </Link>
                  </LinkContainer>
                </NavItem>
                <NavItem animateSearchBar={animateSearchBar} changeTextColor={changeTextColor}>
                  <LinkContainer screenLocation={screenLocation}>
                    <Link to="/about" style={{ color: changeTextColor(), textDecoration: "none" }}>
                      About Us
                    </Link>
                  </LinkContainer>
                </NavItem>
                {/* <NavItem animateSearchBar={animateSearchBar} changeTextColor={changeTextColor}>
                <LinkContainer>
                  <Link to="/about" style={{ color: changeTextColor() }}>
                    Case Studies
                  </Link>
                </LinkContainer>
              </NavItem> */}
                <NavItem
                  animateSearchBar={animateSearchBar}
                  changeTextColor={changeTextColor}
                  onMouseEnter={() => setOurGroupMenuSelected(true)}
                  onMouseLeave={() => setOurGroupMenuSelected(false)}
                >
                  <LinkContainer style={{ cursor: 'pointer', fontWeight: ourGroupMenuSelected ? "bold" : "normal" }} screenLocation={screenLocation}>
                    Our Group
                    &nbsp;
                    <KeyboardArrowDownIcon fill={screenLocation.pathname === '/' ? 'white' : 'black'} transform={ourGroupMenuSelected ? 'rotate(0)' : 'rotate(180)'} />
                  </LinkContainer>
                  <HeaderDropdown
                    isOpen={ourGroupMenuSelected}
                    closeDropdown={() => setOurGroupMenuSelected(false)}
                    backgroundColor={changeDropdownBgColor()}
                    textColor={changeDropdownTxtColor()}
                    stopScrollAnimation={stopScrollAnimation}
                    windowScrollPercentage={windowScrollPercentage}
                    isExternalUrl={true}
                    list={
                      [
                        {
                          title: "Maplexxon - B2B Software Solutions",
                          path: "https://maplexxon.com/"
                        },
                        {
                          title: "ISA - ESG & Sustainability Consulting",
                          path: "https://www.sustainability.co.id/"
                        }
                      ]
                    } />
                </NavItem>
                <NavItem animateSearchBar={animateSearchBar} changeTextColor={changeTextColor}>
                  <LinkContainer screenLocation={screenLocation}>
                    <Link to="/contact-us" style={{ color: changeTextColor(), textDecoration: "none" }}>
                      Contact
                    </Link>
                  </LinkContainer>
                </NavItem>
                <NavItem animateSearchBar={animateSearchBar} changeTextColor={changeTextColor} style={{ fontWeight: 'bold' }}>
                  <RoundButton
                    action={() => window.open('https://wa.me/6281385898240')}
                    width='100%'
                    borderColor="white"
                    backgroundColor={changeHeaderBtnBgColor()}
                    hoverBgColor="white"
                    textColor="white"
                    hoverTextColor="#131A91"
                    radius='20px'
                    text='Consult With Us' />
                </NavItem>
                {/*
                <MagnifyContainer animateSearchBar={animateSearchBar} onClick={() => setAnimateSearchBar(true)} changeTextColor={changeTextColor}>
                  <Magnifier src={changeMagnifierIcon()} />
                  <SearchInputContainer animateSearchBar={animateSearchBar}>
                    <SearchInput
                      placeholder='Search...' 
                      type='text' 
                      value={searchInput} 
                      onChange={({ target: { value }}) => setSearchInput(value)}
                      changeTextColor={changeTextColor} />
                  </SearchInputContainer>
                  <ArrowRightIcon src={SearchArrowIcon} animateSearchBar={animateSearchBar} />
                </MagnifyContainer>
              */}
              </MediaQuery>
              {/* Render burger menu on small screen */}
              <MediaQuery maxWidth={1279}>
                <HamburgerContainer>
                  <Hamburger
                    toggled={isOpenMenu}
                    toggle={toggleCollapsableMenu}
                    size={25}
                    distance='lg'
                    rounded
                    color={changeHamburgerColor()} />
                </HamburgerContainer>
              </MediaQuery>
            </NavbarContainer>
          </FlexContainer>
        </Container>
      </Animated>
    </HeaderContainer>
  )

}

export default Header

const changeHeaderColor = keyframes`
  to {
    background-color: #4C39FF
  }
`
const HeaderContainer = styled.div`
  opacity: ${({ animateHeader }) => animateHeader ? 1 : 0};
  position: fixed;
  right: 0;
  left: 0;
  background-color: ${({ isOpenMenu, changeBackgroundColor }) => {
    if (isOpenMenu) {
      return '#4C39FF'
    } else {
      return changeBackgroundColor()
    }
  }};
  z-index: 5;
  transition: opacity 1s;
  transition-delay: .5s;
  animation: ${({ stopScrollAnimation }) => stopScrollAnimation()};
  animation-play-state: paused;
  animation-delay: ${({ windowScrollPercentage }) => `calc(${windowScrollPercentage} * -30s)`};
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  margin-top: ${({ mediumScreen }) => mediumScreen ? '0' : '40px'};
`
const FlexContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center
`
const LogoContainer = styled.div`
  height: auto;
  margin: 20px 0;
  cursor: pointer;
  width: ${({ mediumScreen }) => mediumScreen ? '160px' : '200px'}
`
const Logo = styled.img`  
  width: 100%;
  height: auto;
  object-fit: contain
`
const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center
`
const NavItem = styled.div`
  opacity: ${({ animateSearchBar }) => animateSearchBar ? 0 : 1};
  color: ${({ changeTextColor }) => changeTextColor()};
  padding: 10px;
  font-size: 18px;
  transition: opacity .2s
`
const LinkContainer = styled.div`
  margin: 20px 0;
  display: inline-block;
  text-decoration: none;
  :after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: ${({ screenLocation }) => screenLocation?.pathname === '/' ? 'white' : 'black'};
    transition: width 1s;
  }
  :hover:after {
    width: 100%;
  }
`
const MagnifyContainer = styled.div`
  width: ${({ animateSearchBar }) => animateSearchBar ? '210px' : '0'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ changeTextColor }) => changeTextColor()};
  margin: 0 10px 0 10px;
  font-size: 15px;
  transition: width .2s;
  transition-delay: .2s;
  border-bottom: ${({ changeTextColor }) => '1px solid ' + changeTextColor()}
`
const Magnifier = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 10px;
  cursor: pointer
`
const SearchInputContainer = styled.div`
  opacity: ${({ animateSearchBar }) => animateSearchBar ? 1 : 0};
  transition: 0;
  transition-delay: .3s;
  padding-bottom: 2px
`
const SearchInput = styled.input`
  background-color: transparent;
  border: none;
  outline: none;
  color: ${({ changeTextColor }) => changeTextColor()};
`
const ArrowRightIcon = styled.img`
  opacity: ${({ animateSearchBar }) => animateSearchBar ? 1 : 0};
  width: 12px;
  height: 12px;
  cursor: pointer;
  transition: opacity .2s
`
const HamburgerContainer = styled.div`
  padding-top: 5px
`