import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
  XIcon,
} from "react-share";
import { Container, Row, Col } from 'react-bootstrap'
import CloudxierLogoSquare from '../assets/images/logo/Cloudxier-logofull-colored-05.png'
import { Animated } from "react-animated-css";
import VisibilitySensor from 'react-visibility-sensor'
// import BackIcon from '../assets/images/icons/back_icon.svg'

// Component
import HeadMeta from '../components/HeadMeta'
import BlogCard from '../components/BlogCards'

import { device } from '../helpers/device'
import SectionHeading from '../components/headings/SectionHeading'
import Loader from '../components/loader/LoadingSpinner'

import { getBlogDetails } from '../redux/actions/blog_action'

function BlogDetails() {
  const history = useHistory()
  const params = useParams()
  const smallScreen = useMediaQuery({ query: '(max-width: 1023px)' })
  const location = useLocation();
  const { blog, relatedBlog, loading } = useSelector((state) => state.blogs);
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getBlogDetails(params && params.id))
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [location.search])

  const renderHtml = (htmlObject) => { return <PostBodyText dangerouslySetInnerHTML={htmlObject} /> };

  const [isContent1Visible, setContent1Visible] = useState(false)
  const [isContent2Visible, setContent2Visible] = useState(false)
  const [isContent3Visible, setContent3Visible] = useState(false)
  const [isContent4Visible, setContent4Visible] = useState(false)
  const [isContent5Visible, setContent5Visible] = useState(false)

  const url = window.location.href;
  const host = window.location.host

  const [isCopyToClipBoard, setIsCopyToClipBoard] = useState(false)

  const copyToClipboard = () => {
    navigator.clipboard.writeText(url)
    setIsCopyToClipBoard(true)
  }

  const handleShowBlogDetail = (id) => {
    history.push(`/blog/details/${id}`)
  }

  return (
    <MainContainer>
      <HeadMeta
        title={blog.title}
        description={blog.summary}
        siteName="Cloudxier"
        url='https://www.cloudxier.com/blog/details'
        image={CloudxierLogoSquare}
      />
      <Container style={{ padding: smallScreen ? '0 16px' : '0 0 50px 0' }}>
        <VisibilitySensor onChange={(isVisible) => setContent1Visible(isVisible)} active={!isContent1Visible} partialVisibility={true}>
          <Animated animationIn="fadeInDown" animationInDuration={2000} isVisible={isContent1Visible}>
            <BackLink href="/blog">
              {/* <BackButtonIcon src={BackIcon} alt="back" /> */}
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8" />
              </svg>
              <BackButtonText>
                Back to Blog Page
              </BackButtonText>
            </BackLink>
          </Animated>
        </VisibilitySensor>
        {
          loading ? (
            <Loader show={loading} />
          ) : (
            <Post>
              <VisibilitySensor onChange={(isVisible) => setContent1Visible(isVisible)} active={!isContent1Visible} partialVisibility={true}>
                <Animated animationIn="fadeInDown" animationInDuration={2000} isVisible={isContent1Visible}>
                  <Row>
                    <Col lg={9}>
                      <PostTitle>
                        {blog.title}
                      </PostTitle>
                    </Col>
                    <Col lg={3}>
                      <PostDate>Posted on {blog["Posted On"]}</PostDate>
                    </Col>
                  </Row>
                  <hr />
                  <SocialMediaContainer className='mb-5'>
                    <span className='mr-3'>Share to : </span>
                    <div className='mr-3 d-flex flex-column align-items-center'>
                      <SocialMedia onClick={copyToClipboard} className='mb-auto'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-link-45deg" viewBox="0 0 16 16">
                          <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1 1 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4 4 0 0 1-.128-1.287z" />
                          <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243z" />
                        </svg>
                      </SocialMedia>
                      {
                        isCopyToClipBoard && (
                          <p>Copied!</p>
                        )
                      }
                    </div>
                    <SocialMedia className='mr-3'>
                      <FacebookShareButton
                        url={url}
                      >
                        <FacebookIcon size={32} round />
                      </FacebookShareButton>
                    </SocialMedia>
                    <SocialMedia className='mr-3'>
                      <TwitterShareButton
                        url={url}
                        title={blog && blog.title || ''}
                      >
                        <XIcon size={32} round />
                      </TwitterShareButton>
                    </SocialMedia>
                    <SocialMedia className='mr-3'>
                      <LinkedinShareButton
                        url={url}
                        source='cloudxier.com'
                        title={blog && blog.title || ''}
                      >
                        <LinkedinIcon size={32} round />
                      </LinkedinShareButton>
                    </SocialMedia>
                  </SocialMediaContainer>
                </Animated>
              </VisibilitySensor>
              <VisibilitySensor onChange={(isVisible) => setContent2Visible(isVisible)} active={!isContent2Visible} partialVisibility={true}>
                <Animated animationIn="zoomIn" animationInDuration={2000} isVisible={isContent2Visible}>
                  <BannerPost src={blog && blog.img_cover} alt='banner-post' />
                </Animated>
              </VisibilitySensor>
              <VisibilitySensor onChange={(isVisible) => setContent3Visible(isVisible)} active={!isContent3Visible} partialVisibility={true}>
                <Animated animationIn="fadeInUp" animationInDuration={2000} isVisible={isContent3Visible}>
                  <PostBody>
                    {

                      renderHtml({ __html: blog?.content })
                    }
                  </PostBody>
                </Animated>
              </VisibilitySensor>
              <VisibilitySensor onChange={(isVisible) => setContent4Visible(isVisible)} active={!isContent4Visible} partialVisibility={true}>
                <Animated animationIn="slideInLeft" animationInDuration={3000} isVisible={isContent4Visible}>
                  <PostCategoryText>TAGS:</PostCategoryText>
                  <PostCategoryContainer>
                    {
                      blog && blog.blogcategory && blog.blogcategory.length > 0 && blog.blogcategory.map((res, i) => {
                        return (
                          <PostCategory className='text-capitalize'>{res && res.category && res.category.category_name}</PostCategory>
                        )
                      })
                    }
                  </PostCategoryContainer>
                </Animated>
              </VisibilitySensor>
            </Post>
          )
        }
        {
          relatedBlog && relatedBlog.length > 0 && (
            <div>
              <VisibilitySensor onChange={(isVisible) => setContent5Visible(isVisible)} active={!isContent5Visible} partialVisibility={true}>
                <Animated animationIn="fadeInUp" animationInDuration={2000} isVisible={isContent5Visible}>
                  <SectionHeading
                    title='Related Blog'
                  />
                  <BlogCard
                    blogList={relatedBlog}
                    handleShowBlogDetail={handleShowBlogDetail}
                  />
                </Animated>
              </VisibilitySensor>
            </div>
          )
        }
      </Container>
    </MainContainer>
  )
}

export default BlogDetails

const MainContainer = styled.div`
  overflow: hidden;
  padding-top: 90px;
  @media ${device.tablet} {
    padding-top: 100px;
  }
  @media ${device.laptop} {
    padding-top: 100px;
  }
  @media ${device.laptopM} {
    padding-top: 150px;
  }
  @media ${device.laptopL} {
    padding-top: 150px;
  }
`

const BackLink = styled.a`
  display: flex;
  align-items: center;
  gap: 12px;
  color: black;
  :hover {
    color: black;
    text-decoration: none;
  }
  @media ${device.tablet} {
    gap: 16px;
  }
  @media ${device.laptop} {
    gap: 16px;
  }
  @media ${device.laptopM} {
    gap: 16px;
  }
  @media ${device.laptopL} {
    gap: 16px;
  }
`
const BackButtonIcon = styled.img`
  width: 18px;
  height: 18px;
  @media ${device.laptop} {
    width: 25px;
    height: 25px;
  }
`
const BackButtonText = styled.div`
  font-size: 16px;
`
const BannerPost = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  aspect-ratio: 16 / 9;
`
const Post = styled.div`
  width: 100%;
  padding: 30px 0 0 0;
  margin-bottom: 30px;
  @media ${device.tablet} {
    padding: 30px 0 0 0;
    margin-bottom: 30px;
  }
  @media ${device.laptop} {
    padding: 30px 0 0 0;
    margin-bottom: 30px;
  }
  @media ${device.laptopM} {
    padding: 30px 0 0 0;
    margin-bottom: 30px;
  }
  @media ${device.laptopL} {
    padding: 30px 0 0 0;
    margin-bottom: 30px;
  }
`
const PostHeading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  @media ${device.laptop} {
    display: flex;
    flex-direction: row;
  }
`
const PostTitle = styled.span`
  color: #336AFE;
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  width: 100%;
  margin-bottom: 8px;
  @media ${device.tablet} {
    font-size: 35px;
    line-height: 50px;
    max-width: 500px;
  }
  @media ${device.laptop} {
    font-size: 40px;
    line-height: 50px;
    max-width: 500px;
  }
  @media ${device.laptopM} {
    font-size: 45px;
    line-height: 60px;
    max-width: 500px;
  }
  @media ${device.laptopL} {
    font-size: 45px;
    line-height: 60px;
    max-width: 500px;
  }
`
const PostDate = styled.div`
  font-size: 16px;
  color: #979797;
  margin-bottom: 8px;
  @media ${device.tablet} {
    font-size: 18px;
  }
  @media ${device.laptop} {
    margin-top: 22px;
    margin-bottom: 0px;
    text-align: right;
    font-size: 18px;
  }
  @media ${device.laptopM} {
    margin-top: 32px;
    margin-bottom: 0px;
    text-align: right;
    font-size: 18px;
  }
  @media ${device.laptopL} {
    margin-top: 32px;
    margin-bottom: 0px;
    text-align: right;
    font-size: 18px;
  }
`
const PostCategoryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  @media ${device.tablet} {
    margin-bottom: 48px;
  }
  @media ${device.laptop} {
    margin-bottom: 48px;
  }
  @media ${device.laptopM} {
    margin-bottom: 48px;
  }
  @media ${device.laptopL} {
    margin-bottom: 48px;
  }
`
const PostCategoryText = styled.div`
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 20px;
  text-align: left;
  letter-spacing: 2px;
  @media ${device.tablet} {
    font-size: 17px;
    margin-bottom: 20px;
    letter-spacing: 3px;
  }
  @media ${device.laptop} {
    font-size: 18px;
    margin-bottom: 30px;
    letter-spacing: 3px;
  }
  @media ${device.laptopM} {
    font-size: 18px;
    margin-bottom: 30px;
    letter-spacing: 3px;
  }
  @media ${device.laptopL} {
    font-size: 18px;
    margin-bottom: 30px;
    letter-spacing: 3px;
  }
`
const PostCategory = styled.span`
  color: black;
  border-radius: 20px;
  border: 2px solid black;
  padding: 5px 15px;
  margin-right: 15px;
  margin-bottom: 20px;
  font-size: 14px;
  @media ${device.tablet} {
    font-size: 15px;
    margin-right: 15px;
    margin-bottom: 20px;
  }
  @media ${device.laptop} {
    font-size: 16px;
    margin-right: 15px;
    margin-bottom: 20px;
  }
  @media ${device.laptopM} {
    font-size: 17px;
    margin-right: 15px;
    margin-bottom: 20px;
  }
  @media ${device.laptopL} {
    font-size: 17px;
    margin-right: 15px;
    margin-bottom: 20px;
  }
`
const PostBody = styled.article`
  margin-bottom: 30px;
  overflow: auto;
  @media ${device.tablet} {
    margin-bottom: 40px;
  }
  @media ${device.laptop} {
    margin-bottom: 40px;
  }
  @media ${device.laptopM} {
    margin-bottom: 40px;
  }
  @media ${device.laptopL} {
    margin-bottom: 40px;
  }
`

const PostBodyText = styled.p`
  color: black;
  font-size: 18px;
`

const SocialMediaContainer = styled.div`
  display: flex;
  justify-content: end;
`

const SocialMedia = styled.a`
  color: black;
  :hover {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
`