// React
import { useEffect, useState } from 'react'

// Styled components
import styled from 'styled-components'

// React router dom
import { useLocation, useHistory } from 'react-router-dom'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Animated
import { Animated } from "react-animated-css";

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

// Images
import ProjectBanner from '../assets/images/projects/shooper/2-cloudxier-shooper-highlight03.jpg'

// Components
import HeadMeta from '../components/HeadMeta'
import ActionBanner from '../components/banners/ActionBanner'
import ProjectHeading from '../components/headings/ProjectHeading'
import ProjectDetailsColumn from '../components/ProjectDetailsColumn'
import SquareButton from '../components/buttons/SquareButton'

// React-bootstrap
import Container from 'react-bootstrap/Container'

// Helpers
import fontSize from '../helpers/fontSize'

// React-redux
import { connect } from 'react-redux'

const mapStateToProps = state => ({
  projects: state.projects.projects
})

function ProjectDetails({ projects }) {

  const query = new URLSearchParams(useLocation().search)

  const smallScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 1279px)' })

  const [isContent1Visible, setContent1Visible] = useState(false)
  const [isContent2Visible, setContent2Visible] = useState(false)

  const history = useHistory()

  // Scroll window to top on load
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])

  useEffect(() => {
    // TODO: get project by ID using query here
    console.log(query.get("id"))
  }, [])

  return (
    <div>
      <HeadMeta
        title='Cloudxier - Our Projects'
        description="Projects created by Cloudxier"
        siteName="Cloudxier"
        url={`https://www.cloudxier.com/projects/details?id=${query.get('id')}`}
        image={ProjectBanner} />
      {
        projects.map((project) => {
          if (project.id.toString() === query.get("id")) {
            return (
              <div style={{ overflow: 'hidden', paddingTop: smallScreen ? '100px' : mediumScreen ? '130px' : '150px' }}>
                <VisibilitySensor onChange={(isVisible) => setContent1Visible(isVisible)} active={!isContent1Visible} partialVisibility={true}>
                  <Animated animationIn="fadeInDown" animationInDuration={2000} isVisible={isContent1Visible}>
                    <Container>
                      <ProjectHeading
                        title={project.category}
                        subtitle={project.title}
                        subtitleColor="#131A91"
                      />
                    </Container>
                  </Animated>
                </VisibilitySensor>
                {
                  project.features.map((feature, index) => {
                    return (
                      <ProjectDetailsColumn
                        isMediaLeft={index % 2 ? false : true}
                        imageUrl={feature.image}
                        text={feature.text}
                        textSize={
                          feature.isHeading ?
                            smallScreen ?
                              fontSize.heading1Sm
                              :
                              mediumScreen ?
                                fontSize.heading3Lg
                                :
                                fontSize.heading2Lg
                            :
                            smallScreen ?
                              '16px'
                              :
                              mediumScreen ?
                                '18px'
                                :
                                fontSize.bodyLg
                        } />
                    )
                  })
                }
                <VisibilitySensor onChange={(isVisible) => setContent2Visible(isVisible)} active={!isContent2Visible} partialVisibility={true}>
                  <Animated animationIn="fadeInUp" animationInDuration={2000} isVisible={isContent2Visible}>
                    <ActionButtonContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
                      <SquareButton
                        action={() => history.push('/projects')}
                        text='See other projects'
                        textSize={smallScreen ? '16px' : mediumScreen ? '18px' : fontSize.bodyLg}
                        textColor='#131A91'
                        hoverTextColor='white'
                        backgroundColor='transparent'
                        hoverBgColor='#131A91'
                        width={smallScreen ? '225px' : mediumScreen ? '250px' : '275px'}
                        border='1px solid #131A91' />
                    </ActionButtonContainer>
                  </Animated>
                </VisibilitySensor>
                <ActionBanner
                  action={() => history.push('/contact-us')}
                  text='Ready to accelerate digital innovation in your business?'
                  actionText='Request a quote'
                />
              </div>
            )
          }
        })
      }
    </div>
  )

}

export default connect(mapStateToProps, null)(ProjectDetails)

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '10px 0 30px 0' : mediumScreen ? '10px 0 30px 0' : '30px 0 50px 0'}
`
