import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import { useMediaQuery } from 'react-responsive'
import { Animated } from "react-animated-css";
import VisibilitySensor from 'react-visibility-sensor';

import HeadMeta from '../components/HeadMeta'
import SectionHeading from '../components/headings/SectionHeading'

import CloudxierLogoSquare from '../assets/images/logo/Cloudxier-logofull-colored-05.png'

import fontSize from '../helpers/fontSize'
import { device } from '../helpers/device'

function SustainabilityPolicy() {
  const smallScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 1023px)' })

  const [isContent1Visible, setContent1Visible] = useState(false)
  const [isContent2Visible, setContent2Visible] = useState(false)
  const [isContent3Visible, setContent3Visible] = useState(false)

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])

  return (
    <MainContainer>
      <HeadMeta
        title='Cloudxier - Sustainability Policy'
        description="Cloudxier sustainability policy"
        siteName="Cloudxier"
        url='https://www.cloudxier.com/sustainability-policy/'
        image={CloudxierLogoSquare}
      />
      <Container style={{ padding: smallScreen ? '0 5vw 30px 5vw' : mediumScreen ? '0 5vw 50px 5vw' : '0 5vw 50px 5vw' }}>
        <VisibilitySensor onChange={(isVisible) => setContent1Visible(isVisible)} active={!isContent1Visible} partialVisibility={true}>
          <Animated animationIn="fadeInDown" animationInDuration={2000} isVisible={isContent1Visible}>
            <SectionHeading
              title='Sustainability Policy Statement'
              subtitle=''
            />
            <BodyText>
              <i>Updated as per May 30, 2024</i>
            </BodyText>
            <BodyText>
              This policy applies to the Cloudxier - PT Cendekia Edukasi Citra Tritunggal including its employees, associates, facilitators, communities, and third parties who undertake activity for and on behalf of Cloudxier - PT Cendekia Edukasi Citra Tritunggal . It applies to the goods and services we procure, our direct operations and the services we provide to our customers and partners. Cloudxier - PT Cendekia Edukasi Citra Tritunggal  is committed to creating a sustainable society actively managing our operations in a way that balances our social, environmental, economic, and well-being objectives. To do this, sustainability considerations are tied throughout a suite of interdependent policies and their procedures, which are implemented collectively in order to deliver the objectives of the Sustainability Policy.
            </BodyText>
          </Animated>
        </VisibilitySensor>
        <VisibilitySensor onChange={(isVisible) => setContent2Visible(isVisible)} active={!isContent2Visible} partialVisibility={true}>
          <Animated animationIn="fadeInLeft" animationInDuration={2000} isVisible={isContent2Visible}>
            <Heading>
              Purpose
            </Heading>
            <BodyText>
              To provide an effective framework for Cloudxier - PT Cendekia Edukasi Citra Tritunggal ’s commitment to creating a sustainable society by protecting and where possible enhancing the social, environmental, well-being and economic impacts, empowering partnership for the goals, providing quality education, and driving sustainable economic development for a better world.
            </BodyText>
          </Animated>
        </VisibilitySensor>
        <VisibilitySensor onChange={(isVisible) => setContent3Visible(isVisible)} active={!isContent3Visible} partialVisibility={true}>
          <Animated animationIn="fadeInRight" animationInDuration={2000} isVisible={isContent3Visible}>
            <Heading>
              Policy
            </Heading>
            <BodyText>
              Cloudxier - PT Cendekia Edukasi Citra Tritunggal is a tech and software development company based in Jakarta, Indonesia. Offering end-to-end technology consulting services from Product ideation, Cloud development to DevOps engineering we help our clients drive growth.
            </BodyText>
            <BodyText>
              In recognising this, Cloudxier - PT Cendekia Edukasi Citra Tritunggal is committed in contributing to a more sustainable society and to continually improve and build positive impacts by ensuring:
            </BodyText>
            <BodyText>
              <ul>
                <li>Compliance with all relevant legislation and standards, and the expectations of our customers, partners and other key stakeholders;</li>
                <li>Continually to develop our products and services to enhance the sustainability initiatives for our customers, partners and other key stakeholders;</li>
                <li>Continually review how we can make improvements to our sustainability performance;</li>
                <li>Operate sustainably and strive to reduce waste, prevent pollution, and minimize resource consumption in all of our systems and procedures;</li>
                <li>Continually to encourage our employees, customers, partners and other key stakeholders to support our sustainability commitments;</li>
                <li>Continually educate, inspire and engage employees on implementing sustainability at home, work, and throughout their communities through a combination of employee communications, learning sessions, and hands-on opportunities;</li>
                <li>Continually to create healthy, collaborative and innovative work environments for our key stakeholders through sustainable operations and certifications whenever possible;</li>
                <li>Report results of our sustainability program to our employees, customers, partners and communities and other key stakeholders on an annual basis;</li>
                <li>Continually improve tracking of sustainability metrics throughout our operations.</li>
              </ul>
            </BodyText>
            <BodyText>
              To implement our sustainability objectives Cloudxier - PT Cendekia Edukasi Citra Tritunggal recognises the need for and is committed to communicating these objectives to our employees, customers, partners and communities and other key stakeholders.
            </BodyText>
            <BodyText>
              This is achieved via our procurement processes, and our existing communication channels to employees including the business plan, newsletters, team briefings.
            </BodyText>
            <BodyText>
              This policy and the actions arising from it will be annually reviewed as part of the business planning process which involves Cloudxier - PT Cendekia Edukasi Citra Tritunggal’s leadership and management teams, with final approval via the Cloudxier - PT Cendekia Edukasi Citra Tritunggal Board of Directors and Board of Commissioners.
            </BodyText>
          </Animated>
        </VisibilitySensor>
      </Container>
    </MainContainer>
  )
}

export default SustainabilityPolicy;

const MainContainer = styled.div`
  overflow: hidden;
  padding-top: 80px;
  @media ${device.tablet} {
    padding-top: 90px;
  }
  @media ${device.laptop} {
    padding-top: 90px;
  } 
  @media ${device.laptopM} {
    padding-top: 150px;
  } 
  @media ${device.laptopL} {
    padding-top: 150px;
  }
`
const Heading = styled.div`
  font-size: ${fontSize.bodyLg};
  color: black;
  font-weight: 700;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 12px;
  @media ${device.tablet} {
    font-size: ${fontSize.heading1Sm};
    margin-top: 48px;
    margin-bottom: 24px;
  }
  @media ${device.laptop} {
    font-size: ${fontSize.heading3Lg};
    margin-top: 48px;
    margin-bottom: 24px;
  }
  @media ${device.laptopM} {
    font-size: ${fontSize.heading3Lg};
    margin-top: 48px;
    margin-bottom: 24px;
  }
  @media ${device.laptopL} {
    font-size: ${fontSize.heading2Lg};
    margin-top: 48px;
    margin-bottom: 24px;
  }
`
const SubHeading = styled.p`
  font-size: ${fontSize.heading3Sm};
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 8px;
  @media ${device.tablet} {
    font-size: ${fontSize.bodyLg};
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptop} {
    font-size: 23px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopM} {
    font-size: 23px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopL} {
    font-size: 26px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
`
const BodyText = styled.p`
  text-align:justify;
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 8px;
  @media ${device.tablet} {
    font-size: 18px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptop} {
    font-size: ${fontSize.bodyLg};
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopM} {
    font-size: ${fontSize.bodyLg};
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopL} {
    font-size: 22px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
`