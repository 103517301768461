// React responsive
import { useMediaQuery } from 'react-responsive'

// Bootstrap
import Container from 'react-bootstrap/Container'

// Components
import FeatureColumn from './FeatureColumn'

function WhyChooseUs({
  fromLandingPage
}) {

  return (
    <Container>
      <FeatureColumn
        fromLandingPage={fromLandingPage}
      />
    </Container>
  )

}

export default WhyChooseUs