// React helmet
// React helmet
import { Helmet } from 'react-helmet'

function HeadMeta (props) {

  return (
    <Helmet>
      <title>{props.title}</title>
      <meta name="description" content={props.description} />
      <meta property="og:type" content={props.type || 'website'} />
      <meta name="og:title" property="og:title" content={props.title} />
      <meta name="og:description" property="og:description" content={props.description} />
      <meta property="og:site_name" content={props.siteName} />
      <meta property="og:url" content={props.url} />  
      <meta name="twitter:card" content="summary" /> 
      <meta name="twitter:title" content={props.title} />
      <meta name="twitter:description" content={props.description} />
      <meta property="og:image" content={props.image} />  
      <meta name="twitter:image" content={props.image} />
    </Helmet>
  )

}

export default HeadMeta