import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import { useMediaQuery } from 'react-responsive'
import { Animated } from "react-animated-css";
import VisibilitySensor from 'react-visibility-sensor';

import HeadMeta from '../components/HeadMeta'
import SectionHeading from '../components/headings/SectionHeading'

import CloudxierLogoSquare from '../assets/images/logo/Cloudxier-logofull-colored-05.png'

import fontSize from '../helpers/fontSize'
import { device } from '../helpers/device'

function PrivacyPolicy() {
  const smallScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 1023px)' })

  const [isContent1Visible, setContent1Visible] = useState(false)
  const [isContent2Visible, setContent2Visible] = useState(false)
  const [isContent3Visible, setContent3Visible] = useState(false)
  const [isContent4Visible, setContent4Visible] = useState(false)
  const [isContent5Visible, setContent5Visible] = useState(false)
  const [isContent6Visible, setContent6Visible] = useState(false)
  const [isContent7Visible, setContent7Visible] = useState(false)
  const [isContent8Visible, setContent8Visible] = useState(false)
  const [isContent9Visible, setContent9Visible] = useState(false)
  const [isContent10Visible, setContent10Visible] = useState(false)
  const [isContent11Visible, setContent11Visible] = useState(false)

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])

  return (
    <MainContainer>
      <HeadMeta
        title='Cloudxier - Privacy Policy'
        description="Cloudxier privacy policy"
        siteName="Cloudxier"
        url='https://www.cloudxier.com/privacy-policy/'
        image={CloudxierLogoSquare}
      />
      <Container style={{ padding: smallScreen ? '0 5vw 30px 5vw' : mediumScreen ? '0 5vw 50px 5vw' : '0 5vw 50px 5vw' }}>
        <VisibilitySensor onChange={(isVisible) => setContent1Visible(isVisible)} active={!isContent1Visible} partialVisibility={true}>
          <Animated animationIn="fadeInDown" animationInDuration={2000} isVisible={isContent1Visible}>
            <SectionHeading
              title='Privacy Policy Statement'
              subtitle=''
            />
            <BodyText>
              Cloudxier - PT Cendekia Edukasi CItra Tritunggal values privacy and is committed to protecting your personal information.
            </BodyText>
            <BodyText>
              We collect your personal information in a number of different ways and for different purposes. How and what we collect and how we manage your information is fully set out in the privacy statement below.
            </BodyText>
            <BodyText>
              <b>Cloudxier</b> is a subsidiary brand <b>under the legal entity of PT CENDEKIA EDUKASI CITRA TRITUNGGAL</b> by guarantee with its registered address at AKR Tower 16th Floor Jl. Panjang no.5, Jakarta Barat - Indonesia, 11530 (“Cloudxier”), operating as an Indonesia-based company in Software and Technology Company.
            </BodyText>
            <BodyText>
              We are committed to respecting your privacy and protecting your personal data, which is any information that is capable of identifying you as an individual person. This Privacy Policy describes how we handle and protect your personal data in connection with Cloudxier's business activities, like webinars and events, and client services, and on websites and communications that post a link to this Privacy Policy (collectively, “the Website”), in our capacity as data controllers.
            </BodyText>
          </Animated>
        </VisibilitySensor>
        <VisibilitySensor onChange={(isVisible) => setContent2Visible(isVisible)} active={!isContent2Visible} partialVisibility={true}>
          <Animated animationIn="fadeInLeft" animationInDuration={2000} isVisible={isContent2Visible}>
            <Heading>
              Use of Information
            </Heading>
            <BodyText>
              Cloudxier uses your personal data to fulfill your requests for information, process your requests to participate in webinars and events, personalize content that you view or receive on the Sites, evaluate and improve our services, distribute newsletters to you, analyze the Website's performance and functioning, prevent fraud, enforce our terms of use, comply with all applicable laws and corporate reporting obligations, enforce Cloudxier's agreements, and accomplish other purposes you may initiate or request.
            </BodyText>
            <BodyText>
              The collection of personal data may be required for the operation of the Website, or we may use it in the course of our business activities, including in connection with some client services, for example, to provide certain services or benchmarking our products.
            </BodyText>
            <BodyText>
              We may combine and/or analyze personal data to evaluate and offer content and services most relevant to you. We may keep any of your personal data on file and use it to contact you.
            </BodyText>
            <BodyText>
              Cloudxier and its service providers may use first and third party Cookies and Other Tracking Technologies, including web beacons, to manage our website and our services and collect analytics about how you use them. Cloudxier and its service providers may collect information about whether you open or click any links in the knowledge, research or event communications that we send to you. The information provided throughout this Privacy Policy about cookies also applies to these other tracking technologies. Please refer to our Cookie Policy for more details regarding our use of Cookies and Other Tracking Technologies.
            </BodyText>
            <BodyText>
              Our Sites do not track you by collecting personal data about your online activities over time and across third party websites or online services. Accordingly, we do not alter our data collection and use practices in response to “do not track” signals transmitted from web browsers.
            </BodyText>
          </Animated>
        </VisibilitySensor>
        <VisibilitySensor onChange={(isVisible) => setContent3Visible(isVisible)} active={!isContent3Visible} partialVisibility={true}>
          <Animated animationIn="fadeInRight" animationInDuration={2000} isVisible={isContent3Visible}>
            <Heading>
              What Data We Collect
            </Heading>
            <BodyText>
              If you visit our website, the data we collect from you are as follows:
              <ul>
                <li>IP address</li>
                <li>cookies ID (identification) tags</li>
                <li>personal information includes, but is not limited to: name, email address, date of birth, occupation, phone number, gender</li>
              </ul>
            </BodyText>
            <BodyText>
              IP address is a numerical label assigned to each device connected to the Internet Protocol for communication and required to show you websites, images, files and other information on the Internet. IP address typically can be used to find out the location of your device, generally down to the city level. In case of business IP address, it can be matched with a business name and street address.
            </BodyText>
            <BodyText>
              Cookies are small text files that are sent from a website and stored on your computer or other device by your web browser when you visit a site. Cookies are created when you visit a website that uses cookies to recognize you, keep track of your interaction with the site, remember your preferences and make all site's functions available for you.
            </BodyText>
            <BodyText>
              Cookies, among other information, typically contain ID tags. A website that uses cookies stores a file with same ID tags corresponding to cookies placed on your computer. When you visit the site again, your browser sends the relevant cookies from your computer to the website. Using cookies ID tags, website, for instance, can recognize you as a returning visitor and apply your preferences automatically.
            </BodyText>
            <SubHeading>
              How We Collect Data
            </SubHeading>
            <BodyText>
              IP address of your networked device is automatically captured whenever a connection is made over the Internet regardless of your consent.
            </BodyText>
            <BodyText>
              This website uses cookies and requests your consent before placing any persistent cookies on your computer. You can accept our website's cookies by clicking “Accept” button in the cookie consent banner that pops up at the bottom of the website's page you open.
            </BodyText>
            <SubHeading>
              How We Store Data
            </SubHeading>
            <BodyText>
              IP addresses and cookies ID tags are logged automatically and kept in the relevant log files.
            </BodyText>
            <SubHeading>
              How We Use Data
            </SubHeading>
            <BodyText>
              We use IP addresses of our website's visitors for security reasons, particularly to identify malicious site visits and hacking attempts, to protect the website's data and traffic and make it safer for anyone visiting and using the website. Thus, we collect and use IP addresses of our website's visitors based on legitimate interest.
            </BodyText>
            <BodyText>
              We will not disclose IP addresses of the website's visitors to any third party except where required to do so by law.
            </BodyText>
            <BodyText>
              We use cookies ID tags to recognize you, keep track of your interaction with the site, remember your preferences and make all site's functions available for you. Since we may use third-party service providers to track and report websites traffic, those third-party service providers may place their own cookies on your computer and use the relevant cookies ID tags to measure and analyze information of your visits.
            </BodyText>
            <BodyText>
              We collect and use cookies ID tags based on your acceptance of the website's cookies. For more information about how we use cookies, how you can accept, manage or withdraw you consent for use of cookies please check our Cookie Notice.
            </BodyText>
          </Animated>
        </VisibilitySensor>
        <VisibilitySensor onChange={(isVisible) => setContent4Visible(isVisible)} active={!isContent4Visible} partialVisibility={true}>
          <Animated animationIn="fadeInLeft" animationInDuration={2000} isVisible={isContent4Visible}>
            <Heading>
              Protection of Personal Data
            </Heading>
            <BodyText>
              To safeguard your personal data from unauthorized access, collection, use, disclosure, copying, modification, disposal or similar risks, we have introduced appropriate administrative, physical and technical measures such as up-to-date antivirus protection, encryption and the use of privacy filters to secure all storage and transmission of personal data by us, and disclosing personal data both internally and to our authorized third party service providers and agents only on a need-to-know basis.
            </BodyText>
            <BodyText>
              You should be aware, however, that no method of transmission over the Internet or method of electronic storage is completely secure. While security cannot be guaranteed, we strive to protect the security of your information and are constantly reviewing and enhancing our information security measures.
            </BodyText>
          </Animated>
        </VisibilitySensor>
        <VisibilitySensor onChange={(isVisible) => setContent5Visible(isVisible)} active={!isContent5Visible} partialVisibility={true}>
          <Animated animationIn="fadeInRight" animationInDuration={2000} isVisible={isContent5Visible}>
            <Heading>
              Sharing of Personal Information Which We Collect
            </Heading>
            <BodyText>
              We may disclose to, allow access to or share with affiliates and other parties your Personal Information for any of the following purposes as well as for such other purposes as are permitted by applicable law:
              <ul>
                <li>where required or authorised by applicable law (including but not limited to responding to regulatory enquiries, investigations or directives, or complying with statutory or regulatory filing and reporting, and licensing requirements), for the purpose so specified in that applicable law;</li>
                <li>where instructed, requested, required or authorised by the government authorities, for the purpose as specified in the government policy, regulations or applicable law;</li>
                <li>where there is any form of legal proceeding between you and us, or between you and another party, in connection with, or relating to the services, for the purposes of that legal proceeding;</li>
                <li>where we enable our services in third parties' platform, (i) to assist us in obtaining your Personal Information and/or (ii) to register you or allow you to use our services from such platforms.</li>
                <li>to third parties (including our agents, vendors, suppliers, partners and any others who provide services to us or you, perform functions on our behalf, or whom we enter into commercial collaboration with) for or in connection with the purposes for which such third parties are engaged, to perform certain disclosure to the relevant third parties which are technically required to process your transaction or for the purposes of our collaboration with such third parties (as the case may be), which may include allowing such third parties to introduce or offer products or services to you, authenticate you or connect with your Account, or conducting other activities including marketing, research, analysis and product development; and</li>
                <li>where we share Personal Information with affiliates, we will do so for the purpose of them helping us to to operate our business (including, where you have subscribed to our mailing list, for direct marketing purposes), or for the purpose of them conducting data processing on our behalf. All of our affiliates are committed to processing the Personal Information that they receive from us in line with this Privacy Policy and applicable law.</li>
              </ul>
            </BodyText>
          </Animated>
        </VisibilitySensor>
        <VisibilitySensor onChange={(isVisible) => setContent6Visible(isVisible)} active={!isContent6Visible} partialVisibility={true}>
          <Animated animationIn="fadeInLeft" animationInDuration={2000} isVisible={isContent6Visible}>
            <Heading>
              Retention of Personal Data
            </Heading>
            <BodyText>
              We may retain your personal data for as long as it is necessary to fulfill the purpose for which it was collected, or as required or permitted by applicable laws.
            </BodyText>
            <BodyText>
              We will cease to retain your personal data, or remove the means by which the data can be associated with you, as soon as it is reasonable to assume that such retention no longer serves the purpose for which the personal data was collected, and is no longer necessary for legal or business purposes.
            </BodyText>
          </Animated>
        </VisibilitySensor>
        <VisibilitySensor onChange={(isVisible) => setContent7Visible(isVisible)} active={!isContent7Visible} partialVisibility={true}>
          <Animated animationIn="fadeInRight" animationInDuration={2000} isVisible={isContent7Visible}>
            <Heading>
              Your Personal Data Protection Rights
            </Heading>
            <BodyText>
              We would like to make sure you are fully aware of your data protection rights.
            </BodyText>
            <BodyText>
              <b>The right to withdraw.</b> You have the right to recall the consent at any time. But please note that it does not affect the possibility to continue processing your personal data based on your consent, which was given prior to its recall.
            </BodyText>
            <BodyText>
              <b>The right to access.</b> You have the right to request from us for copies of your personal data, under certain conditions. We may charge you a fee for this service.
            </BodyText>
            <BodyText>
              <b>The right to rectification.</b> You have the right to request from us to correct any information you believe is inaccurate. You also have the right to request us to complete information you believe is incomplete.
            </BodyText>
            <BodyText>
              <b>The right to erasure.</b> You have the right to request from us to erase your personal data, under certain conditions.
            </BodyText>
            <BodyText>
              <b>The right to restrict processing.</b> You have the right to request from us to restrict the processing of your personal data, under certain conditions.
            </BodyText>
            <BodyText>
              <b>The right to object to processing.</b> You have the right to object to our processing of your personal data, under certain conditions.
            </BodyText>
            <BodyText>
              <b>The right to data portability.</b> You have the right to request from us to transfer the data that we have collected to another organization, or directly to you, under certain conditions.
            </BodyText>
            <BodyText>
              <b>The right on information.</b> You have the right to be informed about a personal data breach in certain cases.
            </BodyText>
          </Animated>
        </VisibilitySensor>
        <VisibilitySensor onChange={(isVisible) => setContent8Visible(isVisible)} active={!isContent8Visible} partialVisibility={true}>
          <Animated animationIn="fadeInLeft" animationInDuration={2000} isVisible={isContent8Visible}>
            <Heading>
              Privacy Policies of Other Websites
            </Heading>
            <BodyText>
              Our website contains links to other websites. This Privacy Policy applies only to our website, so if you click on a link to another website, you should read their privacy policy.
            </BodyText>
          </Animated>
        </VisibilitySensor>
        <VisibilitySensor onChange={(isVisible) => setContent9Visible(isVisible)} active={!isContent9Visible} partialVisibility={true}>
          <Animated animationIn="fadeInRight" animationInDuration={2000} isVisible={isContent9Visible}>
            <Heading>
              Changes To This Privacy Statement
            </Heading>
            <BodyText>
              We may update this privacy statement at any time by publishing an updated version here. So you know when we make changes to this privacy statement, we will amend the revision date at the top of this page. The new modified or amended privacy statement will apply from that revision date. Therefore, we encourage you to review this privacy statement periodically to be informed about how we are protecting your information.
            </BodyText>
          </Animated>
        </VisibilitySensor>
        <VisibilitySensor onChange={(isVisible) => setContent10Visible(isVisible)} active={!isContent10Visible} partialVisibility={true}>
          <Animated animationIn="fadeInLeft" animationInDuration={2000} isVisible={isContent10Visible}>
            <Heading>
              Acknowledgement and Consent
            </Heading>
            <BodyText>
              By accepting the Privacy Policy, you acknowledge that you have read and understood this Privacy Policy and you accept all of its terms. In particular, you agree and consent to us collecting, using, sharing, disclosing, storing, transferring, or otherwise processing your Personal Information in accordance with this Privacy Policy.
            </BodyText>
            <BodyText>
              In circumstances where you provide us with Personal Information relating to other individuals (such as Personal Information relating to your spouse, family members, friends, or other parties), you represent and warrant that you have obtained such individual’s consent for, and hereby consent on behalf of such individual to, the collection, use, disclosure and processing of his/her Personal Information by us.
            </BodyText>
            <BodyText>
              You may withdraw your consent to any or all collection, use or disclosure of your Personal Information at any time by giving us reasonable notice in writing using the contact details stated below. You may also withdraw your consent for us to send you certain communications and information via any “opt-out” or “unsubscribe” facility contained in our messages to you.
            </BodyText>
            <BodyText>
              Depending on the circumstances and the nature of the consent which you are withdrawing, you must understand and acknowledge that after such withdrawal of consent, you may no longer be able to access our website or service. a Withdrawal of consent by you may result in the termination of your account or of your contractual relationship with us, with all accrued rights and obligations remaining fully reserved. Upon receipt of your notice to withdraw consent for any collection, use or disclosure of your Personal Information, we will inform you of the likely consequences of such withdrawal so that you can decide if indeed you wish to withdraw consent.
            </BodyText>
          </Animated>
        </VisibilitySensor>
        <VisibilitySensor onChange={(isVisible) => setContent11Visible(isVisible)} active={!isContent11Visible} partialVisibility={true}>
          <Animated animationIn="fadeInRight" animationInDuration={2000} isVisible={isContent11Visible}>
            <Heading>
              How To Contact Us
            </Heading>
            <BodyText>
              If you have any questions regarding this Privacy Statement or you would like to obtain access to your Personal Information, please contact sayhello@cloudxier.com
            </BodyText>
            <BodyText>
              This privacy statement was last updated on 10th March 2023
            </BodyText>
          </Animated>
        </VisibilitySensor>
      </Container>
    </MainContainer>
  )
}

export default PrivacyPolicy;

const MainContainer = styled.div`
  overflow: hidden;
  padding-top: 80px;
  @media ${device.tablet} {
    padding-top: 90px;
  }
  @media ${device.laptop} {
    padding-top: 90px;
  } 
  @media ${device.laptopM} {
    padding-top: 150px;
  } 
  @media ${device.laptopL} {
    padding-top: 150px;
  }
`
const Heading = styled.div`
  font-size: ${fontSize.bodyLg};
  color: black;
  font-weight: 700;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 12px;
  @media ${device.tablet} {
    font-size: ${fontSize.heading1Sm};
    margin-top: 48px;
    margin-bottom: 24px;
  }
  @media ${device.laptop} {
    font-size: ${fontSize.heading3Lg};
    margin-top: 48px;
    margin-bottom: 24px;
  }
  @media ${device.laptopM} {
    font-size: ${fontSize.heading3Lg};
    margin-top: 48px;
    margin-bottom: 24px;
  }
  @media ${device.laptopL} {
    font-size: ${fontSize.heading2Lg};
    margin-top: 48px;
    margin-bottom: 24px;
  }
`
const SubHeading = styled.p`
  font-size: ${fontSize.heading3Sm};
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 8px;
  @media ${device.tablet} {
    font-size: ${fontSize.bodyLg};
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptop} {
    font-size: 23px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopM} {
    font-size: 23px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopL} {
    font-size: 26px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
`
const BodyText = styled.p`
  text-align:justify;
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 8px;
  @media ${device.tablet} {
    font-size: 18px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptop} {
    font-size: ${fontSize.bodyLg};
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopM} {
    font-size: ${fontSize.bodyLg};
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopL} {
    font-size: 22px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
`