let type = {

  // Projects
  SET_PROJECTS: 'SET PROJECTS',

  // Blogs
  REQUEST_SET_BLOGS: 'REQUEST SET BLOGS',
  SUCCESS_SET_BLOGS: 'SUCCESS SET BLOGS',

  REQUEST_SET_BLOG_DETAILS: 'REQUEST SET BLOG DETAILS',
  SUCCESS_SET_BLOG_DETAILS: 'SUCCESS SET BLOG DETAILS'
}

export default type
