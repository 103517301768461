// React
import { useState } from 'react'

// React router dom
import { Link } from "react-router-dom";

// React responsive
import { useMediaQuery } from 'react-responsive'

// Styled components
import styled from 'styled-components'

function CollapsableMenu({ isOpen, toggleCollapsableMenu, list }) {

  const smallScreen = useMediaQuery({ query: '(max-width: 1279px)' })

  const [isExpertiseClicked, setIsExpertiseClicked] = useState(false)
  const [isOurGroupClicked, setIsOurGroupClicked] = useState(false)
  const [isServicesClicked, setIsServicesClicked] = useState(false)
  const [isProductsClicked, setIsProductsClicked] = useState(false)

  const ourGroupList = [
    {
      title: "Maplexxon - B2B Software Solutions",
      path: "https://maplexxon.com/"
    },
    {
      title: "ISA - ESG & Sustainability Consulting",
      path: "https://sustainability.co.id/"
    }
  ]

  const servicesList = [
    {
      title: "Custom Software Development",
      path: "/our-services/custom-software-development"
    },
    {
      title: "Mobile App Development",
      path: "/our-services/mobile-app-development"
    },
    {
      title: "API Development and Integration",
      path: "/our-services/api-development-integration"
    },
    {
      title: "Cloud Computing and Consultation",
      path: "/our-services/cloud-computing-consultation"
    },
    {
      title: "Dedicated Tech Team",
      path: "/our-services/dedicated-tech-team"
    },
    {
      title: "CTO as a Service",
      path: "/our-services/cto-as-service"
    },
    {
      title: "UI/UX Design and Planning",
      path: "/our-services/ui-ux-design-planning"
    },
    {
      title: "DevOps Consulting",
      path: "/our-services/devops-consulting"
    }
  ]

  const productList = [
    {
      title: "E-Commerce & Inventory",
      path: "https://maplexxon.com/product/ecommerce-website"
    },
    {
      title: "Appointment and Scheduling Management",
      path: "https://maplexxon.com/product/appointment-and-scheduling-management"
    },
    {
      title: "Premium Company Profile Site",
      path: "https://maplexxon.com/product/company-profile-site"
    }
  ]

  function handleClick() {
    // Close collapsable menu
    toggleCollapsableMenu(false)
    // Scroll loaded window to top
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  return (
    <MainContainer isOpen={isOpen} smallScreen={smallScreen}>
      <MenuItemContainer isOpen={isOpen} smallScreen={smallScreen}>
        <MenuItem onClick={() => setIsServicesClicked(!isServicesClicked)}>
          Services
          <MenuServicesSubitemContainer isServicesClicked={isServicesClicked}>
            {
              servicesList.map((item, index) => {
                return (
                  <MenuSubitem key={index}>
                    <Link to={item.path} style={{ color: 'white' }} onClick={() => handleClick()}>
                      {item.title}
                    </Link>
                  </MenuSubitem>
                )
              })
            }
          </MenuServicesSubitemContainer>
        </MenuItem>
        <MenuItem onClick={() => setIsProductsClicked(!isProductsClicked)}>
          Products
          <MenuProductsSubitemContainer isProductsClicked={isProductsClicked}>
            {
              productList.map((item, index) => {
                return (
                  <MenuSubitem key={index}>
                    <p style={{ color: 'white' }} onClick={() => window.open(item.path)}>
                      {item.title}
                    </p>
                  </MenuSubitem>
                )
              })
            }
          </MenuProductsSubitemContainer>
        </MenuItem>
        <MenuItem>
          <Link to="/projects" style={{ color: 'white' }} onClick={() => handleClick()}>
            Projects
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to="/blog" style={{ color: 'white' }} onClick={() => handleClick()}>
            Blog
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to="/about" style={{ color: 'white' }} onClick={() => handleClick()}>
            About Us
          </Link>
        </MenuItem>
        {/* <MenuItem>
          <Link to="/about" style={{ color: 'white' }} onClick={() => handleClick()}>
            Case Studies
          </Link>
        </MenuItem> */}
        <MenuItem onClick={() => setIsOurGroupClicked(!isOurGroupClicked)}>
          Our Group
          <MenuOurGroupSubitemContainer isOurGroupClicked={isOurGroupClicked}>
            {
              ourGroupList.map((item, index) => {
                return (
                  <MenuSubitem key={index}>
                    <p style={{ color: 'white' }} onClick={() => window.open(item.path)}>
                      {item.title}
                    </p>
                  </MenuSubitem>
                )
              })
            }
          </MenuOurGroupSubitemContainer>
        </MenuItem>
        <MenuItem>
          <Link to="/contact-us" style={{ color: 'white' }} onClick={() => handleClick()}>
            Contact
          </Link>
        </MenuItem>

      </MenuItemContainer>
    </MainContainer>
  )

}

export default CollapsableMenu

const MainContainer = styled.div`
  position: fixed;
  z-index: 4;
  height: 100vh;
  width: 100vw;
  background-color: #4C39FF;
  padding: 18vh 8vw 8vw 8vw;
  transition: margin .5s;
  margin-top: ${({ isOpen, smallScreen }) => isOpen && smallScreen ? 0 : '-100vh'};
  overflow: scroll
`
const MenuItemContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  transition: opacity 2s;
  opacity: ${({ isOpen, smallScreen }) => isOpen && smallScreen ? 1 : 0}
`
const MenuItem = styled.div`
  color: white;
  font-size: 20px;
  margin-bottom: 5vh;
  font-weight: 500;
`
const MenuOurGroupSubitemContainer = styled.div`
  display: ${({ isOurGroupClicked }) => isOurGroupClicked ? 'block' : 'none'};
  padding: 15px
`
const MenuServicesSubitemContainer = styled.div`
  display: ${({ isServicesClicked }) => isServicesClicked ? 'block' : 'none'};
  padding: 15px
`
const MenuProductsSubitemContainer = styled.div`
  display: ${({ isProductsClicked }) => isProductsClicked ? 'block' : 'none'};
  padding: 15px
`
const MenuSubitem = styled.div`
  padding-bottom: 2vh;
  font-size: 15px
`