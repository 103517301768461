// React
import { useEffect, useState } from 'react'

// React router dom
import { useHistory } from 'react-router-dom'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Animated
import { Animated } from "react-animated-css";

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

// Components
import HeadMeta from '../components/HeadMeta'
import SectionHeading from '../components/headings/SectionHeading'
import ActionBanner from '../components/banners/ActionBanner'
import ProjectColumn from '../components/ProjectColumn'

// Images
import MetaImage from '../../src/assets/images/projects/shooper/2-cloudxier-shooper-highlight03.jpg'

function Projects() {
  const smallScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 1279px)' })

  const [isContent1Visible, setContent1Visible] = useState(false)
  const [isContent2Visible, setContent2Visible] = useState(false)

  const history = useHistory()

  // Scroll window to top on load
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])

  return (
    <div style={{ overflow: 'hidden', paddingTop: smallScreen ? '100px' : mediumScreen ? '100px' : '150px' }}>
      <HeadMeta
        title="Cloudxier - Our Projects"
        description="Projects created by Cloudxier"
        siteName="Cloudxier"
        url="https://www.cloudxier.com/projects/"
        image={MetaImage} />
      <VisibilitySensor onChange={(isVisible) => setContent1Visible(isVisible)} active={!isContent1Visible} partialVisibility={true}>
        <Animated animationIn="fadeInDown" animationInDuration={2000} isVisible={isContent1Visible}>
          <SectionHeading
            title='Projects'
            subtitle=''
          />
        </Animated>
      </VisibilitySensor>
      <VisibilitySensor onChange={(isVisible) => setContent2Visible(isVisible)} active={!isContent2Visible} partialVisibility={true}>
        <Animated animationIn="bounceInUp" animationInDuration={2000} isVisible={isContent1Visible}>
          <ProjectColumn />
        </Animated>
      </VisibilitySensor>
      <ActionBanner
        action={() => history.push('/contact-us')}
        text='Ready to accelerate digital innovation in your business?'
        actionText='Request a quote' />
    </div>
  )

}

export default Projects