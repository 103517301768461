import { useState } from 'react'

// React router dom
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

// React responsive
import MediaQuery, { useMediaQuery } from 'react-responsive'

// Animated
import { Animated } from "react-animated-css";

// Screens
import Home from './Home'
import AboutUs from './AboutUs'
import Expertise from './Expertise'
import Projects from './Projects'
import ProjectDetails from './ProjectDetails'
import Contact from './Contact'
import PrivacyPolicy from './PrivacyPolicy'
import TPPrivacyPolicy from './TPPrivacyPolicy'
import TPTermsOfService from './TPTermsOfService'
import Blog from './Blog'
import BlogDetails from './BlogDetails'
import Services from './Services';
import CustomSoftwareDevelopment from './OurServices.js/CustomSoftwareDevelopment';
import MobileAppDevelopment from './OurServices.js/MobileAppDevelopment';
import APIDevelopmentIntegration from './OurServices.js/APIDevelopmentIntegration';
import CloudComputingConsultation from './OurServices.js/CloudComputingConsultation';
import DedicatedTechTeam from './OurServices.js/DedicatedTechTeam';
import CTOAsService from './OurServices.js/CTOAsService';
import UIUXDesignPlanning from './OurServices.js/UIUXDesignPlanning';
import DevOpsConsulting from './OurServices.js/DevOpsConsulting';
import SustainabilityPolicy from './SustainabilityPolicy';
import EnviromentalPolicy from './EnviromentalPolicy';

// Components
import Header from '../components/Header'
import SocmedHeader from '../components/SocmedHeader';
import Footer from '../components/Footer'
import CollapsableMenu from '../components/CollapsableMenu'

// Route change tracker from google analytics
import RouteChangeTracker from '../components/RouteChangeTracker'

import styled from 'styled-components'
import WhatsappIcon from '../assets/images/icons/WhatsappIcon';

function Main() {
  const smallScreen = useMediaQuery({ query: '(max-width: 1199px)' })

  const [isOpenMenu, setOpenMenu] = useState(false)

  function toggleCollapsableMenu(isOpen) {
    setOpenMenu(isOpen)
  }

  return (
    <Router>
      <RouteChangeTracker />
      <MediaQuery minWidth={1200}>
        <SocmedHeader />
      </MediaQuery>
      <Header
        toggleCollapsableMenu={toggleCollapsableMenu}
        isOpenMenu={isOpenMenu}
      />
      <CollapsableMenu
        list={
          [
            {
              title: "Mobile Application Development",
              path: "/expertise?id=mobile-application"
            },
            {
              title: "Website Development",
              path: "/expertise?id=website-development"
            },
            {
              title: "Digital Product Experience and Design",
              path: "/expertise?id=digital-product"
            },
            {
              title: "Enterprise Software Development",
              path: "/expertise?id=enterprise-software"
            },
            {
              title: "API Creation and Management",
              path: "/expertise?id=api-management"
            },
            {
              title: "Tech Resource Support",
              path: "/expertise?id=tech-resource-support"
            }
          ]
        }
        isOpen={isOpenMenu}
        toggleCollapsableMenu={toggleCollapsableMenu}
      />
      <Switch>
        <Route path='/about' exact>
          <AboutUs />
        </Route>
        <Route path='/contact-us' exact>
          <Contact />
        </Route>
        {/* <Route path='/expertise' exact>
          <Expertise />
        </Route> */}
        <Route path='/projects' exact>
          <Projects />
        </Route>
        <Route path='/projects/details' exact>
          <ProjectDetails />
        </Route>
        <Route path='/privacy-policy' exact>
          <PrivacyPolicy />
        </Route>
        <Route path='/sustainability-policy' exact>
          <SustainabilityPolicy />
        </Route>
        <Route path='/enviromental-policy' exact>
          <EnviromentalPolicy />
        </Route>
        <Route path='/tp-privacy-policy' exact>
          <TPPrivacyPolicy />
        </Route>
        <Route path='/tp-terms' exact>
          <TPTermsOfService />
        </Route>
        <Route path='/blog' exact>
          <Blog />
        </Route>
        <Route path='/blog/details/:id' exact>
          <BlogDetails />
        </Route>
        {/* <Route path='/services' exact>
          <Services />
        </Route> */}
        <Route path='/our-services/custom-software-development' exact>
          <CustomSoftwareDevelopment />
        </Route>
        <Route path='/our-services/mobile-app-development' exact>
          <MobileAppDevelopment />
        </Route>
        <Route path='/our-services/api-development-integration' exact>
          <APIDevelopmentIntegration />
        </Route>
        <Route path='/our-services/cloud-computing-consultation' exact>
          <CloudComputingConsultation />
        </Route>
        <Route path='/our-services/dedicated-tech-team' exact>
          <DedicatedTechTeam />
        </Route>
        <Route path='/our-services/cto-as-service' exact>
          <CTOAsService />
        </Route>
        <Route path='/our-services/ui-ux-design-planning' exact>
          <UIUXDesignPlanning />
        </Route>
        <Route path='/our-services/devops-consulting' exact>
          <DevOpsConsulting />
        </Route>
        <Route path='/' exact>
          <Home />
        </Route>
      </Switch>
      <WhatsappButton smallScreen={smallScreen} onClick={() => window.open('https://wa.me/6281385898240')}>
        <Animated animationIn="bounceInUp" animationInDuration={2000} isVisible={true}>
          <WhatsappIcon width={smallScreen ? '30px' : '45px'} height={smallScreen ? '30px' : '45px'} />
        </Animated>
      </WhatsappButton>
      <Footer />
    </Router>
  )

}

export default Main

const WhatsappButton = styled.div`
  position:fixed;
  width:${({ smallScreen }) => smallScreen ? '50px' : '80px'};
  height:${({ smallScreen }) => smallScreen ? '50px' : '80px'};
  bottom:50px;
  right:50px;
  background-color:#F4F7FF;
  color:#FFF;
  border-radius:50px;
  display:flex;
  justify-content:center;
  align-items:center;
  z-index:999;
  cursor:pointer;
  box-shadow: 3px 3px 4px #999;
`