import TYPE from './types'
import axios from "axios"
import getServerURL from '../../helpers/getServerURL'

export const requestSetBlogs = (payload) => ({
  type: TYPE.REQUEST_SET_BLOGS,
  payload
})
export const successSetBlogs = (payload) => ({
  type: TYPE.SUCCESS_SET_BLOGS,
  payload
})

export const requestSetBlogDetails = (payload) => ({
  type: TYPE.REQUEST_SET_BLOG_DETAILS,
  payload
})
export const successSetBlogDetails = (payload) => ({
  type: TYPE.SUCCESS_SET_BLOG_DETAILS,
  payload
})

export function getBlogs (limit) {
	return (dispatch) => {
    dispatch(requestSetBlogs())
    return new Promise((resolve, reject) => {
      axios({
        url: `${getServerURL()}/blogs/public?limit=${limit}`,
        method: "GET",
      })
      .then(({data}) => {
        if (data) {
          dispatch(successSetBlogs(data))
          resolve(data)
        }
      })
      .catch((err) => {
        console.log(err)
        reject(err.response.data)
      })
    })
	}
}

export function getBlogDetails (query) {
	return (dispatch) => {
    dispatch(requestSetBlogDetails())
    return new Promise((resolve, reject) => {
      axios({
        url: `${getServerURL()}/blogs/public?id=${query}`,
        method: "GET",
      })
      .then(({data}) => {
        if (data) {
          dispatch(successSetBlogDetails(data));
          resolve(data)
        }
      })
      .catch((err) => {
        console.log(err)
        reject(err.response.data)
      })
    })
	}
}