import MoonLoader from "react-spinners/MoonLoader";
import styled from 'styled-components'
import { device } from "../../helpers/device";

const Loader = ({ show }) => {
  return (
    show ? (
      <LoaderModal>
        <LoaderBody>
          <LoaderContent>
            <MoonLoader
              loading={show}
              size={75}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
            <LoaderText>Loading.....</LoaderText>
          </LoaderContent>
        </LoaderBody>
      </LoaderModal>
    ) : null
  )
}

export default Loader

const LoaderModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: all;
`
const LoaderBody = styled.div`
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${device.tablet} {
    width: 200px;
    height: 200px;
  }
  @media ${device.laptop} {
    width: 250px;
    height: 250px;
  }
  @media ${device.laptopM} {
    width: 250px;
    height: 250px;
  }
  @media ${device.laptopL} {
    width: 300px;
    height: 300px;
  }
`
const LoaderContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  @media ${device.tablet} {
    gap: 10px;
  }
  @media ${device.laptop} {
    gap: 10px;
  }
  @media ${device.laptopM} {
    gap: 10px;
  }
  @media ${device.laptopL} {
    gap: 10px;
  }
`
const LoaderText = styled.div`
  font-size: 18px;
  @media ${device.tablet} {
    padding-top: 20px;
  }
  @media ${device.laptop} {
    padding-top: 22px;
  }
  @media ${device.laptopM} {
    padding-top: 24px;
  }
  @media ${device.laptopL} {
    padding-top: 26px;
  }
`