import { useState, useRef, useEffect } from 'react'

// Styled components
import styled from 'styled-components'

// Animated
import { Animated } from "react-animated-css";

// React responsive
import { useMediaQuery } from 'react-responsive'

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

// Components
import SectionHeading from '../components/headings/SectionHeading'

// Helpers
import fontSize from '../helpers/fontSize'
import { device } from '../helpers/device'

// Images
import ChevronRight from '../assets/images/icons/chevron_right_dark.png'
import WhyChooseUs1 from '../assets/images/landing_page/why_choose_us/Cloudxier-whychooseus-new-01.png'
import WhyChooseUs2 from '../assets/images/landing_page/why_choose_us/Cloudxier-whychooseus-new-02.png'
import WhyChooseUs3 from '../assets/images/landing_page/why_choose_us/Cloudxier-whychooseus-new-03.png'
import WhyChooseUs4 from '../assets/images/landing_page/why_choose_us/Cloudxier-whychooseus-new-04.png'
import WhyChooseUs5 from '../assets/images/landing_page/why_choose_us/Cloudxier-whychooseus-new-05.png'
import { Col, Container, Row } from 'react-bootstrap'
import DropDownUpIcon from '../assets/images/icons/dropdown-up-icon.png'
import DropDownIcon from '../assets/images/icons/dropdown_icon_black.png'

function FeatureColumn({
  titleText,
  titleColor,
  descriptionText,
  descriptionIsList,
  descriptionColor,
  isMediaLeft,
  mediaUrl,
  mediaHeight,
  textContainerPadding,
  smFullWidthMedia,
  fromLandingPage = false
}) {
  const smallScreen = useMediaQuery({ query: '(max-width: 768px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 1400px)' })

  const [isVisibleColumn, setVisibleColumn] = useState(false)

  const [features, setFeatures] = useState([
    {
      title: 'Serves a diverse range of experience both with International and Local clients ',
      description: (
        <div>
          Elevating businesses worldwide, we cater to a diverse spectrum of clients, spanning both international company to small-medium sized businesses.
        </div>
      ),
      icon: WhyChooseUs1,
      isDescOpen: false
    },
    {
      title: 'We match and collaborate based on the technology needs to align with your goals',
      description: (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <div>
            We understand that technology is not one-size-fits-all.
          </div>
          <div>
            That's why we take a personalized approach to every partnership, carefully matching our expertise and resources to align with your business goals and technology needs.
          </div>
        </div>
      ),
      icon: WhyChooseUs4,
      isDescOpen: false
    },
    {
      title: 'Providing the best service and prioritizing quality assurance',
      description: (
        <div>
          At the heart of our mission, lies a relentless commitment to excellence. We are dedicated to delivering top-tier solutions that are reliable, scalable, and exceeding your expectations.
        </div>
      ),
      icon: WhyChooseUs2,
      isDescOpen: false
    },
    {
      title: 'Adapting agility to ensure success in project’s delivery',
      description: (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <div>
            Agility is the key to project success. At Cloudxier, we embrace agility as a fundamental principle in our project delivery approach.
          </div>
          <div>
            By adapting agile methodologies, we prioritize flexibility, collaboration, and rapid iterations.
          </div>
        </div>
      ),
      icon: WhyChooseUs3,
      isDescOpen: false
    },
    {
      title: 'Customer-Centric Approach',
      description: (
        <div>
          We believe in understanding our clients' unique challenges and goals, allowing us to develop tailored solutions that meet their specific needs and exceed their expectations.
        </div>
      ),
      icon: WhyChooseUs5,
      isDescOpen: false
    },
  ])

  const handleDropdown = (i, value) => {
    const newFeatures = features.map((item, itemIndex) => {
      if (itemIndex == i) {
        return {
          ...item,
          isDescOpen: !value
        };
      } else {
        return item;
      }
    });
    setFeatures(newFeatures)
  }

  if (fromLandingPage) {
    return (
      <VisibilitySensor onChange={(isVisible) => setVisibleColumn(isVisible)} active={!isVisibleColumn} partialVisibility={true}>
        <FeatureContainer smallScreen={smallScreen} isVisibleColumn={isVisibleColumn}>
          <Container fluid style={{ padding: smallScreen ? '0 20px' : mediumScreen ? '0 50px 75px 50px' : '0 0 100px 0' }}>
            <>
              <SectionHeading
                title='Why Choose Cloudxier'
                subtitle='Solve your tech challenges with the right partner'
              />
              <Animated animationIn="slideInLeft" animationInDuration={2000} isVisible={isVisibleColumn}>
                <Row style={{ marginTop: smallScreen ? "30px" : mediumScreen ? "40px" : "50px" }}>
                  {
                    features.map((feature, i) => (
                      <Col sm={12} md={12} lg={6} key={i}>
                        <ColFeatureContainer smallScreen={smallScreen} mediumScreen={mediumScreen} isDescOpen={feature.isDescOpen}>
                          <HeadlineFeature>
                            <ImageContainerRelative smallScreen={smallScreen} mediumScreen={mediumScreen}>
                              <Image src={feature.icon} />
                            </ImageContainerRelative>
                            <Title smallScreen={smallScreen} mediumScreen={mediumScreen} onClick={() => handleDropdown(i, feature.isDescOpen)}>
                              {feature.title}
                            </Title>
                            {feature.isDescOpen ? (
                              <DropdownIconContainer smallScreen={smallScreen} mediumScreen={mediumScreen} onClick={() => handleDropdown(i, feature.isDescOpen)}>
                                <DropdownIcon src={DropDownIcon} />
                              </DropdownIconContainer>
                            ) : (
                              <DropdownIconContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
                                <DropdownIcon src={DropDownUpIcon} onClick={() => handleDropdown(i, feature.isDescOpen)} />
                              </DropdownIconContainer>
                            )}
                          </HeadlineFeature>
                          <Description smallScreen={smallScreen} mediumScreen={mediumScreen} hidden={feature.isDescOpen === false} >
                            {feature.description}
                          </Description>
                        </ColFeatureContainer>
                      </Col>
                    ))
                  }
                </Row>
              </Animated>
            </>
          </Container>
        </FeatureContainer>
      </VisibilitySensor >
    )
  } else {
    switch (smallScreen) {
      case true:
        if (smFullWidthMedia) {
          return (
            <VisibilitySensor onChange={(isVisible) => setVisibleColumn(isVisible)} active={!isVisibleColumn} partialVisibility={true}>
              <FeatureContainer smallScreen={smallScreen} isVisibleColumn={isVisibleColumn}>
                <ColContainer smallScreen={smallScreen} textContainerPadding={textContainerPadding}>
                  <Image src={mediaUrl} />
                </ColContainer>
                <ColContainer smallScreen={smallScreen} textContainerPadding={textContainerPadding} smFullWidthMedia={smFullWidthMedia}>
                  <Title
                    textColor={titleColor}
                    smallScreen={smallScreen}>
                    {titleText}
                  </Title>
                  <Description
                    textColor={descriptionColor}
                    smallScreen={smallScreen}>
                    {
                      descriptionIsList ?
                        <ul>
                          {
                            descriptionText.split(',').map((item, index) => {
                              return (
                                <li key={index}>
                                  {item}
                                </li>
                              )
                            })
                          }
                        </ul>
                        :
                        descriptionText
                    }
                  </Description>
                </ColContainer>
              </FeatureContainer>
            </VisibilitySensor>
          )
        } else {
          return (
            <VisibilitySensor onChange={(isVisible) => setVisibleColumn(isVisible)} active={!isVisibleColumn} partialVisibility={true}>
              <FeatureContainer smallScreen={smallScreen} isVisibleColumn={isVisibleColumn}>
                <ColContainer smallScreen={smallScreen} textContainerPadding={textContainerPadding}>
                  <ImageContainer smallScreen={smallScreen} mediaHeight={mediaHeight}>
                    <Image src={mediaUrl} />
                  </ImageContainer>
                </ColContainer>
                <ColContainer smallScreen={smallScreen} textContainerPadding={textContainerPadding}>
                  <Title
                    textColor={titleColor}
                    smallScreen={smallScreen}>
                    {titleText}
                  </Title>
                  <Description
                    textColor={descriptionColor}
                    smallScreen={smallScreen}>
                    {
                      descriptionIsList ?
                        <ul>
                          {
                            descriptionText.split(',').map((item, index) => {
                              return (
                                <li key={index}>
                                  {item}
                                </li>
                              )
                            })
                          }
                        </ul>
                        :
                        descriptionText
                    }
                  </Description>
                </ColContainer>
              </FeatureContainer>
            </VisibilitySensor>
          )
        }
      case false:
        if (isMediaLeft) {
          return (
            <VisibilitySensor onChange={(isVisible) => setVisibleColumn(isVisible)} active={!isVisibleColumn} partialVisibility={true}>
              <FeatureContainer smallScreen={smallScreen} isVisibleColumn={isVisibleColumn}>
                <ColContainer smallScreen={smallScreen} textContainerPadding={textContainerPadding}>
                  <ImageContainer smallScreen={smallScreen} mediaHeight={mediaHeight}>
                    <Image src={mediaUrl} />
                  </ImageContainer>
                </ColContainer>
                <ColContainer smallScreen={smallScreen} textContainerPadding={textContainerPadding}>
                  <Title
                    textColor={titleColor}
                    smallScreen={smallScreen}>
                    {titleText}
                  </Title>
                  <Description
                    textColor={descriptionColor}
                    smallScreen={smallScreen}>
                    {
                      descriptionIsList ?
                        <ul>
                          {
                            descriptionText.split(',').map((item, index) => {
                              return (
                                <li key={index}>
                                  {item}
                                </li>
                              )
                            })
                          }
                        </ul>
                        :
                        descriptionText
                    }
                  </Description>
                </ColContainer>
              </FeatureContainer>
            </VisibilitySensor>
          )
        } else {
          return (
            <VisibilitySensor onChange={(isVisible) => setVisibleColumn(isVisible)} active={!isVisibleColumn} partialVisibility={true}>
              <FeatureContainer smallScreen={smallScreen} isVisibleColumn={isVisibleColumn}>
                <ColContainer smallScreen={smallScreen} textContainerPadding={textContainerPadding}>
                  <Title
                    textColor={titleColor}
                    smallScreen={smallScreen}>
                    {titleText}
                  </Title>
                  <Description
                    textColor={descriptionColor}
                    smallScreen={smallScreen}>
                    {
                      descriptionIsList ?
                        <ul>
                          {
                            descriptionText.split(',').map((item, index) => {
                              return (
                                <li key={index}>
                                  {item}
                                </li>
                              )
                            })
                          }
                        </ul>
                        :
                        descriptionText
                    }
                  </Description>
                </ColContainer>
                <ColContainer smallScreen={smallScreen} textContainerPadding={textContainerPadding}>
                  <ImageContainer smallScreen={smallScreen} mediaHeight={mediaHeight}>
                    <Image src={mediaUrl} />
                  </ImageContainer>
                </ColContainer>
              </FeatureContainer>
            </VisibilitySensor>
          )
        }
    }
  }
}

export default FeatureColumn

const FeatureContainer = styled.div`
  display: ${({ smallScreen, mediumScreen }) => smallScreen ? 'flex' : mediumScreen ? 'flex' : 'flex'};
  justify-content: space-between;
  align-items: center;
  width: 100%;
  opacity: ${({ isVisibleColumn }) => isVisibleColumn ? 1 : 0};
  transition: opacity 1s
`
const ColContainer = styled.div`
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? '100%' : mediumScreen ? '100%' : '100%'};
  padding: ${({ textContainerPadding, smFullWidthMedia }) => {
    if (textContainerPadding && !smFullWidthMedia) {
      return textContainerPadding
    } else if (smFullWidthMedia) {
      return '10vw'
    } else {
      return 0
    }
  }}
`
const HeadlineFeature = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:space-evenly;
`
const ColFeatureContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '2rem' : mediumScreen ? '2rem' : '2rem'};
  height: auto;
  margin-bottom: 30px;
  border-radius: 15px;
  border: solid 2px #4C39FF;
`
const Title = styled.div`
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? 'auto' : mediumScreen ? 'auto' : 'auto'};
  height: ${({ smallScreen, mediumScreen }) => smallScreen ? 'auto' : mediumScreen ? 'auto' : 'auto'};
  color: ${({ textColor }) => textColor ? textColor : 'black'};
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '17px' : mediumScreen ? '21px' : '25px'};
  padding: 0 20px;
  font-weight: 700;
`
const Description = styled.div`
  margin-top: ${({ smallScreen, mediumScreen }) => smallScreen ? '18px' : mediumScreen ? '18px' : '18px'};
  color: ${({ textColor }) => textColor ? textColor : 'black'};
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '14px' : mediumScreen ? '18px' : '20px'};
  font-weight: 400;
  text-align: justify;
`
const ImageContainer = styled.div`
  width: 100%;
  height: ${({ mediaHeight }) => mediaHeight}
`
const ImageContainerRelative = styled.div`
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '0px' : mediumScreen ? '0px' : '10px 5px'};
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? '80px' : mediumScreen ? '90px' : '100px'};
`
const DropdownIconContainer = styled.div`
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '0px' : mediumScreen ? '0px' : '10px 5px'};
`
const Image = styled.img`
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px' : mediumScreen ? '50px' : '80px'};
  height: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px' : mediumScreen ? '50px' : '80px'};
  object-fit: contain
`
const DropdownIcon = styled.img`
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? '10px' : mediumScreen ? '15px' : '20px'};
  height: ${({ smallScreen, mediumScreen }) => smallScreen ? '10px' : mediumScreen ? '15px' : '20px'};
  object-fit: contain;
`
