// React
import { useState } from 'react'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Styled components
import styled from 'styled-components'

// React input mask
import InputMask from 'react-input-mask'

// Components
import SquareButton from '../components/buttons/SquareButton'

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

// Animated
import { Animated } from "react-animated-css";

// React sweetalert2
import Swal from 'sweetalert2'

// Axios
import Axios from 'axios'

// React Google Recaptcha
import ReCAPTCHA from "react-google-recaptcha";

import getServerURL from '../helpers/getServerURL'

function ContactForm() {

  const smallScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 1279px)' })

  const [isComponentVisible, setComponentVisible] = useState(false)

  const [isCaptchaCorrect, setCaptchaCorrect] = useState(false)

  const [isFilled, setFilled] = useState(false)

  const [validation, setValidation] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    companyName: '',
    serviceInterest: '',
    budget: '',
    description: ''
  })

  const [forms, setForms] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    companyName: '',
    serviceInterest: '',
    budget: '',
    description: ''
  })

  function handleChange(value, key) {
    // Deep copy of the previous state
    let newForm = JSON.parse(JSON.stringify(forms))
    // Change the previous value
    newForm[key] = value
    if (newForm[key] != null && value.trim().length > 0) {
      setValidation(key = "") // Still an issue, must click submit twice -Raditya
    }
    // Re-set the form
    setForms(newForm)
  }

  function validateForm() {
    let result = []
    let newValidate = {
      fullName: '',
      email: '',
      phoneNumber: '',
      companyName: '',
      serviceInterest: '',
      budget: '',
      description: ''
    }
    for (let key in forms) {
      if (forms[key]) {
        result.push(true)
      } else {
        result.push(false)
      }
    }
    if (!result[0]) {
      newValidate.fullName = "Please input your fullname"
    }
    if (!result[1]) {
      newValidate.email = "Please input your email"
    }
    if (!result[2]) {
      newValidate.phoneNumber = "Please input your phone number"
    }
    if (!result[3]) {
      newValidate.companyName = "Please input your company"
    }
    if (!result[4]) {
      newValidate.serviceInterest = "Please select your interest"
    }
    if (!result[5]) {
      newValidate.budget = "Please input select your budget"
    }
    if (!result[6]) {
      newValidate.description = "Please input your message"
    }
    setValidation({
      fullName: newValidate.fullName,
      email: newValidate.email,
      phoneNumber: newValidate.phoneNumber,
      companyName: newValidate.companyName,
      serviceInterest: newValidate.serviceInterest,
      budget: newValidate.budget,
      description: newValidate.description
    })
    console.log(validation)
    if (result.indexOf(false) > -1) {
      return false
    } else {
      return true
    }
  }

  function handleSubmit() {
    if (validateForm()) {
      // Added swal loading
      Swal.fire({
        title: "Please Wait",
        text: "We are processing your request",
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => Swal.showLoading()
      })
      // Submit to axios
      Axios({
        method: 'post',
        url: `${getServerURL()}/contact/submit`,
        data: forms
      })
        .then(() => {
          Swal.fire({
            title: "Form Submitted",
            icon: "success",
            text: "We will get back at you shortly!",
            allowOutsideClick: false,
            allowEscapeKey: false
          })
        })
        .catch((err) => {
          Swal.fire({
            title: "Oopss...",
            icon: "error",
            text: "Internal server error, please contact website admin"
          })
        })
    } else {
      Swal.fire({
        title: "Cannot Proceed",
        icon: "warning",
        text: "Please fill all the form fields"
      })
    }
  }

  function onChangeCaptcha(value) {
    if (value) {
      setCaptchaCorrect(true)
    } else {
      setCaptchaCorrect(false)
    }
  }

  return (
    <VisibilitySensor onChange={(isVisible) => setComponentVisible(isVisible)} partialVisibility={true} active={!isComponentVisible}>
      <Animated animationIn="fadeInLeft" animationInDuration={2000} isVisible={isComponentVisible}>
        <MainContainer smallScreen={smallScreen} mediumScreen={mediumScreen} isComponentVisible={isComponentVisible}>
          <Title smallScreen={smallScreen} mediumScreen={mediumScreen}>
            Submit an inquiry
          </Title>
          <FormContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
            <InputContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
              <Input
                value={forms.fullName}
                smallScreen={smallScreen}
                mediumScreen={mediumScreen}
                placeholder="Full name"
                onChange={({ target: { value } }) => handleChange(value, 'fullName')} />
            </InputContainer>
            {
              validation.fullName == '' ?
                null
                :
                <Validation>{validation.fullName}</Validation>
            }
            <InputContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
              <Input
                value={forms.email}
                smallScreen={smallScreen}
                mediumScreen={mediumScreen}
                placeholder="Your email address"
                onChange={({ target: { value } }) => handleChange(value, 'email')} />
            </InputContainer>
            {
              validation.email == '' ?
                null
                :
                <Validation>{validation.email}</Validation>
            }
            <InputContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
              <InputMask
                value={forms.phoneNumber}
                onChange={({ target: { value } }) => handleChange(value, 'phoneNumber')}
                mask="(+99) 9999999999999"
                smallScreen={smallScreen}
                mediumScreen={mediumScreen}
                placeholder="Contact number"
                maskChar=" ">
                {(inputProps) => <Input {...inputProps} />}
              </InputMask>
            </InputContainer>
            {
              validation.phoneNumber == '' ?
                null
                :
                <Validation>{validation.phoneNumber}</Validation>
            }
            <InputContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
              <Input
                value={forms.companyName}
                onChange={({ target: { value } }) => handleChange(value, 'companyName')}
                smallScreen={smallScreen}
                mediumScreen={mediumScreen}
                placeholder="Company name" />
            </InputContainer>
            {
              validation.companyName == '' ?
                null
                :
                <Validation>{validation.companyName}</Validation>
            }
            <InputContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
              <Dropdown
                smallScreen={smallScreen}
                mediumScreen={mediumScreen}
                value={forms.serviceInterest}
                onChange={({ target: { value } }) => handleChange(value, 'serviceInterest')}>
                <option value="" disabled selected hidden>What services are you interested in?</option>
                <option value="custom_software">Custom Software Development</option>
                <option value="mobile_apps">Mobile App Development</option>
                <option value="api_development">API Development and Integration</option>
                <option value="cloud_computing">Cloud Computing and Consultation</option>
                <option value="tech_team">Dedicated Tech Team</option>
                <option value="cto_service">CTO as a Service</option>
                <option value="ui_ux_design_plan">UI/UX Design and Planning</option>
                <option value="devops_consulting">DevOps Consulting</option>
              </Dropdown>
            </InputContainer>
            {
              validation.serviceInterest == '' ?
                null
                :
                <Validation>{validation.serviceInterest}</Validation>
            }
            {/* <InputContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
            <Dropdown 
              smallScreen={smallScreen} 
              mediumScreen={mediumScreen} 
              value={forms.budget} 
              onChange={({ target: { value }}) => handleChange(value, 'budget')}>
                <option value="" disabled selected hidden>What is your budget?</option>
                <option value="0-50000000">Under IDR 50 Mil.</option>
                <option value="50000000-100000000">IDR 50 Mil. - IDR 100 Mil.</option>
                <option value="100000000-180000000">IDR 100 Mil. - IDR 180 Mil.</option>
                <option value="180000000-999999999">More than IDR 180 Mil.</option>
            </Dropdown>
          </InputContainer>
          {
            validation.budget == '' ?
              null
            :
              <Validation>{validation.budget}</Validation>
          } */}
            <InputContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
              <TextArea
                value={forms.description}
                onChange={({ target: { value } }) => handleChange(value, 'description')}
                smallScreen={smallScreen}
                mediumScreen={mediumScreen}
                rows="7"
                placeholder="Describe your project briefly.." />
            </InputContainer>
            {
              validation.description == '' ?
                null
                :
                <Validation>{validation.description}</Validation>
            }
          </FormContainer>
          <ButtonContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
            <ReCAPTCHA
              style={{ marginBottom: smallScreen ? '15px' : mediumScreen ? '15px' : 0 }}
              sitekey='6Le7pvUaAAAAAAiPYg6vfzaD7kFju2jFBEYuvvaK'
              onChange={onChangeCaptcha}
            />
            {
              isCaptchaCorrect ?
                <SquareButton
                  action={handleSubmit}
                  textColor='white'
                  hoverTextColor='white'
                  text='Submit'
                  textSize={smallScreen ? '13px' : mediumScreen ? '14px' : '15px'}
                  backgroundColor='#4A4A4A'
                  hoverBgColor='#5D3F6A'
                  width={smallScreen ? '100%' : mediumScreen ? '100%' : '150px'} />
                :
                <SquareButton
                  action={handleSubmit}
                  textColor='white'
                  hoverTextColor='white'
                  text='Submit'
                  textSize={smallScreen ? '13px' : mediumScreen ? '14px' : '15px'}
                  backgroundColor='#4A4A4A'
                  hoverBgColor='#4A4A4A'
                  width={smallScreen ? '100%' : mediumScreen ? '100%' : '150px'} />
            }
          </ButtonContainer>
        </MainContainer>
      </Animated>
    </VisibilitySensor>
  )

}

export default ContactForm

const MainContainer = styled.div`
  margin: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px 0' : mediumScreen ? '40px 0' : '0'};
  opacity: ${({ isComponentVisible }) => isComponentVisible ? 1 : 0};
  transition: opacity 1s
`
const Title = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '16px' : mediumScreen ? '18px' : '20px'};
  color: black;
  font-weight: ${({ smallScreen, mediumScreen }) => smallScreen ? 'bold' : mediumScreen ? 'bold' : 'bold'};
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '10px' : mediumScreen ? '10px' : '20px'};
`
const FormContainer = styled.div`
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '0 2vw' : mediumScreen ? '0 2vw' : '0'};
`
const InputContainer = styled.div`
  margin-top: ${({ smallScreen, mediumScreen }) => smallScreen ? '20px' : mediumScreen ? '20px' : '30px'};
`
const Input = styled.input`
  border: none;
  border-bottom: ${({ value }) => value ? '1px solid #ededed' : '1px solid black'};
  background-color: ${({ value }) => value ? '#ededed' : 'transparent'};
  outline: none;
  padding: 10px;
  width: 100%;
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '14px' : mediumScreen ? '16px' : '18px'}
`
const Dropdown = styled.select`
  border: none;
  border-bottom: ${({ value }) => value ? '1px solid #ededed' : '1px solid black'};
  background-color: ${({ value }) => value ? '#ededed' : 'transparent'};
  outline: none;
  padding: 10px 5px;
  width: 100%;
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '14px' : mediumScreen ? '16px' : '18px'};
  color: ${({ value }) => value ? 'black' : 'grey'}
`
const TextArea = styled.textarea`
  border: ${({ value }) => value ? '1px solid #ededed' : '1px solid black'};
  background-color: ${({ value }) => value ? '#ededed' : 'transparent'};
  outline: none;
  width: 100%;
  padding: 15px;
  resize: none;
  margin-top: ${({ smallScreen, mediumScreen }) => smallScreen ? '10px' : mediumScreen ? '10px' : '10px'}
`
const ButtonContainer = styled.div`
  display: ${({ smallScreen, mediumScreen }) => smallScreen ? 'block' : mediumScreen ? 'block' : 'flex'};
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 15px;
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '0 2vw' : mediumScreen ? '0 2vw' : '0'}
`
const Validation = styled.div`
  color: #C95757;
  font-size: 15px
`