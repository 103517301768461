// React
import { useEffect, useState } from 'react'

// React bootstrap
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

// Animated
import { Animated } from "react-animated-css";

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Images
import CloudxierLogoSquare from '../assets/images/logo/Cloudxier-logofull-colored-05.png'

// Components
import HeadMeta from '../components/HeadMeta'
import ActionBanner from '../components/banners/ActionBanner'
import ContactHeading from '../components/headings/ContactHeading'
import Address from '../components/texts/Address'
import ContactForm from '../components/ContactForm'

function Contact() {

  const smallScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 1279px)' })

  const [isComponentVisible, setComponentVisible] = useState(false)

  // Scroll window to top on load
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])

  return (
    <div style={{ overflow: 'hidden', paddingTop: smallScreen ? '100px' : mediumScreen ? '125px' : '150px' }}>
      <HeadMeta
        title='Cloudxier - Contact Us'
        description="Discuss your idea with us, we'd love to hear from you"
        siteName="Cloudxier"
        url='https://www.cloudxier.com/contact-us/'
        image={CloudxierLogoSquare} />
      <Container style={{ padding: smallScreen ? '0 10vw 30px 10vw' : mediumScreen ? '0 6vw 30px 5vw' : '0 0 50px 0' }}>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Col sm={12} md={6} lg={6} style={{ padding: smallScreen ? '0' : mediumScreen ? '0 30px 0 0' : '0 100px 0 0' }}>
            <VisibilitySensor onChange={(isVisible) => setComponentVisible(isVisible)} active={!isComponentVisible} partialVisibility={true}>
              <Animated animationIn="fadeInRight" animationInDuration={2000} isVisible={isComponentVisible}>
                <ContactHeading
                  title="Contact us"
                  subtitle="Discuss your idea with us, we'd love to hear from you."
                  titleColor="#131A91"
                />
              </Animated>
            </VisibilitySensor>
            {smallScreen ? <ContactForm /> : mediumScreen ? <ContactForm /> : <Address />}
          </Col>
          <Col sm={12} md={6} lg={6}>
            {smallScreen ? <Address /> : mediumScreen ? <Address /> : <ContactForm />}
          </Col>
        </Row>
      </Container>
      <ActionBanner
        text='Ready to accelerate digital innovation in your business?'
        actionText='Request a quote'
      />
    </div>
  )

}

export default Contact