// React
import { useState } from 'react'

// Styled components
import styled from 'styled-components'

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

// Animated
import { Animated } from "react-animated-css";

// React responsive
import { useMediaQuery } from 'react-responsive'

function ProjectDetailsColumn({ isMediaLeft, text, imageUrl, textSize }) {

  const smallScreen = useMediaQuery({ query: '(max-width: 1023px)' })

  const [isVisibleColumn1, setVisibleColumn1] = useState(false)
  const [isVisibleColumn2, setVisibleColumn2] = useState(false)

  if (isMediaLeft) {
    return (
      <VisibilitySensor onChange={(isVisible) => setVisibleColumn1(isVisible)} active={!isVisibleColumn1} partialVisibility={true}>
        <Animated animationIn="slideInRight" animationInDuration={2000} isVisible={isVisibleColumn1}>
          <ContentContainer isVisibleColumn={isVisibleColumn1} smallScreen={smallScreen}>
            <ImageContainer smallScreen={smallScreen}>
              <Image src={imageUrl} />
            </ImageContainer>
            <DescriptionContainer smallScreen={smallScreen}>
              <Text textSize={textSize}>
                {text}
              </Text>
            </DescriptionContainer>
          </ContentContainer>
        </Animated>
      </VisibilitySensor>
    )
  } else {
    return (
      <VisibilitySensor onChange={(isVisible) => setVisibleColumn2(isVisible)} active={!isVisibleColumn2} partialVisibility={true}>
        <Animated animationIn="slideInLeft" animationInDuration={2000} isVisible={isVisibleColumn2}>
          <ContentContainer isVisibleColumn={isVisibleColumn2} smallScreen={smallScreen}>
            <DescriptionContainer smallScreen={smallScreen}>
              <Text textSize={textSize}>
                {text}
              </Text>
            </DescriptionContainer>
            <ImageContainer smallScreen={smallScreen}>
              <Image src={imageUrl} />
            </ImageContainer>
          </ContentContainer>
        </Animated>
      </VisibilitySensor>
    )
  }

}

export default ProjectDetailsColumn

const ContentContainer = styled.div`
  display: ${({ smallScreen }) => smallScreen ? 'block' : 'flex'};
  justify-content: flex-start;
  align-items: center;
  margin-bottom: ${({ smallScreen }) => smallScreen ? '20px' : '50px'};
  opacity: ${({ isVisibleColumn }) => isVisibleColumn ? 1 : 0};
  transition: opacity 1s
`
const ImageContainer = styled.div`
  width: 100%;
  height: 100%
`
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover
`
const DescriptionContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ smallScreen }) => smallScreen ? '5vw' : '0 8vw'};
  text-align: left
`
const Text = styled.div`
  font-size: ${({ textSize }) => textSize};
  color: black
`