import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Styled components
import styled from 'styled-components'

// Components
import SectionHeading from './headings/SectionHeading'
import SquareButton from './buttons/SquareButton'
import BlogCards from './BlogCards'

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

// Animated
import { Animated } from "react-animated-css";

// Helpers
import fontSize from '../helpers/fontSize'

// Redux
import { getBlogs } from '../redux/actions/blog_action'

function HomeBlogPost() {
  const smallScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 1023px)' })
  const history = useHistory()
  const dispatch = useDispatch()

  const { blogs } = useSelector((state) => state.blogs);

  const [isContentVisible, setContentVisible] = useState(false)

  useEffect(() => {
    dispatch(getBlogs(6))
  }, [])

  const handleShowBlogDetail = (id) => {
    history.push(`/blog/details/${id}`)
  }

  return (
    <VisibilitySensor onChange={(isVisible) => setContentVisible(isVisible)} active={!isContentVisible} partialVisibility={true}>
      <Animated animationIn="slideInLeft" animationInDuration={2000} isVisible={isContentVisible}>
        <MainContainer smallScreen={smallScreen} mediumScreen={mediumScreen} >
          <SectionHeading
            title='Blog'
            subtitle=''
          />
          <BlogCards
            blogList={blogs}
            handleShowBlogDetail={handleShowBlogDetail}
            fromLandingPage={true}
          />
          <ActionButtonContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
            <SquareButton
              action={() => history.push('/blog')}
              text='See more'
              textSize={smallScreen ? '16px' : mediumScreen ? '18px' : fontSize.bodyLg}
              textColor='#131A91'
              hoverTextColor='white'
              backgroundColor='transparent'
              hoverBgColor='#131A91'
              width={smallScreen ? '225px' : mediumScreen ? '275px' : '325px'}
              border='1px solid #131A91'
            />
          </ActionButtonContainer>
        </MainContainer>
      </Animated>
    </VisibilitySensor>

  )

}

export default HomeBlogPost

const MainContainer = styled.div`
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px 15% 30px 15%' : mediumScreen ? '50px 10% 30px 10%' : '80px 10% 30px 10%'}
`
const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px 10% 30px 10%' : mediumScreen ? '30px 0 0 0' : '50px 0 0 0'}
`