import { useState } from 'react'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Bootstrap
import Container from 'react-bootstrap/Container'

// Animated
import { Animated } from "react-animated-css";

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

// Components
import SectionHeading from './headings/SectionHeading'
import FeatureTile from './FeatureTile'

function HomeOurServices() {
  const smallScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 1400px)' })

  const [isContent1Visible, setContent1Visible] = useState(false)

  return (
    <Container style={{
      padding: smallScreen ? '30px 5vw 30px 5vw' : mediumScreen ? '50px 2vw 40px 2vw' : '50px 0 50px 0'
    }}>
      <VisibilitySensor onChange={(isVisible) => setContent1Visible(isVisible)} active={!isContent1Visible} partialVisibility={true}>
        <Animated animationIn="fadeIn" animationInDuration={2000} isVisible={isContent1Visible}>
          <SectionHeading
            title='Our Services'
            subtitle='Accelerate your digital transformation journey to enhance efficiency, and deliver exceptional user experiences'
          />
        </Animated>
      </VisibilitySensor>
      <FeatureTile />
    </Container>
  )

}

export default HomeOurServices