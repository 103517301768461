import { combineReducers } from 'redux'

import projects from './project_reducers'
import blogs from './blog_reducers'

const reducer = combineReducers({
	projects,
	blogs
})

export default reducer