// React
import { useState, useEffect } from 'react'

// Bootstrap
import { Col, Container, Row } from 'react-bootstrap'

// Animated
import { Animated } from "react-animated-css";

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

// Components
import HeadMeta from '../../components/HeadMeta'
import DiscussForm from '../../components/DiscussForm'

// Styled components
import styled from 'styled-components'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Helpers
import fontSize from '../../helpers/fontSize'
import { device } from '../../helpers/device'

// Images
import DropDownUpIcon from '../../assets/images/icons/dropdown-up-icon.png'
import DropDownIcon from '../../assets/images/icons/dropdown_icon_black.png'

function DevOpsConsulting() {
  const smallScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 1279px)' })
  const largeScreen = useMediaQuery({ query: '(max-width: 1499px)' })

  const [isContent1Visible, setContent1Visible] = useState(false)
  const [isContent2Visible, setContent2Visible] = useState(false)

  const [devOpsServices, setDevOpsServices] = useState([
    {
      title: "Assessment and Strategy",
      description: (
        <BodyText>
          <ul>
            <li><b>Current State Analysis:</b> Thorough evaluation of your existing development, deployment, and operational practices.</li>
            <br />
            <li><b>Customized DevOps Strategy: </b> Development of a tailored DevOps strategy that aligns with your business goals and technology stacks used.</li>
          </ul>
        </BodyText>
      ),
      isDescOpen: true
    },
    {
      title: "Infrastructure as Code (IaC)",
      description: (
        <BodyText>
          <ul>
            <li><b>Automation:</b> Implementing IaC to automate the provisioning and management of your infrastructure.</li>
            <br />
            <li><b>Scalability:</b> Ensuring your infrastructure is scalable, reliable, and easily replicable.</li>
          </ul>
        </BodyText>
      ),
      isDescOpen: false
    },
    {
      title: "Continuous Integration and Continuous Deployment (CI/CD)",
      description: (
        <BodyText>
          <ul>
            <li><b>Pipeline Development:</b> Creating robust CI/CD pipelines to automate the build, test, and deployment processes.</li>
            <br />
            <li><b>Integration:</b> Seamlessly integrating CI/CD tools with your existing workflows and tools.</li>
          </ul>
        </BodyText>
      ),
      isDescOpen: false
    },
    {
      title: "Security and Compliance",
      description: (
        <BodyText>
          <ul>
            <li>Ensuring your DevOps processes comply with industry standards and regulations as well as integrating security practices into the DevOps pipeline</li>
          </ul>
        </BodyText>
      ),
      isDescOpen: false
    },
    {
      title: "Improved Scalability and Maintenance",
      description: (
        <BodyText>
          <ul>
            <li><b>Microservices Architecture:</b> Designing and implementing microservices architectures for improved scalability and maintenance.</li>
          </ul>
        </BodyText>
      ),
      isDescOpen: false
    },
    {
      title: "Collaboration and Culture",
      description: (
        <BodyText>
          <ul>
            <li><b>Cross-Functional Teams:</b> Fostering collaboration between development, operations, and other stakeholders.</li>
            <br />
            <li><b>Continuous Improvement:</b> Promoting a culture of continuous learning and improvement through regular feedback loops and retrospectives.</li>
          </ul>
        </BodyText>
      ),
      isDescOpen: false
    },
    {
      title: "Ongoing Support",
      description: (
        <BodyText>
          <ul>
            <li><b>Ongoing Support:</b> Offering continuous support and guidance to help your team implement and maintain DevOps practices.</li>
          </ul>
        </BodyText>
      ),
      isDescOpen: false
    },
  ])

  const handleDropdown = (i, value) => {
    const newDevOpsServices = devOpsServices.map((item, itemIndex) => {
      if (itemIndex == i) {
        return {
          ...item,
          isDescOpen: !value
        };
      } else {
        return item;
      }
    });
    setDevOpsServices(newDevOpsServices)
  }

  // Scroll window to top on load
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])

  const [animateBannerTitle, setAnimateBannerTitle] = useState(false)

  // Start banner title animation on page load
  useEffect(() => {
    setAnimateBannerTitle(true)
  }, [])

  return (
    <div style={{ overflow: 'hidden' }}>
      <HeadMeta
        title="Cloudxier - DevOps Consulting"
        description="Our mission is to help accelerating business in their digital innovation"
        siteName="Cloudxier"
        url="https://www.cloudxier.com/about/"
      />
      <MainBannerContainer >
        <MainBannerTextContainer animateBannerTitle={animateBannerTitle}>
          <MainBannerTextFlexContainer>
            <Container style={{ padding: smallScreen ? '0 10vw' : mediumScreen ? '0 10vw' : 0 }}>
              <MainBannerTitle smallScreen={smallScreen} mediumScreen={mediumScreen}>
                DevOps Consulting
              </MainBannerTitle>
              <MainBannerSubTitle smallScreen={smallScreen} mediumScreen={mediumScreen}>
                Our team will work closely with you to implement industry-leading practices such as Continuous Integration/Continuous Delivery (CI/CD), Infrastructure as Code (IaC), and automated testing. We provide tailored solutions to streamline your workflows, enhance collaboration between development and operations teams, and ensure robust monitoring and logging for optimal performance.
                <br /> <br />

              </MainBannerSubTitle>
            </Container>
          </MainBannerTextFlexContainer>
        </MainBannerTextContainer>
        <BannerOverlay />
        <HomeBannerImage src='https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/feebcced9bf9d32dd36febb5a9f1c175c19c141f4705b9f3c9cded141b91b067c39beadfd4e7a6812db7aa16a86caa090ee257b292329b586a68016c113a11a878cb0c2a34b26dfd9eec9cf1f70b41b5f893bad49a9f8f6a69bd84a52f0a8aad19b0e83c7e948b00c18a7af2d3025c9387124398340ceb81e410/rez-file-1719307818799.png' alt='main-banner-1' />
      </MainBannerContainer>
      <MainContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
        <Container style={{ padding: smallScreen ? '0 10vw' : mediumScreen ? '0 5vw' : '0 0 50px 0' }}>
          <VisibilitySensor onChange={(isVisible) => setContent1Visible(isVisible)} active={!isContent1Visible} partialVisibility={true}>
            <Animated animationIn="fadeInLeft" animationInDuration={2000} isVisible={isContent1Visible}>
              <Heading>
                How we can help with DevOps
              </Heading>
              <Row style={{ marginTop: smallScreen ? "30px" : mediumScreen ? "40px" : "50px" }}>
                {
                  devOpsServices.map((item, i) => (
                    <Col sm={12} md={12} lg={12} key={i}>
                      <ColOfferContainer smallScreen={smallScreen} mediumScreen={mediumScreen} isDescOpen={item.isDescOpen}>
                        <HeadlineOffer>
                          <TitleOffer smallScreen={smallScreen} mediumScreen={mediumScreen} onClick={() => handleDropdown(i, item.isDescOpen)}>
                            {item.title}
                          </TitleOffer>
                          {item.isDescOpen ? (
                            <DropdownIconContainer smallScreen={smallScreen} mediumScreen={mediumScreen} onClick={() => handleDropdown(i, item.isDescOpen)}>
                              <DropdownIcon src={DropDownIcon} />
                            </DropdownIconContainer>
                          ) : (
                            <DropdownIconContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
                              <DropdownIcon src={DropDownUpIcon} onClick={() => handleDropdown(i, item.isDescOpen)} />
                            </DropdownIconContainer>
                          )}
                        </HeadlineOffer>
                        <DescriptionOffer smallScreen={smallScreen} mediumScreen={mediumScreen} hidden={item.isDescOpen === false} >
                          {item.description}
                        </DescriptionOffer>
                      </ColOfferContainer>
                    </Col>
                  ))
                }
              </Row>
            </Animated>
          </VisibilitySensor>
        </Container>
        <Container>
          <br />
          <br />
          <br />
          <br />
          <br />
          <VisibilitySensor onChange={(isVisible) => setContent2Visible(isVisible)} active={!isContent2Visible} partialVisibility={true}>
            <Animated animationIn="fadeInUp" animationInDuration={2000} isVisible={isContent2Visible}>
              <Heading style={{ textAlign: 'center' }}>
                Discuss with us now.
              </Heading>
              <BodyText style={{ textAlign: 'center' }}>
                Have questions or ready to discuss your project? We're here to help! Please fill in the inquiry form below:
              </BodyText>
              <DiscussForm />
            </Animated>
          </VisibilitySensor>
        </Container>
      </MainContainer>
    </div >
  )

}

export default DevOpsConsulting

const MainContainer = styled.div`
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px 0 30px 0' : mediumScreen ? '30px 0 30px 0' : '50px 0 50px 0'}
`
const MainBannerContainer = styled.div`
  width: 100%;
  height: 80vh;
`
const MainBannerTextContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 80%;
  text-align: left;
  color: white;
  z-index: 2;
  transition: opacity 2s, margin 1s;
  opacity: ${({ animateBannerTitle }) => animateBannerTitle ? '1' : '0'};
  margin-top: ${({ animateBannerTitle }) => animateBannerTitle ? '0' : '-50vh'};
`
const MainBannerTextFlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-top: 7%;
`
const MainBannerTitle = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px' : mediumScreen ? '35px' : '40px'};
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '40px' : mediumScreen ? '30px' : '60px'};
  font-weight: 900;
`
const MainBannerSubTitle = styled.div`
   font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '17px' : mediumScreen ? '20px' : '23px'};
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '0' : mediumScreen ? '0' : '0'};
`
const BannerOverlay = styled.div`
  background-color: rgba(0,0,0,0.3);
  width: 100%;
  height: 80vh;
  position: absolute;
  z-index: 1;
`
const HomeBannerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const Heading = styled.div`
  font-size: ${fontSize.heading1Sm};
  color: #4C39FF;
  font-weight: 900;
  text-align: left;
  margin-top: 24px;
  margin-bottom: 12px;
  @media ${device.tablet} {
    font-size: ${fontSize.heading1Sm};
    margin-top: 48px;
    margin-bottom: 24px;
  }
  @media ${device.laptop} {
    font-size: ${fontSize.heading3Lg};
    margin-top: 48px;
    margin-bottom: 24px;
  }
  @media ${device.laptopM} {
    font-size: ${fontSize.heading2Lg};
    margin-top: 48px;
    margin-bottom: 24px;
  }
  @media ${device.laptopL} {
    font-size: 40px;
    margin-top: 48px;
    margin-bottom: 24px;
  }
`
const SubHeading = styled.p`
  font-size: ${fontSize.heading2Sm};
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 8px;
  @media ${device.tablet} {
    font-size: 20px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptop} {
    font-size: ${fontSize.heading1Sm};
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopM} {
    font-size: ${fontSize.heading3Lg};
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopL} {
    font-size: ${fontSize.heading3Lg};
    margin-top: 32px;
    margin-bottom: 16px;
  }
`
const BodyText = styled.p`
  text-align:justify;
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 8px;
  line-height: 40px;
  @media ${device.tablet} {
    font-size: 18px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptop} {
    font-size: ${fontSize.bodyLg};
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopM} {
    font-size: 22px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopL} {
    font-size: 22px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
`
const ColOfferContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px' : mediumScreen ? '30px 40px' : '30px 40px'};
  height: auto;
  margin-bottom: 30px;
  border-radius: 15px;
  background-color: #F8F8F8;
`
const HeadlineOffer = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  align-items:center;
`
const DropdownIconContainer = styled.div`
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '0px' : mediumScreen ? '0px' : '0'};
`
const DropdownIcon = styled.img`
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? '10px' : mediumScreen ? '15px' : '20px'};
  height: ${({ smallScreen, mediumScreen }) => smallScreen ? '10px' : mediumScreen ? '15px' : '20px'};
  object-fit: contain
`
const TitleOffer = styled.div`
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? 'auto' : mediumScreen ? 'auto' : 'auto'};
  height: ${({ smallScreen, mediumScreen }) => smallScreen ? 'auto' : mediumScreen ? 'auto' : 'auto'};
  color: ${({ textColor }) => textColor ? textColor : 'black'};
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '20px' : mediumScreen ? '25px' : '30px'};
  font-weight: 700;
`
const DescriptionOffer = styled.div`
  margin-top: ${({ smallScreen, mediumScreen }) => smallScreen ? '18px' : mediumScreen ? '18px' : '18px'};
  color: ${({ textColor }) => textColor ? textColor : 'black'};
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '16px' : mediumScreen ? '20px' : '22px'};
  font-weight: 400;
  text-align: justify;
`