// React
import { useState } from 'react'

// React router dom
import { Link } from 'react-router-dom'

// React responsive
import { useMediaQuery } from 'react-responsive'

// React bootstrap
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// Styled components
import styled from 'styled-components'

// Helpers
import fontSize from '../helpers/fontSize'

// React-redux
import { connect } from 'react-redux'

const mapStateToProps = state => ({
  projects: state.projects.projects
})

function ProjectColumn({ projects }) {
  const smallScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 1279px)' })

  return (
    <Container style={{
      padding: smallScreen ? '0 5vw 20px 5vw' : mediumScreen ? '0 5vw 20px 5vw' : '0 0 50px 0'
    }}>
      <Row style={{ display: "flex", justifyContent: "center" }}>
        {
          projects.map((project, index) => {
            return (
              <Col key={index} sm={12} md={6} lg={6} style={{ padding: smallScreen ? '0' : mediumScreen ? '0px 20px' : "0 20px" }}>
                <ContentContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
                  <ImageContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
                    <Image src={project.image} />
                  </ImageContainer>
                  <TextContainer>
                    <Category smallScreen={smallScreen} mediumScreen={mediumScreen}>
                      {project.category}
                    </Category>
                    <Title smallScreen={smallScreen} mediumScreen={mediumScreen}>
                      <Link to={`/projects/details?id=${project.id}`} style={{ color: 'black' }} params={{ project: project }}>
                        {project.title}
                      </Link>
                    </Title>
                  </TextContainer>
                </ContentContainer>
              </Col>
            )
          })
        }
      </Row>
    </Container>
  )

}

export default connect(mapStateToProps, null)(ProjectColumn)

const ContentContainer = styled.div`
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '20px 0' : mediumScreen ? '20px 0' : '20px 0'};
  :hover {
    -ms-transform: scale(1.05); /* IE 9 */
    -webkit-transform: scale(1.05); /* Safari 3-8 */
    transform: scale(1.05); 
  }
`
const ImageContainer = styled.div`
  width: auto;
  height: ${({ smallScreen, mediumScreen }) => smallScreen ? '175px' : mediumScreen ? '200px' : '300px'};
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '15px' : mediumScreen ? '20px' : '20px'};
`
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const Category = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '16px' : mediumScreen ? '18px' : fontSize.bodyLg};
  text-transform: uppercase;
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '6px' : mediumScreen ? '8px' : '10px'};
  text-align: center
`
const Title = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '20px' : mediumScreen ? '25px' : fontSize.heading3Lg};
  font-weight: bold;
  text-align: center
`