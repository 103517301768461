import { useState } from 'react'

// Bootstrap
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// Styled components
import styled, { keyframes } from 'styled-components'

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Helpers
import fontSize from '../helpers/fontSize'

function HomeTestimonials() {
  const smallScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 1023px)' })

  const [isVisibleComponent, setVisibleComponent] = useState(false)

  const [testimonials, setTestimonials] = useState([
    {
      content: 'The team are professional, courteous, and very responsive. I would recommend them for your website design requirements. We have used them again for another organisation I represent and will continue to do so. Thank you team great work.',
      author: 'Tony, Ausmincham'
    },
    {
      content: "We collaborate with Cloudxier on a mobile app development project. They are experts regarding UI and UX design. Cloudxier has obviously put a lot of thought and expertise in designing their work, the structure was clear, logical and effective. That was a successful project for all parties!",
      author: 'Nola Romana, M-Plus Software'
    },
    {
      content: 'It was a wonderful experience working with Cloudxier! What stands out the most is the cooperation they showed when working on the corporate website. They were very happy to provide full service from the moment we had our first meeting, and their recommendations opened new insights for us.',
      author: 'Thea Samantha, Heavenly Blush'
    }
  ])

  return (
    <VisibilitySensor onChange={(isVisible) => setVisibleComponent(isVisible)} active={!isVisibleComponent} partialVisibility={true}>
      <MainContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
        <Container style={{ padding: smallScreen ? '0 10vw' : mediumScreen ? '0 5vw' : 0 }}>
          <TitleContainer smallScreen={smallScreen} mediumScreen={mediumScreen} isVisibleComponent={isVisibleComponent}>
            <Title smallScreen={smallScreen} mediumScreen={mediumScreen}>
              What customers say about our services
            </Title>
          </TitleContainer>
          <TestimonialContainer smallScreen={smallScreen} mediumScreen={mediumScreen} isVisibleComponent={isVisibleComponent}>
            <Container fluid={smallScreen ? 'true' : mediumScreen ? 'true' : false}>
              <Row style={{ display: "flex", justifyContent: "center" }}>
                {
                  testimonials.map((item, i) => {
                    return (
                      <Col sm={12} md={4} key={i}>
                        <Testimonial smallScreen={smallScreen} mediumScreen={mediumScreen}>
                          <ContentContainer>
                            <Content smallScreen={smallScreen} mediumScreen={mediumScreen}>
                              {item.content}
                            </Content>
                            <Author smallScreen={smallScreen} mediumScreen={mediumScreen}>
                              {item.author}
                            </Author>
                          </ContentContainer>
                        </Testimonial>
                      </Col>
                    )
                  })
                }
              </Row>
            </Container>
          </TestimonialContainer>
        </Container>
        <BlueBackground
          isVisibleComponent={isVisibleComponent}
          smallScreen={smallScreen}
          mediumScreen={mediumScreen}
        />
      </MainContainer>
    </VisibilitySensor>
  )

}

export default HomeTestimonials

const moveTitleText = keyframes`
  0% { top: 40% }
  50% { top: 10% }
  100% { top: 10% }
`
const moveColumn = keyframes`
  0% { 
    right: -40%; 
    left: 40%; 
    bottom: 0; 
    top: 0; 
    margin: auto 
  }
  50% {
    right: -40%; 
    left: 40%; 
    bottom: 0; 
    top: 20%; 
    margin: auto 
  }
  100% { 
    right: 0; 
    left: 0; 
    bottom: 0; 
    top: 20%; 
    margin: auto 
  }
`
const moveBlueBackground = keyframes`
  0% {
    right: -100%;
    opacity: 0
  }
  100% {
    right: 0;
    opacity: 1
  }
`
const MainContainer = styled.div`
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px 0 30px 0' : mediumScreen ? '30px 0 30px 0' : '50px 0 50px 0'};
  height: ${({ smallScreen, mediumScreen }) => smallScreen ? '100%' : mediumScreen ? '100%' : '800px'};
  position: relative;
  display: flex;
  align-items: center;
  ${({ smallScreen, mediumScreen }) => smallScreen ? 'background-image: linear-gradient(to bottom, #4762E2 , #2611C9);' : mediumScreen ? 'background-image: linear-gradient(to bottom, #4762E2 , #2611C9);' : 'background-color: #F3F3F3;'}
`
const BlueBackground = styled.div`
  visibility: ${({ smallScreen, mediumScreen }) => smallScreen ? 'hidden' : mediumScreen ? 'hidden' : 'visible'};
  background-image: linear-gradient(to bottom, #4762E2 , #2611C9);
  position: absolute;
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? '100%' : mediumScreen ? '100%' : '50%'};
  height: ${({ smallScreen, mediumScreen }) => smallScreen ? '100%' : mediumScreen ? '100%' : '800px'};
  right: 0;
  z-index: 1;
  animation: ${moveBlueBackground} .5s;
  animation-play-state: ${({ isVisibleComponent }) => isVisibleComponent ? 'running' : 'paused'};
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`
const TitleContainer = styled.div`
  position: ${({ smallScreen, mediumScreen }) => smallScreen ? 'static' : mediumScreen ? 'static' : 'absolute'};
  top: 40%;
  bottom: 0;
  margin: auto;
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px' : mediumScreen ? '30px' : 'auto'};
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? '100%' : mediumScreen ? '100%' : '400px'};
  text-align: ${({ smallScreen, mediumScreen }) => smallScreen ? 'center' : mediumScreen ? 'center' : 'left'};
  animation: ${moveTitleText} 1s;
  animation-play-state: ${({ isVisibleComponent }) => isVisibleComponent ? 'running' : 'paused'};
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`
const Title = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '25px' : mediumScreen ? '30px' : fontSize.heading2Lg};
  font-weight: 700;
  color: ${({ smallScreen, mediumScreen }) => smallScreen ? 'white' : mediumScreen ? 'white' : 'black'}
`
const TestimonialContainer = styled.div`
  top: 0;
  bottom: 0;
  left: 40%;
  right: -40%;
  margin: auto;
  position: ${({ smallScreen, mediumScreen }) => smallScreen ? 'static' : mediumScreen ? 'static' : 'absolute'};
  display: ${({ smallScreen, mediumScreen }) => smallScreen ? 'block' : mediumScreen ? 'block' : 'flex'};
  align-items: center;
  animation: ${moveColumn} 1s;
  animation-play-state: ${({ isVisibleComponent }) => isVisibleComponent ? 'running' : 'paused'};
  animation-delay: .2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  width: 100%;
  z-index: 2
`
const Testimonial = styled.div`
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? "100%" : mediumScreen ? "100%" : '100%'};;
  height: ${({ smallScreen, mediumScreen }) => smallScreen ? 'auto' : mediumScreen ? 'auto' : 'calc(700px - 12vw)'}; // Height increases as viewport width decreases
  background-color: #336AFE;
  border-radius: 5px;
  display: ${({ smallScreen, mediumScreen }) => smallScreen ? 'flex' : mediumScreen ? 'flex' : 'block'};
  align-items: center;
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px 30px' : mediumScreen ? '30px 30px' : '30px 30px'};
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px' : mediumScreen ? '50px' : '0'}
`
const ContentContainer = styled.div`
  
`
const Content = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '16px' : mediumScreen ? '18px' : fontSize.bodyLg};
  color: white;
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px' : mediumScreen ? '20px' : '40px'};
  text-align: ${({ smallScreen, mediumScreen }) => smallScreen ? 'justify' : mediumScreen ? 'left' : 'left'};
`
const Author = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '14px' : mediumScreen ? '16px' : '18px'};
  color: white;
  font-style: italic
`