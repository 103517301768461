import { useState } from 'react'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Styled components
import styled from 'styled-components'

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

// Helpers
import fontSize from '../../helpers/fontSize'

function ContactHeading({ title, subtitle, titleColor, subtitleColor }) {

  const smallScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 1279px)' })

  const [isHeadingVisible, setHeadingVisible] = useState(false)

  return (
    <VisibilitySensor onChange={(isVisible) => setHeadingVisible(isVisible)} partialVisibility={true} active={!isHeadingVisible}>
      <HeadingContainer
        isHeadingVisible={isHeadingVisible}
        smallScreen={smallScreen}
        mediumScreen={mediumScreen}
      >
        <Title smallScreen={smallScreen} mediumScreen={mediumScreen} titleColor={titleColor}>
          {title}
        </Title>
        <Subtitle smallScreen={smallScreen} mediumScreen={mediumScreen} subtitleColor={subtitleColor}>
          {subtitle}
        </Subtitle>
      </HeadingContainer>
    </VisibilitySensor>
  )

}

export default ContactHeading

const HeadingContainer = styled.div`
  opacity: ${({ isHeadingVisible }) => isHeadingVisible ? 1 : 0};
  transition: opacity 1s;
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '25px' : mediumScreen ? '37.5px' : '50px'}
`

const Title = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? fontSize.heading2Sm : mediumScreen ? fontSize.heading1Sm : fontSize.heading2Lg};
  color: ${({ titleColor }) => titleColor || 'black'};
  font-weight: bold;
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '15px' : mediumScreen ? '22.5px' : '30px'}
`

const Subtitle = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? fontSize.heading2Sm : mediumScreen ? fontSize.heading1Sm : fontSize.heading2Lg};
  color: ${({ subtitleColor }) => subtitleColor || 'black'};
`