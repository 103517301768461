import { useEffect, useState } from 'react'

// React router dom
import { Link, useLocation, useHistory } from "react-router-dom";

// Styled components
import styled, { keyframes, css } from 'styled-components'

// Bootstrap
import Container from 'react-bootstrap/Container'

// React responsive
import MediaQuery, { useMediaQuery } from 'react-responsive'

// Images
import InstagramLightLogo from '../assets/images/icons/instagram_light.svg'
import FacebookLightLogo from '../assets/images/icons/facebook_light.svg'
import LinkedinLightLogo from '../assets/images/icons/linkedin_light.svg'

function SocmedHeader() {
  const smallScreen = useMediaQuery({ query: '(max-width: 1023px)' })

  return (
    <HeaderContainer>
      <Container style={{ padding: smallScreen ? '0 5vw' : 0 }} fluid>
        <FlexContainer>
          <SocmedTextContainer>
            FOLLOW US ON OUR SOCIAL MEDIA
          </SocmedTextContainer>
          <SocmedLogoContainer>
            <Image src={FacebookLightLogo} onClick={() => window.open('https://www.facebook.com/CloudxierIndonesia', '_blank')} />
            <Image src={InstagramLightLogo} onClick={() => window.open('https://www.instagram.com/cloudxier/', '_blank')} />
            <Image src={LinkedinLightLogo} onClick={() => window.open('https://www.linkedin.com/company/cloudxier', '_blank')} />
          </SocmedLogoContainer>
        </FlexContainer>
      </Container>
    </HeaderContainer>
  )
}

export default SocmedHeader

const HeaderContainer = styled.div`
  position: fixed;
  right: 0;
  left: 0;
  z-index: 10;
  background-color: #131A91;
  margin-bottom: 100px;
  transition: opacity 1s;
  transition-delay: .5s;
  margin-bottom: 100px;
  padding: 0 15vw;
`
const FlexContainer = styled.div`
  width: 100%;
  display: flex; 
  align-items: center;
  padding: 10px 0;
  color: white;
`
const SocmedTextContainer = styled.div`
  font-weight: bold;
`
const SocmedLogoContainer = styled.div`
  width: 50%;
  display: flex;
  margin-left: 15px;
  gap: 10px;
`
const Image = styled.img`
  width: 4%;
  margin-inline: 5px;
  cursor: pointer;
`
