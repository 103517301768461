// React
import { useState, useEffect } from 'react'

// Bootstrap
import { Col, Container, Row } from 'react-bootstrap'

// Animated
import { Animated } from "react-animated-css";

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

// Components
import HeadMeta from '../../components/HeadMeta'
import DiscussForm from '../../components/DiscussForm'

// Styled components
import styled from 'styled-components'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Helpers
import fontSize from '../../helpers/fontSize'
import { device } from '../../helpers/device'

// Images
import DropDownUpIcon from '../../assets/images/icons/dropdown-up-icon.png'
import DropDownIcon from '../../assets/images/icons/dropdown_icon_black.png'

function CloudComputingConsultation() {
  const smallScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 1279px)' })
  const largeScreen = useMediaQuery({ query: '(max-width: 1499px)' })

  const [isContent1Visible, setContent1Visible] = useState(false)
  const [isContent2Visible, setContent2Visible] = useState(false)
  const [isContent3Visible, setContent3Visible] = useState(false)
  const [isContent4Visible, setContent4Visible] = useState(false)
  const [isContent5Visible, setContent5Visible] = useState(false)

  const [consultationServices, setConsultationServices] = useState([
    {
      title: "Cloud Readiness Assessment",
      description: (
        <BodyText>
          <ul>
            <li><b>Comprehensive Analysis:</b> Evaluating your current IT environment to determine readiness for cloud adoption and identify any potential challenges.</li>
            <li><b>Recommendations:</b> Providing detailed recommendations and best practices for a successful cloud transition.</li>
          </ul>
        </BodyText>
      ),
      isDescOpen: true
    },
    {
      title: "Technology and Vendor Selection",
      description: (
        <BodyText>
          <ul>
            <li><b>Expert Guidance:</b> Assisting you in selecting the right cloud technologies and vendors that best meet your business needs and goals.</li>
            <li><b>RFP Assistance:</b> Helping you create and evaluate Requests for Proposals (RFPs) to ensure you choose the best solutions.</li>
          </ul>
        </BodyText>
      ),
      isDescOpen: false
    },
    {
      title: "Ongoing Support and Advisory",
      description: (
        <BodyText>
          <ul>
            <li>Offering ongoing support and advisory services to continuously optimize your cloud environment and strategies.</li>
          </ul>
        </BodyText>
      ),
      isDescOpen: false
    },
  ])

  const [processContent, setProcessContent] = useState([
    {
      img: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/1f2f65a3d153212640c287eb518cc82993250222152e58c695df92e303c5e1ad3c1a3887ef47fa338e8bdf35b1e700547aaebd8cc335627e376a46379b925dea5096e2faa685d9f1e4173ac23a166bc2322e9d01a773a910383c81bb4718fd3fbebcbc8c0527cf831723b05ca1bdbc6eb78d8824606f690c0464/rez-file-1719545704789.png',
      content: (<BodyText style={{ fontWeight: "bold" }}>Reduce operating costs with cloud optimization best practices</BodyText>)
    },
    {
      img: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/54e1c53125c11ba905d50c780f59929bfa62d50ff11cd8ecf74621f43510cbf3a73946c3a36563200f5468c8fae807de358c18b28e422be4f7ca089e13626213896a3a59235450bd3219982237a01c29fc56fee4938a304e249b68807c1a9cb4de5e1ac96d7e56d70c4c269eff64845ed440332ac00dbe096f49/rez-file-1719545725489.png',
      content: (<BodyText style={{ fontWeight: "bold" }}>More efficient monitoring and maintenance</BodyText>)
    },
    {
      img: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/c975708b7dc8c210b5a7b623228bde1705d268f31429e62084e5c3ba604bd90f8b3e813f7f1c0f38c0e336e73996c69de8e9f5f5edef09f865df84a7119607c8b3b0d043e12b4b787a3820415fcf836935e0a763179b113c9481a3b0648183e5dc52a1b08d72204a94e85eb8b8b9b209841f02c466962c611f3e/rez-file-1719545752085.png',
      content: (<BodyText style={{ fontWeight: "bold" }}>Reduce unplanned downtime</BodyText>)
    },
    {
      img: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/ef29a5b9a2d5ef5096ddb9580815a3fbcb16c0479e082c3348bc13838ba06051224be1696b7ed76c156c1c2befb7e585a91e7ee173900f85e7134c0ee1a6f1cbaa19289a8f082066a9135302472262e31d10c83d24b180f099c64ced508a9edd3d4e16777d5d44e1843dc67d1420d0cec7527f232c8f4f998380/rez-file-1719545772494.png',
      content: (<BodyText style={{ fontWeight: "bold" }}>More efficient IT infrastructure management</BodyText>)
    }
  ])

  const handleDropdown = (i, value) => {
    const newConsultationServices = consultationServices.map((item, itemIndex) => {
      if (itemIndex == i) {
        return {
          ...item,
          isDescOpen: !value
        };
      } else {
        return item;
      }
    });
    setConsultationServices(newConsultationServices)
  }

  // Scroll window to top on load
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])

  const [animateBannerTitle, setAnimateBannerTitle] = useState(false)

  // Start banner title animation on page load
  useEffect(() => {
    setAnimateBannerTitle(true)
  }, [])

  return (
    <div style={{ overflow: 'hidden' }}>
      <HeadMeta
        title="Cloudxier - Cloud Computing and Consultation"
        description="Our mission is to help accelerating business in their digital innovation"
        siteName="Cloudxier"
        url="https://www.cloudxier.com/about/"
      />
      <MainBannerContainer >
        <MainBannerTextContainer animateBannerTitle={animateBannerTitle}>
          <MainBannerTextFlexContainer>
            <Container style={{ padding: smallScreen ? '0 10vw' : mediumScreen ? '0 10vw' : 0 }}>
              <MainBannerTitle smallScreen={smallScreen} mediumScreen={mediumScreen}>
                Cloud Computing and Consultation
              </MainBannerTitle>
              <MainBannerSubTitle smallScreen={smallScreen} mediumScreen={mediumScreen}>
                Our mobile app development service is tailor-made to align with your business objectives.
                <br /> <br />
                From iOS, Android or Hybrid cross-platform app development that allows your app to run smoothly across various devices and operating systems, ensuring you get more value for your investment.
              </MainBannerSubTitle>
            </Container>
          </MainBannerTextFlexContainer>
        </MainBannerTextContainer>
        <BannerOverlay />
        <HomeBannerImage src='https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/bcfbeaadc76e1fb664a3dee7e08a96c683185051ff2ebe90f9a2e752228943a3e4c44e8c52d5efaab681a14ec23d4ea77353a510de7193e7d8c116289da860d7b644d687d1a450876c12e3628f920e9a3da49d5b80dde4d8b82f901fafca62e36400b03c9eb8f5a1db6ed1981c128f7f4fed21c2c04b3edf4457/rez-file-1719307546498.png' alt='main-banner-1' />
      </MainBannerContainer>
      <MainContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
        <Container style={{ padding: smallScreen ? '0 10vw' : mediumScreen ? '0 5vw' : '0 0 50px 0' }}>
          <VisibilitySensor onChange={(isVisible) => setContent1Visible(isVisible)} active={!isContent1Visible} partialVisibility={true}>
            <Animated animationIn="fadeInLeft" animationInDuration={2000} isVisible={isContent1Visible}>
              <Heading>
                What We Offer
              </Heading>
              <Row style={{ marginTop: smallScreen ? "30px" : mediumScreen ? "40px" : "50px" }}>
                {
                  consultationServices.map((item, i) => (
                    <Col sm={12} md={12} lg={12} key={i}>
                      <ColOfferContainer smallScreen={smallScreen} mediumScreen={mediumScreen} isDescOpen={item.isDescOpen}>
                        <HeadlineOffer>
                          <TitleOffer smallScreen={smallScreen} mediumScreen={mediumScreen} onClick={() => handleDropdown(i, item.isDescOpen)}>
                            {item.title}
                          </TitleOffer>
                          {item.isDescOpen ? (
                            <DropdownIconContainer smallScreen={smallScreen} mediumScreen={mediumScreen} onClick={() => handleDropdown(i, item.isDescOpen)}>
                              <DropdownIcon src={DropDownIcon} />
                            </DropdownIconContainer>
                          ) : (
                            <DropdownIconContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
                              <DropdownIcon src={DropDownUpIcon} onClick={() => handleDropdown(i, item.isDescOpen)} />
                            </DropdownIconContainer>
                          )}
                        </HeadlineOffer>
                        <DescriptionOffer smallScreen={smallScreen} mediumScreen={mediumScreen} hidden={item.isDescOpen === false} >
                          {item.description}
                        </DescriptionOffer>
                      </ColOfferContainer>
                    </Col>
                  ))
                }
              </Row>
            </Animated>
          </VisibilitySensor>
          <VisibilitySensor onChange={(isVisible) => setContent2Visible(isVisible)} active={!isContent2Visible} partialVisibility={true}>
            <Animated animationIn="zoomIn" animationInDuration={2000} isVisible={isContent2Visible}>
              <HomeBannerImage src={'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/7f665e2ef9d7d189b9b5b380eff0364d15b2783771da1a0d489486b8d541f20281709462c6d7822ed8bee8c086140925bb35f08e62e9690923c633fb8927b9d6f9566636a69cb8fcece5688381a0b79efe605f90031e5a7b2805f52318bad1430b91b8541cf3dd3f038db6697e1c453a35b565c8ff10abde5b0d/rez-file-1719545679086.png'} alt='main-banner-2' />
            </Animated>
          </VisibilitySensor>
          <VisibilitySensor onChange={(isVisible) => setContent3Visible(isVisible)} active={!isContent3Visible} partialVisibility={true}
          >
            <Animated animationIn="fadeInRight" animationInDuration={2000} isVisible={isContent3Visible}>
              <SubHeading>
                Cloud Strategy and Planning
              </SubHeading>
              <BodyText>
                <ul>
                  <li><b>Tailored Roadmaps:</b> We develop customized cloud strategies and roadmaps that align with your business goals, ensuring a smooth transition to the cloud.</li>
                  <li><b>Cost-Benefit Analysis:</b> Conduct a thorough cost-benefit analysis to help you understand the financial and operational impacts of cloud adoption.</li>
                </ul>
              </BodyText>
              <SubHeading>
                Cloud Migration Services
              </SubHeading>
              <BodyText>
                <ul>
                  <li><b>Assessment and Planning:</b> We assess your current IT environment and plan the migration to the cloud to minimize the downtime.</li>
                  <li><b>Data and Application Migration:</b> Our experts handle the migration of data, applications, and workloads to the cloud, ensuring integrity and security throughout the process.</li>
                </ul>
              </BodyText>
              <SubHeading>
                Cloud Infrastructure Management
              </SubHeading>
              <BodyText>
                <ul>
                  <li><b>Scalable Solutions:</b> We design and manage scalable cloud infrastructures that can grow with your business, ensuring optimal performance and cost-efficiency.</li>
                  <li><b>Monitoring and Maintenance:</b> Continuous monitoring and proactive maintenance of your cloud environment to ensure reliability and performance.</li>
                </ul>
              </BodyText>
              <SubHeading>
                Cloud Security and Compliance
              </SubHeading>
              <BodyText>
                <ul>
                  <li><b>Security Measures:</b> Implementation of security to protect your data and applications in the cloud.</li>
                  <li><b>Compliance Management:</b> Ensuring that your cloud operations comply with relevant industry standards and regulations.</li>
                </ul>
              </BodyText>
              <SubHeading>
                DevOps and Automation
              </SubHeading>
              <BodyText>
                <ul>
                  <li><b>CI/CD Pipelines:</b> We set up continuous integration and continuous deployment (CI/CD) pipelines to streamline your development processes and reduce time-to-market.</li>
                </ul>
              </BodyText>
              <SubHeading>
                Cloud Cost Optimization
              </SubHeading>
              <BodyText>
                <ul>
                  <li><b>Resource Management:</b> Optimizing resource allocation and usage to reduce costs and improve efficiency.</li>
                  <li><b>Billing and Budgeting:</b> Providing detailed insights and management tools to track and control your cloud spending effectively.</li>
                </ul>
              </BodyText>
              <SubHeading>
                Backup Solutions
              </SubHeading>
              <BodyText>
                <ul>
                  <li><b>Business Continuity Planning:</b> Developing and implementing robust disaster recovery plans to ensure business continuity in the event of an outage or disaster.</li>
                  <li><b>Automated Backups:</b> Setting up automated backup solutions to protect your data and enable quick recovery.</li>
                </ul>
              </BodyText>
            </Animated>
          </VisibilitySensor>
          <Container style={{ marginTop: smallScreen ? '60px' : mediumScreen ? '70px' : '80px' }}>
            <VisibilitySensor onChange={(isVisible) => setContent4Visible(isVisible)} active={!isContent4Visible} partialVisibility={true}>
              <Animated animationIn="slideInUp" animationInDuration={2000} isVisible={isContent4Visible}>
                <Heading>
                  The right cloud development strategy and planning for software engineering benefit business to:
                </Heading>
                <Row style={{ marginTop: smallScreen ? '30px' : mediumScreen ? '40px' : '50px' }}>
                  {
                    processContent.map((item, i) => {
                      return (
                        <Col key={i} sm={12} md={6} lg={6} style={{ padding: smallScreen ? '0' : mediumScreen ? '0px 50px 25px 0' : "0px 60px 30px 0" }}>
                          <img src={item.img} style={{ width: smallScreen ? '125px' : mediumScreen ? '150px' : '175px', height: smallScreen ? '125px' : mediumScreen ? '150px' : '175px' }} alt={`image-${i}`} />
                          {item.content}
                        </Col>
                      )
                    })
                  }
                </Row>
              </Animated>
            </VisibilitySensor>
          </Container>
        </Container>
        <Container>
          <br />
          <br />
          <br />
          <br />
          <br />
          <VisibilitySensor onChange={(isVisible) => setContent5Visible(isVisible)} active={!isContent5Visible} partialVisibility={true}>
            <Animated animationIn="fadeInUp" animationInDuration={2000} isVisible={isContent5Visible}>
              <Heading style={{ textAlign: 'center' }}>
                Discuss with us now..
              </Heading>
              <BodyText style={{ textAlign: 'center' }}>
                Have questions or ready to discuss your project? We're here to help! Please fill in the inquiry form below:
              </BodyText>
              <DiscussForm />
            </Animated>
          </VisibilitySensor>
        </Container>
      </MainContainer>
    </div >
  )

}

export default CloudComputingConsultation

const MainContainer = styled.div`
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px 0 30px 0' : mediumScreen ? '30px 0 30px 0' : '50px 0 50px 0'}
`
const MainBannerContainer = styled.div`
  width: 100%;
  height: 80vh;
`
const MainBannerTextContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 80%;
  text-align: left;
  color: white;
  z-index: 2;
  transition: opacity 2s, margin 1s;
  opacity: ${({ animateBannerTitle }) => animateBannerTitle ? '1' : '0'};
  margin-top: ${({ animateBannerTitle }) => animateBannerTitle ? '0' : '-50vh'};
`
const MainBannerTextFlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-top: 7%;
`
const MainBannerTitle = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px' : mediumScreen ? '35px' : '40px'};
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '40px' : mediumScreen ? '30px' : '60px'};
  font-weight: 900;
`
const MainBannerSubTitle = styled.div`
   font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '17px' : mediumScreen ? '20px' : '23px'};
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '0' : mediumScreen ? '0' : '0'};
`
const BannerOverlay = styled.div`
  background-color: rgba(0,0,0,0.3);
  width: 100%;
  height: 80vh;
  position: absolute;
  z-index: 1;
`
const HomeBannerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const Heading = styled.div`
  font-size: ${fontSize.heading1Sm};
  color: #4C39FF;
  font-weight: 900;
  text-align: left;
  margin-top: 24px;
  margin-bottom: 12px;
  @media ${device.tablet} {
    font-size: ${fontSize.heading1Sm};
    margin-top: 48px;
    margin-bottom: 24px;
  }
  @media ${device.laptop} {
    font-size: ${fontSize.heading3Lg};
    margin-top: 48px;
    margin-bottom: 24px;
  }
  @media ${device.laptopM} {
    font-size: ${fontSize.heading2Lg};
    margin-top: 48px;
    margin-bottom: 24px;
  }
  @media ${device.laptopL} {
    font-size: 40px;
    margin-top: 48px;
    margin-bottom: 24px;
  }
`
const SubHeading = styled.p`
  font-size: ${fontSize.heading2Sm};
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 8px;
  @media ${device.tablet} {
    font-size: 20px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptop} {
    font-size: ${fontSize.heading1Sm};
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopM} {
    font-size: ${fontSize.heading3Lg};
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopL} {
    font-size: ${fontSize.heading3Lg};
    margin-top: 32px;
    margin-bottom: 16px;
  }
`
const BodyText = styled.p`
  text-align:justify;
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 8px;
  line-height: 40px;
  @media ${device.tablet} {
    font-size: 18px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptop} {
    font-size: ${fontSize.bodyLg};
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopM} {
    font-size: 22px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopL} {
    font-size: 22px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
`
const ColOfferContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px' : mediumScreen ? '30px 40px' : '30px 40px'};
  height: auto;
  margin-bottom: 30px;
  border-radius: 15px;
  background-color: #F8F8F8;
`
const HeadlineOffer = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  align-items:center;
`
const DropdownIconContainer = styled.div`
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '0px' : mediumScreen ? '0px' : '0'};
`
const DropdownIcon = styled.img`
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? '10px' : mediumScreen ? '15px' : '20px'};
  height: ${({ smallScreen, mediumScreen }) => smallScreen ? '10px' : mediumScreen ? '15px' : '20px'};
  object-fit: contain
`
const TitleOffer = styled.div`
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? 'auto' : mediumScreen ? 'auto' : 'auto'};
  height: ${({ smallScreen, mediumScreen }) => smallScreen ? 'auto' : mediumScreen ? 'auto' : 'auto'};
  color: ${({ textColor }) => textColor ? textColor : 'black'};
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '20px' : mediumScreen ? '25px' : '30px'};
  font-weight: 700;
`
const DescriptionOffer = styled.div`
  margin-top: ${({ smallScreen, mediumScreen }) => smallScreen ? '18px' : mediumScreen ? '18px' : '18px'};
  color: ${({ textColor }) => textColor ? textColor : 'black'};
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '16px' : mediumScreen ? '20px' : '22px'};
  font-weight: 400;
  text-align: justify;
`