// React
import { useState, useEffect } from 'react'

// Bootstrap
import { Col, Container, Row } from 'react-bootstrap'

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

// Animated
import { Animated } from "react-animated-css";

// Components
import HeadMeta from '../../components/HeadMeta'
import DiscussForm from '../../components/DiscussForm'

// Styled components
import styled from 'styled-components'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Helpers
import fontSize from '../../helpers/fontSize'
import { device } from '../../helpers/device'

// Components
import PostColumn from '../../components/PostColumn'

// Images
import dedicatedTeamIcon1 from '../../assets/images/dedicated_tech_team/dedicated_team_icon_1.png'
import dedicatedTeamIcon2 from '../../assets/images/dedicated_tech_team/dedicated_team_icon_2.png'
import dedicatedTeamIcon3 from '../../assets/images/dedicated_tech_team/dedicated_team_icon_3.png'

function DedicatedTechTeam() {
  const smallScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 1279px)' })
  const largeScreen = useMediaQuery({ query: '(max-width: 1499px)' })

  const [isContent1Visible, setContent1Visible] = useState(false)
  const [isContent2Visible, setContent2Visible] = useState(false)
  const [isContent3Visible, setContent3Visible] = useState(false)
  const [isContent4Visible, setContent4Visible] = useState(false)
  const [isContent5Visible, setContent5Visible] = useState(false)

  const [processContent, setProcessContent] = useState([
    {
      img: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/42f67d38e3e8d3fe2a086745b0fceb9e3a2875135c625660a795f0199e06b7cb7e679521e5b48fb0df9855ecc5c5a0252582c554d2f497a2c1d1d72772f9768d00b1b6e603b4e852768379f5717e5ad2645c6da89913c60fa1b26f9f6f2fdea261acd89ee2628d69732ce6cda532444a808540649f1e161823ea/rez-file-1719545974203.png',
      content: (<>
        <SubHeading>
          Needs Assessment
        </SubHeading>
        <BodyText>
          We start by understanding your business needs, project requirements, and technological challenges to identify the right resources for you.
        </BodyText>
      </>)
    },
    {
      img: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/8d1fd1b720f2f86e0eedd807b6143e8d254b70577deeab3623238887b52d564296c3c7ff3563501c7e2394994e5412573057e1eaa8b0191701f282e73ff20335439c4d12bc5b03b8a6c89d6d53d9ba3a7222380a0404bed7ec029130f4167f2155a978dc1aaac8569d9ad68e6f95a754cbe0738436d6302bf95f/rez-file-1719545990249.png',
      content: (<>
        <SubHeading>
          Team Selection
        </SubHeading>
        <BodyText>
          Based on your requirements, we select a team of experts with the relevant skills and experience to meet your project goals.
        </BodyText>
      </>)
    },
    {
      img: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/24edca677a385b4fb91630aea01532306c5996b78785449b09982cadf74ff340950fc2dfa180f03f01b56a1dfc2678aceaa44f6eab7355f6bf8347610ed2db7cc58fcaa9bfcaa79edb4ac2c35cd657aec105e0bdaaa2a1db0f0a6e252dcf9dae558f22a6999fb529f5c7b94a5b651e34d03f5e9cfe18aeef3e6d/rez-file-1719546005387.png',
      content: (<>
        <SubHeading>
          Onboarding and Integration
        </SubHeading>
        <BodyText>
          Our team undergoes a thorough onboarding process to integrate seamlessly with your in-house team, ensuring smooth collaboration and communication.
        </BodyText>
      </>)
    },
    {
      img: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/522a7875f4fcc840d67c7f67b9953f809c4290af2f83e11a8a26007ed2892d7a03633b13c4f0b206a2cd1f3e2e051a4a7e5f60e38a751edaa7f51c88aca0649698abdd2f38a6c2d923b5f804916cfa4391ccdaeef2cfce88a9eed239d4d3bb1b5db035da1b6eb9057bff01cad7ba6201183e8ce992c297102b7c/rez-file-1719546019549.png',
      content: (<>
        <SubHeading>
          Project Execution
        </SubHeading>
        <BodyText>
          We follow agile methodologies and best practices to ensure efficient project execution, timely delivery, and high-quality outcomes.
        </BodyText>
      </>)
    },
    {
      img: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/fb63b268a8200d45c379744e8bf11a2ba439d4a29ee3bd1beb7a7239727a4a0391063ecd3aa9e98b57a5490d3a72f1d67576d9b3624a21843b71d719b80caf46c7040221433ee203c9503894fde657b221c62f4eef134bd77c8e8102f68ff34a50880276431ca5c66170e3b0f1eae600c5ca35e0cb6347264a55/rez-file-1719546037273.png',
      content: (<>
        <SubHeading>
          Ongoing Support and Management
        </SubHeading>
        <BodyText>
          Our dedicated project managers and support staff provide ongoing support, monitoring, and management to ensure continuous improvement and alignment with your business objectives.
        </BodyText>
      </>)
    }
  ])

  const [efficiencyContent, setEfficiencyContent] = useState([
    {
      img: dedicatedTeamIcon1,
      content: (<>
        <SubHeading style={{ fontSize: smallScreen ? '20px' : mediumScreen ? '22.5px' : "25px" }}>
          On-Demand Services
        </SubHeading>
      </>)
    },
    {
      img: dedicatedTeamIcon2,
      content: (<>
        <SubHeading style={{ fontSize: smallScreen ? '20px' : mediumScreen ? '22.5px' : "25px" }}>
          Monthly-Basis Service
        </SubHeading>
      </>)
    },
    {
      img: dedicatedTeamIcon3,
      content: (<>
        <SubHeading style={{ fontSize: smallScreen ? '20px' : mediumScreen ? '22.5px' : "25px" }}>
          Contract-Based
          <br />
          <span style={{ fontSize: smallScreen ? '16px' : mediumScreen ? '18px' : "20px" }}><b>(Based on the work scope)</b></span>
        </SubHeading>
      </>)
    }
  ])

  // Scroll window to top on load
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])

  const [animateBannerTitle, setAnimateBannerTitle] = useState(false)

  // Start banner title animation on page load
  useEffect(() => {
    setAnimateBannerTitle(true)
  }, [])

  return (
    <div style={{ overflow: 'hidden' }}>
      <HeadMeta
        title="Cloudxier - Dedicated Tech Team"
        description="Our mission is to help accelerating business in their digital innovation"
        siteName="Cloudxier"
        url="https://www.cloudxier.com/about/"
      />
      <MainBannerContainer >
        <MainBannerTextContainer animateBannerTitle={animateBannerTitle}>
          <MainBannerTextFlexContainer>
            <Container style={{ padding: smallScreen ? '0 10vw' : mediumScreen ? '0 10vw' : 0 }}>
              <MainBannerTitle smallScreen={smallScreen} mediumScreen={mediumScreen}>
                Dedicated Tech Team
              </MainBannerTitle>
              <MainBannerSubTitle smallScreen={smallScreen} mediumScreen={mediumScreen}>
                Reduce hiring costs and get an extended team to support your IT department.
                <br /> <br />
                Our dedicated tech support plans are flexible (monthly or contract-based period), allowing you to choose the level of service that aligns with your software complexity, usage patterns, and business goals.
              </MainBannerSubTitle>
            </Container>
          </MainBannerTextFlexContainer>
        </MainBannerTextContainer>
        <BannerOverlay />
        <HomeBannerImage src='https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/8b1a3cea0e838f5e9a2759e6f67acdb43eb4873d84fca0d7a7f70b605765cd4524f66c7609b5a79b9ba2a7ecf8eb30cb5cee8f0dc02609dcfe3f01db53f22e540a3850c503b6bd0ecff4d27d60a54936bbaafb771657724834ecd5768f679bf66d9957540ac5aad8037702a6ad0aeca5ae051c99765fb91163bd/rez-file-1719307774706.png' alt='main-banner-1' />
      </MainBannerContainer>
      <MainContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
        <Container style={{ padding: smallScreen ? '0 10vw' : mediumScreen ? '0 5vw' : '0 0 50px 0' }}>
          <VisibilitySensor onChange={(isVisible) => setContent1Visible(isVisible)} active={!isContent1Visible} partialVisibility={true}>
            <Animated animationIn="zoomIn" animationInDuration={2000} isVisible={isContent1Visible}>
              <HomeBannerImage src={'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/b9c6f68dea96386b4dc54a3b6ebee710e850f10d35f8da1461f7b7bfb6b3230991ccba58f2d106da8122d11712a02fa8e8cb5efc4c7cbb97861890d9bfe0fe1633c949ffb01dd1d9b9307178aea90caa3a998646aa3acd31c1cfce2f23a7dd77a4211555f80ee8565c7cc63d75c597b12d58076281b68f1af0bc/rez-file-1719545874885.png'} alt='main-banner-2' />
            </Animated>
          </VisibilitySensor>
          <Container style={{ margin: smallScreen ? '25px 0' : mediumScreen ? '25px 0' : '0' }}>
            <VisibilitySensor onChange={(isVisible) => setContent2Visible(isVisible)} active={!isContent2Visible} partialVisibility={true}>
              <Animated animationIn="fadeInUp" animationInDuration={2000} isVisible={isContent2Visible}>
                <Heading>
                  How It Works
                </Heading>
                <Row style={{ marginTop: smallScreen ? '30px' : mediumScreen ? '40px' : '50px' }}>
                  {
                    processContent.map((item, i) => {
                      return (
                        <Col key={i} sm={12} md={12} lg={12} style={{ display: 'flex', flexDirection: smallScreen ? 'column' : mediumScreen ? 'row' : 'row', alignItems: smallScreen ? 'center' : mediumScreen ? 'center' : 'center', borderRadius: '25px', backgroundColor: '#F8F8F8', padding: smallScreen ? '30px' : mediumScreen ? '30px' : '30px', marginBottom: smallScreen ? '30px' : mediumScreen ? '40px' : '50px' }}>
                          <img src={item.img} style={{ width: smallScreen ? '150px' : mediumScreen ? '200px' : '250px', height: smallScreen ? '150px' : mediumScreen ? '200px' : '250px' }} alt={`image-${i}`} />
                          <div>
                            {item.content}
                          </div>
                        </Col>
                      )
                    })
                  }
                </Row>
              </Animated>
            </VisibilitySensor>
          </Container>
        </Container>
        <VisibilitySensor onChange={(isVisible) => setContent3Visible(isVisible)} active={!isContent3Visible} partialVisibility={true}>
          <Animated animationIn="slideInRight" animationInDuration={2000} isVisible={isContent3Visible}>
            <PointBanner smallScreen={smallScreen} mediumScreen={mediumScreen}>
              <Heading style={{ textAlign: "center" }}>
                Optimizing efficiency to upscale your technology team
              </Heading>
              <Row style={{ display: "flex", justifyContent: 'center', gap: '50px', marginTop: smallScreen ? '30px' : mediumScreen ? '40px' : '50px' }}>
                {
                  efficiencyContent.map((item, i) => {
                    return (
                      <Col
                        key={i}
                        sm={12}
                        md={12}
                        lg={3}
                        style={{
                          borderRadius: '25px',
                          backgroundColor: '#FFFFFF',
                          padding: smallScreen ? '30px' : mediumScreen ? '40px' : '50px',
                          maxWidth: smallScreen ? '275px' : mediumScreen ? '300px' : '325px',
                          height: smallScreen ? '275px' : mediumScreen ? '300px' : '325px',
                        }}
                      >
                        <img src={item.img} style={{ width: smallScreen ? '40px' : mediumScreen ? '50px' : '60px', height: smallScreen ? '40px' : mediumScreen ? '50px' : '60px' }} alt={`image-${i}`} />
                        {item.content}
                      </Col>
                    )
                  })
                }
              </Row>
            </PointBanner>
          </Animated>
        </VisibilitySensor>
        <VisibilitySensor onChange={(isVisible) => setContent4Visible(isVisible)} active={!isContent4Visible} partialVisibility={true}>
          <Animated animationIn="slideInLeft" animationInDuration={2000} isVisible={isContent4Visible}>
            <Container>
              <Heading>
                How We Help Clients
              </Heading>
            </Container>
            <PostColumn />
          </Animated>
        </VisibilitySensor>
        <Container>
          <br />
          <br />
          <br />
          <br />
          <br />
          <VisibilitySensor onChange={(isVisible) => setContent5Visible(isVisible)} active={!isContent5Visible} partialVisibility={true}>
            <Animated animationIn="fadeInUp" animationInDuration={2000} isVisible={isContent5Visible}>
              <Heading style={{ textAlign: 'center' }}>
                Discuss with us now.
              </Heading>
              <BodyText style={{ textAlign: 'center' }}>
                Have questions or ready to discuss your project? We're here to help! Please fill in the inquiry form below:
              </BodyText>
              <DiscussForm />
            </Animated>
          </VisibilitySensor>
        </Container>
      </MainContainer>
    </div >
  )

}

export default DedicatedTechTeam

const MainContainer = styled.div`
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px 0 30px 0' : mediumScreen ? '30px 0 30px 0' : '50px 0 50px 0'}
`
const MainBannerContainer = styled.div`
  width: 100%;
  height: 80vh;
`
const MainBannerTextContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 80%;
  text-align: left;
  color: white;
  z-index: 2;
  transition: opacity 2s, margin 1s;
  opacity: ${({ animateBannerTitle }) => animateBannerTitle ? '1' : '0'};
  margin-top: ${({ animateBannerTitle }) => animateBannerTitle ? '0' : '-50vh'};
`
const MainBannerTextFlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-top: 7%;
`
const MainBannerTitle = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px' : mediumScreen ? '35px' : '40px'};
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '40px' : mediumScreen ? '30px' : '60px'};
  font-weight: 900;
`
const MainBannerSubTitle = styled.div`
   font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '17px' : mediumScreen ? '20px' : '23px'};
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '0' : mediumScreen ? '0' : '0'};
`
const BannerOverlay = styled.div`
  background-color: rgba(0,0,0,0.3);
  width: 100%;
  height: 80vh;
  position: absolute;
  z-index: 1;
`
const PointBanner = styled.div`
  background-color: #FBFAF0;
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px 10vw 60px 10vw' : mediumScreen ? '30px 5vw 60px 5vw' : '50px 0 100px 0'};
`
const HomeBannerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const Heading = styled.div`
  font-size: ${fontSize.heading1Sm};
  color: #4C39FF;
  font-weight: 900;
  text-align: left;
  margin-top: 24px;
  margin-bottom: 12px;
  @media ${device.tablet} {
    font-size: ${fontSize.heading1Sm};
    margin-top: 48px;
    margin-bottom: 24px;
  }
  @media ${device.laptop} {
    font-size: ${fontSize.heading3Lg};
    margin-top: 48px;
    margin-bottom: 24px;
  }
  @media ${device.laptopM} {
    font-size: ${fontSize.heading2Lg};
    margin-top: 48px;
    margin-bottom: 24px;
  }
  @media ${device.laptopL} {
    font-size: 40px;
    margin-top: 48px;
    margin-bottom: 24px;
  }
`
const SubHeading = styled.p`
  font-size: ${fontSize.heading2Sm};
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 8px;
  @media ${device.tablet} {
    font-size: 20px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptop} {
    font-size: ${fontSize.heading1Sm};
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopM} {
    font-size: ${fontSize.heading3Lg};
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopL} {
    font-size: ${fontSize.heading3Lg};
    margin-top: 32px;
    margin-bottom: 16px;
  }
`
const BodyText = styled.p`
  text-align:justify;
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 8px;
  line-height: 40px;
  @media ${device.tablet} {
    font-size: 18px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptop} {
    font-size: ${fontSize.bodyLg};
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopM} {
    font-size: 22px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopL} {
    font-size: 22px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
`
const ColOfferContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px' : mediumScreen ? '30px 40px' : '30px 40px'};
  height: auto;
  margin-bottom: 30px;
  border-radius: 15px;
  background-color: #F8F8F8;
`
const HeadlineOffer = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  align-items:center;
`
const DropdownIconContainer = styled.div`
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '0px' : mediumScreen ? '0px' : '0'};
`
const DropdownIcon = styled.img`
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? '10px' : mediumScreen ? '15px' : '20px'};
  height: ${({ smallScreen, mediumScreen }) => smallScreen ? '10px' : mediumScreen ? '15px' : '20px'};
  object-fit: contain
`
const TitleOffer = styled.div`
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? 'auto' : mediumScreen ? 'auto' : 'auto'};
  height: ${({ smallScreen, mediumScreen }) => smallScreen ? 'auto' : mediumScreen ? 'auto' : 'auto'};
  color: ${({ textColor }) => textColor ? textColor : 'black'};
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '20px' : mediumScreen ? '25px' : '30px'};
  font-weight: 700;
`
const DescriptionOffer = styled.div`
  margin-top: ${({ smallScreen, mediumScreen }) => smallScreen ? '18px' : mediumScreen ? '18px' : '18px'};
  color: ${({ textColor }) => textColor ? textColor : 'black'};
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '16px' : mediumScreen ? '20px' : '22px'};
  font-weight: 400;
  text-align: justify;
`