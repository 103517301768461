// React
import { useState, useEffect } from 'react'

// Bootstrap
import { Col, Container, Row } from 'react-bootstrap'

// Animated
import { Animated } from "react-animated-css";

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

// Timeline
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

// Components
import HeadMeta from '../../components/HeadMeta'
import DiscussForm from '../../components/DiscussForm'

// Styled components
import styled from 'styled-components'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Helpers
import fontSize from '../../helpers/fontSize'
import { device } from '../../helpers/device'

// Images
import designPlanIcon1 from '../../assets/images/ui_ux_design_plan/ui_ux_design_icon_1.png'
import designPlanIcon2 from '../../assets/images/ui_ux_design_plan/ui_ux_design_icon_2.png'
import designPlanIcon3 from '../../assets/images/ui_ux_design_plan/ui_ux_design_icon_3.png'

function UIUXDesignPlanning() {
  const smallScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 1279px)' })
  const largeScreen = useMediaQuery({ query: '(max-width: 1499px)' })

  const [isContent1Visible, setContent1Visible] = useState(false)
  const [isContent2Visible, setContent2Visible] = useState(false)
  const [isContent3Visible, setContent3Visible] = useState(false)
  const [isContent4Visible, setContent4Visible] = useState(false)

  const [consultationServices, setConsultationServices] = useState([
    {
      title: "Discovery and Research",
      description: (
        <BodyText style={{ fontSize: smallScreen ? '18px' : mediumScreen ? '20px' : '22px' }}>
          We start by understanding your current business, user needs, and evaluate the product features required to develop your website or mobile application.
        </BodyText>
      ),
      isDescOpen: true
    },
    {
      title: "User Journey Flow",
      description: (
        <BodyText style={{ fontSize: smallScreen ? '18px' : mediumScreen ? '20px' : '22px' }}>
          After going through the ideation process based on the discovery data, we begin to develop a user journey flow documentation based on the features and specific action goals.
        </BodyText>
      ),
      isDescOpen: false
    },
    {
      title: "Design and Prototyping",
      description: (
        <BodyText style={{ fontSize: smallScreen ? '18px' : mediumScreen ? '20px' : '22px' }}>
          The third process is to create wireframes, prototypes, and visual designs, incorporating your feedback to ensure alignment with your vision.
        </BodyText>
      ),
      isDescOpen: false
    },
    {
      title: "Testing and Validation",
      description: (
        <BodyText style={{ fontSize: smallScreen ? '18px' : mediumScreen ? '20px' : '22px' }}>
          We conduct usability tests and gather user feedback to validate design choices, making necessary adjustments to enhance usability and satisfaction.
        </BodyText>
      ),
      isDescOpen: false
    },
    {
      title: "Implementation and Support",
      description: (
        <BodyText style={{ fontSize: smallScreen ? '18px' : mediumScreen ? '20px' : '22px' }}>
          Our team works closely with the engineers to ensure the design is implemented accurately and efficiently, providing ongoing support as needed.
        </BodyText>
      ),
      isDescOpen: false
    },
  ])

  const [supportContent, setSupportContent] = useState([
    {
      img: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/7011897649fafe46bfcf15c50d835af73672b5be994a8994c6fe1d52b10f4acc0a2c6aff3ef64a114222c378b8974a4d4412963f798c7269767890dc4241ba5ae78d3591b067c9353ddf8f686c36ec2039cd4515217852799aba64364c0b615364a8153b8f16f6ecdeac101bd09f7c2669594dadf3e21ae0b220/rez-file-1719547276090.png',
      content: (<>
        <SubHeading>
          Responsive Design
        </SubHeading>
        <BodyText>
          <ul>
            <li><b>Cross-Platform Compatibility:</b> Designing interfaces that work seamlessly across various devices and screen sizes, from desktops to mobile devices.</li>
            <br />
            <li><b>Adaptive Layouts:</b> Implementing adaptive layouts that adjust to different screen sizes, providing an optimal viewing experience.</li>
          </ul>
        </BodyText>
      </>)
    },
    {
      img: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/f997072134c88adf0cea17e2746129155df27c2cb3fb8bc0c3dc8800bc202d67876adf3099be4c6d0566a96f9d75fb791f424119415a58c760f0b4e7efc72b5e24e610c0379a3b27d7b75a143754c31e70cff01ed0d1154219819196db47e9a691f400bbf864f05c641382115e61e561b2042955cb50280690e0/rez-file-1719547294915.png',
      content: (<>
        <SubHeading>
          Inclusive Design with Accessibility
        </SubHeading>
        <BodyText>
          <ul>
            <li><b>Inclusive Design:</b> Designing with accessibility in mind to ensure your software is usable by people with diverse abilities.</li>
            <br />
            <li><b>User Feedback Integration:</b> Continuously gathering user feedback to refine and enhance the design, ensuring it evolves with user needs.</li>
          </ul>
        </BodyText>
      </>)
    },
  ])

  const [includedContent, setEfficiencyContent] = useState([
    {
      img: designPlanIcon1,
      content: (<>
        <SubHeading style={{ fontSize: smallScreen ? '20px' : mediumScreen ? '20px' : "25px" }}>
          Design System Guidelines
        </SubHeading>
      </>)
    },
    {
      img: designPlanIcon2,
      content: (<>
        <SubHeading style={{ fontSize: smallScreen ? '20px' : mediumScreen ? '22.5px' : "25px" }}>
          Wireframing
        </SubHeading>
      </>)
    },
    {
      img: designPlanIcon3,
      content: (<>
        <SubHeading style={{ fontSize: smallScreen ? '20px' : mediumScreen ? '22.5px' : "25px" }}>
          Prototyping and Testing
        </SubHeading>
      </>)
    }
  ])

  const handleDropdown = (i, value) => {
    const newConsultationServices = consultationServices.map((item, itemIndex) => {
      if (itemIndex == i) {
        return {
          ...item,
          isDescOpen: !value
        };
      } else {
        return item;
      }
    });
    setConsultationServices(newConsultationServices)
  }

  // Scroll window to top on load
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])

  const [animateBannerTitle, setAnimateBannerTitle] = useState(false)

  // Start banner title animation on page load
  useEffect(() => {
    setAnimateBannerTitle(true)
  }, [])

  return (
    <div style={{ overflow: 'hidden' }}>
      <HeadMeta
        title="Cloudxier - UI/UX Design and Planning"
        description="Our mission is to help accelerating business in their digital innovation"
        siteName="Cloudxier"
        url="https://www.cloudxier.com/about/"
      />
      <MainBannerContainer >
        <MainBannerTextContainer animateBannerTitle={animateBannerTitle}>
          <MainBannerTextFlexContainer>
            <Container style={{ padding: smallScreen ? '0 10vw' : mediumScreen ? '0 10vw' : 0 }}>
              <MainBannerTitle smallScreen={smallScreen} mediumScreen={mediumScreen}>
                UI/UX Design and Planning
              </MainBannerTitle>
              <MainBannerSubTitle smallScreen={smallScreen} mediumScreen={mediumScreen}>
                Craft a blueprint for success with our strategic UI/UX planning services. We analyze your business goals, target audience, and industry trends to create a visual roadmap that ensures your digital product not only meets but exceeds user expectations.
                <br /> <br />

              </MainBannerSubTitle>
            </Container>
          </MainBannerTextFlexContainer>
        </MainBannerTextContainer>
        <BannerOverlay />
        <HomeBannerImage src='https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/e1fd82f4753058f21123e6f65821d30b0dadb90e0a64c7acc388e0ebca359771e3c8d58895f1425b6da46566f53375c109a51be1415543c1ef07ff129532e6f8ea899a2c0f8101bc0f622d090c327208c76b4c8d3bfc52317a66bc0361a6e055a8a6bde7941eec6cac7919c0484f9874a4f94e80941b814a5df4/rez-file-1719307878166.png' alt='main-banner-1' />
      </MainBannerContainer>
      <MainContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
        <VisibilitySensor onChange={(isVisible) => setContent1Visible(isVisible)} active={!isContent1Visible} partialVisibility={true}>
          <Animated animationIn="zoomIn" animationInDuration={2000} isVisible={isContent1Visible}>
            <PointBanner smallScreen={smallScreen} mediumScreen={mediumScreen}>
              <Heading style={{ textAlign: "center" }}>
                What's included?
              </Heading>
              <Row style={{ display: "flex", justifyContent: 'center', gap: '50px', marginTop: smallScreen ? '30px' : mediumScreen ? '40px' : '50px' }}>
                {
                  includedContent.map((item, i) => {
                    return (
                      <Col
                        key={i}
                        sm={12}
                        md={12}
                        lg={3}
                        style={{
                          borderRadius: '25px',
                          backgroundColor: '#FFFFFF',
                          padding: smallScreen ? '30px' : mediumScreen ? '40px' : '50px',
                          maxWidth: smallScreen ? '275px' : mediumScreen ? '300px' : '325px',
                          height: smallScreen ? '275px' : mediumScreen ? '300px' : '325px',
                        }}
                      >
                        <img src={item.img} style={{ width: smallScreen ? '40px' : mediumScreen ? '50px' : '60px', height: smallScreen ? '40px' : mediumScreen ? '50px' : '60px' }} alt={`image-${i}`} />
                        {item.content}
                      </Col>
                    )
                  })
                }
              </Row>
            </PointBanner>
          </Animated>
        </VisibilitySensor>
        <Container style={{ padding: smallScreen ? '0 10vw' : mediumScreen ? '0 5vw' : '0 0 50px 0' }}>
          <VisibilitySensor onChange={(isVisible) => setContent2Visible(isVisible)} active={!isContent2Visible} partialVisibility={true}>
            <Animated animationIn="fadeInUp" animationInDuration={2000} isVisible={isContent2Visible}>
              <Container style={{ margin: smallScreen ? '25px 0' : mediumScreen ? '25px 0' : '0' }}>
                <Row style={{ display: "flex", justifyContent: "space-evenly", marginTop: smallScreen ? '50px' : mediumScreen ? '65px' : '80px' }}>
                  {
                    supportContent.map((item, i) => {
                      return (
                        <Col key={i} sm={12} md={5} lg={5} style={{ backgroundColor: "#F8F8F8", borderRadius: "25px", padding: smallScreen ? '30px' : mediumScreen ? '40px' : '40px', marginBottom: smallScreen ? "30px" : mediumScreen ? "40px" : "50px" }}>
                          <img src={item.img} style={{ width: smallScreen ? '150px' : mediumScreen ? '200px' : '250px', height: smallScreen ? '150px' : mediumScreen ? '200px' : '250px' }} alt={`image-${i}`} />
                          {item.content}
                        </Col>
                      )
                    })
                  }
                </Row>
              </Container>
            </Animated>
          </VisibilitySensor>
          <VisibilitySensor onChange={(isVisible) => setContent3Visible(isVisible)} active={!isContent3Visible} partialVisibility={true}>
            <Animated animationIn="fadeInUp" animationInDuration={2000} isVisible={isContent3Visible}>
              <Heading >
                How It Works
              </Heading>
              <VerticalTimeline
                className='design-plan-timeline'
                lineColor="#A6A6A6"
                layout='1-column-left'
                animate={isContent3Visible}
              >
                {
                  consultationServices.map((item, i) => (
                    <VerticalTimelineElement
                      iconStyle={{ background: '#6DDFEF' }}
                      icon={(
                        <div style={{ textAlign: "center", fontSize: "26px", color: "#4C39FF", fontWeight: "bold" }}>
                          {i + 1}
                        </div>
                      )}
                      contentStyle={{ paddingTop: "0", boxShadow: "none" }}
                    >
                      <TitleOffer smallScreen={smallScreen} mediumScreen={mediumScreen} >
                        {item.title}
                      </TitleOffer>
                      {item.description}
                    </VerticalTimelineElement>
                  ))
                }
              </VerticalTimeline>
            </Animated>
          </VisibilitySensor>
        </Container>
        <Container>
          <br />
          <br />
          <br />
          <br />
          <br />
          <VisibilitySensor onChange={(isVisible) => setContent4Visible(isVisible)} active={!isContent4Visible} partialVisibility={true}>
            <Animated animationIn="fadeInUp" animationInDuration={2000} isVisible={isContent4Visible}>
              <Heading style={{ textAlign: 'center' }}>
                Discuss with us now.
              </Heading>
              <BodyText style={{ textAlign: 'center' }}>
                Have questions or ready to discuss your project? We're here to help! Please fill in the inquiry form below:
              </BodyText>
              <DiscussForm />
            </Animated>
          </VisibilitySensor>
        </Container>
      </MainContainer>
    </div >
  )

}

export default UIUXDesignPlanning

const MainContainer = styled.div`
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '0 0 30px 0' : mediumScreen ? '0 0 30px 0' : '0 0 50px 0'}
`
const MainBannerContainer = styled.div`
  width: 100%;
  height: 80vh;
`
const MainBannerTextContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 80%;
  text-align: left;
  color: white;
  z-index: 2;
  transition: opacity 2s, margin 1s;
  opacity: ${({ animateBannerTitle }) => animateBannerTitle ? '1' : '0'};
  margin-top: ${({ animateBannerTitle }) => animateBannerTitle ? '0' : '-50vh'};
`
const MainBannerTextFlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-top: 8%;
`
const MainBannerTitle = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px' : mediumScreen ? '35px' : '40px'};
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '40px' : mediumScreen ? '30px' : '60px'};
  font-weight: 900;
`
const MainBannerSubTitle = styled.div`
   font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '17px' : mediumScreen ? '20px' : '23px'};
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '0' : mediumScreen ? '0' : '0'};
`
const BannerOverlay = styled.div`
  background-color: rgba(0,0,0,0.3);
  width: 100%;
  height: 80vh;
  position: absolute;
  z-index: 1;
`
const PointBanner = styled.div`
  background-color: #FBFAF0;
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px 10vw 60px 10vw' : mediumScreen ? '30px 5vw 60px 5vw' : '50px 0 100px 0'};
`
const HomeBannerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const Heading = styled.div`
  font-size: ${fontSize.heading1Sm};
  color: #4C39FF;
  font-weight: 900;
  text-align: left;
  margin-top: 24px;
  margin-bottom: 12px;
  @media ${device.tablet} {
    font-size: ${fontSize.heading1Sm};
    margin-bottom: 24px;
  }
  @media ${device.laptop} {
    font-size: ${fontSize.heading3Lg};
    margin-bottom: 24px;
  }
  @media ${device.laptopM} {
    font-size: ${fontSize.heading2Lg};
    margin-bottom: 24px;
  }
  @media ${device.laptopL} {
    font-size: 40px;
    margin-bottom: 24px;
  }
`
const SubHeading = styled.p`
  font-size: ${fontSize.heading2Sm};
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 8px;
  @media ${device.tablet} {
    font-size: 20px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptop} {
    font-size: ${fontSize.heading1Sm};
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopM} {
    font-size: ${fontSize.heading3Lg};
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopL} {
    font-size: ${fontSize.heading3Lg};
    margin-top: 32px;
    margin-bottom: 16px;
  }
`
const BodyText = styled.p`
  text-align:justify;
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 8px;
  line-height: 40px;
  @media ${device.tablet} {
    font-size: 18px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptop} {
    font-size: ${fontSize.bodyLg};
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopM} {
    font-size: 22px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopL} {
    font-size: 22px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
`
const ColOfferContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px' : mediumScreen ? '30px 40px' : '30px 40px'};
  height: auto;
  margin-bottom: 30px;
  border-radius: 15px;
  background-color: #F8F8F8;
`
const HeadlineOffer = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  align-items:center;
`
const DropdownIconContainer = styled.div`
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '0px' : mediumScreen ? '0px' : '0'};
`
const DropdownIcon = styled.img`
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? '10px' : mediumScreen ? '15px' : '20px'};
  height: ${({ smallScreen, mediumScreen }) => smallScreen ? '10px' : mediumScreen ? '15px' : '20px'};
  object-fit: contain
`
const TitleOffer = styled.div`
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? 'auto' : mediumScreen ? 'auto' : 'auto'};
  height: ${({ smallScreen, mediumScreen }) => smallScreen ? 'auto' : mediumScreen ? 'auto' : 'auto'};
  color: ${({ textColor }) => textColor ? textColor : 'black'};
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '20px' : mediumScreen ? '25px' : '30px'};
  font-weight: 700;
`
const DescriptionOffer = styled.div`
  margin-top: ${({ smallScreen, mediumScreen }) => smallScreen ? '18px' : mediumScreen ? '18px' : '18px'};
  color: ${({ textColor }) => textColor ? textColor : 'black'};
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '16px' : mediumScreen ? '20px' : '22px'};
  font-weight: 400;
  text-align: justify;
`