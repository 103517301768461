// React
import { useEffect } from 'react'

// Components
import HeadMeta from '../components/HeadMeta'
import OurServices from '../components/OurServices';

// Images
import AboutUsBanner from '../assets/images/about_us_banner/Cloudxier-aboutus-banner.png'

function Services() {
  // Scroll window to top on load
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])

  return (
    <div style={{ overflow: 'hidden', paddingTop: '100px' }}>
      <HeadMeta
        title="Cloudxier - Our Services"
        description="Empowering business with technology innovation"
        siteName="Cloudxier Services"
        url="https://www.cloudxier.com/services/"
        image={AboutUsBanner}
      />
      <OurServices />
    </div>
  )
}

export default Services