import { useState } from 'react'

// Styled components
import styled from 'styled-components'

// Bootstrap
import Container from 'react-bootstrap/Container'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Animated
import { Animated } from "react-animated-css";

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

// Components
import SquareButton from '../buttons/SquareButton'

// Helpers
import fontSize from '../../helpers/fontSize'

// Image
import TalkDiscusBanner from '../../assets/images/talk_discuss/talk-discuss-banner.jpg'

// Icon
import WhatsappWhiteIcon from '../../assets/images/icons/whatsapp-white-icon.png'
import EmailWhiteIcon from '../../assets/images/icons/email-white-icon.png'

function ActionBanner() {
  const smallScreen = useMediaQuery({ query: '(max-width: 900px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 1600px)' })

  const [isContentVisible, setContentVisible] = useState(false)

  return (
    <VisibilitySensor onChange={(isVisible) => setContentVisible(isVisible)} active={!isContentVisible} partialVisibility={true}>
      <Animated animationIn="slideInRight" animationInDuration={2000} isVisible={isContentVisible}>
        <MainContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
          <Post smallScreen={smallScreen} mediumScreen={mediumScreen}>
            <ImageContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
              <Image src={TalkDiscusBanner} alt='talk-discuss-banner' smallScreen={smallScreen} mediumScreen={mediumScreen} />
            </ImageContainer>
            <Description smallScreen={smallScreen} mediumScreen={mediumScreen}>
              <Title smallScreen={smallScreen} mediumScreen={mediumScreen} >
                Talk and discuss with us
              </Title>
              <ActionText smallScreen={smallScreen} mediumScreen={mediumScreen}>
                Contact us today to discuss further and discover how we can help you achieve your goals
              </ActionText>
              <ActionButton onClick={() => window.open('https://wa.me/6281385898240')}>
                <ButtonIconContainer>
                  <WhatsappIcon src={WhatsappWhiteIcon} smallScreen={smallScreen} mediumScreen={mediumScreen} />
                </ButtonIconContainer>
                <ButtonText smallScreen={smallScreen} mediumScreen={mediumScreen}>
                  Contact our Whatsapp
                </ButtonText>
              </ActionButton>
              <ActionButton onClick={() => window.open('mailto:sayhello@cloudxier.com')}>
                <ButtonIconContainer>
                  <EmailIcon src={EmailWhiteIcon} smallScreen={smallScreen} mediumScreen={mediumScreen} />
                </ButtonIconContainer>
                <ButtonText smallScreen={smallScreen} mediumScreen={mediumScreen}>
                  Reach us through email
                </ButtonText>
              </ActionButton>
            </Description>
          </Post>
        </MainContainer>
      </Animated>
    </VisibilitySensor>
  )

}

export default ActionBanner

const MainContainer = styled.div`
  padding-top: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px' : mediumScreen ? '40px' : '50px'}
`
const Post = styled.div`
  display: ${({ smallScreen, mediumScreen }) => smallScreen ? 'block' : mediumScreen ? 'flex' : 'flex'};
  width: 100%;
`
const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${({ smallScreen, mediumScreen }) => smallScreen ? 'center' : mediumScreen ? 'flex-start' : 'flex-start'};
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? '100%' : mediumScreen ? '100%' : '50%'};
  height: ${({ smallScreen, mediumScreen }) => smallScreen ? 'auto' : mediumScreen ? 'auto' : 'auto'};
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px 10vw' : mediumScreen ? '30px 5vw' : '20px 100px'};
`
const Title = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px' : mediumScreen ? '40px' : '50px'};
  color: black;
  font-weight: 900;
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '15px' : mediumScreen ? '25px' : '30px'};
  cursor: pointer;
  text-align: ${({ smallScreen, mediumScreen }) => smallScreen ? 'center' : mediumScreen ? 'left' : 'left'};
`
const ActionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #4C39FF;
  width: fit-content;
  border-radius:10px;
  color: white;
  margin-bottom: 20px;
  padding: 15px 20px;
  cursor: pointer;
`
const ButtonIconContainer = styled.div`
`
const ButtonText = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? "18px" : mediumScreen ? '22px' : '25px'};
  font-weight: 700;
`
const ActionText = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? "20px" : mediumScreen ? "30px" : '40px'};
  color: black;
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px' : mediumScreen ? '35px' : '40px'};
  text-align: ${({ smallScreen, mediumScreen }) => smallScreen ? 'center' : mediumScreen ? 'left' : 'left'};
`
const ImageContainer = styled.div`
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? '100%' : mediumScreen ? '100%' : '50%'};
  height: auto;
`
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover
`
const WhatsappIcon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding-right: 15px;
`
const EmailIcon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding-right: 15px;
`