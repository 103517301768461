// React
import { useState, useEffect } from 'react'

// Animated
import { Animated } from "react-animated-css";

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

// Bootstrap
import { Col, Container, Row } from 'react-bootstrap'

// Components
import HeadMeta from '../../components/HeadMeta'
import DiscussForm from '../../components/DiscussForm'

// Styled components
import styled from 'styled-components'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Helpers
import fontSize from '../../helpers/fontSize'
import { device } from '../../helpers/device'

// Images
import DropDownUpIcon from '../../assets/images/icons/dropdown-up-icon.png'
import DropDownIcon from '../../assets/images/icons/dropdown_icon_black.png'

function APIDevelopmentIntegration() {
  const smallScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 1279px)' })
  const largeScreen = useMediaQuery({ query: '(max-width: 1499px)' })

  const [isContent1Visible, setContent1Visible] = useState(false)
  const [isContent2Visible, setContent2Visible] = useState(false)
  const [isContent3Visible, setContent3Visible] = useState(false)
  const [isContent4Visible, setContent4Visible] = useState(false)

  const [whatWeOffer, setWhatWeOffer] = useState([
    {
      title: "Custom API Development",
      description: (
        <BodyText>
          <ul>
            <li><b>Tailored Solutions:</b> Our team develops custom APIs that are specifically designed to meet your unique business needs, ensuring seamless integration with your existing infrastructure.</li>
            <li><b>Scalability:</b> We build APIs that are scalable and capable of handling high traffic and large data volumes, allowing your business to expand without technical limitations.</li>
            <li><b>Security:</b> We prioritize security by implementing advanced measures such as encryption, authentication, and authorization protocols to protect your data and ensure secure communication.
            </li>
          </ul>
        </BodyText>
      ),
      isDescOpen: true
    },
    {
      title: "Third-Party API Integration",
      description: (
        <BodyText>
          <ul>
            <li><b>Extensive Experience:</b> Our team has extensive experience integrating a wide range of third-party APIs, including payment gateways, social media platforms, geolocation services, and more.</li>
            <li><b>Connectivity:</b> We ensure that your mobile app connects smoothly with external services, enhancing features and functionality for your users.</li>
            <li><b>Real-Time Data Sync:</b> Our integration services enable real-time data synchronization, ensuring your app displays the most current and accurate information.</li>
          </ul>
        </BodyText>
      ),
      isDescOpen: false
    },
    {
      title: "API Management and Monitoring",
      description: (
        <BodyText>
          <ul>
            <li><b>Comprehensive Management: </b>We provide end-to-end API management services, including version control, thorough documentation, and lifecycle management.</li>
            <li><b>Performance Monitoring:</b> We monitor API performance continuously, identifying and resolving any issues to ensure optimal functionality and uptime.</li>
          </ul>
        </BodyText>
      ),
      isDescOpen: false
    },
    {
      title: "API Support and Maintenance",
      description: (
        <BodyText>
          <ul>
            <li><b>Continuous Monitoring:</b> We continuously monitor your API’s performance to detect and address any issues before they impact users.</li>
            <li><b>Optimization:</b> Our team regularly reviews API performance data to identify areas for optimization, ensuring your API operates at peak efficiency.</li>
            <li><b>API Versioning:</b> We manage API versions to ensure compatibility and provide a clear upgrade path for new features and improvements.</li>
            <li><b>Proactive Bug Identification:</b> Our team proactively identifies and fixes bugs to maintain the reliability and functionality of your APIs.</li>
            <li><b>Timely Patches:</b> We deploy timely patches to address any issues that arise, minimizing impact on your operations.</li>
          </ul>
        </BodyText>
      ),
      isDescOpen: false
    },
  ])

  const [advantagesContent, setAdvantagesContent] = useState([
    {
      img: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/77ebf1080fec8d8e6dcfcb6ef8a5da04944a7da6b1788c18acd2bc25e342c1036780d80399d11e990f54c4218f641ad4413919cd5fc21c9c3a2b364ef0b1b49333f0a99d268c4ed63ced8319b8116bff4d9ef5213062ea8a0f050fe68cf51bc50a440cfd10edfbdc7c29c67f6739cf642287812bfbb05462d0b4/rez-file-1719545450307.png',
      content: (<>
        <SubHeading>
          Enhanced Functionality
        </SubHeading>
        <BodyText>
          By integrating various APIs, your mobile app can offer a wide range of features, significantly improving user experience and engagement.
        </BodyText>
      </>)
    },
    {
      img: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/0034faaa6770ae2fe1728c5147ba294658266c1b5745bfe87cfc79297cb27725edc0555c045ebf8d71abdddd8aa17ca048d58ebc537c2c4b8ed3243eb53c36ad649e8a201e8700b8e25602ccdc2feb8ce6c2c7f61528844536ee9fb0c8f4449706e5e9cee928f3ad9d853d1c30dd7591b56121a3f350c8785115/rez-file-1719545542348.png',
      content: (<>
        <SubHeading>
          Improved Efficiency
        </SubHeading>
        <BodyText>
          Our APIs streamline data exchange between systems, reducing manual processes and increasing operational efficiency.
        </BodyText>
      </>)
    },
    {
      img: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/8e768d0f36ae1a56364164013683367e24d43018e63b36c1f48044a32ad64d6e9674b0e4fad1eafda8dccef524460a5472645764779ef997c5fa830d5102cc106f8e60d740c9a2374c454c1447ec2142082b93705d76dd92bef13a1a030d0446aa50c3459ff08e8aa45680618521a00f726823d59da14bd44c64/rez-file-1719545524600.png',
      content: (<>
        <SubHeading>
          Future-Proof Solutions
        </SubHeading>
        <BodyText>
          We develop scalable APIs that can adapt to your evolving business needs, ensuring long-term usability and relevance.
        </BodyText>
      </>)
    },
    {
      img: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/99f6f1fff283c603eb20513ae3758061488d13601f52c20a39dfecfb66879e814f5a9eab321a38e2ddfab8a90449d704797917f58a582836f224830062f566df6e6ff14fa61869a290024100032a2dd8ea319ae10baa6010c6740732638f4a7309590c41a3a9bec3312a4a54026b517b4f84c7f1e1c72aa76ec3/rez-file-1719545499081.png',
      content: (<>
        <SubHeading>
          Faster Time-to-Market
        </SubHeading>
        <BodyText>
          With our expertise in API development and integration, we accelerate the development process, enabling you to launch your app more quickly.
        </BodyText>
      </>)
    },
  ])

  const handleDropdown = (i, value) => {
    const newWhatWeOffer = whatWeOffer.map((item, itemIndex) => {
      if (itemIndex == i) {
        return {
          ...item,
          isDescOpen: !value
        };
      } else {
        return item;
      }
    });
    setWhatWeOffer(newWhatWeOffer)
  }

  // Scroll window to top on load
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])

  const [animateBannerTitle, setAnimateBannerTitle] = useState(false)

  // Start banner title animation on page load
  useEffect(() => {
    setAnimateBannerTitle(true)
  }, [])

  return (
    <div style={{ overflow: 'hidden' }}>
      <HeadMeta
        title="Cloudxier - API Development and Integration"
        description="Our mission is to help accelerating business in their digital innovation"
        siteName="Cloudxier"
        url="https://www.cloudxier.com/about/"
      />
      <MainBannerContainer >
        <MainBannerTextContainer animateBannerTitle={animateBannerTitle}>
          <MainBannerTextFlexContainer>
            <Container style={{ padding: smallScreen ? '0 10vw' : mediumScreen ? '0 10vw' : 0 }}>
              <MainBannerTitle smallScreen={smallScreen} mediumScreen={mediumScreen}>
                API Development and Integration
              </MainBannerTitle>
              <MainBannerSubTitle smallScreen={smallScreen} mediumScreen={mediumScreen}>
                Embrace modern and efficient communication protocols.
                <br /> <br />
                Our team is well-versed in developing RESTful APIs and GraphQL endpoints, providing versatile solutions that cater to a wide range of applications and use cases, including: versioning strategies and backward compatibility, ensuring a seamless transition for existing users and allowing you to introduce new features without disrupting current implementations.
              </MainBannerSubTitle>
            </Container>
          </MainBannerTextFlexContainer>
        </MainBannerTextContainer>
        <BannerOverlay />
        <HomeBannerImage src='https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/89c78bf70c3a808019950d70a38ff3c4f0ce3e40ba2f5f1086ed03fd0d4aca89736e9b5f8b898d44da43ff5ea92098bddf9a13f3a6f595ac8513b8a6c0ecd6f7cfaa329e0e27bde20cce8556188b53a8e7bc8942d04af0cc3e7d527b5f3d30437948f6c28f739eb09eb8389a7530268d0a01b83fd952b085d01e/rez-file-1719307711412.png' alt='main-banner-1' />
      </MainBannerContainer>
      <MainContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
        <Container style={{ padding: smallScreen ? '0 10vw' : mediumScreen ? '0 5vw' : '0 0 50px 0' }}>
          <VisibilitySensor onChange={(isVisible) => setContent1Visible(isVisible)} active={!isContent1Visible} partialVisibility={true}>
            <Animated animationIn="zoomIn" animationInDuration={2000} isVisible={isContent1Visible}>
              <HomeBannerImage src={'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/69058950166803baf00f71ceaed37362a1ccbb1903c43074934f82c2577adddd66869305bc6b5539e345d316ae667cff003b961b060d83d1fe27a2ad355a473a2514e0942985524b456462b2c0ab0072bfe786510daa811131284b8a1a4b4c7765e897008620b2959ddef380e4ebeece89ea27790f9ddd34d149/rez-file-1719545378706.png'} alt='main-banner-2' />
            </Animated>
          </VisibilitySensor>
          <VisibilitySensor onChange={(isVisible) => setContent2Visible(isVisible)} active={!isContent2Visible} partialVisibility={true}>
            <Animated animationIn="fadeInLeft" animationInDuration={2000} isVisible={isContent2Visible}>
              <Row style={{ marginTop: smallScreen ? "30px" : mediumScreen ? "40px" : "50px" }}>
                {
                  whatWeOffer.map((offering, i) => (
                    <Col sm={12} md={12} lg={12} key={i}>
                      <ColOfferContainer smallScreen={smallScreen} mediumScreen={mediumScreen} isDescOpen={offering.isDescOpen}>
                        <HeadlineOffer>
                          <TitleOffer smallScreen={smallScreen} mediumScreen={mediumScreen} onClick={() => handleDropdown(i, offering.isDescOpen)}>
                            {offering.title}
                          </TitleOffer>
                          {offering.isDescOpen ? (
                            <DropdownIconContainer smallScreen={smallScreen} mediumScreen={mediumScreen} onClick={() => handleDropdown(i, offering.isDescOpen)}>
                              <DropdownIcon src={DropDownIcon} />
                            </DropdownIconContainer>
                          ) : (
                            <DropdownIconContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
                              <DropdownIcon src={DropDownUpIcon} onClick={() => handleDropdown(i, offering.isDescOpen)} />
                            </DropdownIconContainer>
                          )}
                        </HeadlineOffer>
                        <DescriptionOffer smallScreen={smallScreen} mediumScreen={mediumScreen} hidden={offering.isDescOpen === false} >
                          {offering.description}
                        </DescriptionOffer>
                      </ColOfferContainer>
                    </Col>
                  ))
                }
              </Row>
            </Animated>
          </VisibilitySensor>
          <Container style={{ margin: smallScreen ? '25px 0' : mediumScreen ? '25px 0' : '0' }}>
            <VisibilitySensor onChange={(isVisible) => setContent3Visible(isVisible)} active={!isContent3Visible} partialVisibility={true}>
              <Animated animationIn="slideInUp" animationInDuration={2000} isVisible={isContent3Visible}>
                <Heading>
                  The advantages using our API development and integration service
                </Heading>
                <Row style={{ marginTop: smallScreen ? '30px' : mediumScreen ? '40px' : '50px' }}>
                  {
                    advantagesContent.map((item, i) => {
                      return (
                        <Col key={i} sm={12} md={6} lg={6} style={{ padding: smallScreen ? '0' : mediumScreen ? '0px 50px 25px 0' : "0px 60px 30px 0" }}>
                          <img src={item.img} style={{ width: smallScreen ? '150px' : mediumScreen ? '200px' : '250px', height: smallScreen ? '150px' : mediumScreen ? '200px' : '250px' }} alt={`image-${i}`} />
                          {item.content}
                        </Col>
                      )
                    })
                  }
                </Row>
              </Animated>
            </VisibilitySensor>
          </Container>
        </Container>
        <Container>
          <br />
          <br />
          <br />
          <br />
          <br />
          <VisibilitySensor onChange={(isVisible) => setContent4Visible(isVisible)} active={!isContent4Visible} partialVisibility={true}>
            <Animated animationIn="fadeInUp" animationInDuration={2000} isVisible={isContent4Visible}>
              <Heading style={{ textAlign: 'center' }}>
                Discuss with us now.
              </Heading>
              <BodyText style={{ textAlign: 'center' }}>
                Have questions or ready to discuss your project? We're here to help! Please fill in the inquiry form below:
              </BodyText>
              <DiscussForm />
            </Animated>
          </VisibilitySensor>
        </Container>
      </MainContainer>
    </div >
  )

}

export default APIDevelopmentIntegration

const MainContainer = styled.div`
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px 0 30px 0' : mediumScreen ? '30px 0 30px 0' : '50px 0 50px 0'}
`
const MainBannerContainer = styled.div`
  width: 100%;
  height: 80vh;
`
const MainBannerTextContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 80%;
  text-align: left;
  color: white;
  z-index: 2;
  transition: opacity 2s, margin 1s;
  opacity: ${({ animateBannerTitle }) => animateBannerTitle ? '1' : '0'};
  margin-top: ${({ animateBannerTitle }) => animateBannerTitle ? '0' : '-50vh'};
`
const MainBannerTextFlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-top: 7%;
`
const MainBannerTitle = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px' : mediumScreen ? '35px' : '40px'};
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '40px' : mediumScreen ? '30px' : '60px'};
  font-weight: 900;
`
const MainBannerSubTitle = styled.div`
   font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '17px' : mediumScreen ? '20px' : '23px'};
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '0' : mediumScreen ? '0' : '0'};
`
const BannerOverlay = styled.div`
  background-color: rgba(0,0,0,0.3);
  width: 100%;
  height: 80vh;
  position: absolute;
  z-index: 1;
`
const HomeBannerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const Heading = styled.div`
  font-size: ${fontSize.heading1Sm};
  color: #4C39FF;
  font-weight: 900;
  text-align: left;
  margin-top: 24px;
  margin-bottom: 12px;
  @media ${device.tablet} {
    font-size: ${fontSize.heading1Sm};
    margin-top: 48px;
    margin-bottom: 24px;
  }
  @media ${device.laptop} {
    font-size: ${fontSize.heading3Lg};
    margin-top: 48px;
    margin-bottom: 24px;
  }
  @media ${device.laptopM} {
    font-size: ${fontSize.heading2Lg};
    margin-top: 48px;
    margin-bottom: 24px;
  }
  @media ${device.laptopL} {
    font-size: 40px;
    margin-top: 48px;
    margin-bottom: 24px;
  }
`
const SubHeading = styled.p`
  font-size: ${fontSize.heading2Sm};
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 8px;
  @media ${device.tablet} {
    font-size: 20px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptop} {
    font-size: ${fontSize.heading1Sm};
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopM} {
    font-size: ${fontSize.heading3Lg};
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopL} {
    font-size: ${fontSize.heading3Lg};
    margin-top: 32px;
    margin-bottom: 16px;
  }
`
const BodyText = styled.p`
  text-align:justify;
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 8px;
  line-height: 40px;
  @media ${device.tablet} {
    font-size: 18px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptop} {
    font-size: ${fontSize.bodyLg};
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopM} {
    font-size: 22px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopL} {
    font-size: 22px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
`
const ColOfferContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px' : mediumScreen ? '30px 40px' : '30px 40px'};
  height: auto;
  margin-bottom: 30px;
  border-radius: 15px;
  background-color: #F8F8F8;
`
const HeadlineOffer = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  align-items:center;
`
const DropdownIconContainer = styled.div`
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '0px' : mediumScreen ? '0px' : '0'};
`
const DropdownIcon = styled.img`
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? '10px' : mediumScreen ? '15px' : '20px'};
  height: ${({ smallScreen, mediumScreen }) => smallScreen ? '10px' : mediumScreen ? '15px' : '20px'};
  object-fit: contain
`
const TitleOffer = styled.div`
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? 'auto' : mediumScreen ? 'auto' : 'auto'};
  height: ${({ smallScreen, mediumScreen }) => smallScreen ? 'auto' : mediumScreen ? 'auto' : 'auto'};
  color: ${({ textColor }) => textColor ? textColor : 'black'};
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '20px' : mediumScreen ? '25px' : '30px'};
  font-weight: 700;
`
const DescriptionOffer = styled.div`
  margin-top: ${({ smallScreen, mediumScreen }) => smallScreen ? '18px' : mediumScreen ? '18px' : '18px'};
  color: ${({ textColor }) => textColor ? textColor : 'black'};
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '16px' : mediumScreen ? '20px' : '22px'};
  font-weight: 400;
  text-align: justify;
`