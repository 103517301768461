import { useState } from 'react'

// Styled components
import styled from 'styled-components'

// Bootstrap
import Container from 'react-bootstrap/Container'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Images
import CloudxierLogo from '../assets/images/logo/cloudxier_logo_dark.png'
import Facebook from '../assets/images/icons/facebook_dark.png'
import Instagram from '../assets/images/icons/instagram_dark.png'
import Linkedin from '../assets/images/icons/linkedin_dark.png'

// Helpers
import CurrentYear from "../helpers/CurrentYear"

// Components
import HeaderDropdown from './dropdowns/HeaderDropdown'

function Footer() {
  const smallScreen = useMediaQuery({ query: '(max-width: 800px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 1200px)' })

  const [expertiseMenuSelected, setExpertiseMenuSelected] = useState(false)
  const [ourGroupMenuSelected, setOurGroupMenuSelected] = useState(false)

  const [isContent1Visible, setContent1Visible] = useState(false)

  return (
    <MainContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
      <div style={{ paddingLeft: smallScreen ? '50px' : mediumScreen ? '70px' : '0' }}>
        <BrandLogo src={CloudxierLogo} smallScreen={smallScreen} mediumScreen={mediumScreen} />
      </div>
      <FlexContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
        {
          smallScreen || mediumScreen ?
            null
            :
            <LeftContainer>
              <Title smallScreen={smallScreen} mediumScreen={mediumScreen}>Contact Us</Title>
              <DescriptionText>
                PT Cendekia Edukasi Citra Tritunggal HQ <br />
                AKR Tower 16<sup>th</sup> Floor Unit 16A <br />
                Jl Panjang No. 5 <br />
                Kebon Jeruk - West Jakarta, 11530
                <br />
                <br />
                sayhello@cloudxier.com
                <br />
                <br />
                <br />
              </DescriptionText>
              <SocialMediaContainer>
                <SocmedLogo>
                  <SocmedIcon onClick={() => window.open('https://www.facebook.com/CloudxierIndonesia')} src={Facebook} smallScreen={smallScreen} mediumScreen={mediumScreen} />
                  <SocmedIcon onClick={() => window.open('https://www.instagram.com/cloudxier/')} src={Instagram} smallScreen={smallScreen} mediumScreen={mediumScreen} />
                  <SocmedIcon onClick={() => window.open('https://www.linkedin.com/company/cloudxier')} src={Linkedin} smallScreen={smallScreen} mediumScreen={mediumScreen} />
                </SocmedLogo>
              </SocialMediaContainer>
            </LeftContainer>
        }
        <RightContainer>
          <LinkContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
            <LinkGroup smallScreen={smallScreen} mediumScreen={mediumScreen}>
              <Title smallScreen={smallScreen} mediumScreen={mediumScreen}>Services</Title>
              <NavItem href="/our-services/custom-software-development" smallScreen={smallScreen} mediumScreen={mediumScreen}>
                Custom Software Development
              </NavItem>
              <NavItem href="/our-services/mobile-app-development" smallScreen={smallScreen} mediumScreen={mediumScreen}>
                Mobile App Development
              </NavItem>
              <NavItem href="/our-services/api-development-integration" smallScreen={smallScreen} mediumScreen={mediumScreen}>
                API Development and Integration
              </NavItem>
              <NavItem href="/our-services/cloud-computing-consultation" smallScreen={smallScreen} mediumScreen={mediumScreen}>
                Cloud Computing and Consultation
              </NavItem>
              <NavItem href="/our-services/dedicated-tech-team" smallScreen={smallScreen} mediumScreen={mediumScreen}>
                Dedicated Tech Team
              </NavItem>
              <NavItem href="/our-services/cto-as-service" smallScreen={smallScreen} mediumScreen={mediumScreen}>
                CTO As Service
              </NavItem>
              <NavItem href="/our-services/ui-ux-design-planning" smallScreen={smallScreen} mediumScreen={mediumScreen}>
                UI/UX Design and Planning
              </NavItem>
              <NavItem href="/our-services/devops-consulting" smallScreen={smallScreen} mediumScreen={mediumScreen}>
                DevOps Consulting
              </NavItem>
            </LinkGroup>
            <LinkGroup smallScreen={smallScreen} mediumScreen={mediumScreen}>
              <Title smallScreen={smallScreen} mediumScreen={mediumScreen}>Products</Title>
              <NavItem href="https://maplexxon.com/product/ecommerce-website" target='_blank' rel='noopener noreferrer' smallScreen={smallScreen} mediumScreen={mediumScreen}>
                E-Commerce & Inventory
              </NavItem>
              <NavItem href="https://maplexxon.com/product/appointment-and-scheduling-management" target='_blank' rel='noopener noreferrer' smallScreen={smallScreen} mediumScreen={mediumScreen}>
                Appointment & Scheduling Management
              </NavItem>
              <NavItem href="https://maplexxon.com/product/company-profile-site" target='_blank' rel='noopener noreferrer' smallScreen={smallScreen} mediumScreen={mediumScreen}>
                Premium Company Profile Site
              </NavItem>
            </LinkGroup>
            <LinkGroup smallScreen={smallScreen} mediumScreen={mediumScreen}>
              <Title smallScreen={smallScreen} mediumScreen={mediumScreen}>Company</Title>
              <NavItem href="/about" smallScreen={smallScreen} mediumScreen={mediumScreen}>
                About Us
              </NavItem>
              <NavItem href="/projects" smallScreen={smallScreen} mediumScreen={mediumScreen}>
                Projects
              </NavItem>
              <NavItem href="https://maplexxon.com/" target='_blank' rel='noopener noreferrer' smallScreen={smallScreen} mediumScreen={mediumScreen}>
                Maplexxon Software
              </NavItem>
              <NavItem href="https://www.sustainability.co.id/" target='_blank' rel='noopener noreferrer' smallScreen={smallScreen} mediumScreen={mediumScreen}>
                ISA Consulting
              </NavItem>
              <NavItem href="/contact-us" smallScreen={smallScreen} mediumScreen={mediumScreen}>
                Contact
              </NavItem>
            </LinkGroup>
            <LinkGroup smallScreen={smallScreen} mediumScreen={mediumScreen}>
              <Title smallScreen={smallScreen} mediumScreen={mediumScreen}>Resources</Title>
              <NavItem href="/blog" smallScreen={smallScreen} mediumScreen={mediumScreen}>
                Blog
              </NavItem>
              {/* <NavItem href="/about" smallScreen={smallScreen} mediumScreen={mediumScreen}>
                  Case Studies
                </NavItem> */}
            </LinkGroup>
          </LinkContainer>
        </RightContainer>
      </FlexContainer>
      <Seperator />
      <FlexContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
        <SmallBottomTextSecondary href="/privacy-policy" smallScreen={smallScreen} mediumScreen={mediumScreen} >
          Privacy Policy
        </SmallBottomTextSecondary>
        <SmallBottomTextSecondary href="/tp-privacy-policy" smallScreen={smallScreen} mediumScreen={mediumScreen}>
          Technical Provider Privacy Policy
        </SmallBottomTextSecondary>
        <SmallBottomTextSecondary href="/tp-terms" smallScreen={smallScreen} mediumScreen={mediumScreen}>
          Technical Provider Terms of Service
        </SmallBottomTextSecondary>
        <SmallBottomTextSecondary href="/sustainability-policy" smallScreen={smallScreen} mediumScreen={mediumScreen}>
          Sustainability Policy
        </SmallBottomTextSecondary>
        <SmallBottomTextSecondary href="/enviromental-policy" smallScreen={smallScreen} mediumScreen={mediumScreen}>
          Enviromental Policy
        </SmallBottomTextSecondary>
        <SmallBottomText smallScreen={smallScreen} mediumScreen={mediumScreen}>
          © Cloudxier {<CurrentYear />}. All Rights Reserved.
        </SmallBottomText>
      </FlexContainer>
    </MainContainer>
  )

}

export default Footer

const MainContainer = styled.div`
  background-color: #F3F3F3;
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px 0' : mediumScreen ? '30px 0' : '50px 80px'};
`
const FlexContainer = styled.div`
  display: ${({ smallScreen, mediumScreen }) => smallScreen ? 'flex' : mediumScreen ? 'flex' : 'flex'};
  flex-direction: ${({ smallScreen, mediumScreen }) => smallScreen ? 'column' : mediumScreen ? 'row' : 'row'};
  justify-content: space-between;
  align-items: top;
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '10px 50px' : mediumScreen ? '10px 50px' : '0 30px;'}; 
`
const LeftContainer = styled.div`
  width: auto;
  margin-right: 30px;
`
const BrandLogo = styled.img`
  width: 50%;
  max-width: 250px;
  height: auto;
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '20px' : mediumScreen ? '20px' : '40px'}
`
const DescriptionText = styled.div`
  font-size: 20px;
  line-height: 40px;
`
const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const LinkContainer = styled.div`
  display: flex;
  flex-direction: ${({ smallScreen, mediumScreen }) => smallScreen ? 'column' : mediumScreen ? 'row' : 'row'};
  gap: ${({ smallScreen, mediumScreen }) => smallScreen ? 'unset' : mediumScreen ? 'unset' : '72px'};
`

const LinkGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '0' : mediumScreen ? '0 20px' : '0'};
  margin-bottom: 20px;
`
const Title = styled.div`
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '20px' : mediumScreen ? '15px' : '30px'};
  font-weight: bold;
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '18px' : mediumScreen ? '20px' : '22px'};
`
const NavItem = styled.a`
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '10px' : mediumScreen ? '15px' : '20px'};
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '16px' : mediumScreen ? '18px' : '20px'};
  line-height: ${({ smallScreen, mediumScreen }) => smallScreen ? '20px' : mediumScreen ? '22px' : '24px'};
  color: black;
  cursor: pointer;
  text-decoration: none !important;
`
const SocialMediaContainer = styled.div`
  margin: 40px 0;
`
const SocmedText = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '12px' : mediumScreen ? '12px' : '16px'};
  font-weight: bold;
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '8px' : mediumScreen ? '8px' : '16px'};
  line-height: ${({ smallScreen, mediumScreen }) => smallScreen ? '16px' : mediumScreen ? '16px' : '20px'};
`
const SocmedLogo = styled.div`
  display: flex;
  justify-content: flex-start;
`
const SocmedIcon = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 30px;
  object-fit: contain;
  cursor: pointer;
`
const Seperator = styled.div`
  height: 1px;
  width: 100%;
  background-color: black;
  margin-bottom: 20px;
`
const SmallBottomTextSecondary = styled.a`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '13px' : mediumScreen ? '14px' : '15px'};
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '10px' : mediumScreen ? '0' : '20px'}; 
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '0' : mediumScreen ? '0 20px' : '0'};
  text-align: ${({ smallScreen, mediumScreen }) => smallScreen ? 'left' : mediumScreen ? 'center' : 'center'};
  color: black;
  text-decoration: none !important;
  cursor: pointer;
`
const SmallBottomText = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '14px' : mediumScreen ? '15px' : '16px'};
  margin-top: ${({ smallScreen, mediumScreen }) => smallScreen ? '15px' : mediumScreen ? '0' : '0'};
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '0' : mediumScreen ? '0 20px' : '0'};
  text-align: ${({ smallScreen, mediumScreen }) => smallScreen ? 'left' : mediumScreen ? 'center' : 'center'};
`