import TYPE from '../actions/types'

// Project images
import Shooper1 from '../../assets/images/projects/shooper/1-cloudxier-shooper-highlight01.JPEG'
import Shooper2 from '../../assets/images/projects/shooper/2-cloudxier-shooper-highlight03.jpg'
import Shooper3 from '../../assets/images/projects/shooper/3-cloudxier-shooper-highlight02.jpg'
import Shooper4 from '../../assets/images/projects/shooper/4-cloudxier-shooper-dashboardoverview.jpg'
import Shooper5 from '../../assets/images/projects/shooper/5-cloudxier-shooper-receiptvalidation.jpg'
import Pln1 from '../../assets/images/projects/pln/1-cloudxier-PLN-highlightpreview.jpg'
import Pln2 from '../../assets/images/projects/pln/2-cloudxier-PLN-mockup01.jpg'
import Pln3 from '../../assets/images/projects/pln/3-cloudxier-PLN-mockup02.jpg'
import Hb1 from '../../assets/images/projects/hb/1-Cloudxier-Heavenlyblush-website-homepage.jpg'
import Hb2 from '../../assets/images/projects/hb/2-Cloudxier-Heavenlyblush-website-allproducts.jpg'
import Hb3 from '../../assets/images/projects/hb/3-Cloudxier-Heavenlyblush-website-productpage.jpg'
import Hb4 from '../../assets/images/projects/hb/4-Cloudxier-Heavenlyblush-website-recipepage.jpg'
import Csa1 from '../../assets/images/projects/csa/1-Cloudxier-CECTSustainabilityAwards1.jpg'
import Csa2 from '../../assets/images/projects/csa/1-Cloudxier-CECTSustainabilityAwards2.png'
import Mmsust1 from '../../assets/images/projects/mmsust/1-Cloudxier-MMSustainability-Mockup.jpg'
import Ausmincham1 from '../../assets/images/projects/ausmincham/Cloudxier-Ausmincham-website.jpg'
import Intimas1 from '../../assets/images/projects/intimas/1-Cloudxier-IntiMasSejahtera-Website.png'
import Pertamina1 from '../../assets/images/projects/pertamina/1COVER-Cloudxier-CSR-Report-Pertamina.jpg'
import Pertamina2 from '../../assets/images/projects/pertamina/2-Cloudxier-CSR-Report-Pertamina.jpg'
import Pertamina3 from '../../assets/images/projects/pertamina/3-Cloudxier-CSR-Report-Pertamina.jpg'
import Pertamina4 from '../../assets/images/projects/pertamina/4-Cloudxier-CSR-Report-Pertamina.JPEG'
import Bis1 from '../../assets/images/projects/bis/1COVER-Cloudxier-PTBIS.jpg'
import Bis2 from '../../assets/images/projects/bis/2-Cloudxier-PTBIS.png'
import Bis3 from '../../assets/images/projects/bis/3-Cloudxier-PTBIS.png'
import Bis4 from '../../assets/images/projects/bis/4-Cloudxier-PTBIS.png'
import WonderfulIndonesia1 from '../../assets/images/projects/wonderful_indonesia/1-Cloudxier-AdventureIndonesia.jpg'
import WonderfulIndonesia2 from '../../assets/images/projects/wonderful_indonesia/2-Cloudxier-AdventureIndonesiaLogo.png'
import WonderfulIndonesia3 from '../../assets/images/projects/wonderful_indonesia/3-Cloudxier-AdventureIndonesia.png'
import WonderfulIndonesia4 from '../../assets/images/projects/wonderful_indonesia/4-Cloudxier-AdventureIndonesia.png'
import Stp1 from '../../assets/images/projects/japfa_stp/1-Cloudxier-PTSTP.png'
import Stp2 from '../../assets/images/projects/japfa_stp/2-Cloudxier-PTSTP.png'
import Stp3 from '../../assets/images/projects/japfa_stp/3-Cloudxier-PTSTP.png'
import Stp4 from '../../assets/images/projects/japfa_stp/4-Cloudxier-PTSTP.jpg'
import DailyCafeine1 from '../../assets/images/projects/daily_cafeine/1-Cloudxier-DailyCaffeine.jpg'
import DailyCafeine2 from '../../assets/images/projects/daily_cafeine/2-Cloudxier-DailyCaffeine.jpg'
import DailyCafeine3 from '../../assets/images/projects/daily_cafeine/3-Cloudxier-DailyCaffeine.jpg'
import DailyCafeine4 from '../../assets/images/projects/daily_cafeine/4-Cloudxier-DailyCaffeine.jpg'
import XOPA1 from '../../assets/images/projects/xopa/xopa_cloudxier1.png'
import XOPA2 from '../../assets/images/projects/xopa/xopa_cloudxier2.png'
import Sirclo1 from '../../assets/images/projects/sirclo/sirclo_cloudxier1.png'
import CCS1 from '../../assets/images/projects/ccs/CCS_cloudxier1.png'
import CCS2 from '../../assets/images/projects/ccs/CCS_cloudxier2.png'
import Onic1 from '../../assets/images/projects/onic/ONIC_Esports_cloudxier1.png'
import ISA1 from '../../assets/images/projects/isa/ISA_cloudxier1.png'
import ISA2 from '../../assets/images/projects/isa/ISA_cloudxier2.png'
import eDOT1 from '../../assets/images/projects/edot/edot_cloudxier1.png'
import eDOT2 from '../../assets/images/projects/edot/edot_cloudxier2.png'
import eDOT3 from '../../assets/images/projects/edot/edot_cloudxier3.png'
import eDOT4 from '../../assets/images/projects/edot/edot_cloudxier4.png'

let initialState = {
  projects: [
    {
      id: 13,
      title: 'XOPA Academia Hiring Management Software',
      image: XOPA1,
      category: 'Dedicated tech support & software development',
      features: [
        {
          text: "X0PA is a B2B SaaS Hiring Management Software powered by AI and Machine Learning based in Singapore. With the software, it enables recruiters to make the best informed hiring decisions",
          image: XOPA1,
          isHeading: true,
        },
        {
          text: "Cloudxier has been working together with XOPA to develop, support and maintain the Academia software. The Academia product objective is to enhance the optimal allocation of students to jobs while enhancing placement rates for institutions and provide better-fit hiring for students to employer jobs.",
          image: XOPA2,
          isHeading: false
        },
      ]
    },
    {
      id: 14,
      title: 'Sirclo Store Hybrid Mobile App Development',
      image: Sirclo1,
      category: 'Mobile App Development',
      features: [
        {
          text: "Sirclo store is an e-commerce website creation platform where sellers can create an online store easily",
          image: Sirclo1,
          isHeading: true,
        },
        {
          text: <>
            <p>Sirclo store app has various feature such as: monitoring sales performance, upload products, sales inventory system, marketplace integration, and many more.</p>
            <p>Cloudxier has helped with mobile app development (Hybrid Mobile App Development - iOS and Android) from Sirclo store's current web platform with the aim to increase user engagement to manage online store easily just in one app.</p>
          </>,
          image: Sirclo1,
          isHeading: false
        },
      ]
    },
    {
      id: 15,
      title: 'Combined Clinics Sustainability Edtech Platform',
      image: CCS1,
      category: 'Website Development',
      features: [
        {
          text: "As one of our special brand under our group, Combined Clinics Sustainability - an Online education platform to help young entrepreneurs and professionals get 1-on-1 mentorship and upscale business knowledge, empowering them to unlock their potentials",
          image: CCS1,
          isHeading: true,
        },
        {
          text: <>
            <p>In this platform, students can choose topic and schedule an online consultation of their business challenges.</p>
            <p>Some of the features are include:
              <ol>
                <li>Topic finder based on user's interests</li>
                <li>Schedule a consultation session based on user availability and our platform will match with the mentors</li>
                <li>Exclusive resources and manual book preview</li>
                <li>Get 1-on-1 consultation with our video conferencing software (more secure and stable)</li>
                <li>Experts can register to become mentor and grow networks in this platform</li>
              </ol>
            </p>
          </>,
          image: CCS2,
          isHeading: false
        },
      ]
    },
    {
      id: 1,
      title: 'Shooper Mobile Application',
      image: Shooper1,
      category: 'Mobile Application',
      features: [
        {
          text: "Community-based application that helps shoppers to find lowest prices at supermarkets and get valuable rewards for every grocery spending",
          image: Shooper2,
          isHeading: true,
        },
        {
          text: "In Indonesia, 97% of people still buy groceries at offline supermarkets and there are often very large product price differences from one supermarket to another. Shooper builds all-in-one solution for shoppers to save time and money doing groceries efficiently, from creating and sharing grocery list, checking current product prices, tracking grocery spending and budget, exploring recipe ideas for delicious meals and interestingly Shooper give reward points for every grocery spending.",
          image: Shooper3,
          isHeading: false
        },
        {
          text: "Shooper utilizes shopping receipts and analyzes real-time data to improve shoppers’ experience on finding nearest supermarket locations and comparing product prices. Cloudxier has helped to develop technology roadmap from ideas and concepts, MVP creation to product launch, as well as building system architecture for processing receipts and product database.",
          image: Shooper4,
          isHeading: false
        },
        {
          text: "Shooper has successfully launched an Android version in early 2020. Now, shoppers can easily check prices from a grocery list that has been created within less than 10 seconds, then the application will give detailed prices and recommend nearest supermarket locations to go.",
          image: Shooper5,
          isHeading: false
        }
      ]
    },
    {
      id: 18,
      title: 'Video Meeting Software Development',
      image: eDOT1,
      category: 'Software Development',
      features: [
        {
          text: "PT Elektronik Distribusi Otomatisasi Terkemuka (eDOT) is one of a super app based in Bandung, Indonesia. The main objective of the project is building a hybrid communication that will be launched in Website and Mobile Application (Android and iOS)",
          image: eDOT1,
          isHeading: true,
        },
        {
          text: "We have been partnered up with eDOT to develop a video meeting software development (website based and hybrid mobile application) to be integrated with their super app.",
          image: eDOT2,
          isHeading: false
        },
        {
          text: <>
            <p>The scope of project is including:
              <ul>
                <li>UI/UX development</li>
                <li>MVP prototype development</li>
                <li>Integration with other third party APIs</li>
                <li>Product roadmap planning and development</li>
              </ul>
            </p>
          </>,
          image: eDOT3,
          isHeading: false
        },
        {
          text: <>
            <p>Video meeting key features development, as follows:
              <ol>
                <li>Create and schedule instant meeting or on specific date for later</li>
                <li>Meeting calendar development to schedule meeting directly</li>
                <li>Exclusive meeting calendar preview on daily, weekly, monthly views</li>
                <li>Choosing blur or upload own background for video meeting</li>
                <li>Communicate through personal or group chat in room meeting</li>
                <li>Create notes meeting directly, mention people, send notes meeting afterwards</li>
                <li>Meeting schedule integration on GoogleCalendar and email</li>
              </ol>
            </p>
          </>,
          image: eDOT4,
          isHeading: false
        },
      ]
    },
    {
      id: 16,
      title: 'ONIC Esports Website Development',
      image: Onic1,
      category: 'Website Development',
      features: [
        {
          text: "ONIC E-SPORT is a Professional E-Sports Management Team based in Indonesia. Cloudxier has helped ONIC to develop a website to showcase their best players, achievements and as well as to increase their brand awareness as a leading esports management team in Indonesia",
          image: Onic1,
          isHeading: true,
        },
      ]
    },
    {
      id: 17,
      title: 'ISA Website & Academy Course Finder',
      image: ISA2,
      category: 'Website Development',
      features: [
        {
          text: "Institute for Sustainability and Agility (ISA) is a Indonesia's leading in sustainability education and consultancy with mission to inspire excellence and make a difference in the world",
          image: ISA2,
          isHeading: true,
        },
        {
          text: "We have been partnered up with ISA to help them creating website that is user-friendly, scalable and accomodating full information of the services they offer. Other than that, we have developed a ISA Academy course finder to accommodate workshop participants to find the desired course based on their interest, method of learning and schedule availability.",
          image: ISA1,
          isHeading: false,
        },
      ]
    },
    {
      id: 2,
      title: 'Project Management and Tracking Software for PLN',
      image: Pln1,
      category: "Digital Product Experience and Design",
      features: [
        {
          text: "Project management and tracking software that enables various teams to collaborate on projects",
          image: Pln1,
          isHeading: true
        },
        {
          text: "PLN (Perusahaan Listrik Negara) is an Indonesian government-owned corporation which has mission to focus on electricity distribution and generates the majority of Indonesia’s electrical power. This software was made for PLN’s project in Sumatera area which aims to provide better project management for various teams, contractors and vendors.",
          image: Pln2,
          isHeading: false
        },
        {
          text: "Cloudxier has helped to develop overall user journey, workflow process and UI/UX to allow progress tracking smarter and easier way. The software helps improving task distribution more clearly and enhance teamwork performance so that each team can deliver its best results.",
          image: Pln3,
          isHeading: false
        }
      ]
    },
    {
      id: 3,
      title: 'Heavenly Blush Yogurt Website',
      image: Hb1,
      category: "Website Development",
      features: [
        {
          text: "Improving the website experience for favorite yogurt brand in Indonesia",
          image: Hb1,
          isHeading: true
        },
        {
          text: "Heavenly Blush is a yogurt brand that provides rich benefits for health. They wanted to improve website presence to engage more customers can choose yogurt based on their personal preferences.",
          image: Hb2,
          isHeading: false
        },
        {
          text: "Cloudxier added dynamic “pick a flavor” yogurt products in each category with extra “click to compare” feature to give customers suitable choice.",
          image: Hb3,
          isHeading: false
        },
        {
          text: "We also provided direct link to online marketplace channels to enable customers shop digitally and integrate live chat feature into the website to allow customers get inquiry response faster.",
          image: Hb4,
          isHeading: false
        }
      ]
    },
    {
      id: 4,
      title: 'CECT Sustainability Awards Website',
      image: Csa1,
      category: "Website Development",
      features: [
        {
          text: "CECT Sustainability Awards is a form of appreciation given to companies who have great CSR and Sustainability Holistic performance that meets the ISO 26000 criteria (International Standards & Guidance for Sustainable Social Responsibility). Founded by CECT (Center for Entrepreneurship, Change and Third Sector) Trisakti University, this award aims to encourage and guide corporates to treat their CSR in a holistic way to achieve Sustainable Development Goals (SDGs).",
          image: Csa1,
          isHeading: false
        },
        {
          text: "They wanted to launch a website that provides complete experience for award participants  to gain full information about the award as well as an easy award submission process. Through the website, we have successfully gain traffic and increase the number of award participations from the previous years. Over 100 companies have received this research-based prestigious Award.",
          image: Csa2,
          isHeading: false
        }
      ]
    },
    {
      id: 5,
      title: 'MM-Sustainability Trisakti University Website',
      image: Mmsust1,
      category: "Website Development",
      features: [
        {
          text: "MM-Sustainability is the master degree program that specializes in Sustainability Education, pioneering and the first in Asia Pacific, they wanted to improve website presence and increase online enrollment. Our team have worked closely with their marketing team to conduct user research to optimize the web usability and experience. ",
          image: Mmsust1,
          isHeading: false
        }
      ]
    },
    {
      id: 6,
      title: 'Ausmincham Website',
      image: Ausmincham1,
      category: "Website Development",
      features: [
        {
          text: "Ausmincham (Australia Mining, Infrastructure, Energy and Resource Chamber) has main focus to promote many benefits Australian investment delivers to Indonesia.  They provide an actionable business information to members and also assist them through business networking and introductions, sourcing of experienced professional, advisory and other business services. Cloudxier has helped to improve the website appearance to be more professional, easy to navigate and added a dedicated portal access for members to join online.",
          image: Ausmincham1,
          isHeading: false
        }
      ]
    },
    {
      id: 7,
      title: 'Inti Mas Sejahtera Website',
      image: Intimas1,
      category: "Website Development",
      features: [
        {
          text: "PT Inti Mas Sejahtera is a palm oil company based in Indonesia, has established more than 10 years in the industry, they engaged in trading palm kernel or palm kernel and other products. Supported by a reliable and competent team, PT Inti Mas Sejahtera serves the purchase and sale of Palm Kernel, Palm Kernel Oil, Palm and Palm Kernel Meals to meet industrial needs in Indonesia and outside Indonesia (exports). Cloudxier has helped to build their digital presence through simple and user-friendly company website.",
          image: Intimas1,
          isHeading: false
        }
      ]
    },
    {
      id: 8,
      title: 'CSR Report PT Pertamina Lubricants',
      image: Pertamina1,
      category: "Branding and Graphic Design",
      features: [
        {
          text: "",
          image: Pertamina1,
          isHeading: false
        },
        {
          text: "",
          image: Pertamina2,
          isHeading: false
        },
        {
          text: "",
          image: Pertamina3,
          isHeading: false
        },
        {
          text: "",
          image: Pertamina4,
          isHeading: false
        }
      ]
    },
    {
      id: 9,
      title: 'BIS Industries Company Profile',
      image: Bis1,
      category: "Branding and Graphic Design",
      features: [
        {
          text: "",
          image: Bis1,
          isHeading: false
        },
        {
          text: "",
          image: Bis2,
          isHeading: false
        },
        {
          text: "",
          image: Bis3,
          isHeading: false
        },
        {
          text: "",
          image: Bis4,
          isHeading: false
        }
      ]
    },
    {
      id: 10,
      title: 'Adventure Indonesia Branding Identity Guide',
      image: WonderfulIndonesia1,
      category: "Branding and Graphic Design",
      features: [
        {
          text: "",
          image: WonderfulIndonesia1,
          isHeading: false
        },
        {
          text: "",
          image: WonderfulIndonesia2,
          isHeading: false
        },
        {
          text: "",
          image: WonderfulIndonesia3,
          isHeading: false
        },
        {
          text: "",
          image: WonderfulIndonesia4,
          isHeading: false
        }
      ]
    },
    {
      id: 11,
      title: 'PT Suri Tani Pemuka Company Brochure',
      image: Stp1,
      category: "Branding and Graphic Design",
      features: [
        {
          text: "",
          image: Stp1,
          isHeading: false
        },
        {
          text: "",
          image: Stp2,
          isHeading: false
        },
        {
          text: "",
          image: Stp3,
          isHeading: false
        },
        {
          text: "",
          image: Stp4,
          isHeading: false
        }
      ]
    },
    {
      id: 12,
      title: 'Daily Caffeine Brand',
      image: DailyCafeine1,
      category: "Branding and Graphic Design",
      features: [
        {
          text: "",
          image: DailyCafeine1,
          isHeading: false
        },
        {
          text: "",
          image: DailyCafeine2,
          isHeading: false
        },
        {
          text: "",
          image: DailyCafeine3,
          isHeading: false
        },
        {
          text: "",
          image: DailyCafeine4,
          isHeading: false
        }
      ]
    }
  ]
}

export default function projectReducers(state = initialState, action) {
  let { type, payload } = action
  switch (type) {
    case TYPE.SET_PROJECTS:
      return { ...state, isFetchingSettings: payload }
    default:
      return state
  }
}