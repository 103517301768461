// React
import { useState } from 'react'

// React bootstrap
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// Styled components
import styled from 'styled-components'

// React responsive
import { useMediaQuery } from 'react-responsive'

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

// Components
import SectionHeading from './headings/SectionHeading'

// Expertise details images
import MobileApps1 from '../assets/images/each_expertise_details/mobile_app_development/Cloudxier_graphics-each-expertise-01.png'
import APICreation1 from '../assets/images/each_expertise_details/api_creation_and_management/Cloudxier_graphics-each-expertise-01.png'
import Branding1 from '../assets/images/each_expertise_details/branding_and_graphic_design/Cloudxier_graphics-each-expertise-01.png'
import DigitalProduct1 from '../assets/images/each_expertise_details/digital_product_experience_and_design/Cloudxier_graphics-each-expertise-01.png'
import Enterprise1 from '../assets/images/each_expertise_details/enterprise_software_development/Cloudxier_graphics-each-expertise-01.png'
import Enterprise2 from '../assets/images/each_expertise_details/enterprise_software_development/Cloudxier_graphics-each-expertise-02.png'
import Webdev1 from '../assets/images/each_expertise_details/website_development/Cloudxier_graphics-each-expertise-01.png'
import Webdev2 from '../assets/images/each_expertise_details/website_development/Cloudxier_graphics-each-expertise-02.png'


function OurServices() {

  const smallScreen = useMediaQuery({ query: '(max-width: 1024px)' })

  const [isVisibleColumn, setVisibleColumn] = useState(false)

  const services = [
    {
      id: "software-development",
      title: "Software Development",
      description_list: [
        {
          description: "Providing custom software development services together with technical architecture consultation that suits your business goals.",
        },
        {
          description: "From concept to deployment, we ensure a seamless development process. Whether it's front-end interfaces, back-end logic, or database structure and integration, we've got it covered.",
        }
      ],
      bannerImg: Webdev1,
      keypoints: [
        {
          title: "Software customization",
        },
        {
          title: "Build user-first approach software",
        },
        {
          title: "Technical architecture review and assessment",
        },
        {
          title: "Agile process, testing and delivery",
        }
      ]
    },
    {
      id: "ci-cd-implementation-consultation",
      title: "CI/CD Implementation and Consultation",
      description_list: [
        {
          description: "We can work closely with your team to design and implement customized CI/CD workflows by integrating automated testing, code analysis, and deployment processes that align with your project requirements.",
        },
        {
          description: "Our CI/CD solutions incorporate robust testing frameworks, ensuring that each code change is thoroughly tested, reducing the likelihood of bugs and enhancing the overall stability of your software.",
        }
      ],
      bannerImg: Enterprise1,
      keypoints: [
        {
          title: "Faster delivery with CI/CD pipeline and multiple daily releases",
        },
        {
          title: "Conduct build, test, and deployment process",
        }
      ]
    },
    {
      id: "api-development",
      title: "API Development",
      description_list: [
        {
          description: "Embrace modern and efficient communication protocols.",
        },
        {
          description: "Our team is well-versed in developing RESTful APIs and GraphQL endpoints, providing versatile solutions that cater to a wide range of applications and use cases, including: versioning strategies and backward compatibility, ensuring a seamless transition for existing users and allowing you to introduce new features without disrupting current implementations.",
        }
      ],
      bannerImg: APICreation1,
      keypoints: [
        {
          title: "API Consultation",
        },
        {
          title: "Custom API Development",
        },
        {
          title: "Custom API Integration",
        },
        {
          title: "Cloud API Integration",
        },
        {
          title: "API Implementation, Support, Monitoring and Maintenance",
        }
      ]
    },
    {
      id: "dedicated-tech-support",
      title: "Dedicated Tech Support",
      description_list: [
        {
          description: "Reduce hiring costs and get extended team to support your IT department. Our dedicated tech support plans are flexible (monthly and contract-based period), allowing you to choose the level of service that aligns with your software complexity, usage patterns, and business goals.",
        },
        {
          description: "This service includes regular updates and patch management to address vulnerabilities, improve functionality, and deliver a seamless user experience.",
        }
      ],
      bannerImg: Branding1,
      keypoints: [
        {
          title: "Monthly-basis or per project-basis*",
        },
        {
          title: "Proven quality of talents",
        },
        {
          title: "Complete skill sets that suit your business needs",
        },
        {
          title: "Reduce overhead costs and save time on hiring process",
        }
      ]
    },
    {
      id: "ui-ux-design-and-planning",
      title: "UI/UX Design and Planning",
      description_list: [
        {
          description: "Craft a blueprint for success with our strategic UI/UX planning services. We analyze your business goals, target audience, and industry trends to create a visual roadmap that ensures your digital product not only meets but exceeds user expectations.",
        },
        {
          description: "We craft detailed user journey flows, wireframes and interactive prototypes, allowing you to preview the user flow and functionality before delivering to , providing a valuable opportunity for refinement and feedback.",
        }
      ],
      bannerImg: DigitalProduct1,
      keypoints: [
        {
          title: "Product roadmap planning",
        },
        {
          title: "Concept and develop wireframes",
        },
        {
          title: "User journey mapping and segmentation",
        },
        {
          title: "User Interface (UI) and User Experience Design (UX)",
        },
        {
          title: "Interactive prototyping",
        }
      ]
    },
    {
      id: "mobile-application-development",
      title: "Mobile Application Development",
      description_list: [
        {
          description: "Our mobile app development service is tailor-made to align seamlessly with your business objectives, providing an unique solution that sets you apart from the competition.",
        },
        {
          description: "From iOS, Android or Hybrid cross-platform app development that allows your app to run smoothly across various devices and operating systems, ensuring you get more value for your investment.",
        }
      ],
      bannerImg: MobileApps1,
      keypoints: [
        {
          title: "Hybrid Cross-Platform Mobile App Development",
        },
        {
          title: "Business analysis and customer journey process ",
        },
        {
          title: "Software architecture planning",
        },
        {
          title: "Feature customizations",
        },
        {
          title: "Quality Assurance (QA) and Support",
        }
      ]
    },
    {
      id: "cloud-computing-consultation",
      title: "Cloud Computing and Consultation",
      description_list: [
        {
          description: "Maximize your business value by implementing end-to-end cloud computing solution; from planning, development, and management.",
        },
        {
          description: "This includes cloud management to cover ongoing monitoring and optimization of your cloud infrastructure, ensuring the software systems are always operating at their best and costs are kept in check.",
        },
      ],
      bannerImg: Webdev2,
      keypoints: [
        {
          title: "Cloud Development",
          description: "Finding the best cloud platform and conducting infrastructure assessment."
        },
        {
          title: "Cloud Migration",
          description: "Evaluating current business-specific use cases and build a cloud migration strategy."
        },
        {
          title: "Cloud Deployment",
          description: "From provisioning and configuration to monitoring and optimization, we manage the entire deployment lifecycle."
        }
      ],
      additional: [
        {
          description: "The right cloud development strategy and planning for software engineering benefit business to:"
        },
        {
          title: "Reduce operating costs with cloud optimization best practices",
        },
        {
          title: "More efficient monitoring and maintenance",
        },
        {
          title: "Reduce unplanned downtime",
        },
        {
          title: "More efficient IT infrastructure management",
        },
      ]
    },
    {
      id: "cto-as-service",
      title: "CTO as a Service",
      description_list: [
        {
          description: "Providing businesses with access to high-level technical expertise and strategic guidance without the need for a full-time Chief Technology Officer (CTO).",
        },
        {
          description: "This flexible and scalable solution allows businesses to tap into the knowledge and experience of technology leaders with on-demand basis, it’s more cost-effective and flexible!",
        }
      ],
      bannerImg: Enterprise2,
      keypoints: [
        {
          title: "Technology Strategy Development and Roadmap Creation",
          description: "Create collaboration with internal (in-house) tech team and stakeholders to define a clear and achievable technical roadmap for future development plans, outlining key milestones and priorities."
        },
        {
          title: "Vendor and Technology Evaluation",
          description: "We assist businesses in selecting the right technology solutions and vendors to meet their specific needs, conducting thorough evaluations and assessments to ensure compatibility and scalability."
        },
        {
          title: "Risk Management and Compliance",
          description: "Helping businesses identify and mitigate technology-related risks, ensuring compliance with industry regulations and standards."
        },
      ]
    },
  ]

  return (
    <VisibilitySensor onChange={(isVisible) => setVisibleColumn(isVisible)} active={!isVisibleColumn} partialVisibility={true}>
      <MainContainer smallScreen={smallScreen} isVisibleColumn={isVisibleColumn}>
        <SectionHeading
          title='Our Services'
          subtitle='' />
        <ContentContainer smallScreen={smallScreen} isVisibleColumn={isVisibleColumn}>
          <Row>
            {
              services && services.map((service, index) => {
                return (
                  <Col md={smallScreen ? 12 : 6} key={index}>
                    <ServiceContainer smallScreen={smallScreen} isVisibleColumn={isVisibleColumn}>
                      <div>
                        <ImageContainer smallScreen={smallScreen}>
                          <Image src={service.bannerImg} smallScreen={smallScreen} />
                        </ImageContainer>
                        <DetailContainer smallScreen={smallScreen}>
                          <Name smallScreen={smallScreen} >
                            {service.title}
                          </Name>
                          <div style={{ overflowY: "scroll", maxHeight: "100px" }}>
                            {
                              service.description_list && service.description_list.map((desc, index) => {
                                return (
                                  <Description smallScreen={smallScreen} key={index}>
                                    {desc.description}
                                  </Description>
                                )
                              })
                            }
                          </div>
                        </DetailContainer>
                        <div style={{ marginTop: "1rem", overflowY: "scroll", height: "160px", maxHeight: "160px" }}>
                          <KeypointContainer>
                            {
                              service.keypoints && service.keypoints.map((keypoint, index) => {
                                return (
                                  <>
                                    <Keypoint smallScreen={smallScreen} key={index}>
                                      <CircleIcon smallScreen={smallScreen} i={index} xmlns="http://www.w3.org/2000/CircleIcon">
                                        <circle cx="6" cy="6" r="5.5" stroke="white" />
                                      </CircleIcon>
                                      {keypoint.title}
                                    </Keypoint>
                                    {keypoint.description && (
                                      <Description smallScreen={smallScreen} key={index} style={{ paddingLeft: "30px" }}>
                                        {keypoint.description}
                                      </Description>
                                    )}
                                  </>
                                )
                              })
                            }
                          </KeypointContainer>
                          {
                            service.additional && service.additional.length > 0 && (
                              <AdditionalContainer smallScreen={smallScreen}>
                                {
                                  service.additional.map((additional, index) => {
                                    return (
                                      <>
                                        {additional.description && (
                                          <Description smallScreen={smallScreen} key={index}>
                                            {additional.description}
                                          </Description>
                                        )}
                                        {additional.title && (
                                          <Keypoint smallScreen={smallScreen} key={index}>
                                            <div>
                                              <CircleIcon smallScreen={smallScreen} i={index} xmlns="http://www.w3.org/2000/CircleIcon">
                                                <circle cx="6" cy="6" r="5.5" stroke="white" />
                                              </CircleIcon>
                                            </div>
                                            <div>
                                              {additional.title}
                                            </div>
                                          </Keypoint>
                                        )}
                                      </>
                                    )
                                  })
                                }
                              </AdditionalContainer>
                            )
                          }
                        </div>
                      </div>
                    </ServiceContainer>
                  </Col>
                )
              })
            }
          </Row>
        </ContentContainer>
      </MainContainer >
    </VisibilitySensor>
  )
}

export default OurServices

const MainContainer = styled.div`
  opacity: ${({ isVisibleColumn }) => isVisibleColumn ? 1 : 0};
  padding: ${({ smallScreen }) => smallScreen ? '30px 0 30px 0' : '50px 0 50px 0'}
`

const ContentContainer = styled.div`
  opacity: ${({ isVisibleColumn }) => isVisibleColumn ? 1 : 0};
  padding: ${({ smallScreen }) => smallScreen ? '20px 10vw 20px 10vw' : '25px 15vw 25px 15vw'}
`
const ServiceContainer = styled.div`
  width: 100%;
  height: ${({ smallScreen }) => smallScreen ? 'max-content' : 'max-content'};
  background-color: #F3F3F3;
  border-radius: 20px;
  display: flex;
  justify-content: ${({ smallScreen }) => smallScreen ? 'center' : 'left'};
  align-items: center;
  box-shadow: 10px 10px 5px lightgrey;
  margin: ${({ smallScreen }) => smallScreen ? '20px 0' : '30px 0'};
  padding: ${({ smallScreen }) => smallScreen ? '0vh 50px 30px 50px' : '20vh 50px 40px 50px'};
  opacity: ${({ isVisibleColumn }) => isVisibleColumn ? 1 : 0};
`
const ImageContainer = styled.div`
  position: ${({ smallScreen }) => smallScreen ? 'relative' : 'absolute'};
  top: ${({ smallScreen }) => smallScreen ? '4vh' : '30px'};
  left: ${({ smallScreen }) => smallScreen ? '4vw' : '5vw'};
  margin-bottom: ${({ smallScreen }) => smallScreen ? '20px' : '30px'};
`
const Image = styled.img`
  width: ${({ smallScreen }) => smallScreen ? '50%' : '100%'};
  height: ${({ smallScreen }) => smallScreen ? '50%' : '25vh'};
  border-radius: 100%;
  object-fit: cover;
  margin-top: ${({ smallScreen }) => smallScreen ? '0' : '0'};
`
const DetailContainer = styled.div`
  background-color: white;
  width: ${({ smallScreen }) => smallScreen ? '100%' : '100%'};
  padding:  ${({ smallScreen }) => smallScreen ? '20px 20px 20px 20px' : '40px 30px 20px 30px'};
  border-radius: 20px;
`
const AdditionalContainer = styled.div`
  background-color: white;
  width: ${({ smallScreen }) => smallScreen ? '100%' : '100%'};
  padding:  ${({ smallScreen }) => smallScreen ? '20px 20px 20px 20px' : '20px 30px 20px 30px'};
  margin-top:  ${({ smallScreen }) => smallScreen ? '20px' : '25px'};
  border-radius: 20px;
`
const Name = styled.div`
  font-size: ${({ smallScreen }) => smallScreen ? "18px" : "23px"};
  font-weight: bold;
  text-align: left;
  color: #6A24FE;
  margin-bottom: .5rem;
`
const Description = styled.div`
  font-size: ${({ smallScreen }) => smallScreen ? "12px" : "15px"};
  text-align: justify;
  color: #000000;
  margin-bottom: .5rem;
`
const Keypoint = styled.div`
  display:flex;
  align-items:center;
  font-size: ${({ smallScreen }) => smallScreen ? "14px" : "17px"};
  margin-top:  ${({ smallScreen }) => smallScreen ? '5px' : '10px'};
  text-align: left;
  font-weight: bold;
  color: #000000;
`
const LinkedInImage = styled.img`
  width: ${({ smallScreen }) => smallScreen ? '20px' : '40px'};
  height: ${({ smallScreen }) => smallScreen ? '20px' : '40px'};
  border-radius: 100%;
  object-fit: cover;
  margin-top: ${({ smallScreen }) => smallScreen ? '-25px' : '10px'};
  cursor: pointer;
`
const KeypointContainer = styled.div`
  margin-top: 20px;
`
const CircleIcon = styled.svg`
  fill: #000000;
  margin-top: ${({ smallScreen }) => smallScreen ? '15px' : '5px'};
  margin-right:  ${({ smallScreen }) => smallScreen ? '10px' : '15px'};
  width: ${({ smallScreen }) => smallScreen ? '1.5rem' : '1rem'};
  height: ${({ smallScreen }) => smallScreen ? '1.5rem' : '1rem'};
  object-view-box: inset(0% 0% 10% 10%);
`