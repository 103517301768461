import { useEffect, useState, useRef } from 'react'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Animated
import { Animated } from "react-animated-css";

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

// Bootstrap
import Container from 'react-bootstrap/Container'

// Styled components
import styled from 'styled-components'

function HomeSliderBanner() {
  const smallScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 1200px)' })

  const [isVisibleColumn, setVisibleColumn] = useState(false)

  const slideContainerRef = useRef(null)
  const slidesRef = useRef([])
  const [currBannerIndex, setCurrBannerIndex] = useState(0)
  const [slidingBanner, setSlidingBanner] = useState([
    {
      image: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/6c60a1995ef2f8ad9ef291ed144512205a6c4b17db604849cbdcc1589525a63e36d1be193ac73e46e960500a3c3b23147caf5eb1f6dbe87589b024e99c3143a557c50e72521aa4b92f241fa2f3cb4614c2c7e24ccc04b7a49e3e02cb32603185d108bad2e53ad23f8164174af5f217430791d21d87dd899a98a5/rez-file-1719292324506.png',
      title: "banner1",
      externalLink: "https://cloudxier.com/",
    },
    {
      image: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/38dbd5290c9374edb7fc3ffefb2bb39be7f681df3060b3675771febd55e4945a977ad29a6796e648d06a17a901c766c1debcbd081e5c4a22ee194181ed33a6205ff33a8fff446ab95d6aca9d5979e74c6a34f783e00781037c80eeb2e0bc409026ec38653a51c4710ca79a43fe81bf4b9acd46237fd81afea8dc/rez-file-1719292376788.png',
      title: "banner2",
      externalLink: "https://maplexxon.com/",
    },
    {
      image: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/3c9cd3104339332d314dd7c7608a16ee02b4b2c77bd46fbc3eef0770c74b715049a536abacda7660324396b0f667b342c9c20532cb0d6da13728734451b2f2979f8026aac9f53492e5a8b7df44fedc4b16d266627c6a7f2709740fbf747cbab820070760d2c724f59aec0080ced206efe6e0b9a673e36cba7c21/rez-file-1719292393703.png',
      title: "HomeSliderBanner3",
      externalLink: "https://maplexxon.com/",
    },
  ])

  useEffect(() => {
    const slideContainer = slideContainerRef?.current
    const slides = slidesRef?.current
    const slideWidth = slides?.[0].clientWidth;
    const interval = 10000;
    let currentIndex = 0;

    function nextSlide() {
      currentIndex = (currentIndex + 1) % slides.length;
      setCurrBannerIndex(currentIndex)
      updateSlide();
    }

    function updateSlide() {
      const translateX = -currentIndex * slideWidth;
      slideContainer.style.transform = `translateX(${translateX}px)`;
    }

    const slideInterval = setInterval(nextSlide, interval);
    return () => clearInterval(slideInterval)
  }, [])

  return (
    <VisibilitySensor onChange={(isVisible) => setVisibleColumn(isVisible)} active={!isVisibleColumn} partialVisibility={true}>
      <Container style={{
        padding: smallScreen ? '30px 2vw 30px 2vw' : mediumScreen ? '0 0 30px 0' : '0'
      }}>
        <MainContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
          <Animated animationIn="zoomIn" animationInDuration={2000} isVisible={isVisibleColumn}>
            <SlideBannerContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
              <SlideContainer ref={slideContainerRef}>
                {slidingBanner.map((item, i) => (
                  <ImageContainer smallScreen={smallScreen} mediumScreen={mediumScreen} onClick={() => window.open(item?.externalLink, '_blank')} ref={(el) => slidesRef.current[i] = el}>
                    <Images src={item?.image} alt={item?.title} />
                  </ImageContainer>
                ))}
              </SlideContainer>
              <CircleIconContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
                {slidingBanner.map((item, i) => (
                  <CircleIcon smallScreen={smallScreen} mediumScreen={mediumScreen} i={i} currBannerIndex={currBannerIndex} xmlns="http://www.w3.org/2000/CircleIcon">
                    <circle cx="6" cy="6" r="5.5" stroke="black" />
                  </CircleIcon>
                ))}
              </CircleIconContainer>
            </SlideBannerContainer>
          </Animated>
        </MainContainer>
      </Container>
    </VisibilitySensor>
  )
}

export default HomeSliderBanner

const MainContainer = styled.div`
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '0 5vw' : mediumScreen ? '0 5vw' : '0'};
  border-radius: 15px;
`
const SlideBannerContainer = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  border-radius: 15px;
`
const SlideContainer = styled.div`
  display: flex;
  border-radius: 15px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 500ms;
`
const ImageContainer = styled.div`
  flex: 1 1 0%;
  min-width: 100%;
  cursor: pointer;
  border-radius: 15px;
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '0 0.5rem' : mediumScreen ? '0 0.25rem' : '0'};
`
const Images = styled.img`
  width: 100%;
  border-radius: 15px;
`
const CircleIconContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: ${({ smallScreen, mediumScreen }) => smallScreen ? '4px' : mediumScreen ? '6px' : '8px'};
  margin-left: ${({ smallScreen, mediumScreen }) => smallScreen ? '0.25rem' : mediumScreen ? '0.25rem' : '0.5rem'};
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '1.5rem 0' : mediumScreen ? '1.75rem 0' : '2rem 0'};
`
const CircleIcon = styled.svg`
  fill: ${({ i, currBannerIndex }) => i == currBannerIndex ? '#000000' : 'none'};;
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? '1rem' : mediumScreen ? '1rem' : '1rem'};
  height: ${({ smallScreen, mediumScreen }) => smallScreen ? '1rem' : mediumScreen ? '1rem' : '1rem'};
  object-view-box: inset(0% 0% 12% 12%);
`