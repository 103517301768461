// React
import { useEffect, useState } from 'react'

// React router dom
import { useLocation } from 'react-router-dom'

// React bootstrap
import Container from 'react-bootstrap/Container'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Components
import HeadMeta from '../components/HeadMeta'
import ExpertiseMainBanner from '../components/banners/ExpertiseMainBanner'
import FeatureColumn from '../components/FeatureColumn'
import ActionBanner from '../components/banners/ActionBanner'

// Expertise banner images
import MobileAppsBanner from '../assets/images/expertise_banner/What we do-eachpage-MobileApp.png'
import APICreationBanner from '../assets/images/expertise_banner/What we do-eachpage-APICreation&Mgmt.png'
import ProductDesignBanner from '../assets/images/expertise_banner/What we do-eachpage-Digital Product_Experience and Design.png'
import EnterpriseSoftwareBanner from '../assets/images/expertise_banner/What we do-eachpage-Enterprise Software_Development.png'
import WebsiteDevBanner from '../assets/images/expertise_banner/What we do-eachpage-Website_Development.png'
import BrandingBanner from '../assets/images/expertise_banner/What we do-eachpage-Branding & Graphic_Design.png'

// Expertise details images
import MobileApps1 from '../assets/images/each_expertise_details/mobile_app_development/Cloudxier_graphics-each-expertise-01.png'
import MobileApps2 from '../assets/images/each_expertise_details/mobile_app_development/Cloudxier_graphics-each-expertise-02.png'
import MobileApps3 from '../assets/images/each_expertise_details/mobile_app_development/Cloudxier_graphics-each-expertise-03.png'
import MobileApps4 from '../assets/images/each_expertise_details/mobile_app_development/Cloudxier_graphics-each-expertise-04.png'
import APICreation1 from '../assets/images/each_expertise_details/api_creation_and_management/Cloudxier_graphics-each-expertise-01.png'
import Branding1 from '../assets/images/each_expertise_details/branding_and_graphic_design/Cloudxier_graphics-each-expertise-01.png'
import DigitalProduct1 from '../assets/images/each_expertise_details/digital_product_experience_and_design/Cloudxier_graphics-each-expertise-01.png'
import DigitalProduct2 from '../assets/images/each_expertise_details/digital_product_experience_and_design/Cloudxier graphics-each-expertise-02.png'
import DigitalProduct3 from '../assets/images/each_expertise_details/digital_product_experience_and_design/Cloudxier graphics-each-expertise-03.png'
import Enterprise1 from '../assets/images/each_expertise_details/enterprise_software_development/Cloudxier_graphics-each-expertise-01.png'
import Enterprise2 from '../assets/images/each_expertise_details/enterprise_software_development/Cloudxier_graphics-each-expertise-02.png'
import Enterprise3 from '../assets/images/each_expertise_details/enterprise_software_development/Cloudxier_graphics-each-expertise-03.png'
import Webdev1 from '../assets/images/each_expertise_details/website_development/Cloudxier_graphics-each-expertise-01.png'
import Webdev2 from '../assets/images/each_expertise_details/website_development/Cloudxier_graphics-each-expertise-02.png'

function Expertise () {

  const smallScreen = useMediaQuery({ query: '(max-width: 1023px)'})

  const query = new URLSearchParams(useLocation().search)

  const [expertise, setExpertise] = useState([
    {
      id: "mobile-application",
      title: "Mobile Application Development",
      description: "We craft your ideas and turn it into highly usable mobile applications that works across all platforms and consumer will love to use.",
      bannerImg: MobileAppsBanner,
      keypoints: [
        {
          title: "Product ideation and process",
          description: "Validate the product idea and entire application process that suit user needs.",
          keypointImg: MobileApps1
        },
        {
          title: "User Interface (UI) and User Experience Design (UX)",
          description: "Beautifully crafted User Interface (UI) and functional User Experience (UX) that focused on human-centered design approach.",
          keypointImg: MobileApps2
        },
        {
          title: "End-to-end development process",
          description: "We offers full-stack development that includes front-end (user facing), server (algorithm process), and database (business database).",
          keypointImg: MobileApps3
        },
        {
          title: "Cross platform (hybrid) mobile application",
          description: "Building mobile application that works across all platforms and scalable.",
          keypointImg: MobileApps4
        }
      ]
    },
    {
      id: "digital-product",
      title: "Digital Product Design and Experience",
      description: "We deliver strategic value in designing digital products to create memorable experience for consumers.",
      bannerImg: ProductDesignBanner,
      keypoints: [
        {
          title: "Product Roadmap",
          description: "Product Roadmap is an essential part that communicates the detailed plan and direction to achieve well-executed products.",
          keypointImg: DigitalProduct1
        },
        {
          title: "Concept and Prototyping",
          description: "Creating experiences that looks like real before we further move to development stage.",
          keypointImg: DigitalProduct2
        },
        {
          title: "User journey mapping and segmentation",
          description: "Defining clear personas and flows that makes user actions easier.",
          keypointImg: DigitalProduct3
        }
      ]
    },
    {
      id: "enterprise-software",
      title: "Enterprise Software Development",
      description: "We help bring together ideas and transform it into tailor-made software for your business.",
      bannerImg: EnterpriseSoftwareBanner,
      keypoints: [
        {
          title: "Customised software",
          description: "We provides custom software development services together with technical consultation that suits your business goals.",
          keypointImg: Enterprise1
        },
        {
          title: "Well structured system architecture",
          description: "Choosing the right system architecture is very important to ensure the success of project delivery.",
          keypointImg: Enterprise2
        },
        {
          title: "Focused on User-first approach",
          description: "Great software must be able to meet user satisfaction based on data-driven insights",
          keypointImg: Enterprise3
        }
      ]
    },
    {
      id: "website-development",
      title: "Website Development",
      description: "We offer end-to-end website development service. Make your business accessible from anywhere.",
      bannerImg: WebsiteDevBanner,
      keypoints: [
        {
          title: "Responsive website",
          description: "Nowadays, building compatible website with any devices is the key. Enabling your audience to access the site from desktop, tablet and mobile.",
          keypointImg: Webdev1
        },
        {
          title: "Seamless experience",
          description: "Letting your audience experience a smooth interface and seamless site.",
          keypointImg: Webdev2
        }
      ]
    },
    {
      id: "api-management",
      title: "API Creation and Management",
      description: "Provide the logic behind your business idea as a service and connect it via an Application Programming Interface (API) with your website or mobile application.",
      bannerImg: APICreationBanner,
      keypoints: [
        {
          title: "Trusted API Endpoints",
          description: "Helps you to bring more secure API endpoints by using enchanced authentication methods.",
          keypointImg: APICreation1
        }
      ]
    },
    {
      id: "tech-resource-support",
      title: "Tech Resource Support",
      description: "Provide you with dedicated tech resource support to extend your internal devs team without any hassle",
      bannerImg: BrandingBanner,
      keypoints: [
        {
          title: "Our capabilities",
          description: "Dedicated tech resources team, Proven quality of talents, Complete skill sets that suit your business needs, Reduce overhead costs and save time on hiring process, Software or Mobile App support and maintenance service",
          keypointImg: Branding1,
          isList: true
        }
      ]
    }
  ])

  // Scroll window to top on load
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])

  return (
    <div style={{overflow: 'hidden', paddingTop: '100px'}}>
      <HeadMeta 
        title={`Cloudxier - ${query.get('id').split('-').join(' ').toUpperCase()}`}
        description="Our mission is to help accelerating business in their digital innovation"
        siteName="Cloudxier"
        url={`https://www.cloudxier.com/expertise?id=${query.get('id')}`}
        image={MobileAppsBanner} />
      {
        expertise.map((expertise) => {
          if (expertise.id === query.get("id")) {
            return (
              <div>
                <ExpertiseMainBanner
                  title="Expertise"
                  subtitle={expertise.title}
                  text={expertise.description}
                  backgroundImg={expertise.bannerImg} />
                <Container style={{
                  padding: smallScreen ? '30px 0' : '50px 0 50px 0'
                }}>
                  {
                    expertise.keypoints.map((keypoint, index) => {
                      return (
                        <FeatureColumn
                          key={index}
                          smFullWidthMedia={true}
                          isMediaLeft={index % 2 == 0}
                          titleText={keypoint.title}
                          descriptionText={keypoint.description}
                          descriptionIsList={keypoint.isList}
                          titleColor='black'
                          textContainerPadding={smallScreen ? "0" : "0 50px"}
                          mediaUrl={keypoint.keypointImg}
                          mediaHeight={smallScreen ? '200px' : '500px'} />
                      )
                    })
                  }
                </Container>
                <ActionBanner
                  text='Ready to accelerate digital innovation in your business?'
                  actionText='Request a quote' />
              </div>
            )
          }
        })
      }
    </div>
  )

}

export default Expertise