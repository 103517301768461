import React from 'react'

function EcommerceIcon({
    className,
    width = "71",
    height = "71",
    fill = "#8000CF"
}) {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M27.1761 66.8846V57.0562C27.1761 53.993 27.1761 52.4631 27.8346 51.323C28.2659 50.576 28.8862 49.9557 29.6332 49.5244C30.7733 48.8659 32.3033 48.8659 35.3664 48.8659C38.4296 48.8659 39.9596 48.8659 41.0997 49.5244C41.8467 49.9557 42.467 50.576 42.8983 51.323C43.5568 52.4631 43.5568 53.993 43.5568 57.0562V66.8846M64.8517 68.5227H25.538M5.88123 68.5227H14.0716M58.2994 68.5227V45.5897M12.4335 68.5227V45.5897"
                stroke={fill}
                strokeWidth="4.9142"
                strokeLinecap="round"
            />
            <path
                d="M35.3665 3H20.5321C16.5909 3 14.622 3 13.0396 3.97956C11.4539 4.95585 10.5727 6.71841 8.81338 10.2435L4.21041 21.8705C3.14894 24.557 2.21852 27.7217 4.00729 29.9921C4.84997 31.0634 6.0058 31.8453 7.3138 32.2289C8.62179 32.6124 10.0168 32.5784 11.3046 32.1317C12.5924 31.6851 13.7088 30.8479 14.4984 29.7368C15.288 28.6257 15.7114 27.296 15.7096 25.9329C15.7096 27.6707 16.4 29.3373 17.6288 30.5661C18.8575 31.7949 20.5241 32.4852 22.2619 32.4852C23.9997 32.4852 25.6663 31.7949 26.8951 30.5661C28.1239 29.3373 28.8142 27.6707 28.8142 25.9329C28.8142 27.6707 29.5045 29.3373 30.7333 30.5661C31.9621 31.7949 33.6287 32.4852 35.3665 32.4852C37.1042 32.4852 38.7708 31.7949 39.9996 30.5661C41.2284 29.3373 41.9187 27.6707 41.9187 25.9329C41.9187 27.6707 42.6091 29.3373 43.8378 30.5661C45.0666 31.7949 46.7332 32.4852 48.471 32.4852C50.2088 32.4852 51.8754 31.7949 53.1041 30.5661C54.3329 29.3373 55.0233 27.6707 55.0233 25.9329C55.0231 27.2949 55.4473 28.6231 56.237 29.7328C57.0266 30.8425 58.1425 31.6785 59.4293 32.1246C60.7161 32.5708 62.11 32.6048 63.4171 32.222C64.7242 31.8392 65.8795 31.0586 66.7223 29.9888C68.5111 27.7184 67.5807 24.5537 66.5192 21.8673L61.9195 10.2435C60.1602 6.71841 59.279 4.95585 57.6933 3.97956C56.1109 3 54.142 3 50.2008 3H48.471"
                stroke={fill}
                strokeWidth="4.9142"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default EcommerceIcon