import { useState } from 'react'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Styled components
import styled from 'styled-components'

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

function ProjectHeading({ title, subtitle, titleColor, subtitleColor }) {

  const smallScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 1279px)' })

  const [isHeadingVisible, setHeadingVisible] = useState(false)

  return (
    <VisibilitySensor onChange={(isVisible) => setHeadingVisible(isVisible)} partialVisibility={true} active={!isHeadingVisible}>
      <HeadingContainer
        isHeadingVisible={isHeadingVisible}
        smallScreen={smallScreen}
        mediumScreen={mediumScreen}
      >
        <Title smallScreen={smallScreen} mediumScreen={mediumScreen} titleColor={titleColor}>
          {title}
        </Title>
        <Subtitle smallScreen={smallScreen} mediumScreen={mediumScreen} subtitleColor={subtitleColor}>
          {subtitle}
        </Subtitle>
      </HeadingContainer>
    </VisibilitySensor>
  )

}

export default ProjectHeading

const HeadingContainer = styled.div`
  text-align: center;
  opacity: ${({ isHeadingVisible }) => isHeadingVisible ? 1 : 0};
  transition: opacity 1s;
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px' : mediumScreen ? '30px' : '50px'}
`

const Title = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '12px' : mediumScreen ? '14px' : '16px'};
  color: ${({ titleColor }) => titleColor || 'black'};
  text-transform: uppercase
`

const Subtitle = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px' : mediumScreen ? '35px' : '40px'};
  color: ${({ subtitleColor }) => subtitleColor || 'black'};
  font-weight: bold
`