// React
import { useEffect } from 'react'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Components
import HeadMeta from '../components/HeadMeta'
import OurStory from '../components/OurStory'
import OurTeam from '../components/OurTeam';
import OurCoreValues from '../components/OurCoreValues';
import OurHoldingGroup from '../components/OurHoldingGroup';

// Images
import AboutUsBanner from '../assets/images/about_us_banner/Cloudxier-aboutus-banner.png'

function AboutUs() {
  const smallScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 1279px)' })

  // Scroll window to top on load
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])

  return (
    <div style={{ overflow: 'hidden', paddingTop: smallScreen ? '50px' : mediumScreen ? '50px' : '100px' }}>
      <HeadMeta
        title="Cloudxier - About Us"
        description="Our mission is to help accelerating business in their digital innovation"
        siteName="Cloudxier"
        url="https://www.cloudxier.com/about/"
        image={AboutUsBanner}
      />
      <OurStory />
      <OurTeam />
      <OurCoreValues />
      <OurHoldingGroup />
    </div>
  )

}

export default AboutUs