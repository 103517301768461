const {
  CLIENT_STAGING_HOST,
  CLIENT_PROD_HOST,
  SERVER_DEV_URL,
  SERVER_STAGING_URL,
  SERVER_PROD_URL
} = require('../config')

module.exports = () => {
  let url = SERVER_DEV_URL
  const host = window.location.host
  if (host.includes(CLIENT_STAGING_HOST)) {
    url = SERVER_STAGING_URL
  }
  if (host.includes(CLIENT_PROD_HOST)) {
    url = SERVER_PROD_URL
  }
  return url
}
