// React
import { useState, useEffect } from 'react'

// Bootstrap
import { Col, Container, Row } from 'react-bootstrap'

// Animated
import { Animated } from "react-animated-css";

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

// Components
import HeadMeta from '../../components/HeadMeta'
import DiscussForm from '../../components/DiscussForm'

// Styled components
import styled from 'styled-components'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Helpers
import fontSize from '../../helpers/fontSize'
import { device } from '../../helpers/device'

function MobileAppDevelopment() {
  const smallScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 1279px)' })
  const largeScreen = useMediaQuery({ query: '(max-width: 1499px)' })

  const [isContent1Visible, setContent1Visible] = useState(false)
  const [isContent2Visible, setContent2Visible] = useState(false)
  const [isContent3Visible, setContent3Visible] = useState(false)
  const [isContent4Visible, setContent4Visible] = useState(false)
  const [isContent5Visible, setContent5Visible] = useState(false)
  const [isContent6Visible, setContent6Visible] = useState(false)

  const [upperContent, setUpperContent] = useState([
    (
      <>
        <BodyText style={{ fontSize: smallScreen ? '21px' : mediumScreen ? '23px' : '25px' }}>
          Our mobile app development process is a comprehensive journey designed to transform your ideas into powerful, user-friendly applications. It begins with an in-depth discovery and consultation phase, where we conduct discussion to understand your business goals and app requirements (including: the features, action goals of the user).
        </BodyText>
        <BodyText style={{ fontSize: smallScreen ? '21px' : mediumScreen ? '23px' : '25px' }}>
          We then move to the planning and strategy stage, developing detailed project roadmaps, wireframes, and prototypes. Our design and user experience phase focuses on crafting intuitive, visually appealing interfaces. During development, we use agile methodologies to implement features, ensuring cross-platform compatibility for iOS, Android, or hybrid solutions.
        </BodyText>
      </>
    ),
    (
      <>
        <BodyText style={{ fontSize: smallScreen ? '21px' : mediumScreen ? '23px' : '25px' }}>
          Followed by rigorous testing and quality assurance to ensure a flawless user experience. Finally, we handle deployment and launch, including app store submissions and post-launch support, to ensure your app achieves maximum impact and continuous improvement.  After the mobile app is launched, we also provide ongoing support and maintenance service to address any issues that may arise, ensuring your application remains reliable, up-to-date, and optimized for peak performance.
        </BodyText>
      </>
    ),
  ])

  const [whatWeDoContent, setWhatWeDoContent] = useState([
    (
      <>
        <SubHeading>
          Ensuring a consistent user experience for both iOS or Android platform
        </SubHeading>
        <BodyText style={{ textAlign: 'left' }}>
          Our cross-platform mobile app development service delivers efficient and high-quality mobile applications for both iOS and Android platforms, ensuring a consistent user experience across all devices. By leveraging advanced frameworks like React Native, we build mobile apps that perform smoothly on all devices.
        </BodyText>
        <BodyText style={{ textAlign: 'left' }}>
          This reduces development time and costs, as well as ensuring the mobile app is optimized for both operating systems and performance.
        </BodyText>
      </>
    ),
    (
      <>
        <SubHeading>
          Building mobile applications with scalable solutions
        </SubHeading>
        <BodyText style={{ textAlign: 'left' }}>
          Building mobile apps with scalable solutions is at the core of our development philosophy. We design and develop applications that are built to grow with your business. Utilizing advanced technologies and flexible architectures, we ensure that your app can handle increasing user loads and expanding feature sets without compromising performance.
        </BodyText>
      </>
    ),
    (
      <>
        <SubHeading>
          Delivering high-performance mobile app that is easy to navigate
        </SubHeading>
        <BodyText style={{ textAlign: 'left' }}>
          By focusing on optimized performance, user-focused design, and functionality, our mobile app provides users with an engaging and efficient experience, whether they're browsing, shopping, or interacting with dynamic content. Our commitment to quality ensures that every app we develop runs flawlessly across different devices and platforms, delivering fast load times, and a user interface that is both attractive and easy to navigate.
        </BodyText>
      </>
    ),
    (
      <>
        <SubHeading>
          Implement the design into functional code using agile methodologies
        </SubHeading>
        <BodyText style={{ textAlign: 'left' }}>
          By using agile practices, we ensure a flexible and iterative development process that adapts to changing requirements and feedback. Our team collaborates closely with clients and stakeholders to prioritize features, manage tasks, and deliver incremental improvements, ensuring continuous progress and alignment with project goals. This approach allows us to rapidly translate design concepts into functional applications, maintaining a strong focus on usability, performance, and reliability.
        </BodyText>
      </>
    )
  ])

  // Scroll window to top on load
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])

  const [animateBannerTitle, setAnimateBannerTitle] = useState(false)

  // Start banner title animation on page load
  useEffect(() => {
    setAnimateBannerTitle(true)
  }, [])

  return (
    <div style={{ overflow: 'hidden' }}>
      <HeadMeta
        title="Cloudxier - Mobile App Development"
        description="Our mission is to help accelerating business in their digital innovation"
        siteName="Cloudxier"
        url="https://www.cloudxier.com/about/"
      />
      <MainBannerContainer >
        <MainBannerTextContainer animateBannerTitle={animateBannerTitle}>
          <MainBannerTextFlexContainer>
            <Container style={{ padding: smallScreen ? '0 10vw' : mediumScreen ? '0 10vw' : 0 }}>
              <MainBannerTitle smallScreen={smallScreen} mediumScreen={mediumScreen}>
                Mobile App Development
              </MainBannerTitle>
              <MainBannerSubTitle smallScreen={smallScreen} mediumScreen={mediumScreen}>
                Our mobile app development service is tailor-made to align with your business objectives.
                <br /> <br />
                From iOS, Android or Hybrid cross-platform app development that allows your app to run smoothly across various devices and operating systems, ensuring you get more value for your investment.
              </MainBannerSubTitle>
            </Container>
          </MainBannerTextFlexContainer>
        </MainBannerTextContainer>
        <BannerOverlay />
        <HomeBannerImage src='https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/af19781d221edf2945ce757e41ff67fd54f8846b60111d28837ee2e77c5dd4990dac4086de9d4f127c0cf305966fbb571679f18d69602bd8d6a6a5fd386160b2e77cbca5a120d77d5522d638003adbba7e0ab6568c2e6e964c990b97bad06f9d4bafc3deb26678aeee6c2baf38895e2549183c2613b9dc6924b5/rez-file-1719307661174.png' alt='main-banner-1' />
      </MainBannerContainer>
      <MainContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
        <Container style={{ padding: smallScreen ? '0 10vw' : mediumScreen ? '0 5vw' : '0 0 50px 0' }}>
          <VisibilitySensor onChange={(isVisible) => setContent1Visible(isVisible)} active={!isContent1Visible} partialVisibility={true}>
            <Animated animationIn="fadeInUp" animationInDuration={2000} isVisible={isContent1Visible}>
              <SubHeading style={{ fontSize: smallScreen ? '30px' : mediumScreen ? '35px' : '40px', margin: smallScreen ? '25px 0' : mediumScreen ? '25px 0' : '25px 50px' }}>
                Transforming ideas into powerful Mobile Applications
              </SubHeading>
              <hr style={{ height: "2px", border: "none", color: "#333", backgroundColor: "#333" }} />
              <Row style={{ margin: smallScreen ? '25px 0' : mediumScreen ? '25px 0' : '50px 50px' }}>
                {
                  upperContent.map((item, i) => {
                    return (
                      <Col key={i} sm={12} md={6} lg={6} style={{ padding: smallScreen ? '0' : mediumScreen ? '0px 30px' : "0px 50px" }}>
                        {item}
                      </Col>
                    )
                  })
                }
              </Row>
            </Animated>
          </VisibilitySensor>
          <VisibilitySensor onChange={(isVisible) => setContent2Visible(isVisible)} active={!isContent2Visible} partialVisibility={true}>
            <Animated animationIn="zoomIn" animationInDuration={2000} isVisible={isContent2Visible}>
              <HomeBannerImage src={'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/e8af07c4dea70b217151a5fce00c4b7098e3fba7d8c842d9d87e50dc306b760c50e40d7bab4b5ab10243f238637cb0b2dbb7075f578c4fa65b03bd4ef596cff819af3cbf00b4c2abea81ffa284f6403d2ea88a966399a9d22dd562c1465ef662808439c858fe1d447cf28fab3c229737b8311905615077972083/rez-file-1719545191825.png'} alt='main-banner-2' />
            </Animated>
          </VisibilitySensor>
          <VisibilitySensor onChange={(isVisible) => setContent3Visible(isVisible)} active={!isContent3Visible} partialVisibility={true}>
            <Animated animationIn="fadeInRight" animationInDuration={2000} isVisible={isContent3Visible}>
              <Heading>
                Our Approach
              </Heading>
              <SubHeading>
                Discovery, Consultation and Planning:
              </SubHeading>
              <BodyText>
                <ul>
                  <li>Understand your business goals and app feature requirements.</li>
                  <li>Intensive consultation to ensure our perspective is aligned with clients.</li>
                  <li>Develop a detailed project plan and roadmap to launch a Mobile App.</li>
                  <li>Create wireframes and prototypes to visualize the Mobile App’s structure and user journey flow.</li>
                  <li>Choose the right technology stacks and develop a software architecture plan.</li>
                </ul>
              </BodyText>
              <SubHeading>
                Mobile App UI/UX Design:
              </SubHeading>
              <BodyText>
                <ul>
                  <li>Create design system documentation as a guideline standards for consistency and quality throughout the development process.</li>
                  <li>Develop wireframes and prototypes.</li>
                  <li>Create user-focused UI/UX Design.</li>
                </ul>
              </BodyText>
              <SubHeading>
                Development to App Store or Play Store:
              </SubHeading>
              <BodyText>
                <ul>
                  <li>Implement the design into functional code using agile methodologies</li>
                  <li>Develop backend services, APIs, and integrate with third-party systems (if required).</li>
                  <li>Ensure cross-platform compatibility for both iOS and Android.</li>
                </ul>
              </BodyText>
              <SubHeading>
                Testing & Quality Assurance with UAT:
              </SubHeading>
              <BodyText>
                <ul>
                  <li>Perform extensive testing; related to functional, performance, and security testing.</li>
                  <li>Address any bugs or issues to ensure a smooth and reliable app experience.</li>
                  <li>Conduct user acceptance testing (UAT) to validate the app with real users.</li>
                </ul>
              </BodyText>
              <SubHeading>
                Deployment & Launch:
              </SubHeading>
              <BodyText>
                <ul>
                  <li>Prepare and manage app registration to Apple App Store and Google Play Store.</li>
                  <li>Manage all aspects of app store compliance and approval processes.</li>
                  <li>Plan and execute launch strategy together with clients.</li>
                </ul>
              </BodyText>
              <SubHeading>
                Maintenance & Support:
              </SubHeading>
              <BodyText>
                <ul>
                  <li>Provide ongoing support to address any issues after the app is launched.</li>
                  <li>Regularly update the app with new features and improvements.</li>
                  <li>Monitor app performance and user feedback for continuous enhancement.</li>
                </ul>
              </BodyText>
            </Animated>
          </VisibilitySensor>
          <Container style={{ margin: smallScreen ? '25px 0' : mediumScreen ? '25px 0' : '0' }}>
            <VisibilitySensor onChange={(isVisible) => setContent4Visible(isVisible)} active={!isContent4Visible} partialVisibility={true}>
              <Animated animationIn="slideInUp" animationInDuration={2000} isVisible={isContent4Visible}>
                <Heading>
                  What We Do
                </Heading>
                <Row style={{ margin: smallScreen ? '0' : mediumScreen ? '0' : '0' }}>
                  {
                    whatWeDoContent.map((item, i) => {
                      return (
                        <Col key={i} sm={12} md={6} lg={6} style={{ padding: smallScreen ? '0' : mediumScreen ? '0px 50px 0 0' : "0px 60px 0 0" }}>
                          {item}
                        </Col>
                      )
                    })
                  }
                </Row>
              </Animated>
            </VisibilitySensor>
          </Container>
        </Container>
        <VisibilitySensor onChange={(isVisible) => setContent5Visible(isVisible)} active={!isContent5Visible} partialVisibility={true}>
          <Animated animationIn="slideInLeft" animationInDuration={2000} isVisible={isContent5Visible}>
            <Post smallScreen={smallScreen} mediumScreen={mediumScreen}>
              <ImageContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
                <Image src={'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/1dd799d264afe5850b4f22d280348d89abade1f906bd88d64b2e597376b5945a60ddb68e64d11d0cad0ab03c2cfc76139e4ab8a3ee652e870881b961b1bf128cf9f0e9ecc85631e6bb7fd59596ad89c1dcd3ab22c67d91e8836a33f4b6782de4b07b4821870bd040129104af2ae0f8158adf4f8dec3ddf41ea4a/rez-file-1719545227418.jpg'} alt='banner-images-3' smallScreen={smallScreen} mediumScreen={mediumScreen} />
              </ImageContainer>
              <Description smallScreen={smallScreen} mediumScreen={mediumScreen} largeScreen={largeScreen}>
                <Title smallScreen={smallScreen} mediumScreen={mediumScreen} >
                  Whether you're looking to develop a new app from scratch or enhance an existing one, our team is here to deliver high-performance, user-friendly, and scalable mobile application.
                </Title>
              </Description>
            </Post>
          </Animated>
        </VisibilitySensor>
        <Container>
          <br />
          <br />
          <br />
          <br />
          <br />
          <VisibilitySensor onChange={(isVisible) => setContent6Visible(isVisible)} active={!isContent6Visible} partialVisibility={true}>
            <Animated animationIn="fadeInUp" animationInDuration={2000} isVisible={isContent6Visible}>
              <Heading style={{ textAlign: 'center' }}>
                Discuss with us now.
              </Heading>
              <BodyText style={{ textAlign: 'center' }}>
                Have questions or ready to discuss your project? We're here to help! Please fill in the inquiry form below:
              </BodyText>
              <DiscussForm />
            </Animated>
          </VisibilitySensor>
        </Container>
      </MainContainer>
    </div >
  )

}

export default MobileAppDevelopment

const MainContainer = styled.div`
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px 0 30px 0' : mediumScreen ? '30px 0 30px 0' : '50px 0 50px 0'}
`
const MainBannerContainer = styled.div`
  width: 100%;
  height: 80vh;
`
const MainBannerTextContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 80%;
  text-align: left;
  color: white;
  z-index: 2;
  transition: opacity 2s, margin 1s;
  opacity: ${({ animateBannerTitle }) => animateBannerTitle ? '1' : '0'};
  margin-top: ${({ animateBannerTitle }) => animateBannerTitle ? '0' : '-50vh'};
`
const MainBannerTextFlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-top: 7%;
`
const MainBannerTitle = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px' : mediumScreen ? '35px' : '40px'};
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '40px' : mediumScreen ? '30px' : '60px'};
  font-weight: 900;
`
const MainBannerSubTitle = styled.div`
   font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '17px' : mediumScreen ? '20px' : '23px'};
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '0' : mediumScreen ? '0' : '0'};
`
const BannerOverlay = styled.div`
  background-color: rgba(0,0,0,0.3);
  width: 100%;
  height: 80vh;
  position: absolute;
  z-index: 1;
`
const HomeBannerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const ImageContainer = styled.div`
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? '100%' : mediumScreen ? '100%' : '50%'};
  height: auto;
`
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover
`
const Post = styled.div`
  display: ${({ smallScreen, mediumScreen }) => smallScreen ? 'block' : mediumScreen ? 'block' : 'flex'};
  width: 100%;
  margin: 80px 0;
`
const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${({ smallScreen, mediumScreen }) => smallScreen ? 'center' : mediumScreen ? 'center' : 'flex-start'};
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? '100%' : mediumScreen ? '100%' : '50%'};
  height: ${({ smallScreen, mediumScreen }) => smallScreen ? 'auto' : mediumScreen ? 'auto' : 'auto'};
  padding: ${({ smallScreen, mediumScreen, largeScreen }) => smallScreen ? '50px 10vw' : mediumScreen ? '50px 10vw' : largeScreen ? '20px 100px' : '20px 100px'};
`
const Title = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px' : mediumScreen ? '35px' : '45px'};
  font-weight: 900;
  text-align: ${({ smallScreen, mediumScreen }) => smallScreen ? 'center' : mediumScreen ? 'center' : 'left'};
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '0' : mediumScreen ? '0' : '0'};
  cursor: pointer;
`
const Heading = styled.div`
  font-size: ${fontSize.heading1Sm};
  color: #4C39FF;
  font-weight: 900;
  text-align: left;
  margin-top: 24px;
  margin-bottom: 12px;
  @media ${device.tablet} {
    font-size: ${fontSize.heading1Sm};
    margin-top: 48px;
    margin-bottom: 24px;
  }
  @media ${device.laptop} {
    font-size: ${fontSize.heading3Lg};
    margin-top: 48px;
    margin-bottom: 24px;
  }
  @media ${device.laptopM} {
    font-size: ${fontSize.heading2Lg};
    margin-top: 48px;
    margin-bottom: 24px;
  }
  @media ${device.laptopL} {
    font-size: 40px;
    margin-top: 48px;
    margin-bottom: 24px;
  }
`
const SubHeading = styled.p`
  font-size: ${fontSize.heading2Sm};
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 8px;
  @media ${device.tablet} {
    font-size: 20px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptop} {
    font-size: ${fontSize.heading1Sm};
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopM} {
    font-size: ${fontSize.heading3Lg};
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopL} {
    font-size: ${fontSize.heading3Lg};
    margin-top: 32px;
    margin-bottom: 16px;
  }
`
const BodyText = styled.p`
  text-align:justify;
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 8px;
  @media ${device.tablet} {
    font-size: 18px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptop} {
    font-size: ${fontSize.bodyLg};
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopM} {
    font-size: 22px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopL} {
    font-size: 22px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
`