// React
import { useState, useEffect } from 'react'

// Animated
import { Animated } from "react-animated-css";

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

// Bootstrap
import { Col, Container, Row } from 'react-bootstrap'

// Timeline
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

// Components
import HeadMeta from '../../components/HeadMeta'
import DiscussForm from '../../components/DiscussForm'

// Styled components
import styled from 'styled-components'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Helpers
import fontSize from '../../helpers/fontSize'
import { device } from '../../helpers/device'

function CTOAsService() {
  const smallScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 1279px)' })
  const largeScreen = useMediaQuery({ query: '(max-width: 1499px)' })

  const [isContent1Visible, setContent1Visible] = useState(false)
  const [isContent2Visible, setContent2Visible] = useState(false)
  const [isContent3Visible, setContent3Visible] = useState(false)

  const [consultationServices, setConsultationServices] = useState([
    {
      title: "Initial Consultation",
      description: (
        <BodyText style={{ fontSize: smallScreen ? '18px' : mediumScreen ? '20px' : '22px' }}>
          We start with an in-depth consultation to understand your business and technology context
        </BodyText>
      ),
      isDescOpen: true
    },
    {
      title: "Assessment and Planning",
      description: (
        <BodyText style={{ fontSize: smallScreen ? '18px' : mediumScreen ? '20px' : '22px' }}>
          Conduct a thorough assessment of your current technology environment and develop a strategic plan based on your business goals.
        </BodyText>
      ),
      isDescOpen: false
    },
    {
      title: "Implementation and Leadership",
      description: (
        <BodyText style={{ fontSize: smallScreen ? '18px' : mediumScreen ? '20px' : '22px' }}>
          Provide hands-on leadership to implement the strategic plan, including overseeing projects, managing teams, and ensuring alignment with business objectives.
        </BodyText>
      ),
      isDescOpen: false
    },
    {
      title: "Ongoing Support and Advisory",
      description: (
        <BodyText style={{ fontSize: smallScreen ? '18px' : mediumScreen ? '20px' : '22px' }}>
          Offer continuous support and advisory services to adapt to evolving needs and ensure sustained success.
        </BodyText>
      ),
      isDescOpen: false
    },
  ])

  const [supportContent, setSupportContent] = useState([
    {
      img: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/e401dd07b18018d2a455c59a04c22b84d440cf40dcdfcacc321c2043d929505fc3c38299a03682a576638d1a421e02b6f793f7e98b0509f257e4d27bae9273391a88e709c7b11779bbaf7dcbc4df56d8a2ab17e1698224e605608c61c7a4282c6441c194495e48223c9786a8699ba8708b3efc7f438e13cb6b14/rez-file-1719546819020.png',
      content: (<>
        <SubHeading>
          Technology Planning and Strategy
        </SubHeading>
        <BodyText style={{ textAlign: "left" }}>
          <ul>
            <li>Managing the technical aspects involved in the work and development processes.</li>
            <br />
            <li>Evaluation and selection of appropriate technologies and platforms.</li>
          </ul>
        </BodyText>
      </>)
    },
    {
      img: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/d92c416858c57f4f0444b0b6a9d8bb78139d2ca2d43a49a3c987274e0d1b00db3b9485b7a8e0b9ba01e2213d6b74effa0a6cb8e520a7bc257af38b20ab82262a9abd372a3ffcb8d622e072d092b5b92ef06f97e397575140a1187cc85fa40b43552becc314ac823ee14c820607fa707659e0adf6eacdd5e95a80/rez-file-1719546846069.png',
      content: (<>
        <SubHeading>
          Technical Software Architecture
        </SubHeading>
        <BodyText style={{ textAlign: "left" }}>
          <ul>
            <li>Designing a system with the right technology stacks to ensure scalability, security and performance.</li>
            <br />
            <li>Recommendations for technical architecture improvements.</li>
          </ul>
        </BodyText>
      </>)
    },
    {
      img: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/0ceb1cbe6d87f16508e3a8e27a6c1cc9a239d902a306bcbe47a7a6f040577187e2d6b1e5dfa55c5b74aeae8eeeb4cd4dbc7c8eb5cd32eceafd85deb62112da896734cefb22eea0863f8892fe12993b3ec872026db8d234a2e307b50933c7216149e7b95d1a57cbafb9ebd3267f1459e983e1c87a85de2c1f6840/rez-file-1719546860887.png',
      content: (<>
        <SubHeading>
          Security and Compliance
        </SubHeading>
        <BodyText style={{ textAlign: "left" }}>
          <ul>
            <li>Development and implementation of robust security procedures.</li>
            <br />
            <li>Ensuring compliance with relevant regulations and standards.</li>
          </ul>
        </BodyText>
      </>)
    },
    {
      img: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/42992232f18f883fa602e99983668848b3a018791b5cefee9c490f72ae2bd695172eefb4f049f0b02875b255e2d90f074df7e306fc75301405d8467bb4c5342fa40281ea4bb6b307045f2c103ee485d36563f35fc8606265c45e81a9d15890a52e9435bc1c1a95de67bbba90c11a24985be6f07d346c10a0a587/rez-file-1719546887153.png',
      content: (<>
        <SubHeading>
          Vendor and Stakeholder Management
        </SubHeading>
        <BodyText style={{ textAlign: "left" }}>
          <ul>
            <li>Management of relationships with technology vendors and service providers.</li>
            <br />
            <li>Coordination with stakeholders to align technology initiatives with business needs.</li>
          </ul>
        </BodyText>
      </>)
    },
    {
      img: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/9c21ff884b1d1f3086395081ea6e1a1ce043919199b005715eeb9e9d21f1fd473ed7984ba6bfe7494914cd945a52fe1d87f31a0f7b70a03f3bbbe0cfed84d2667cb505ff5dc4d58beef89d9a5f565eda1ded6b3d06f4a2a936a9b831414642788978959242c20d7b506a3d16feb459254e49f2e06b67ff846905/rez-file-1719546904552.png',
      content: (<>
        <SubHeading>
          Assistance in Hiring Tech Team
        </SubHeading>
        <BodyText style={{ textAlign: "left" }}>
          <ul>
            <li>Conduct a mentoring for the existing technology team.</li>
            <br />
            <li>Assistance in hiring and building a strong team.</li>
          </ul>
        </BodyText>
      </>)
    },
    {
      img: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/ea813c16663355c2bda812a4c477859ed370e297fac994261432143d9b2233e2e0cbd3b4d4bacb65648f6ac1657a62fedb5c00cd95e00ec2efc9213abd85826831705ebe2cd93e1a35113b316ea21db5d9ac3857c0dc49d5f0009143f25e5070037a57c9cdcd5ff5e634771b61fbcb814c140c64c16f3c24c6ac/rez-file-1719546921345.png',
      content: (<>
        <SubHeading>
          Product Development
        </SubHeading>
        <BodyText style={{ textAlign: "left" }}>
          <ul>
            <li>Providing guidance on product development lifecycle, from concepting to launch.</li>
            <br />
            <li>Coordination with product team to ensure alignment between technical and business objectives.</li>
          </ul>
        </BodyText>
      </>)
    },
  ])

  const handleDropdown = (i, value) => {
    const newConsultationServices = consultationServices.map((item, itemIndex) => {
      if (itemIndex == i) {
        return {
          ...item,
          isDescOpen: !value
        };
      } else {
        return item;
      }
    });
    setConsultationServices(newConsultationServices)
  }

  // Scroll window to top on load
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])

  const [animateBannerTitle, setAnimateBannerTitle] = useState(false)

  // Start banner title animation on page load
  useEffect(() => {
    setAnimateBannerTitle(true)
  }, [])

  return (
    <div style={{ overflow: 'hidden' }}>
      <HeadMeta
        title="Cloudxier - CTO as Service"
        description="Our mission is to help accelerating business in their digital innovation"
        siteName="Cloudxier"
        url="https://www.cloudxier.com/about/"
      />
      <MainBannerContainer >
        <MainBannerTextContainer animateBannerTitle={animateBannerTitle}>
          <MainBannerTextFlexContainer>
            <Container style={{ padding: smallScreen ? '0 10vw' : mediumScreen ? '0 10vw' : 0 }}>
              <MainBannerTitle smallScreen={smallScreen} mediumScreen={mediumScreen}>
                CTO as a Service
              </MainBannerTitle>
              <MainBannerSubTitle smallScreen={smallScreen} mediumScreen={mediumScreen}>
                Providing businesses with access to high-level technical expertise and strategic guidance without the need for a full-time Chief Technology Officer (CTO).
                <br /> <br />
                This flexible and scalable solution allows businesses to tap into the knowledge and experience of technology leaders with an on-demand basis, it’s more cost-effective and flexible!
              </MainBannerSubTitle>
            </Container>
          </MainBannerTextFlexContainer>
        </MainBannerTextContainer>
        <BannerOverlay />
        <HomeBannerImage src='https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/74083d5b8a93c29ecfcd9fc58c687125a75fe8deaa110be57f06e62a6b9dfc25777562ef55ddd74626d8e5218ee77df6d8031cb35b587c304d9273034548b1ecca6547219ce484a89b1de2a6408bcfbc1e9b24e89c6b2efdbc90f33740f42cbd8138147fb6158bc580b2e9c1b091632f1d8890c67e51039bfc3d/rez-file-1719307096707.png' alt='main-banner-1' />
      </MainBannerContainer>
      <MainContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
        <Container style={{ padding: smallScreen ? '0 10vw' : mediumScreen ? '0 5vw' : '0 0 50px 0' }}>
          <Container style={{ margin: smallScreen ? '25px 0' : mediumScreen ? '25px 0' : '0' }}>
            <VisibilitySensor onChange={(isVisible) => setContent1Visible(isVisible)} active={!isContent1Visible} partialVisibility={true}>
              <Animated animationIn="fadeInUp" animationInDuration={2000} isVisible={isContent1Visible}>
                <Heading>
                  How We Support
                </Heading>
                <Row style={{ display: "flex", justifyContent: "space-evenly", marginTop: smallScreen ? '30px' : mediumScreen ? '40px' : '50px' }}>
                  {
                    supportContent.map((item, i) => {
                      return (

                        <Col key={i} sm={12} md={5} lg={5} style={{ backgroundColor: "#F8F8F8", borderRadius: "25px", padding: smallScreen ? '30px' : mediumScreen ? '40px' : '40px', marginBottom: smallScreen ? "30px" : mediumScreen ? "40px" : "50px" }}>
                          <img src={item.img} style={{ width: smallScreen ? '150px' : mediumScreen ? '200px' : '250px', height: smallScreen ? '150px' : mediumScreen ? '200px' : '250px' }} alt={`image-${i}`} />
                          {item.content}
                        </Col>
                      )
                    })
                  }
                </Row>
              </Animated>
            </VisibilitySensor>
          </Container>
          <VisibilitySensor onChange={(isVisible) => setContent2Visible(isVisible)} active={!isContent2Visible} partialVisibility={true}>
            <Animated animationIn="fadeInUp" animationInDuration={2000} isVisible={isContent2Visible}>
              <Heading>
                How It Works
              </Heading>
              <VerticalTimeline
                className='cto-service-timeline'
                lineColor="#A6A6A6"
                layout='1-column-left'
                animate={isContent2Visible}
              >
                {
                  consultationServices.map((item, i) => (
                    <VerticalTimelineElement
                      iconStyle={{ background: '#6DDFEF' }}
                      icon={(
                        <div style={{ textAlign: "center", fontSize: "26px", color: "#4C39FF", fontWeight: "bold" }}>
                          {i + 1}
                        </div>
                      )}
                      contentStyle={{ paddingTop: "0", boxShadow: "none" }}
                    >
                      <TitleOffer smallScreen={smallScreen} mediumScreen={mediumScreen} >
                        {item.title}
                      </TitleOffer>
                      {item.description}
                    </VerticalTimelineElement>
                  ))
                }
              </VerticalTimeline>
            </Animated>
          </VisibilitySensor>
        </Container>
        <Container>
          <br />
          <br />
          <br />
          <br />
          <br />
          <VisibilitySensor onChange={(isVisible) => setContent3Visible(isVisible)} active={!isContent3Visible} partialVisibility={true}>
            <Animated animationIn="fadeInUp" animationInDuration={2000} isVisible={isContent3Visible}>
              <Heading style={{ textAlign: 'center' }}>
                Discuss with us now.
              </Heading>
              <BodyText style={{ textAlign: 'center' }}>
                Have questions or ready to discuss your project? We're here to help! Please fill in the inquiry form below:
              </BodyText>
              <DiscussForm />
            </Animated>
          </VisibilitySensor>
        </Container>
      </MainContainer>
    </div >
  )

}

export default CTOAsService

const MainContainer = styled.div`
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px 0 30px 0' : mediumScreen ? '30px 0 30px 0' : '50px 0 50px 0'}
`
const MainBannerContainer = styled.div`
  width: 100%;
  height: 80vh;
`
const MainBannerTextContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 80%;
  text-align: left;
  color: white;
  z-index: 2;
  transition: opacity 2s, margin 1s;
  opacity: ${({ animateBannerTitle }) => animateBannerTitle ? '1' : '0'};
  margin-top: ${({ animateBannerTitle }) => animateBannerTitle ? '0' : '-50vh'};
`
const MainBannerTextFlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-top: 7%;
`
const MainBannerTitle = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px' : mediumScreen ? '35px' : '40px'};
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '40px' : mediumScreen ? '30px' : '60px'};
  font-weight: 900;
`
const MainBannerSubTitle = styled.div`
   font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '17px' : mediumScreen ? '20px' : '23px'};
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '0' : mediumScreen ? '0' : '0'};
`
const BannerOverlay = styled.div`
  background-color: rgba(0,0,0,0.3);
  width: 100%;
  height: 80vh;
  position: absolute;
  z-index: 1;
`
const HomeBannerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const Heading = styled.div`
  font-size: ${fontSize.heading1Sm};
  color: #4C39FF;
  font-weight: 900;
  text-align: left;
  margin-top: 24px;
  margin-bottom: 12px;
  @media ${device.tablet} {
    font-size: ${fontSize.heading1Sm};
    margin-top: 48px;
    margin-bottom: 24px;
  }
  @media ${device.laptop} {
    font-size: ${fontSize.heading3Lg};
    margin-top: 48px;
    margin-bottom: 24px;
  }
  @media ${device.laptopM} {
    font-size: ${fontSize.heading2Lg};
    margin-top: 48px;
    margin-bottom: 24px;
  }
  @media ${device.laptopL} {
    font-size: 40px;
    margin-top: 48px;
    margin-bottom: 24px;
  }
`
const SubHeading = styled.p`
  font-size: ${fontSize.heading2Sm};
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 8px;
  @media ${device.tablet} {
    font-size: 20px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptop} {
    font-size: ${fontSize.heading1Sm};
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopM} {
    font-size: ${fontSize.heading3Lg};
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopL} {
    font-size: ${fontSize.heading3Lg};
    margin-top: 32px;
    margin-bottom: 16px;
  }
`
const BodyText = styled.p`
  text-align:justify;
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 8px;
  line-height: 40px;
  @media ${device.tablet} {
    font-size: 18px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptop} {
    font-size: ${fontSize.bodyLg};
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopM} {
    font-size: 22px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopL} {
    font-size: 22px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
`
const ColOfferContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px' : mediumScreen ? '30px 40px' : '30px 40px'};
  height: auto;
  margin-bottom: 30px;
  border-radius: 15px;
  background-color: #F8F8F8;
`
const HeadlineOffer = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  align-items:center;
`
const DropdownIconContainer = styled.div`
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '0px' : mediumScreen ? '0px' : '0'};
`
const DropdownIcon = styled.img`
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? '10px' : mediumScreen ? '15px' : '20px'};
  height: ${({ smallScreen, mediumScreen }) => smallScreen ? '10px' : mediumScreen ? '15px' : '20px'};
  object-fit: contain
`
const TitleOffer = styled.div`
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? 'auto' : mediumScreen ? 'auto' : 'auto'};
  height: ${({ smallScreen, mediumScreen }) => smallScreen ? 'auto' : mediumScreen ? 'auto' : 'auto'};
  color: ${({ textColor }) => textColor ? textColor : 'black'};
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '20px' : mediumScreen ? '25px' : '30px'};
  font-weight: 700;
`
const DescriptionOffer = styled.div`
  margin-top: ${({ smallScreen, mediumScreen }) => smallScreen ? '18px' : mediumScreen ? '18px' : '18px'};
  color: ${({ textColor }) => textColor ? textColor : 'black'};
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '16px' : mediumScreen ? '20px' : '22px'};
  font-weight: 400;
  text-align: justify;
`