// React
import { useState } from 'react'

// React bootstrap
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// Styled components
import styled, { keyframes } from 'styled-components'

// Animated
import { Animated } from "react-animated-css";

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Components
import SectionHeading from './headings/SectionHeading'
import OurFounders from './OurFounders'

// Helpers
import fontSize from '../helpers/fontSize'

// Images
import Handson from '../assets/images/our_team/Cloudxier-HANDSON-Profile-Developer.png'
import Abil from '../assets/images/our_team/Cloudxier-ABIL-Profile-Developer.png'
import Arif from '../assets/images/our_team/Cloudxier-ARIF-Profile-Developer.png'
import Benedictus from '../assets/images/our_team/Cloudxier-BENEDICTUS BILLY-Profile-Content.png'
import Emilianus from '../assets/images/our_team/Cloudxier-EMILIANUS-Profile-Finance.png'
import Nabila from '../assets/images/our_team/Cloudxier-NABILA-Profile-Designer.png'

function OurTeam() {

  const smallScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 1279px)' })

  const [isContent1Visible, setContent1Visible] = useState(false)
  const [isContent2Visible, setContent2Visible] = useState(false)

  const [developerTeams, setDeveloperTeams] = useState([
    {
      imageUrl: Handson,
      name: 'Handson Panggabean',
      position: 'Full-stack Software Engineer'
    },
    {
      imageUrl: Abil,
      name: 'Abil Fathir Majid',
      position: 'Full-stack Software Engineer'
    },
    {
      imageUrl: Arif,
      name: 'Arif Rahman',
      position: 'Back-end Software Engineer'
    },
    {
      imageUrl: Nabila,
      name: 'Nabila Charisma Azelia',
      position: 'Creative Designer'
    },
    {
      imageUrl: Benedictus,
      name: 'Benedictus Billy',
      position: 'Communication Specialist'
    },
    {
      imageUrl: Emilianus,
      name: 'Emilianus Irawanto',
      position: 'Finance, HR, Legal Manager'
    }
  ])

  return (
    <MainContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
      <OurFounders />
      <VisibilitySensor onChange={(isVisible) => setContent1Visible(isVisible)} active={!isContent1Visible} partialVisibility={true}>
        <Animated animationIn="fadeIn" animationInDuration={2000} isVisible={isContent1Visible}>
          <SectionHeading
            title='Our Team'
            subtitle=''
          />
        </Animated>
      </VisibilitySensor>
      <ContentContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
        <VisibilitySensor onChange={(isVisible) => setContent2Visible(isVisible)} active={!isContent2Visible} partialVisibility={true}>
          <Animated animationIn="zoomIn" animationInDuration={2000} isVisible={isContent2Visible}>
            <Row style={{ display: "flex", justifyContent: "center" }}>
              {
                developerTeams.map((team, index) => {
                  return (
                    <Col sm={6} md={6} lg={4} key={index} style={{ marginInline: smallScreen ? '' : mediumScreen ? '' : '0px' }}>
                      <DeveloperTeamContainer style={{ backgroundColor: "transparent" }} smallScreen={smallScreen} mediumScreen={mediumScreen}>
                        <div>
                          <ImageContainer smallScreen={smallScreen} mediumScreen={mediumScreen} style={{ marginTop: "0px" }}>
                            <Image src={team.imageUrl} smallScreen={smallScreen} mediumScreen={mediumScreen} />
                          </ImageContainer>
                          <Name smallScreen={smallScreen} mediumScreen={mediumScreen}>
                            {team.name}
                          </Name>
                          <Position smallScreen={smallScreen} mediumScreen={mediumScreen}>
                            {team.position}
                          </Position>
                        </div>
                      </DeveloperTeamContainer>
                    </Col>
                  )
                })
              }
            </Row>
          </Animated>
        </VisibilitySensor>
      </ContentContainer>
    </MainContainer>
  )

}

export default OurTeam

const MainContainer = styled.div`
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '0 0 30px 0' : mediumScreen ? '30px 0 30px 0' : '0 0 50px 0'}
`
const ContentContainer = styled.div`
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '0 10vw 20px 10vw' : mediumScreen ? '0 10vw 20px 10vw' : '0 12vw 50px 12vw'}
`
const DeveloperTeamContainer = styled.div`
  width: 100%;
  height: ${({ smallScreen, mediumScreen }) => smallScreen ? '200px' : mediumScreen ? '300px' : '500px'};
  background-color: #336AFE;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${({ smallScreen, mediumScreen }) => smallScreen ? '10px 0' : mediumScreen ? '20px 0' : '0'}
`
const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '10px' : mediumScreen ? '15px' : '20px'};
  margin-top: 30px
`
const Image = styled.img`
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? '150px' : mediumScreen ? '225px' : '300px'};
  height: ${({ smallScreen, mediumScreen }) => smallScreen ? '150px' : mediumScreen ? '225px' : '300px'};
  object-fit: cover;
  margin-top: ${({ smallScreen, mediumScreen }) => smallScreen ? '10px' : mediumScreen ? '10px' : '30px'}
`
const Name = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '16px' : mediumScreen ? fontSize.heading2Sm : fontSize.heading1Sm};
  font-weight: 700;
  text-align: center;
  color: #336AFE;
`
const Position = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '16px' : mediumScreen ? fontSize.heading2Sm : fontSize.heading1Sm};
  text-align: center;
  color: #336AFE;
`