import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { Container } from 'react-bootstrap'
import { Animated } from "react-animated-css";
import VisibilitySensor from 'react-visibility-sensor'

import HeadMeta from '../components/HeadMeta'
import SectionHeading from '../components/headings/SectionHeading'
import Loader from '../components/loader/LoadingSpinner'
import BlogCard from '../components/BlogCards'

import CloudxierLogoSquare from '../assets/images/logo/Cloudxier-logofull-colored-05.png'
import { device } from '../helpers/device'

import { getBlogs } from '../redux/actions/blog_action'

function Blog() {
  const history = useHistory()
  const dispatch = useDispatch()
  const smallScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 1279px)' })
  const { blogs, totalBlog, loading } = useSelector((state) => state.blogs);
  const [limit, setLimit] = useState(6)

  const [isContent1Visible, setContent1Visible] = useState(false)
  const [isContent2Visible, setContent2Visible] = useState(false)
  const [isContent3Visible, setContent3Visible] = useState(false)

  useEffect(() => {
    dispatch(getBlogs(limit))
  }, [limit])

  // useEffect(() => {
  //   window.scrollTo({
  //     top: 400,
  //     left: 0,
  //     behavior: 'smooth'
  //   })
  // }, [])

  const handleShowBlogDetail = (id) => {
    history.push(`/blog/details/${id}`)
  }

  return (
    <MainContainer>
      <HeadMeta
        title='Cloudxier - Blog'
        description="Cloudxier blog posts"
        siteName="Cloudxier"
        url='https://www.cloudxier.com/blog/'
        image={CloudxierLogoSquare}
      />
      <Container style={{ padding: smallScreen ? '0 30px' : mediumScreen ? '0 50px' : '0 0 50px 0' }}>
        {totalBlog && totalBlog > 0 ? (
          <>
            <VisibilitySensor onChange={(isVisible) => setContent1Visible(isVisible)} active={!isContent1Visible} partialVisibility={true}>
              <Animated animationIn="fadeInDown" animationInDuration={2000} isVisible={isContent1Visible}>
                <SectionHeading
                  title='Blog'
                  subtitle=''
                />
              </Animated>
            </VisibilitySensor>
            {loading && limit <= 6 ? (
              <Loader show={loading} />
            ) : (
              <VisibilitySensor onChange={(isVisible) => setContent2Visible(isVisible)} active={!isContent2Visible} partialVisibility={true}>
                <Animated animationIn="bounceInUp" animationInDuration={2000} isVisible={isContent2Visible}>
                  <BlogCard
                    blogList={blogs}
                    handleShowBlogDetail={handleShowBlogDetail}
                  />
                </Animated>
              </VisibilitySensor>
            )}
          </>
        ) : (
          <VisibilitySensor onChange={(isVisible) => setContent1Visible(isVisible)} active={!isContent1Visible} partialVisibility={true}>
            <Animated animationIn="fadeInDown" animationInDuration={2000} isVisible={isContent1Visible}>
              <SectionHeading
                title='Blog'
                subtitle='No Available Posts'
              />
            </Animated>
          </VisibilitySensor>
        )}
        <LoadContainer>
          {loading && limit > 6 && (
            <Loader show={loading} />
          )}
          {totalBlog > limit && !loading && (
            <VisibilitySensor onChange={(isVisible) => setContent3Visible(isVisible)} active={!isContent3Visible} partialVisibility={true}>
              <Animated animationIn="fadeInUp" animationInDuration={2000} isVisible={isContent3Visible}>
                <ButtonLoad onClick={() => setLimit(limit + 6)}>
                  Load More
                </ButtonLoad>
              </Animated>
            </VisibilitySensor>
          )}
        </LoadContainer>
      </Container>
    </MainContainer>
  )
}

export default Blog

const MainContainer = styled.div`
  overflow: hidden;
  padding-top: 80px;
  @media ${device.tablet} {
    padding-top: 80px;
  }
  @media ${device.laptop} {
    padding-top: 100px;
  }
  @media ${device.laptopM} {
    padding-top: 150px;
  }
  @media ${device.laptopL} {
    padding-top: 150px;
  }
`
const LoadContainer = styled.div`
  text-align: center;
  margin-bottom: 30px;
  margin-top: 20px;
  @media ${device.tablet} {
    margin-bottom: 30px;
    margin-top: 30px;
  }
  @media ${device.laptop} {
    margin-bottom: 30px;
    margin-top: 30px;
  }
  @media ${device.laptopM} {
    margin-bottom: 50px;
    margin-top: 30px;
  }
  @media ${device.laptopL} {
    margin-bottom: 50px;
    margin-top: 30px;
  }
`
const ButtonLoad = styled.button`
  width: 200px;
  border: none;
  background-color: #4C39FF;
  color: white;
  height: min-content;
  padding: 8px;
  border-radius: 10px;
  @media ${device.tablet} {
    width: 200px;
  }
  @media ${device.laptop} {
    width: 250px;
  }
  @media ${device.laptopM} {
    width: 250px;
  }
  @media ${device.laptopL} {
    width: 300px;
  }
`