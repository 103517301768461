import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import { useMediaQuery } from 'react-responsive'
import { Animated } from "react-animated-css";
import VisibilitySensor from 'react-visibility-sensor';

import HeadMeta from '../components/HeadMeta'
import SectionHeading from '../components/headings/SectionHeading'

import CloudxierLogoSquare from '../assets/images/logo/Cloudxier-logofull-colored-05.png'

import fontSize from '../helpers/fontSize'
import { device } from '../helpers/device'

function TPPrivacyPolicy() {
  const smallScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 1023px)' })

  const [isContent1Visible, setContent1Visible] = useState(false)
  const [isContent2Visible, setContent2Visible] = useState(false)
  const [isContent3Visible, setContent3Visible] = useState(false)
  const [isContent4Visible, setContent4Visible] = useState(false)
  const [isContent5Visible, setContent5Visible] = useState(false)
  const [isContent6Visible, setContent6Visible] = useState(false)
  const [isContent7Visible, setContent7Visible] = useState(false)

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])

  return (
    <MainContainer>
      <HeadMeta
        title='Cloudxier - Technical Provider Privacy Policy'
        description="Cloudxier technical provider privacy policy"
        siteName="Cloudxier"
        url='https://www.cloudxier.com/tp-privacy-policy/'
        image={CloudxierLogoSquare}
      />
      <Container style={{ padding: smallScreen ? '0 5vw 30px 5vw' : mediumScreen ? '0 5vw 50px 5vw' : '0 5vw 50px 5vw' }}>
        <VisibilitySensor onChange={(isVisible) => setContent1Visible(isVisible)} active={!isContent1Visible} partialVisibility={true}>
          <Animated animationIn="fadeInDown" animationInDuration={2000} isVisible={isContent1Visible}>
            <SectionHeading
              title='Technical Provider Privacy Policy'
              subtitle=''
            />
            <BodyText>
              Cloudxier - PT Cendekia Edukasi Citra Tritunggal values privacy and is committed to protecting your personal information.
            </BodyText>
            <BodyText>
              Welcome to our Privacy Policy page. This policy outlines how we collect, use, and safeguard your information when you use our services as a technical provider for WhatsApp Business. We understand the importance of privacy and are committed to protecting your personal data. By using our services, you agree to the collection and use of information as described in this Privacy Policy.
            </BodyText>
          </Animated>
        </VisibilitySensor>
        <VisibilitySensor onChange={(isVisible) => setContent2Visible(isVisible)} active={!isContent2Visible} partialVisibility={true}>
          <Animated animationIn="fadeInLeft" animationInDuration={2000} isVisible={isContent2Visible}>
            <Heading>
              Information We Collect
            </Heading>
            <BodyText>
              When you use our technical services for WhatsApp Business, we may collect the following types of information:
              <ul>
                <li><b>Personal Information:</b> This may include your name, email address, phone number, and other contact details provided to us.</li>
                <li><b>Device Information:</b> We may collect information about the devices you use to access WhatsApp Business, including device model, operating system, unique device identifiers, and IP address.</li>
                <li><b>Usage Information:</b> We may collect information about how you interact with WhatsApp Business, such as the features you use, the time and duration of your activities, and other usage statistics.</li>
                <li><b>Communications:</b> We may collect information exchanged between you and WhatsApp Business, such as messages, calls, and other communications.</li>
              </ul>
            </BodyText>
          </Animated>
        </VisibilitySensor>
        <VisibilitySensor onChange={(isVisible) => setContent3Visible(isVisible)} active={!isContent3Visible} partialVisibility={true}>
          <Animated animationIn="fadeInRight" animationInDuration={2000} isVisible={isContent3Visible}>
            <Heading>
              How We Use Your Information
            </Heading>
            <BodyText>
              We use the information we collect for the following purposes:
              <ul>
                <li><b>Providing Services:</b> We use your information to provide technical services for WhatsApp Business, including troubleshooting, maintenance, and customer support.</li>
                <li><b>Improving Services:</b> We analyze usage data to improve the functionality, performance, and user experience of WhatsApp Business.</li>
                <li><b>Communications:</b> We may use your contact information to communicate with you about our services, updates, and important notices.</li>
                <li><b>Legal Compliance:</b> We may use your information to comply with legal obligations, such as responding to legal requests and enforcing our terms and policies.</li>
              </ul>
            </BodyText>
          </Animated>
        </VisibilitySensor>
        <VisibilitySensor onChange={(isVisible) => setContent4Visible(isVisible)} active={!isContent4Visible} partialVisibility={true}>
          <Animated animationIn="fadeInLeft" animationInDuration={2000} isVisible={isContent4Visible}>
            <Heading>
              Information Sharing
            </Heading>
            <BodyText>
              We do not sell, trade, or rent your personal information to third parties. However, we may share your information in the following circumstances:
              <ul>
                <li><b>Service Providers:</b> We may share your information with trusted third-party service providers who assist us in delivering our services, such as hosting providers, analytics platforms, and communication tools.</li>
                <li><b>Legal Compliance:</b> We may disclose your information when required by law or to protect our rights, property, or safety, or that of others.</li>
              </ul>
            </BodyText>
          </Animated>
        </VisibilitySensor>
        <VisibilitySensor onChange={(isVisible) => setContent5Visible(isVisible)} active={!isContent5Visible} partialVisibility={true}>
          <Animated animationIn="fadeInRight" animationInDuration={2000} isVisible={isContent5Visible}>
            <Heading>
              Data Security
            </Heading>
            <BodyText>
              We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure.
            </BodyText>
          </Animated>
        </VisibilitySensor>
        <VisibilitySensor onChange={(isVisible) => setContent6Visible(isVisible)} active={!isContent6Visible} partialVisibility={true}>
          <Animated animationIn="fadeInLeft" animationInDuration={2000} isVisible={isContent6Visible}>
            <Heading>
              Updates To This Privacy Policy
            </Heading>
            <BodyText>
              We may update this Privacy Policy from time to time to reflect changes in our services or legal requirements. We will notify you of any significant changes by posting the updated policy on our website or through other appropriate channels.
            </BodyText>
          </Animated>
        </VisibilitySensor>
        <VisibilitySensor onChange={(isVisible) => setContent7Visible(isVisible)} active={!isContent7Visible} partialVisibility={true}>
          <Animated animationIn="fadeInRight" animationInDuration={2000} isVisible={isContent7Visible}>
            <Heading>
              How To Contact Us
            </Heading>
            <BodyText>
              If you have any questions regarding this Privacy Statement or you would like to obtain access to your Personal Information, please contact sayhello@cloudxier.com
            </BodyText>
            <BodyText>
              This privacy statement was last updated on 30 April 2024
            </BodyText>
          </Animated>
        </VisibilitySensor>
      </Container>
    </MainContainer>
  )
}

export default TPPrivacyPolicy;

const MainContainer = styled.div`
  overflow: hidden;
  padding-top: 80px;
  @media ${device.tablet} {
    padding-top: 90px;
  }
  @media ${device.laptop} {
    padding-top: 90px;
  } 
  @media ${device.laptopM} {
    padding-top: 150px;
  } 
  @media ${device.laptopL} {
    padding-top: 150px;
  }
`
const Heading = styled.div`
  font-size: ${fontSize.bodyLg};
  color: black;
  font-weight: 700;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 12px;
  @media ${device.tablet} {
    font-size: ${fontSize.heading1Sm};
    margin-top: 48px;
    margin-bottom: 24px;
  }
  @media ${device.laptop} {
    font-size: ${fontSize.heading3Lg};
    margin-top: 48px;
    margin-bottom: 24px;
  }
  @media ${device.laptopM} {
    font-size: ${fontSize.heading3Lg};
    margin-top: 48px;
    margin-bottom: 24px;
  }
  @media ${device.laptopL} {
    font-size: ${fontSize.heading2Lg};
    margin-top: 48px;
    margin-bottom: 24px;
  }
`
const SubHeading = styled.p`
  font-size: ${fontSize.heading3Sm};
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 8px;
  @media ${device.tablet} {
    font-size: ${fontSize.bodyLg};
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptop} {
    font-size: 23px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopM} {
    font-size: 23px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopL} {
    font-size: 26px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
`
const BodyText = styled.p`
  text-align:justify;
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 8px;
  @media ${device.tablet} {
    font-size: 18px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptop} {
    font-size: ${fontSize.bodyLg};
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopM} {
    font-size: ${fontSize.bodyLg};
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopL} {
    font-size: 22px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
`