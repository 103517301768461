import React from 'react'
import { withRouter } from 'react-router-dom';
import styled from 'styled-components'
import { device } from '../helpers/device'

const BlogCard = ({
  blogList,
  handleShowBlogDetail,
  fromLandingPage
}) => {
  return (
    <BlogPosts fromLandingPage={fromLandingPage}>
      {blogList && Array.isArray(blogList) && blogList.length > 0 && blogList.map((post, i) => (
        <BlogPostItem key={i}>
          <BlogPostImage src={post.img_cover} alt="post_image" onClick={() => handleShowBlogDetail(post.id)} />
          <BlogPostTitleContainer>
            <BlogPostTitle onClick={() => handleShowBlogDetail(post.id)}>
              {post.title}
            </BlogPostTitle>
          </BlogPostTitleContainer>
          <BlogPostDescriptionContainer onClick={() => handleShowBlogDetail(post.id)}>
            <BlogPostDescriptionText>
              {post.summary}
            </BlogPostDescriptionText>
          </BlogPostDescriptionContainer>
          <BlogPostDateContainer>
            <BlogPostDate>
              {post["Posted On"]}
            </BlogPostDate>
            <BlogPostReadMoreContainer onClick={() => handleShowBlogDetail(post.id)}>
              <BlogPostReadMoreText className='ms-2 mr-2'>Read More</BlogPostReadMoreText>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
              </svg>
            </BlogPostReadMoreContainer>
          </BlogPostDateContainer>
        </BlogPostItem>
      ))}
    </BlogPosts>
  )
};
export default withRouter(BlogCard)

const BlogPosts = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-gap: 20px;
  @media ${device.tablet} {
    grid-template-columns: repeat(${({ fromLandingPage }) => fromLandingPage ? '3' : '2'}, 1fr);
    grid-gap: 30px;
  }
  @media ${device.laptop} {
    grid-template-columns: repeat(${({ fromLandingPage }) => fromLandingPage ? '3' : '2'}, 1fr);
    grid-gap: 40px;
  }
  @media ${device.laptopM} {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 50px;
  }
  @media ${device.laptopL} {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 50px;
  }
`
const BlogPostItem = styled.div`
  color: black;
  :hover {
    -ms-transform: scale(1.05); /* IE 9 */
    -webkit-transform: scale(1.05); /* Safari 3-8 */
    transform: scale(1.05); 
  }
`

const BlogPostImage = styled.img`
  aspect-ratio: 16/9;
  width: 100%;
  height: auto;
  object-fit: contain;
  margin-bottom: 20px;
  :hover {
    cursor: pointer;
  }
  @media ${device.tablet} {
    margin-bottom: 22px;
  }
  @media ${device.laptop} {
    margin-bottom: 22px;
  }
  @media ${device.laptopM} {
    margin-bottom: 24px;
  }
  @media ${device.laptopL} {
    margin-bottom: 24px;
  }
`

const BlogPostTitleContainer = styled.div`
  display: grid;
  align-items: center;
  justify-items: left;
`

const BlogPostTitle = styled.span`
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 10px;
  font-weight: 700;
  color: #4C39FF;
  :hover {
    cursor: pointer;
  }
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  @media ${device.tablet} {
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 16px;
  }
  @media ${device.laptop} {
    font-size: 24px;
    line-height: 26px;
    margin-bottom: 16px;
  }
  @media ${device.laptopM} {
    font-size: 24px;
    line-height: 26px;
    margin-bottom: 16px;
  }
  @media ${device.laptopL} {
    font-size: 26px;
    line-height: 28px;
    margin-bottom: 16px;
  }
`
const BlogPostReadMoreContainer = styled.div`
  margin-left: 5px;
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 6px;
  font-weight: 400;
  color: black;
  :hover {
    cursor: pointer;
  }
  @media ${device.tablet} {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 6px;
  }
  @media ${device.laptop} {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
  }
  @media ${device.laptopM} {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
  }
  @media ${device.laptopL} {
    font-size: 17px;
    line-height: 28px;
    margin-bottom: 15px;
  }
`
const BlogPostReadMoreText = styled.span`
  font-weight: 700;
`
const BlogPostDescriptionContainer = styled.div`
  display: grid;
  align-items: center;
  justify-items: left;
  :hover {
    cursor: pointer;
  }
`
const BlogPostDescriptionText = styled.div`
  width:100%;
  font-size: 16px;
  margin-bottom: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  text-align: justify;
  @media ${device.tablet} {
    font-size: 17px;
    margin-bottom: 15px;
  }
  @media ${device.laptop} {
    font-size: 18px;
    margin-bottom: 15px;
  }
  @media ${device.laptopM} {
    font-size: 18px;
    margin-bottom: 12px;
  }
  media ${device.laptopL} {
    font-size: 19px;
    margin-bottom: 12px;
  }
`
const BlogPostDateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
`
const BlogPostDate = styled.p`
  font-size: 15px;
  color: #979797;
  @media ${device.tablet} {
    font-size: 15px;
  }
  @media ${device.laptop} {
    font-size: 16px;
  }
  @media ${device.laptopM} {
    font-size: 16px;
  }
  @media ${device.laptopL} {
    font-size: 17px;
  }
`