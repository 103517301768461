// React
import { useState } from 'react'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Styled components
import styled from 'styled-components'

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

// Animated
import { Animated } from "react-animated-css";

// Helpers
import fontSize from '../../helpers/fontSize'

function Address() {

  const smallScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 1279px)' })

  const [isComponentVisible, setComponentVisible] = useState(false)

  return (
    <VisibilitySensor onChange={(isVisible) => setComponentVisible(isVisible)} partialVisibility={true} active={!isComponentVisible}>
      <Animated animationIn="fadeInRight" animationInDuration={2000} isVisible={isComponentVisible}>
        <MainContainer smallScreen={smallScreen} mediumScreen={mediumScreen} isComponentVisible={isComponentVisible}>
          <Title smallScreen={smallScreen} mediumScreen={mediumScreen}>
            Our Office
          </Title>
          <Content smallScreen={smallScreen} mediumScreen={mediumScreen}>
            <b style={{ fontWeight: '600' }}>CLOUDXIER</b> at PT Cendekia Edukasi Citra Tritunggal HQ <br />
            AKR Tower 16<sup>th</sup> Floor Unit 16A <br />
            Jl Panjang No. 5 Kebon Jeruk <br />
            West Jakarta, 11530
          </Content>
          <ContactDetails smallScreen={smallScreen} mediumScreen={mediumScreen}>
            <b style={{ fontWeight: '600' }}>Email.</b> sayhello@cloudxier.com <br />
            <b style={{ fontWeight: '600' }}>Phone.</b><br />+6221-80647744<br /> +62 81385898240 <a href="http://wa.me/6281385898240">(Click to Whatsapp)</a>
          </ContactDetails>
        </MainContainer>
      </Animated>
    </VisibilitySensor>
  )

}

export default Address

const MainContainer = styled.div`
  padding-top: ${({ smallScreen, mediumScreen }) => smallScreen ? '0' : mediumScreen ? '0' : '50px'};
  opacity: ${({ isComponentVisible }) => isComponentVisible ? 1 : 0};
  transition: opacity 1s
`
const Title = styled.div`
  letter-spacing: 5px;
  text-transform: uppercase;
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '16px' : mediumScreen ? '18px' : fontSize.bodyLg};
  color: black;
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '10px' : mediumScreen ? '12px' : '15px'};
`
const Content = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '16px' : mediumScreen ? '18px' : fontSize.bodyLg};
  color: black;
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '15px' : mediumScreen ? '18px' : '20px'};
  font-weight: 300
`
const ContactDetails = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '16px' : mediumScreen ? '18px' : fontSize.bodyLg};
  color: black;
  font-weight: 300
`