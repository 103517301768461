// React
import { useState, useEffect } from 'react'

// Bootstrap
import { Col, Container, Row } from 'react-bootstrap'

// Animated
import { Animated } from "react-animated-css";

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

// Components
import HeadMeta from '../../components/HeadMeta'
import DiscussForm from '../../components/DiscussForm'

// Styled components
import styled from 'styled-components'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Helpers
import fontSize from '../../helpers/fontSize'
import { device } from '../../helpers/device'

function CustomSoftwareDevelopment() {
  const smallScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 1279px)' })
  const largeScreen = useMediaQuery({ query: '(max-width: 1499px)' })

  const [isContent1Visible, setContent1Visible] = useState(false)
  const [isContent2Visible, setContent2Visible] = useState(false)
  const [isContent3Visible, setContent3Visible] = useState(false)
  const [isContent4Visible, setContent4Visible] = useState(false)
  const [isContent5Visible, setContent5Visible] = useState(false)
  const [isContent6Visible, setContent6Visible] = useState(false)
  const [isContent7Visible, setContent7Visible] = useState(false)

  const [upperContent, setUpperContent] = useState([
    (
      <>
        <BodyText style={{ fontSize: smallScreen ? '21px' : mediumScreen ? '23px' : '25px' }}>
          We start with a comprehensive discovery and analysis phase to deeply understand your objectives, challenges, and requirements.
        </BodyText>
        <BodyText style={{ fontSize: smallScreen ? '21px' : mediumScreen ? '23px' : '25px' }}>
          This is followed by meticulous planning and design, where we create detailed project plans, wireframes, and user-centric designs. During development, we employ agile methodologies, allowing for flexibility and iterative progress, while maintaining high-quality standards through regular code reviews and testing.
        </BodyText>
      </>
    ),
    (
      <BodyText style={{ fontSize: smallScreen ? '23px' : mediumScreen ? '24px' : '25px' }}>
        Our rigorous testing and quality assurance processes ensure that the software performs flawlessly and meets all security and compliance standards. Upon deployment,  we ensure seamless integration with your existing systems and provide thorough documentation and training. Our commitment to ongoing support and maintenance ensures that your software remains up-to-date and continues to deliver value as your business evolves.
      </BodyText>
    ),
  ])

  const [whatWeDoContent, setWhatWeDoContent] = useState([
    (
      <>
        <SubHeading>
          Providing a tailor-made software designed to fit your business needs and goals
        </SubHeading>
        <BodyText style={{ textAlign: 'left' }}>
          Every business operates differently and has specific requirements, that's why we take a personalized approach, thoroughly understanding the business process, challenges, and objectives to develop a software that aligns perfectly with your vision.
        </BodyText>
        <BodyText style={{ textAlign: 'left' }}>
          From the initial consultation to the final deployment, our team works closely with you to ensure that every feature and functionality is crafted to enhance your business processes, improve efficiency, and drive growth.
        </BodyText>
      </>
    ),
    (
      <>
        <SubHeading>
          Deliver solutions that enhance efficiency: simplifying processes and automation to boost productivity
        </SubHeading>
        <BodyText style={{ textAlign: 'left' }}>
          Before the design and planning phase, we look thoroughly and analyze your existing workflows and identify the tasks/flows that can be optimized. Our team then crafts bespoke software solutions tailored to meet your business goals by reducing manual intervention and minimizing errors.
        </BodyText>
        <BodyText style={{ textAlign: 'left' }}>
          By implementing automation on the process and integrating advanced features, we help you to focus on higher-value activities, accelerating your operational pace and improving overall productivity.
        </BodyText>
      </>
    ),
    (
      <>
        <SubHeading>
          Providing integration services with existing systems or any third party applications
        </SubHeading>
        <BodyText style={{ textAlign: 'left' }}>
          Our team specializes in designing and implementing custom solutions that bridge gaps between the system environment in your business.
        </BodyText>
        <BodyText style={{ textAlign: 'left' }}>
          Whether you need to integrate with CRM, ERP, payment gateways, or other specialized software, we create robust interfaces and APIs that facilitate efficient and secure connections. By leveraging our integration services, you can enhance your operational efficiency and reduce manual data entry.
        </BodyText>
      </>
    ),
    (
      <>
        <SubHeading>
          Well-architectured solution supported by interfaces that enhance user satisfaction
        </SubHeading>
        <BodyText style={{ textAlign: 'left' }}>
          By prioritizing a good user experience on a software, we ensure the users can easily navigate and utilize the software to its full potential.
        </BodyText>
        <BodyText style={{ textAlign: 'left' }}>
          Our meticulous attention to software architecture design and planning guarantees that the software integrates smoothly with your existing systems and can adapt to future needs. With our custom solutions, you benefit from a blend of technical excellence and superior usability, driving efficiency and satisfaction for your users.
        </BodyText>
      </>
    ),
    (
      <>
        <SubHeading>
          Building a robust, scalable and accessible cloud-based solutions
        </SubHeading>
        <BodyText style={{ textAlign: 'left' }}>
          Our software are built to ensure high availability and performance, providing easy access from anywhere in the world.
        </BodyText>
        <BodyText style={{ textAlign: 'left' }}>
          By utilizing cloud-native architectures, we enhance scalability, allowing your applications to handle increasing loads efficiently. Additionally, our focus on security and compliance ensures your data is protected, giving you peace of mind. With our expertise in cloud technologies, we create solutions that empower your business to thrive and grow.
        </BodyText>
      </>
    ),
  ])

  // Scroll window to top on load
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])

  const [animateBannerTitle, setAnimateBannerTitle] = useState(false)

  // Start banner title animation on page load
  useEffect(() => {
    setAnimateBannerTitle(true)
  }, [])

  return (
    <div style={{ overflow: 'hidden' }}>
      <HeadMeta
        title="Cloudxier - Custom Software Development"
        description="Our mission is to help accelerating business in their digital innovation"
        siteName="Cloudxier"
        url="https://www.cloudxier.com/about/"
      />
      <MainBannerContainer >
        <MainBannerTextContainer animateBannerTitle={animateBannerTitle}>
          <MainBannerTextFlexContainer>
            <Container style={{ padding: smallScreen ? '0 10vw' : mediumScreen ? '0 10vw' : 0 }}>
              <MainBannerTitle smallScreen={smallScreen} mediumScreen={mediumScreen}>
                Custom Software Development
              </MainBannerTitle>
              <MainBannerSubTitle smallScreen={smallScreen} mediumScreen={mediumScreen}>
                Providing custom software development services together with technical architecture consultation that suits your business goals. <br /> <br />
                From concept to deployment and maintenance, we ensure a seamless development process. Whether it's front-end interfaces, back-end logic, or database structure and integration, we've got it covered.
              </MainBannerSubTitle>
            </Container>
          </MainBannerTextFlexContainer>
        </MainBannerTextContainer>
        <BannerOverlay />
        <HomeBannerImage src='https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/bc2f310eca054ad8501716d9cee28cb087a23feb863f29650c9c695055b706f1c169545944cc2b8b4446a2070f9d89ed89d5fceec350ad1728ca8a697925eb48d49ea0112352b3ca0bba9f80e3a8b33044d770860051fa56cf649770b0e1f6bb19b22facee717bf7b9c3599aef6890e34dc4dc7059ddcd3d1d85/rez-file-1719307598791.png' alt='main-banner-1' />
      </MainBannerContainer>
      <MainContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
        <Container style={{ padding: smallScreen ? '0 10vw' : mediumScreen ? '0 5vw' : '0 0 50px 0' }}>
          <VisibilitySensor onChange={(isVisible) => setContent1Visible(isVisible)} active={!isContent1Visible} partialVisibility={true}>
            <Animated animationIn="fadeInUp" animationInDuration={2000} isVisible={isContent1Visible}>
              <SubHeading style={{ fontSize: smallScreen ? '30px' : mediumScreen ? '35px' : '40px', margin: smallScreen ? '25px 0' : mediumScreen ? '25px 0' : '25px 50px' }}>
                Delivering solutions that precisely meet your unique business needs
              </SubHeading>
              <hr style={{ height: "2px", border: "none", color: "#333", backgroundColor: "#333" }} />
              <Row style={{ margin: smallScreen ? '25px 0' : mediumScreen ? '25px 0' : '50px 50px' }}>
                {
                  upperContent.map((item, i) => {
                    return (
                      <Col key={i} sm={12} md={6} lg={6} style={{ padding: smallScreen ? '0' : mediumScreen ? '0px 30px' : "0px 50px" }}>
                        {item}
                      </Col>
                    )
                  })
                }
              </Row>
            </Animated>
          </VisibilitySensor>
          <VisibilitySensor onChange={(isVisible) => setContent2Visible(isVisible)} active={!isContent2Visible} partialVisibility={true}>
            <Animated animationIn="zoomIn" animationInDuration={2000} isVisible={isContent2Visible}>
              <HomeBannerImage src={'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/d55e3f63668bda7c5b26347adfd0bb5e2b18a252610a5fd16f298581438f3165ac186cf6e448903469ffec434bf34f2a5d4db0aa60bddaf88624b9472684abe8c5758781fbb980d2bd54ec8662efdf1a863a0e3ca41432f22afe5350c8dc58c7a98e9ff2d483c6c5ead32081ecc6200b44a981f2bc493b4f2da7/rez-file-1719544714212.png'} alt='main-banner-2' />
            </Animated>
          </VisibilitySensor>
          <VisibilitySensor onChange={(isVisible) => setContent3Visible(isVisible)} active={!isContent3Visible} partialVisibility={true}>
            <Animated animationIn="fadeInRight" animationInDuration={2000} isVisible={isContent3Visible}>
              <Heading>
                Our Approach
              </Heading>
              <SubHeading>
                Discovery & Analysis:
              </SubHeading>
              <BodyText>
                <ul>
                  <li>Conduct a thorough analysis of your business needs and objectives.</li>
                  <li>Collaborate with your team to understand your vision, challenges, and specific requirements.</li>
                  <li>Define the project scope, timeline, and key deliverables.
                  </li>
                  <li>Identify challenges and opportunities for technological improvements.</li>
                </ul>
              </BodyText>
              <SubHeading>
                Planning & Design:
              </SubHeading>
              <BodyText>
                <ul>
                  <li>Develop a detailed project plan, outlining each phase of development.</li>
                  <li>Create wireframes and prototypes to visualize the solution.</li>
                  <li>Design user-friendly interfaces focused on delivering an exceptional user experience.
                  </li>
                </ul>
              </BodyText>
              <SubHeading>
                Development:
              </SubHeading>
              <BodyText>
                <ul>
                  <li>Use agile methodologies to ensure flexibility and responsiveness to changes.</li>
                  <li>Implement robust coding practices and maintain high-quality standards.</li>
                  <li>Conduct regular code reviews and testing to ensure functionality and performance.
                  </li>
                </ul>
              </BodyText>
              <SubHeading>
                Testing & Quality Assurance:
              </SubHeading>
              <BodyText>
                <ul>
                  <li>Perform comprehensive testing, including unit tests, integration tests, and user acceptance tests.</li>
                  <li>Identify and resolve any bugs or issues to ensure a seamless user experience.</li>
                  <li>Ensure the software meets all performance, security, and compliance requirements.
                  </li>
                </ul>
              </BodyText>
              <SubHeading>
                Deployment & Integration:
              </SubHeading>
              <BodyText>
                <ul>
                  <li>Deploy the software to your preferred environment, whether on-premises or cloud-based.</li>
                  <li>Ensure seamless integration with existing systems and workflows.</li>
                  <li>Provide thorough documentation and training to ensure your team can effectively use the new software.
                  </li>
                  <li>Ensure data consistency and interoperability across different platforms.</li>
                  <li>Provide API development and integration services for enhanced connectivity.</li>
                </ul>
              </BodyText>
              <SubHeading>
                Support & Maintenance:
              </SubHeading>
              <BodyText>
                <ul>
                  <li>Offer ongoing support and maintenance services to keep your software up-to-date.</li>
                  <li>Provide regular updates, enhancements, and technical support.</li>
                  <li>Monitor performance and address any issues promptly to ensure optimal functionality.
                  </li>
                </ul>
              </BodyText>
            </Animated>
          </VisibilitySensor>
          <Container style={{ margin: smallScreen ? '25px 0' : mediumScreen ? '25px 0' : '0' }}>
            <VisibilitySensor onChange={(isVisible) => setContent4Visible(isVisible)} active={!isContent4Visible} partialVisibility={true}>
              <Animated animationIn="slideInUp" animationInDuration={2000} isVisible={isContent4Visible}>
                <Heading>
                  What We Do
                </Heading>
                <Row style={{ margin: smallScreen ? '0' : mediumScreen ? '0' : '0' }}>
                  {
                    whatWeDoContent.map((item, i) => {
                      return (
                        <Col key={i} sm={12} md={6} lg={6} style={{ padding: smallScreen ? '0' : mediumScreen ? '0px 50px 0 0' : "0px 60px 0 0" }}>
                          {item}
                        </Col>
                      )
                    })
                  }
                </Row>
              </Animated>
            </VisibilitySensor>
          </Container>
        </Container>
        <VisibilitySensor onChange={(isVisible) => setContent5Visible(isVisible)} active={!isContent5Visible} partialVisibility={true}>
          <Animated animationIn="slideInLeft" animationInDuration={2000} isVisible={isContent5Visible}>
            <Post smallScreen={smallScreen} mediumScreen={mediumScreen}>
              <Description smallScreen={smallScreen} mediumScreen={mediumScreen} largeScreen={largeScreen}>
                <Title smallScreen={smallScreen} mediumScreen={mediumScreen} >
                  Type of Custom Software We Offer
                </Title>
              </Description>
              <ImageContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
                <Image src={'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/48488e34f095101262daa7b9d3ce36a7702ec4da6a6056cfb8f1bf0585fad97f33fb56467218a94d516983460f792b409a3c47f05bb965fb4620314072e60f21b4a2927457814eec9474e5c4a5d914306946d6a0639a0b9049627913fed32350f829fa390e31500c27578b0bf7751931645f427a33f888347890/rez-file-1719544763022.jpg'} alt='banner-images-3' smallScreen={smallScreen} mediumScreen={mediumScreen} />
              </ImageContainer>
            </Post>
          </Animated>
        </VisibilitySensor>
        <Container>
          <VisibilitySensor onChange={(isVisible) => setContent6Visible(isVisible)} active={!isContent6Visible} partialVisibility={true}>
            <Animated animationIn="slideInUp" animationInDuration={2000} isVisible={isContent6Visible}>
              <Row style={{ margin: smallScreen ? '25px 0' : mediumScreen ? '25px 0' : '50px 50px' }}>
                <Col sm={12} md={6} lg={6} style={{ padding: smallScreen ? '0' : mediumScreen ? '0 30px' : "0px 50px" }}>
                  <img src={'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/e3705cc16474102e47729b99a3b4db219ef0eaefc061c07618671cc8d60d8252c8cf5c894172013f5d3eab0844e236b7610952faba92a2911961080ce083cb340608be0a28969ea4c5c12698c33018194febd59e0812eb441d92fd245a861bee201b79366a9df5a13a1dbb2c67f20f07c3cf3dfdf96c17de9943/rez-file-1719544828426.png'} style={{ width: smallScreen ? '150px' : mediumScreen ? '200px' : '250px', height: smallScreen ? '150px' : mediumScreen ? '200px' : '250px' }} alt='image-1' />
                  <SubHeading style={{ color: "#4C39FF" }}>
                    Web Applications
                  </SubHeading>
                  <BodyText>
                    <ul>
                      <li>E-Commerce Platforms</li>
                      <li>Content Management Systems (CMS)</li>
                      <li>Human Resources Management (HRMS)</li>
                      <li>Customer Relationship Management Software (CRMs)
                      </li>
                      <li>SaaS Applications</li>
                    </ul>
                  </BodyText>
                </Col>
                <Col sm={12} md={6} lg={6} style={{ padding: smallScreen ? '0' : mediumScreen ? '0 30px' : "0px 50px" }}>
                  <img src={'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/bda6a58cda0c1f9be5606ef2e97e9348f5ebc2b6e5b0f6f61fb5abfb5f96a3c5d8c02ce4bb74e5d082de6228f623009283c72e889d5ecaf76794830213eaf6d6af4a72ab8e4e5d47a08634c684d97dec9ec5c34f5327d288c2cc6f0c3da3da945e50949b490e16baad2aa1b1b1f4b73ef734b6296260aa5b23a7/rez-file-1719544876348.png'} style={{ width: smallScreen ? '150px' : mediumScreen ? '200px' : '250px', height: smallScreen ? '150px' : mediumScreen ? '200px' : '250px' }} alt='image-2' />
                  <SubHeading style={{ color: "#4C39FF" }}>
                    Other Specialized Services Software
                  </SubHeading>
                  <BodyText>
                    <ul>
                      <li>Logistics and Supply Chain Management
                      </li>
                      <li>Travel Booking Systems</li>
                      <li>Project Management Tools</li>
                      <li>Educational Platforms
                      </li>
                      <li>Healthcare Management System</li>
                    </ul>
                  </BodyText>
                </Col>
              </Row>
            </Animated>
          </VisibilitySensor>
          <br />
          <br />
          <br />
          <br />
          <br />
          <VisibilitySensor onChange={(isVisible) => setContent7Visible(isVisible)} active={!isContent7Visible} partialVisibility={true}>
            <Animated animationIn="fadeInUp" animationInDuration={2000} isVisible={isContent7Visible}>
              <Heading style={{ textAlign: 'center' }}>
                Discuss with us now.
              </Heading>
              <BodyText style={{ textAlign: 'center' }}>
                Have questions or ready to discuss your project? We're here to help! Please fill in the inquiry form below:
              </BodyText>
              <DiscussForm />
            </Animated>
          </VisibilitySensor>
        </Container>
      </MainContainer>
    </div >
  )

}

export default CustomSoftwareDevelopment

const MainContainer = styled.div`
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px 0 30px 0' : mediumScreen ? '30px 0 30px 0' : '50px 0 50px 0'}
`
const MainBannerContainer = styled.div`
  width: 100%;
  height: 80vh;
`
const MainBannerTextContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 80%;
  text-align: left;
  color: white;
  z-index: 2;
  transition: opacity 2s, margin 1s;
  opacity: ${({ animateBannerTitle }) => animateBannerTitle ? '1' : '0'};
  margin-top: ${({ animateBannerTitle }) => animateBannerTitle ? '0' : '-50vh'};
`
const MainBannerTextFlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-top: 7%;
`
const MainBannerTitle = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px' : mediumScreen ? '35px' : '40px'};
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '40px' : mediumScreen ? '30px' : '60px'};
  font-weight: 900;
`
const MainBannerSubTitle = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '17px' : mediumScreen ? '20px' : '23px'};
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '0' : mediumScreen ? '0' : '0'};
`
const BannerOverlay = styled.div`
  background-color: rgba(0,0,0,0.3);
  width: 100%;
  height: 80vh;
  position: absolute;
  z-index: 1;
`
const HomeBannerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const ImageContainer = styled.div`
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? '100%' : mediumScreen ? '100%' : '50%'};
  height: auto;
`
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover
`
const Post = styled.div`
  display: ${({ smallScreen, mediumScreen }) => smallScreen ? 'block' : mediumScreen ? 'block' : 'flex'};
  width: 100%;
  margin: 80px 0;
`
const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #3628B4;
  align-items: ${({ smallScreen, mediumScreen }) => smallScreen ? 'center' : mediumScreen ? 'center' : 'flex-start'};
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? '100%' : mediumScreen ? '100%' : '50%'};
  height: ${({ smallScreen, mediumScreen }) => smallScreen ? '300px' : mediumScreen ? '400px' : 'auto'};
  padding: ${({ smallScreen, mediumScreen, largeScreen }) => smallScreen ? '50px 10vw' : mediumScreen ? '50px 10vw' : largeScreen ? '20px 100px' : '20px 200px'};
`
const Title = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px' : mediumScreen ? '40px' : '50px'};
  color: white;
  font-weight: 900;
  text-align: ${({ smallScreen, mediumScreen }) => smallScreen ? 'center' : mediumScreen ? 'center' : 'left'};
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '0' : mediumScreen ? '0' : '0'};
  cursor: pointer;
`
const Heading = styled.div`
  font-size: ${fontSize.heading1Sm};
  color: #4C39FF;
  font-weight: 900;
  text-align: left;
  margin-top: 24px;
  margin-bottom: 12px;
  @media ${device.tablet} {
    font-size: ${fontSize.heading1Sm};
    margin-top: 48px;
    margin-bottom: 24px;
  }
  @media ${device.laptop} {
    font-size: ${fontSize.heading3Lg};
    margin-top: 48px;
    margin-bottom: 24px;
  }
  @media ${device.laptopM} {
    font-size: ${fontSize.heading2Lg};
    margin-top: 48px;
    margin-bottom: 24px;
  }
  @media ${device.laptopL} {
    font-size: 40px;
    margin-top: 48px;
    margin-bottom: 24px;
  }
`
const SubHeading = styled.p`
  font-size: ${fontSize.heading2Sm};
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 8px;
  @media ${device.tablet} {
    font-size: 20px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptop} {
    font-size: ${fontSize.heading1Sm};
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopM} {
    font-size: ${fontSize.heading3Lg};
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopL} {
    font-size: ${fontSize.heading3Lg};
    margin-top: 32px;
    margin-bottom: 16px;
  }
`
const BodyText = styled.p`
  text-align: justify;
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 8px;
  @media ${device.tablet} {
    font-size: 18px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptop} {
    font-size: ${fontSize.bodyLg};
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopM} {
    font-size: 22px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopL} {
    font-size: 22px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
`