import { useState } from 'react'

// Styled components
import styled from 'styled-components'

// React responsive
import { useMediaQuery } from 'react-responsive'

// React slick slider
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Tech Stack Logo
import ReactNativeLogo from '../../assets/images/tech_stack_logo/React-Native-Logo.png'
import ReactJsLogo from '../../assets/images/tech_stack_logo/Reactjs-Logo.png'
import PostgreSqlLogo from '../../assets/images/tech_stack_logo/Postgre-Logo.png'
import NodejsLogo from '../../assets/images/tech_stack_logo/Nodejs-Logo.png'
import MySqlLogo from '../../assets/images/tech_stack_logo/MySql-Logo.png'
import AndroidStudioLogo from '../../assets/images/tech_stack_logo/Android-Studio-Logo.png'
import FirebaseLogo from '../../assets/images/tech_stack_logo/Firebase-Logo.png'
import XcodeLogo from '../../assets/images/tech_stack_logo/Xcode-Logo.png'
import AlibabaCloudLogo from '../../assets/images/tech_stack_logo/Alibaba-Cloud-Logo.png'
import GoogleCloudLogo from '../../assets/images/tech_stack_logo/Google-Cloud-Logo.png'
import AwsLogo from '../../assets/images/tech_stack_logo/Aws-Logo.png'
import { Col, Row } from 'react-bootstrap'

function TechStackLogo() {

  const smallScreen = useMediaQuery({ query: '(max-width: 768px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 1200px)' })

  const sliderSettings = {
    infinite: true,
    slidesToShow: smallScreen ? 2 : mediumScreen ? 3 : 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 0,
    cssEase: "linear"
  }

  const [images, setImages] = useState([
    {
      title: 'React Native',
      externalLink: '',
      image: ReactNativeLogo
    },
    {
      title: 'React Js',
      externalLink: '',
      image: ReactJsLogo
    },
    {
      title: 'Postgre SQL',
      externalLink: '',
      image: PostgreSqlLogo
    },
    {
      title: 'Node Js',
      externalLink: '',
      image: NodejsLogo
    },
    {
      title: 'My SQL',
      externalLink: '',
      image: MySqlLogo
    },
    {
      title: 'Android Studio',
      externalLink: '',
      image: AndroidStudioLogo
    },
    {
      title: 'Firebase',
      externalLink: '',
      image: FirebaseLogo
    },
    {
      title: 'Google Cloud',
      externalLink: '',
      image: GoogleCloudLogo
    },
    {
      title: 'Apple Xcode',
      externalLink: '',
      image: XcodeLogo
    },
    {
      title: 'Alibaba Cloud',
      externalLink: '',
      image: AlibabaCloudLogo
    },
    {
      title: 'AWS',
      externalLink: '',
      image: AwsLogo
    },
  ])

  function renderSliderContent() {
    let firstSlider = []
    let secondSlider = []
    let partOne = images.slice(0, Math.round(images.length / 2))
    let partTwo = images.slice(partOne.length, images.length)
    firstSlider.push(
      <Slider {...sliderSettings}>
        {
          partOne.map((item, i) => {
            return (
              <ImageContainer key={i} smallScreen={smallScreen} mediumScreen={mediumScreen}>
                <Images key={i} src={item.image} alt={item.title} />
              </ImageContainer>
            )
          })
        }
      </Slider>
    )
    secondSlider.push(
      <Slider {...sliderSettings}>
        {
          partTwo.map((item, i) => {
            return (
              <ImageContainer key={i} smallScreen={smallScreen} mediumScreen={mediumScreen}>
                <Images key={i} src={item.image} alt={item.title} />
              </ImageContainer>
            )
          })
        }
      </Slider>
    )
    return (
      <div>
        {firstSlider}
        {secondSlider}
      </div>
    )
  }

  return (
    <MainContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
      {
        smallScreen || mediumScreen ?
          renderSliderContent()
          :
          images.map((item, i) => {
            return (
              <ImageContainer key={i} smallScreen={smallScreen} mediumScreen={mediumScreen}>
                <Images key={i} src={item.image} alt={item.title} />
              </ImageContainer>
            )
          })
      }
    </MainContainer>
  )
}

export default TechStackLogo

const MainContainer = styled.div`
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px 0 30px 0' : mediumScreen ? '40px 0 40px 0' : '50px 0 50px 0'};
  display: ${({ smallScreen, mediumScreen }) => smallScreen ? 'block' : mediumScreen ? 'block' : 'flex'};
  justify-content: center;
  flex-wrap: wrap;
  width: 100%
`
const ImageContainer = styled.div`
  width: 100%;
  max-width: 250px;
  height: ${({ smallScreen, mediumScreen }) => smallScreen ? '100px' : mediumScreen ? '125px' : '150px'};
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '5px' : mediumScreen ? '10px' : '15px'};
`
const Images = styled.img`
  width: 100%;
  height: 100%;
  object-fit: scale-down;
`