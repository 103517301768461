import React from 'react'

function CompanyProfileIcon({
    className,
    width = "71",
    height = "71",
    fill = "#8000CF",
}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} className={className} x="0" y="0" viewBox="0 0 512 512" >
            <g>
                <path d="M496.995 482h-16V227.006a14.999 14.999 0 0 0-10.257-14.23l-169.739-56.58v-51.187c0-6.253-3.878-11.85-9.733-14.045L51.272.966c-9.796-3.673-20.267 3.58-20.267 14.045v466.99h-16c-8.284 0-15 6.716-15 15s6.716 15 15 15h481.99c8.284 0 15-6.716 15-15s-6.716-15.001-15-15.001zm-345.993 0h-29.999v-59.999h29.999V482zm59.999 0h-29.999v-59.999h29.999V482zM271 167.007V482h-29.999v-74.998c0-8.284-6.716-15-15-15H106.003c-8.284 0-15 6.716-15 15V482H61.004V36.655L271 115.403v51.604zM390.997 482h-29.999v-59.999h29.999V482zm59.999 0h-29.999v-74.998c0-8.284-6.716-15-15-15h-59.999c-8.284 0-15 6.716-15 15V482h-29.999V187.818l149.997 49.999V482z" fill={fill} />
                <path d="M136.003 332.003h-29.999c-8.284 0-15 6.716-15 15s6.715 15 15 15h29.999c8.284 0 15-6.716 15-15s-6.716-15-15-15zM226.001 332.003h-29.999c-8.284 0-15 6.716-15 15s6.715 15 15 15h29.999c8.284 0 15-6.716 15-15s-6.716-15-15-15zM136.003 272.005h-29.999c-8.284 0-15 6.716-15 15s6.715 15 15 15h29.999c8.284 0 15-6.716 15-15s-6.716-15-15-15zM226.001 272.005h-29.999c-8.284 0-15 6.716-15 15s6.715 15 15 15h29.999c8.284 0 15-6.716 15-15s-6.716-15-15-15zM405.997 332.003h-59.999c-8.284 0-15 6.716-15 15s6.715 15 15 15h59.999c8.284 0 15-6.716 15-15s-6.716-15-15-15zM405.997 272.005h-59.999c-8.284 0-15 6.716-15 15s6.715 15 15 15h59.999c8.284 0 15-6.716 15-15s-6.716-15-15-15zM136.003 212.006h-29.999c-8.284 0-15 6.716-15 15 0 8.284 6.715 15 15 15h29.999c8.284 0 15-6.716 15-15-.001-8.284-6.716-15-15-15zM226.001 212.006h-29.999c-8.284 0-15 6.716-15 15 0 8.284 6.715 15 15 15h29.999c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15zM136.003 152.007h-29.999c-8.284 0-15 6.716-15 15 0 8.284 6.715 15 15 15h29.999c8.284 0 15-6.716 15-15-.001-8.284-6.716-15-15-15zM226.001 152.007h-29.999c-8.284 0-15 6.716-15 15 0 8.284 6.715 15 15 15h29.999c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15z" fill={fill} />
            </g>
        </svg>
    )
}

export default CompanyProfileIcon