import { useState } from 'react'

// Styled components
import styled from 'styled-components'

// React responsive
import { useMediaQuery } from 'react-responsive'

// React slick slider
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Clients Logo
import AusminchamLogo from '../../assets/images/clients_logo/210430-Cloudxier Clients Logo-01.png'
import MmsustLogo from '../../assets/images/clients_logo/210430-Cloudxier Clients Logo-02.png'
import FeatherWorldLogo from '../../assets/images/clients_logo/210430-Cloudxier Clients Logo-03.png'
import HeavenlyBlushLogo from '../../assets/images/clients_logo/210430-Cloudxier Clients Logo-04.png'
import IntimasLogo from '../../assets/images/clients_logo/210430-Cloudxier Clients Logo-05.png'
import ShooperLogo from '../../assets/images/clients_logo/210430-Cloudxier Clients Logo-06.png'
import JapfaStpLogo from '../../assets/images/clients_logo/210430-Cloudxier Clients Logo-07.png'
import BisLogo from '../../assets/images/clients_logo/210430-Cloudxier Clients Logo-08.png'
import SustainabilityAwardsLogo from '../../assets/images/clients_logo/210430-Cloudxier Clients Logo-09.png'
import NasLogo from '../../assets/images/clients_logo/210430-Cloudxier Clients Logo-10.png'
import AirsimLogo from '../../assets/images/clients_logo/210430-Cloudxier Clients Logo-11.png'
import BravoLogo from '../../assets/images/clients_logo/210430-Cloudxier Clients Logo-12.png'
import InterlinkLogo from '../../assets/images/clients_logo/210430-Cloudxier Clients Logo-13.png'
import IndonesiaCareLogo from '../../assets/images/clients_logo/210430-Cloudxier Clients Logo-14.png'
import PLNLogo from '../../assets/images/clients_logo/210430-Cloudxier Clients Logo-15.png'
import UndanginLogo from '../../assets/images/clients_logo/210430-Cloudxier Clients Logo-16.png'
import WonderfulIndonesiaLogo from '../../assets/images/clients_logo/210430-Cloudxier Clients Logo-17.png'
import PetaniRiauLogo from '../../assets/images/clients_logo/210430-Cloudxier Clients Logo-18.png'
import TotalBuahLogo from '../../assets/images/clients_logo/210430-Cloudxier Clients Logo-19.png'
import VitaminPlusLogo from '../../assets/images/clients_logo/210430-Cloudxier Clients Logo-20.png'
import SircloLogo from '../../assets/images/clients_logo/210430-Cloudxier Clients Logo-21.png'
import XopaLogo from '../../assets/images/clients_logo/210430-Cloudxier Clients Logo-22.png'
import CcsLogo from '../../assets/images/clients_logo/210430-Cloudxier Clients Logo-23.png'
import OnicLogo from '../../assets/images/clients_logo/210430-Cloudxier Clients Logo-25.png'
import IsaLogo from '../../assets/images/clients_logo/ISA_Logo.png'
import AgriG8 from '../../assets/images/clients_logo/AgriG8_Logo_COLOUR.png'

function ClientsLogo() {

  const smallScreen = useMediaQuery({ query: '(max-width: 768px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 1200px)' })

  const sliderSettings = {
    infinite: true,
    slidesToShow: smallScreen ? 2 : mediumScreen ? 3 : 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 0,
    cssEase: "linear"
  }

  const [images, setImages] = useState([
    {
      title: 'XOPA',
      externalLink: '',
      image: XopaLogo
    },
    {
      title: 'SIRCLO',
      externalLink: '',
      image: SircloLogo
    },
    {
      title: 'ONIC',
      externalLink: '',
      image: OnicLogo
    },
    {
      title: 'Ausmincham Indonesia',
      externalLink: 'https://ausmincham.or.id',
      image: AusminchamLogo
    },
    {
      title: 'AgriG8',
      externalLink: '',
      image: AgriG8
    },
    {
      title: 'Interlink Australia',
      externalLink: 'https://itpl.com.au',
      image: InterlinkLogo
    },
    {
      title: 'Wonderful Indonesia',
      externalLink: '',
      image: WonderfulIndonesiaLogo
    },
    {
      title: 'Total Buah Segar',
      externalLink: '',
      image: TotalBuahLogo
    },
    {
      title: 'MM-Sustainability Trisakti',
      externalLink: 'https://mmsustainability.ac.id',
      image: MmsustLogo
    },
    {
      title: 'Feather World',
      externalLink: '',
      image: FeatherWorldLogo
    },
    {
      title: 'Heavenly Blush',
      externalLink: '',
      image: HeavenlyBlushLogo
    },
    // {
    //   title: 'Inti Mas Sejahtera',
    //   externalLink: 'https://intimassejahtera.com',
    //   image: IntimasLogo
    // },
    {
      title: 'Shooper',
      externalLink: 'https://shooper.co.id',
      image: ShooperLogo
    },
    {
      title: 'Suri Tani Pemuka',
      externalLink: '',
      image: JapfaStpLogo
    },
    {
      title: 'BIS Industries',
      externalLink: '',
      image: BisLogo
    },
    {
      title: 'Vitamin+',
      externalLink: '',
      image: VitaminPlusLogo
    },
    {
      title: 'CECT Sustainability Awards',
      externalLink: 'https://cectsustainabilityawards.com',
      image: SustainabilityAwardsLogo
    },
    {
      title: 'NAS Builder',
      externalLink: '',
      image: NasLogo
    },
    {
      title: 'Airsim',
      externalLink: '',
      image: AirsimLogo
    },
    {
      title: 'Bravo Adventure',
      externalLink: '',
      image: BravoLogo
    },
    {
      title: 'Kemenpar Indonesia Care',
      externalLink: '',
      image: IndonesiaCareLogo
    },
    {
      title: 'Undangin.com',
      externalLink: '',
      image: UndanginLogo
    },
    {
      title: 'Perusahaan Listrik Negara (PLN)',
      externalLink: '',
      image: PLNLogo
    },
    {
      title: 'Petani Riau (Chevron)',
      externalLink: '',
      image: PetaniRiauLogo
    },
    {
      title: 'Combined Clinics Sustainability',
      externalLink: '',
      image: CcsLogo
    },
    // {
    //   title: 'ISA',
    //   externalLink: '',
    //   image: IsaLogo
    // },
  ])

  function renderSliderContent() {
    let firstSlider = []
    let secondSlider = []
    let partOne = images.slice(0, Math.round(images.length / 2))
    let partTwo = images.slice(partOne.length, images.length)
    firstSlider.push(
      <Slider {...sliderSettings}>
        {
          partOne.map((item, i) => {
            return (
              <ImageContainer key={i} smallScreen={smallScreen} mediumScreen={mediumScreen}>
                <Images key={i} src={item.image} alt={item.title} />
              </ImageContainer>
            )
          })
        }
      </Slider>
    )
    secondSlider.push(
      <Slider {...sliderSettings}>
        {
          partTwo.map((item, i) => {
            return (
              <ImageContainer key={i} smallScreen={smallScreen} mediumScreen={mediumScreen}>
                <Images key={i} src={item.image} alt={item.title} />
              </ImageContainer>
            )
          })
        }
      </Slider>
    )
    return (
      <div>
        {firstSlider}
        {secondSlider}
      </div>
    )
  }

  return (
    <MainContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
      {/* {
        smallScreen || mediumScreen ?
          renderSliderContent()
          :
          images.map((item, i) => {
            return (
              <ImageContainer key={i} smallScreen={smallScreen} mediumScreen={mediumScreen}>
                <Images key={i} src={item.image} alt={item.title} />
              </ImageContainer>
            )
          })
      } */}
      {renderSliderContent()}
    </MainContainer>
  )

}

export default ClientsLogo

const MainContainer = styled.div`
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px 0 30px 0' : mediumScreen ? '40px 0 40px 0' : '50px 0 50px 0'};
  display: ${({ smallScreen, mediumScreen }) => smallScreen ? 'block' : mediumScreen ? 'block' : 'block'};
  justify-content: center;
  flex-wrap: wrap;
  width: 100%
`
const ImageContainer = styled.div`
  width: 30%;
  max-width: 250px;
  height: auto;
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '5px' : mediumScreen ? '10px' : '15px'};
`
const Images = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain
`