import { useState } from 'react'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Bootstrap
import Container from 'react-bootstrap/Container'

// Styled components
import styled from 'styled-components'

// Animated
import { Animated } from "react-animated-css";

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

// Components
import FeaturedOnLogo from './banners/FeaturedOnLogo'

// Helpers
import fontSize from '../helpers/fontSize'

function HomeFeaturedOn() {
  const smallScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 1023px)' })

  const [isVisibleColumn, setVisibleColumn] = useState(false)

  return (
    <VisibilitySensor onChange={(isVisible) => setVisibleColumn(isVisible)} active={!isVisibleColumn} partialVisibility={true}>
      <Animated animationIn="fadeInUp" animationInDuration={2000} isVisible={isVisibleColumn}>
        <Container>
          <MainContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
            {/* <Heading smallScreen={smallScreen} mediumScreen={mediumScreen}>
        </Heading> */}
            <FeaturedOnLogo />
          </MainContainer>
        </Container>
      </Animated>
    </VisibilitySensor>

  )

}

export default HomeFeaturedOn

const MainContainer = styled.div`
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '10px 5vw' : mediumScreen ? '30px 5vw' : '50px 0'}
`
const Heading = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? fontSize.heading3Sm : mediumScreen ? fontSize.heading3Sm : fontSize.heading3Lg};
  color: black;
  font-weight: 700;
  text-align: center
`