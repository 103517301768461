// React
import { useState } from 'react'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Styled components
import styled from 'styled-components'

// React input mask
import InputMask from 'react-input-mask'

// Components
import SquareButton from './buttons/SquareButton'

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

// React sweetalert2
import Swal from 'sweetalert2'

// Axios
import Axios from 'axios'

// React Google Recaptcha
import ReCAPTCHA from "react-google-recaptcha";

import getServerURL from '../helpers/getServerURL';

import { countryPhoneCodes } from '../helpers/constants'

function DiscussForm() {

  const smallScreen = useMediaQuery({ query: '(max-width: 600px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 991px)' })

  const [isComponentVisible, setComponentVisible] = useState(false)

  const [isCaptchaCorrect, setCaptchaCorrect] = useState(false)

  const [isFilled, setFilled] = useState(false)

  const [validation, setValidation] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    companyName: '',
    message: '',
    captcha: ''
  })

  const validateEmail = (email) => {
    const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9-]{2,6}$/;
    if (email.match(validEmailRegex)) {
      return true;
    } else {
      return false;
    }
  }

  // remove duplicate phone codes
  const newCountryPhoneCodes = countryPhoneCodes.filter((value, index, self) =>
    index === self.findIndex((t) => (
      t.code === value.code
    ))
  )

  const [forms, setForms] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    companyName: '',
    message: '',
    phoneCode: '+62',
    isDiscussForm: true
  })

  function handleChange(value, key) {
    // Deep copy of the previous state
    let newForm = JSON.parse(JSON.stringify(forms))
    // Change the previous value
    newForm[key] = value
    if (newForm[key] != null && value.trim().length > 0) {
      setValidation(key = "") // Still an issue, must click submit twice -Raditya
    }
    // Re-set the form
    setForms(newForm)
  }

  function validateForm() {
    let result = []
    let newValidate = {
      fullName: '',
      email: '',
      phoneNumber: '',
      companyName: '',
      message: '',
    }
    for (let key in forms) {
      if (forms[key]) {
        result.push(true)
      } else {
        result.push(false)
      }
    }
    if (!result[0]) {
      newValidate.fullName = "Please input your fullname"
    }
    if (!result[1]) {
      newValidate.email = "Please input your email"
    } else {
      if (!validateEmail(forms['email'])) {
        newValidate.email = "Invalid email format"
        result.push(false)
      }
    }
    if (!result[2]) {
      newValidate.phoneNumber = "Please input your phone number"
    }
    if (!result[3]) {
      newValidate.companyName = "Please input your company"
    }
    if (!result[4]) {
      newValidate.message = "Please input your message"
    }
    if (!isCaptchaCorrect) {
      newValidate.captcha = "Please input captcha correctly"
      result.push(false)
    }
    setValidation({
      fullName: newValidate.fullName,
      email: newValidate.email,
      phoneNumber: newValidate.phoneNumber,
      companyName: newValidate.companyName,
      message: newValidate.message,
      phoneCode: newValidate.phoneCode,
      captcha: newValidate.captcha,
    })
    if (result.indexOf(false) > -1) {
      return false
    } else {
      return true
    }
  }

  function handleSubmit() {
    if (validateForm()) {
      // Added swal loading
      Swal.fire({
        title: "Please Wait",
        text: "We are processing your request",
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => Swal.showLoading()
      })
      // Submit to axios
      Axios({
        method: 'post',
        url: `${getServerURL()}/contact/submit`,
        data: forms
      })
        .then(() => {
          Swal.fire({
            title: "Form Submitted",
            icon: "success",
            text: "We will get back at you shortly!",
            allowOutsideClick: false,
            allowEscapeKey: false
          })
        })
        .catch((err) => {
          Swal.fire({
            title: "Oopss...",
            icon: "error",
            text: "Internal server error, please contact website admin"
          })
        })
    } else {
      Swal.fire({
        title: "Cannot Proceed",
        icon: "warning",
        text: "Please fill all the form fields"
      })
    }
  }

  function onChangeCaptcha(value) {
    if (value) {
      setCaptchaCorrect(true)
    } else {
      setCaptchaCorrect(false)
    }
  }

  return (
    <VisibilitySensor onChange={(isVisible) => setComponentVisible(isVisible)} partialVisibility={true} active={!isComponentVisible}>
      <MainContainer smallScreen={smallScreen} mediumScreen={mediumScreen} isComponentVisible={isComponentVisible}>
        <FormContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
          <InputContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
            <Title smallScreen={smallScreen} mediumScreen={mediumScreen}>
              Full Name
            </Title>
            <Input
              value={forms.fullName}
              smallScreen={smallScreen}
              mediumScreen={mediumScreen}
              placeholder="Fill in your full name..."
              onChange={({ target: { value } }) => handleChange(value, 'fullName')} />
          </InputContainer>
          {
            validation.fullName == '' ?
              null
              :
              <Validation smallScreen={smallScreen} mediumScreen={mediumScreen}>{validation.fullName}</Validation>
          }
          <InputContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
            <Title smallScreen={smallScreen} mediumScreen={mediumScreen}>
              Email Address
            </Title>
            <Input
              value={forms.email}
              smallScreen={smallScreen}
              mediumScreen={mediumScreen}
              placeholder="Fill in your email address..."
              onChange={({ target: { value } }) => handleChange(value, 'email')} />
          </InputContainer>
          {
            validation.email == '' ?
              null
              :
              <Validation smallScreen={smallScreen} mediumScreen={mediumScreen}>{validation.email}</Validation>
          }
          <InputContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
            <Title smallScreen={smallScreen} mediumScreen={mediumScreen}>
              Phone Number
            </Title>
            <InputPhoneNumber smallScreen={smallScreen} mediumScreen={mediumScreen}>
              <SelectInput
                value={forms.phoneCode}
                onChange={({ target: { value } }) => handleChange(value, 'phoneCode')}
                placeholder='Choose your country code'
                smallScreen={smallScreen}
                mediumScreen={mediumScreen}
              >
                {newCountryPhoneCodes.sort((a, b) => a.name - b.name).map(((country) => (
                  country.name === "Indonesia" ? <option value={country.code} selected>({country.code}) - {country.name}</option>
                    : <option value={country.code}>({country.code}) - {country.name}</option>
                )))}
              </SelectInput>
              <InputMask
                value={forms.phoneNumber}
                onChange={({ target: { value } }) => handleChange(value, 'phoneNumber')}
                mask="9999999999999"
                smallScreen={smallScreen}
                mediumScreen={mediumScreen}
                placeholder="Fill in your phone number..."
                maskChar=" ">
                {(inputProps) => <Input {...inputProps} />}
              </InputMask>
            </InputPhoneNumber>
          </InputContainer>
          {
            validation.phoneNumber == '' ?
              null
              :
              <Validation smallScreen={smallScreen} mediumScreen={mediumScreen}>{validation.phoneNumber}</Validation>
          }
          {
            validation.phoneCode == '' ?
              null
              :
              <Validation smallScreen={smallScreen} mediumScreen={mediumScreen}>{validation.phoneCode}</Validation>
          }
          <InputContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
            <Title smallScreen={smallScreen} mediumScreen={mediumScreen}>
              Company / Organization
            </Title>
            <Input
              value={forms.companyName}
              onChange={({ target: { value } }) => handleChange(value, 'companyName')}
              smallScreen={smallScreen}
              mediumScreen={mediumScreen}
              placeholder="Fill in your company name..." />
          </InputContainer>
          {
            validation.companyName == '' ?
              null
              :
              <Validation smallScreen={smallScreen} mediumScreen={mediumScreen}>{validation.companyName}</Validation>
          }
          <InputContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
            <Title smallScreen={smallScreen} mediumScreen={mediumScreen}>
              Message
            </Title>
            <TextArea
              value={forms.message}
              onChange={({ target: { value } }) => handleChange(value, 'message')}
              smallScreen={smallScreen}
              mediumScreen={mediumScreen}
              rows="7"
              placeholder="Your message..." />
          </InputContainer>
          {
            validation.message == '' ?
              null
              :
              <Validation smallScreen={smallScreen} mediumScreen={mediumScreen}>{validation.message}</Validation>
          }
        </FormContainer>
        <ButtonContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
          <ReCAPTCHA
            style={{ margin: smallScreen ? '20px 0 0 0' : mediumScreen ? '20px 0 0 0' : '20px 0 0 0' }}
            sitekey='6Le7pvUaAAAAAAiPYg6vfzaD7kFju2jFBEYuvvaK'
            onChange={onChangeCaptcha}
          />
          {
            validation.captcha == '' ?
              null
              :
              <Validation smallScreen={smallScreen} mediumScreen={mediumScreen} style={{ justifyContent: "center" }}>{validation.captcha}</Validation>
          }
          {
            isCaptchaCorrect ?
              <SquareButton
                action={handleSubmit}
                textColor='white'
                hoverTextColor='white'
                text='Submit'
                textSize={smallScreen ? '16px' : mediumScreen ? '17px' : '18px'}
                backgroundColor='#4A4A4A'
                hoverBgColor='#5D3F6A'
                marginTop={smallScreen ? '30px' : mediumScreen ? '30px' : '30px'}
                width={smallScreen ? '70%' : mediumScreen ? '70%' : '60%'} />
              :
              <SquareButton
                action={handleSubmit}
                textColor='white'
                hoverTextColor='white'
                text='Submit'
                textSize={smallScreen ? '16px' : mediumScreen ? '17px' : '18px'}
                backgroundColor='#4A4A4A'
                hoverBgColor='#4A4A4A'
                marginTop={smallScreen ? '30px' : mediumScreen ? '30px' : '30px'}
                width={smallScreen ? '100%' : mediumScreen ? '100%' : '60%'} />
          }
        </ButtonContainer>
      </MainContainer>
    </VisibilitySensor>
  )

}

export default DiscussForm

const MainContainer = styled.div`
  margin: ${({ smallScreen, mediumScreen }) => smallScreen ? '0' : mediumScreen ? '0' : '0 0 50px 0'};
  opacity: ${({ isComponentVisible }) => isComponentVisible ? 1 : 0};
  transition: opacity 1s
`
const Title = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '16px' : mediumScreen ? '18px' : '20px'};
  color: black;
  font-weight: ${({ smallScreen, mediumScreen }) => smallScreen ? 'bold' : mediumScreen ? 'bold' : 'bold'};
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '12px' : mediumScreen ? '15px' : '18px'}
`
const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '0 10vw' : mediumScreen ? '0 10vw' : '0'}
`
const InputContainer = styled.div`
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? '100%' : mediumScreen ? '100%' : '60%'};
  margin-top: ${({ smallScreen, mediumScreen }) => smallScreen ? '20px' : mediumScreen ? '25px' : '30px'};
`
const InputPhoneNumber = styled.div`
  display: flex;
  flex-direction: ${({ smallScreen, mediumScreen }) => smallScreen ? 'column' : mediumScreen ? 'row' : 'row'};
  justify-items: center;
  align-items: center;
  gap: 10px;
`
const SelectInput = styled.select`
  border: ${({ value }) => value ? '1px solid ##4C39FF' : '1px solid #4C39FF'};
  background-color: ${({ value }) => value ? 'transparent' : 'transparent'};
  border-radius: 10px;
  outline: none;
  padding: 13px;
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? '100%' : mediumScreen ? '50%' : '40%'};
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '14px' : mediumScreen ? '16px' : '18px'}
`
const Input = styled.input`
  border: ${({ value }) => value ? '1px solid ##4C39FF' : '1px solid #4C39FF'};
  background-color: ${({ value }) => value ? 'transparent' : 'transparent'};
  border-radius: 10px;
  outline: none;
  padding: 10px;
  width: 100%;
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '14px' : mediumScreen ? '16px' : '18px'};
`
const TextArea = styled.textarea`
  border: ${({ value }) => value ? '1px solid ##4C39FF' : '1px solid #4C39FF'};
  background-color: ${({ value }) => value ? 'transparent' : 'transparent'};
  border-radius: 10px;
  outline: none;
  width: 100%;
  padding: 15px;
  resize: none;
  margin-top: ${({ smallScreen, mediumScreen }) => smallScreen ? '6px' : mediumScreen ? '8px' : '10px'};
`
const ButtonContainer = styled.div`
  display: ${({ smallScreen, mediumScreen }) => smallScreen ? 'flex' : mediumScreen ? 'flex' : 'flex'};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '0 10vw' : mediumScreen ? '0 10vw' : '0'}
`
const Validation = styled.div`
  display: flex;
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? '100%' : mediumScreen ? '100%' : '60%'} ;
  align-items: flex-start;
  justify-content: start;
  text-align: left;
  color: #C95757;
  font-size: 15px;
`