module.exports = {
  PORT: "3993",
  NODE_ENV: "production",

  MAIN_WEBSITE_TITLE: 'CLOUDXIER | ',
  SITE_NAME: 'Cloudxier',

  SERVER_DEV_URL: "http://localhost:3131",
  SERVER_STAGING_URL: "https://deltastg.cloudxier.com",
  SERVER_PROD_URL: "https://delta.cloudxier.com",

  CLIENT_DEV_HOST: "localhost",
  CLIENT_STAGING_HOST: "stg.cloudxier.com",
  CLIENT_PROD_HOST: "cloudxier.com",

  CLIENT_DEV_URL: "http://localhost:3232",
  CLIENT_STAGING_URL: "https://stg.cloudxier.com",
  CLIENT_PROD_URL: "https://www.cloudxier.com"
}
