import { useState, useRef } from "react"
import styled from "styled-components"

// React responsive
import { useMediaQuery } from 'react-responsive'

// Helpers
import fontSize from '../../helpers/fontSize'

function FounderProfileModal({ founderData, closePopModalProfile, idModalBody, idModalContent, setFindIdModal }) {

  // const smallScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const smallScreen = useMediaQuery({ query: '(max-width: 800px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 1300px)' })
  const largeScreen = useMediaQuery({ query: '(max-width: 1600px)' })
  const [splitTextDesc, setSplitTextDesc] = useState([])
  const [tempTextDesc, setTempTextDesc] = useState('')

  const modalBodyRef = useRef(null)
  const trigeredCloseModal = (ref) => {
    window.onclick = function (event) {
      if (ref.current && !ref.current.contains(event.target)) {
        closePopModalProfile()
      }
    }
  }

  return (
    <ModalBody id={idModalBody} smallScreen={smallScreen} mediumScreen={mediumScreen} onClick={() => trigeredCloseModal(modalBodyRef)}>
      <ModalContent id={idModalContent} smallScreen={smallScreen} mediumScreen={mediumScreen} ref={modalBodyRef}>
        <CloseButton onClick={closePopModalProfile}>&times;</CloseButton>
        <Name smallScreen={smallScreen} mediumScreen={mediumScreen}>{founderData.name}</Name>
        <Position smallScreen={smallScreen} mediumScreen={mediumScreen}>{founderData.position}</Position>
        <Profile>
          {founderData.Profile}
        </Profile>
      </ModalContent>
    </ModalBody>
  )
}

const ModalBody = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 12px;
  margin: auto;
  z-index: 3;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0, 0, 0, 0.4);
`
const ModalContent = styled.div`
  position: absolute;
  z-index: 2;
  background-color: white;
  margin: auto;
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '40px 30px' : mediumScreen ? '40px 40px' : '50px 50px'};
  border: 1px solid #888;
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? '80vw' : mediumScreen ? '60vw' : '50vw'};
  justify-content: center;
  border-radius: 20px;
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '0.9rem' : mediumScreen ? '1rem' : '1.1rem'};
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: justify
`
const CloseButton = styled.button`
  color: #aaaaaa;
  float: right;
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '20px' : mediumScreen ? '10px' : '28px'};
  background: transparent;
  border: 0;
  font-weight: bold;
  line-height: ${({ smallScreen, mediumScreen }) => smallScreen ? '5rem' : mediumScreen ? '3rem' : '1.5rem'};
  margin-top: ${({ smallScreen, mediumScreen }) => smallScreen ? '-20px' : mediumScreen ? '-5px' : '-20px'};
  &: hover {
    cursor: pointer;
    color: black;
  }
`
const Name = styled.p`
  color: #336AFE;
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '1.2rem' : mediumScreen ? '1.5rem' : '1.8rem'};
  font-weight: bold;
`
const Position = styled.p`
  color: black;
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '1rem' : mediumScreen ? '1.25rem' : '1.5rem'};
  margin-top: -15px;
`
const Profile = styled.div`
  height: 20rem;
  max-height: 20rem;
  overflow-y: auto;
`

export default FounderProfileModal