import { useState } from 'react'

// Bootstrap
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

// Styled components
import styled from 'styled-components'

// Components
import SectionHeading from '../components/headings/SectionHeading'

// Image 
import ClearCodeIcon from '../assets/images/way_of_development/clear_code_reviews.png'
import SecureScalableIcon from '../assets/images/way_of_development/secure_and_scalable.png'
import ContDeliveryIcon from '../assets/images/way_of_development/continuous_delivery.png'
import UserFocusedIcon from '../assets/images/way_of_development/user_focused_approach.png'

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

// Animated
import { Animated } from "react-animated-css";

// React responsive
import { useMediaQuery } from 'react-responsive'

function FeatureCard() {

  const smallScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 991px)' })

  const [isContentVisible, setContentVisible] = useState(false)

  const [content, setContent] = useState([
    {
      title: 'Clear Code Reviews',
      image: ClearCodeIcon,
      label: 'content-1',
      description: (
        <p>Clean code reviews are an integral part of our software development process, ensuring that every piece of code meets the highest standards of quality, readability, and maintainability. During code reviews, our experienced developers meticulously examine the code for adherence to best practices, coding standards, and design patterns.</p>
      )
    },
    {
      title: 'Secure and Scalable',
      image: SecureScalableIcon,
      label: 'content-2',
      description: (
        <div>
          <p>In our software development process, we prioritize creating secure and scalable solutions tailored to your business needs. We integrate security best practices throughout the development lifecycle, ensuring your software is resilient against evolving cyber threats.</p>
          <p>Additionally, we design our systems with scalability in mind, utilizing modern architectures such as microservices and cloud-native technologies.</p>
        </div>
      )
    },
    {
      title: 'Continuous Delivery',
      image: ContDeliveryIcon,
      label: 'content-3',
      description: (
        <div>
          <p>Our continuous delivery approach in software development ensures that new features, updates, and improvements are delivered to users quickly and reliably. We use robust deployment pipelines to automatically deploy updates to production environments, ensuring that new features and fixes are available to users as soon as they are ready.</p>
          <p>By adopting continuous delivery, we help you achieve faster time-to-market, increased efficiency, and enhanced customer satisfaction.</p>
        </div>
      )
    },
    {
      title: 'User Focused Approach',
      image: UserFocusedIcon,
      label: 'content-4',
      description: (
        <div>
          <p>Our user-focused approach in software development ensures that every solution we create is intuitive, engaging, and tailored to meet the needs of your users. </p>
          <p>By prioritizing usability and accessibility, we ensure that our software is easy to navigate and inclusive for all users. Throughout the development lifecycle, we conduct regular usability testing to gather feedback and make iterative improvements. </p>
        </div>
      )
    },
  ])

  return (
    <FeatureContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
      <Container style={{ padding: smallScreen ? '0 15vw' : mediumScreen ? '0 10vw' : '0 3vw' }}>
        <SectionHeading
          title="CLOUDXIER'S WAY OF DEVELOPMENT"
          subtitle='Solve your tech challenges with the right partner'
          titleColor='white'
          subtitleColor='white' />
        <VisibilitySensor onChange={(isVisible) => setContentVisible(isVisible)} active={!isContentVisible} partialVisibility={true}>
          <ContentContainer smallScreen={smallScreen} mediumScreen={mediumScreen} isContentVisible={isContentVisible}>
            {/* <Animated animationIn="slideInUp" animationInDuration={2000} isVisible={isContentVisible}> */}
            <Row style={{ display: "flex", justifyContent: "center" }} >
              {
                content.map((item, i) => {
                  return (
                    <Col key={i} sm={12} md={12} lg={6} style={{ padding: smallScreen ? '0' : mediumScreen ? '0' : "0px 40px" }} data-aos="fade-up" data-aos-duration="2000" data-aos-once={true}>
                      <ImageContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
                        <Images src={item.image} alt={item.label} smallScreen={smallScreen} mediumScreen={mediumScreen} />
                      </ImageContainer>
                      <ContentHeader smallScreen={smallScreen} mediumScreen={mediumScreen}>
                        {item.title}
                      </ContentHeader>
                      <ContentDescription smallScreen={smallScreen} mediumScreen={mediumScreen}>
                        {item.description}
                      </ContentDescription>
                    </Col>
                  )
                })
              }
            </Row>
            {/* </Animated> */}
          </ContentContainer>
        </VisibilitySensor>
      </Container>
    </FeatureContainer>
  )

}

export default FeatureCard

const FeatureContainer = styled.div`
  background-image: linear-gradient(to bottom, #472D80 , #131A91);
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '50px 0 50px 0' : mediumScreen ? '50px 0 50px 0' : '50px 0 50px 0'}
`
const ContentContainer = styled.div`
  opacity: ${({ isContentVisible }) => isContentVisible ? 1 : 0};
  transition: opacity 1s
`
const ContentHeader = styled.div`
  text-align: ${({ smallScreen, mediumScreen }) => smallScreen ? 'center' : mediumScreen ? 'center' : 'left'};
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? '100%' : mediumScreen ? '100%' : '100%'};
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '25px' : mediumScreen ? '30px' : '35px'};
  color: white;
  font-weight: 700;
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '10px' : mediumScreen ? '20px' : '30px'}
`
const ContentDescription = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '17px' : mediumScreen ? '21px' : '25px'};
  color: white;
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? '100%' : mediumScreen ? '100%' : '100%'};
  text-align: ${({ smallScreen, mediumScreen }) => smallScreen ? 'justify' : mediumScreen ? 'justify' : 'justify'};
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '40px' : mediumScreen ? '40px' : '40px'}
`
const ImageContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: ${({ smallScreen, mediumScreen }) => smallScreen ? 'none' : mediumScreen ? 'none' : 'none'};
  height: auto;
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '10px 0' : mediumScreen ? '12px 0' : '15px 0'};
  justify-content: ${({ smallScreen, mediumScreen }) => smallScreen ? 'center' : mediumScreen ? 'center' : 'flex-start'};
`
const Images = styled.img`
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? '50%' : mediumScreen ? '40%' : '50%'};;
  height: ${({ smallScreen, mediumScreen }) => smallScreen ? '50%' : mediumScreen ? '40%' : '50%'};;
  object-fit: contain
`