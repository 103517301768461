import { useState } from 'react';

// Animated
import { Animated } from "react-animated-css";

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

// Styled components
import styled from 'styled-components'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Components
import SectionHeading from '../components/headings/SectionHeading'

// Images
import organisation_structure from '../assets/images/our_holding_group/organisation_structure.jpg'

function OurHoldingGroup() {
    const smallScreen = useMediaQuery({ query: '(max-width: 767px)' })
    const mediumScreen = useMediaQuery({ query: '(max-width: 1279px)' })

    const [isContent1Visible, setContent1Visible] = useState(false)

    return (
        <MainContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
            <VisibilitySensor onChange={(isVisible) => setContent1Visible(isVisible)} active={!isContent1Visible} partialVisibility={true}>
                <Animated animationIn="slideInUp" animationInDuration={2000} isVisible={isContent1Visible}>
                    <SectionHeading
                        title='Our Holding Group'
                        subtitle=''
                    />
                    <ContentContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
                        <Image src={organisation_structure} alt="organisation_structure" />
                    </ContentContainer>
                </Animated>
            </VisibilitySensor>
        </MainContainer>
    )

}

export default OurHoldingGroup;

const MainContainer = styled.div`
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px 0 30px 0' : mediumScreen ? '50px 0 30px 0' : '50px 0 50px 0'};
`
const ContentContainer = styled.div`
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '0 5px 0 5px' : mediumScreen ? '0 10px 0 10px' : '0 200px 0 200px'};
`
const Image = styled.img`
    width: 100%;
`