import { useState } from 'react'

// Bootstrap
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// React router dom
import { Link, useHistory } from 'react-router-dom'

// Styled components
import styled from 'styled-components'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Images
import ArrowRightIconDark from '../assets/images/icons/arrow_right_icon_dark.png'

// Background images
import MobileApplicationDevelopment from '../assets/images/landing_page/what_we_do/What-we-do-Landing page-MobileApp.png'
import DigitalProduct from '../assets/images/landing_page/what_we_do/What-we-do-Landing page-Digital Product Experience and Design.png'
import EnterpriseSoftware from '../assets/images/landing_page/what_we_do/What-we-do-Landing page-Enterprise Software Development.png'
import WebsiteDevelopment from '../assets/images/landing_page/what_we_do/What-we-do-Landing page-Website Development.png'
import BrandingGraphic from '../assets/images/landing_page/what_we_do/What-we-do-Landing page-Branding & Graphic Design.png'
import APICreation from '../assets/images/landing_page/what_we_do/What-we-do-Landing page-APICreation&Mgmt.png'

// Images 
import CustomSoftwareDev from '../assets/images/our_services/our_services_1.png'
import MobileAppDev from '../assets/images/our_services/our_services_2.png'
import APIDevIntegration from '../assets/images/our_services/our_services_3.png'
import CloudComputingConsult from '../assets/images/our_services/our_services_4.png'
import DesignPlanning from '../assets/images/our_services/our_services_5.png'
import CTOService from '../assets/images/our_services/our_services_6.png'
import DedicatedTeam from '../assets/images/our_services/our_services_7.png'
import DevOpsConsult from '../assets/images/our_services/our_services_8.png'

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

// Animated
import { Animated } from "react-animated-css";

// Helpers
import fontSize from '../helpers/fontSize'

function FeatureTile() {

  const smallScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 1400px)' })

  const history = useHistory()

  const [isVisibleTile, setVisibleTile] = useState(false)

  const [features, setFeatures] = useState([{
    title: 'Custom Software Development',
    description: (
      <div>
        <p>Providing custom software development services together with technical architecture consultation that suits your business goals. From concept to deployment, we ensure a seamless development process.</p>
        <p>Whether it's front-end interfaces, back-end logic, or database structure and integration, we've got it covered.</p>
      </div>
    ),
    img: CustomSoftwareDev,
    label: 'our-services-1',
    backgroundImg: MobileApplicationDevelopment,
    hover: false,
    link: "/our-services/custom-software-development"
  }, {
    title: 'Mobile App Development',
    description: (
      <div>
        <p>Our mobile app development service is tailor-made to align with your business objectives.</p>
        <p>From iOS, Android or Hybrid cross-platform app development that allows your app to run smoothly across various devices and operating systems, ensuring you get more value for your investment</p>
      </div>
    ),
    img: MobileAppDev,
    label: 'our-services-2',
    backgroundImg: DigitalProduct,
    hover: false,
    link: "/our-services/mobile-app-development"
  }, {
    title: 'API Development and Integration',
    description: (
      <div>
        <p>Embrace modern and efficient communication protocols.</p>
        <p>Our team is well-versed in developing RESTful APIs and GraphQL endpoints, providing versatile solutions that cater to a wide range of applications and use cases, including: versioning strategies and backward compatibility, ensuring a seamless transition for existing users and allowing you to introduce new features without disrupting current implementations</p>
      </div>
    ),
    img: APIDevIntegration,
    label: 'our-services-3',
    backgroundImg: EnterpriseSoftware,
    hover: false,
    link: "/our-services/api-development-integration"
  }, {
    title: 'Cloud Computing and Consultation',
    description: (
      <div>
        <p>Maximize your business value by implementing end-to-end cloud computing solution; from planning, development, and management.</p>
        <p>This includes cloud management to cover ongoing monitoring and optimization of your cloud infrastructure, ensuring the software systems are always operating at their best and costs are kept in check.</p>
      </div>
    ),
    img: CloudComputingConsult,
    label: 'our-services-4',
    backgroundImg: WebsiteDevelopment,
    hover: false,
    link: "/our-services/cloud-computing-consultation"
  }, {
    title: 'Dedicated Tech Team',
    description: (
      <div>
        <p>Reduce hiring costs and get an extended team to support your IT department.</p>
        <p>Our dedicated tech support plans are flexible (monthly or contract-based period), allowing you to choose the level of service that aligns with your software complexity, usage patterns, and business goals.</p>
      </div>
    ),
    img: DedicatedTeam,
    label: 'our-services-5',
    backgroundImg: APICreation,
    hover: false,
    link: "/our-services/dedicated-tech-team"
  }, {
    title: 'CTO as a Service',
    description: (
      <div>
        <p>Providing businesses with access to high-level technical expertise and strategic guidance without the need for a full-time Chief Technology Officer (CTO). </p>
        <p>This flexible and scalable solution allows businesses to tap into the knowledge and experience of technology leaders with an on-demand basis, it’s more cost-effective and flexible!</p>
      </div>
    ),
    img: CTOService,
    label: 'our-services-6',
    backgroundImg: BrandingGraphic,
    hover: false,
    link: "/our-services/cto-as-service"
  }, {
    title: 'UI/UX Design and Planning',
    description: (
      <div>
        <p>Craft a blueprint for success with our strategic UI/UX planning services. We analyze your business goals, target audience, and industry trends to create a visual roadmap that ensures your digital product not only meets but exceeds user expectations.</p>
      </div>
    ),
    img: DesignPlanning,
    label: 'our-services-7',
    backgroundImg: BrandingGraphic,
    hover: false,
    link: "/our-services/ui-ux-design-planning"
  }, {
    title: 'DevOps Consulting',
    description: (
      <div>
        <p>Our team will work closely with you to implement industry-leading practices such as Continuous Integration/Continuous Delivery (CI/CD), Infrastructure as Code (IaC), and automated testing. We provide tailored solutions to streamline your workflows, enhance collaboration between development and operations teams, and ensure robust monitoring and logging
          for optimal performance.
        </p>
      </div>
    ),
    img: DevOpsConsult,
    label: 'our-services-8',
    backgroundImg: BrandingGraphic,
    hover: false,
    link: "/our-services/devops-consulting"
  }])

  return (
    <Container fluid={smallScreen || mediumScreen ? 'true' : false}>
      <VisibilitySensor onChange={(isVisible) => setVisibleTile(isVisible)} partialVisibility={true} active={!isVisibleTile}>
        {/* <Animated animationIn="slideInUp" animationInDuration={2000} isVisible={isVisibleTile}> */}
        <Row >
          {
            features.map((feature, i) => (
              <Col sm={12} md={6} lg={6} key={i} data-aos="fade-up" data-aos-duration="2000" data-aos-once={true}>
                <Tile
                  smallScreen={smallScreen}
                  mediumScreen={mediumScreen}
                >
                  <ImageContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
                    <Images src={feature.img} alt={feature.label} />
                  </ImageContainer>
                  <Title smallScreen={smallScreen} mediumScreen={mediumScreen}>
                    {feature.title}
                  </Title>
                  <Description smallScreen={smallScreen} mediumScreen={mediumScreen}>
                    {feature.description}
                  </Description>
                  <ActionButton onClick={() => history.push(feature.link)}>
                    <ActionText smallScreen={smallScreen} mediumScreen={mediumScreen}>
                      Learn more
                    </ActionText>
                    <ArrowIcon
                      src={ArrowRightIconDark}
                      smallScreen={smallScreen}
                      mediumScreen={mediumScreen}
                    />
                  </ActionButton>
                </Tile>
              </Col>
            ))
          }
        </Row>
        {/* </Animated> */}
      </VisibilitySensor>
    </Container>
  )

}

export default FeatureTile

const Tile = styled.div`
  width: 100%;
  padding: 20px 30px;
`
const Title = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '25px' : mediumScreen ? '30px' : '35px'};
  opacity: 1;
  color: #4C39FF;
  font-weight: 700;
`
const Description = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '16px' : mediumScreen ? '20px' : '25px'};
  color: black;
  font-weight: 400; 
  text-align: justify;
  padding: 20px 0;
`
const ActionButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`
const ActionText = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '14px' : mediumScreen ? '16px' : '18px'};
  color: black;
  margin-right: 20px;
  text-transform: uppercase;
  font-weight: 700; 
`
const ArrowIcon = styled.img`
  padding-bottom: 1px;
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? '14px' : mediumScreen ? '16px' : '18px'};
  height: auto;
  cursor: pointer;
`
const ImageContainer = styled.div`
  width: 100%;
  max-width: 250px;
  height: auto;
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '5px' : mediumScreen ? '10px' : '0px'};
`
const Images = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain
`