// Styled components
import styled from 'styled-components'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Helpers
import fontSize from '../../helpers/fontSize'

function ExpertiseMainBanner ({ subtitle, title, text, backgroundImg }) {

  const smallScreen = useMediaQuery({ query: '(max-width: 1023px)'})

  return (
    <MainContainer smallScreen={smallScreen} backgroundImg={backgroundImg}>
      <InnerContainer smallScreen={smallScreen}>
        <Title smallScreen={smallScreen}>
          {title}
        </Title>
        <Subtitle smallScreen={smallScreen}>
          {subtitle}
        </Subtitle>
        <Text smallScreen={smallScreen}>
          {text}
        </Text>
      </InnerContainer>
    </MainContainer>
  )

}

export default ExpertiseMainBanner

const MainContainer = styled.div`
  padding: ${({ smallScreen }) => smallScreen ? '0 10vw' : '0'};
  background-color: ${({ smallScreen }) => smallScreen ? 'white' : '#2611C9'};
  background-image: ${({ smallScreen, backgroundImg }) => smallScreen ? 'none' : `url("${backgroundImg}")`};
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
`
const InnerContainer = styled.div`
  width: ${({ smallScreen }) => smallScreen ? '100%' : '700px'};
  margin: ${({ smallScreen }) => smallScreen ? '20px 0 50px 0' : '150px 0'}
`
const Title = styled.div`
  font-size: ${({ smallScreen }) => smallScreen ? fontSize.heading3Sm : fontSize.heading3Lg};
  color: ${({ smallScreen }) => smallScreen ? 'black' : 'white'};;
  text-align: center;
  margin-bottom: ${({ smallScreen }) => smallScreen ? '10px' : '15px'};
  text-transform: uppercase;
  letter-spacing: 8px
`
const Subtitle = styled.div`
  font-size: ${({ smallScreen }) => smallScreen ? fontSize.heading2Sm : fontSize.heading2Lg};
  color: ${({ smallScreen }) => smallScreen ? '#131A91' : 'white'};;;
  font-weight: bold;
  text-align: center;
  margin-bottom: ${({ smallScreen }) => smallScreen ? '25px' : '50px'};
`
const Text = styled.div`
  font-size: ${({ smallScreen }) => smallScreen ? fontSize.heading2Sm : fontSize.heading2Lg};
  color: ${({ smallScreen }) => smallScreen ? 'black' : 'white'};;;
  text-align: center
`