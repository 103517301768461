import { useState } from 'react'

// React bootstrap
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// Styled components
import styled from 'styled-components'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Animated
import { Animated } from "react-animated-css";

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

// Components
import SectionHeading from '../components/headings/SectionHeading'

// Helpers
import fontSize from '../helpers/fontSize'

// Images
import AboutUsBanner from '../assets/images/about_us_banner/Cloudxier-aboutus-banner.png'

function OurStory() {

  const smallScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 1279px)' })

  const [isContent1Visible, setContent1Visible] = useState(false)
  const [isContent2Visible, setContent2Visible] = useState(false)

  return (
    <MainContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>

      <Animated animationIn="fadeInDown" animationInDuration={2000} isVisible={true}>
        <SectionHeading
          title='About Us'
          subtitle=''
        />
      </Animated>
      <ContentContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
        <VisibilitySensor onChange={(isVisible) => setContent1Visible(isVisible)} active={!isContent1Visible} partialVisibility={true}>
          <Animated animationIn="fadeInDown" animationInDuration={2000} isVisible={isContent1Visible}>
            <TextContainer>
              <AboutText smallScreen={smallScreen} mediumScreen={mediumScreen} fontSize={smallScreen ? fontSize.heading2Sm : mediumScreen ? fontSize.heading3Lg : `40px`} style={{ fontWeight: 700 }}>
                We're more than just a software development company; we're your trusted partner in digital transformation.
              </AboutText>
              <AboutText smallScreen={smallScreen} mediumScreen={mediumScreen} fontSize={smallScreen ? '16px' : mediumScreen ? '21px' : `26px`}>
                With a <span style={{ fontWeight: 700 }}>passion for innovation</span> and a <span style={{ fontWeight: 700 }}>commitment to excellence</span>, we specialize in crafting bespoke software solutions that boost businesses forward in today's dynamic market landscape.
              </AboutText>
            </TextContainer>
            <SubText smallScreen={smallScreen} mediumScreen={mediumScreen}>
              Vision
            </SubText>
            <MainText smallScreen={smallScreen} mediumScreen={mediumScreen}>
              To be the most trusted partner in digital transformation
            </MainText>
            <SubText smallScreen={smallScreen} mediumScreen={mediumScreen}>
              Mission
            </SubText>
            <MainText smallScreen={smallScreen} mediumScreen={mediumScreen}>
              Helping business to accelerate with technology for better and sustainable future
            </MainText>
          </Animated>
        </VisibilitySensor>
        {/* <Row>
            <Col md={6}>
              <Image src={AboutUsBanner} smallScreen={smallScreen} mediumScreen={mediumScreen} />
            </Col>
            <Col md={6}>
              <TextContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
                <SubText smallScreen={smallScreen} mediumScreen={mediumScreen}>
                  Vision
                </SubText>
                <MainText smallScreen={smallScreen} mediumScreen={mediumScreen}>
                  To be the most trusted partner in digital transformation
                </MainText>
                <SubText smallScreen={smallScreen} mediumScreen={mediumScreen}>
                  Mission
                </SubText>
                <MainText smallScreen={smallScreen} mediumScreen={mediumScreen}>
                  Helping business to accelerate with technology for better and sustainable future
                </MainText>
              </TextContainer>
            </Col>
          </Row> */}
      </ContentContainer>
      <DescriptionContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
        <VisibilitySensor onChange={(isVisible) => setContent2Visible(isVisible)} active={!isContent2Visible} partialVisibility={true}>
          <Animated animationIn="fadeInRight" animationInDuration={2000} isVisible={isContent2Visible}>
            <TitleText smallScreen={smallScreen} mediumScreen={mediumScreen}>
              You dream it, <br />
              we build and make it happen.
            </TitleText>
            <BodyText smallScreen={smallScreen} mediumScreen={mediumScreen}>
              Founded in 2018, Cloudxier is a tech and software development company based in Jakarta, Indonesia. Offering end-to-end technology consulting services from Product ideation, Cloud development to DevOps engineering we help our clients drive growth.
              <br />
              <br />
              We believe that in today's digital world, businesses need to harness technology and innovation quickly to deliver valuable impact every step of the way.
              <br />
              That’s where we come in, our team of strategists and problem-solvers can help organizations to execute your digital transformation journeys.
            </BodyText>
          </Animated>
        </VisibilitySensor>
      </DescriptionContainer>

    </MainContainer>
  )

}

export default OurStory

const MainContainer = styled.div`
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px 0 30px 0' : mediumScreen ? '30px 0 30px 0' : '50px 0 50px 0'}
`
const ContentContainer = styled.div`
  // margin: ${({ smallScreen, mediumScreen }) => smallScreen ? '12px 0 22px 0' : mediumScreen ? '12px 0 22px 0' : '26px 0 50px 0'};
  background: #F3F3F3;
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '25px 10vw' : mediumScreen ? '25px 10vw' : '90px 150px'}
`
const AboutText = styled.p`
  font-size: ${({ fontSize }) => `${fontSize}`};
`
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px' : mediumScreen ? '30px' : '0'}
`
const TextContainer = styled.div`
  // padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '22px 10vw' : mediumScreen ? '22px 10vw' : '64px 180px 56px 80px'}
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '15px' : mediumScreen ? '15px' : '40px'};
`
const MainText = styled.div`
  margin-top: ${({ smallScreen, mediumScreen }) => smallScreen ? '10px' : mediumScreen ? '10px' : '16px'};
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '18px' : mediumScreen ? '18px' : '48px'};
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '20px' : mediumScreen ? '25px' : "30px"};
  color: black;
  font-weight: 700;
  text-align: ${({ smallScreen, mediumScreen }) => smallScreen ? 'center' : mediumScreen ? 'center' : "left"};
`
const SubText = styled.div`
  color: #131A91;
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? fontSize.heading2Sm : mediumScreen ? fontSize.heading3Lg : "40px"};
  font-weight: bold;
  text-align: ${({ smallScreen, mediumScreen }) => smallScreen ? 'center' : mediumScreen ? 'center' : "left"};
`
const DescriptionContainer = styled.div`
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '20px 10vw' : mediumScreen ? '20px 10vw' : '112px 150px 90px 150px'}
`
const TitleText = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? "30px" : mediumScreen ? "40px" : "50px"};
  color: #336AFE;
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? "30px" : mediumScreen ? "30px" : "60px"};
  line-height:  ${({ smallScreen, mediumScreen }) => smallScreen ? "unset" : mediumScreen ? "unset" : "60px"};
  font-weight: 700;
`
const BodyText = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? fontSize.heading2Sm : mediumScreen ? fontSize.heading1Sm : "30px"};
  line-height:  ${({ smallScreen, mediumScreen }) => smallScreen ? "unset" : mediumScreen ? "unset" : "60px"};
  font-weight: 500;
  margin-right: ${({ smallScreen, mediumScreen }) => smallScreen ? "0" : mediumScreen ? "0" : "180px"};
  text-align: ${({ smallScreen, mediumScreen }) => smallScreen ? "justify" : mediumScreen ? "justify" : "left"};
`