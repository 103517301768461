import { useState } from 'react'

// Styled components
import styled from 'styled-components'

// React router dom
import { useHistory } from 'react-router-dom'

// Animated
import { Animated } from "react-animated-css";

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

// Components
import SectionHeading from '../components/headings/SectionHeading'
import PostColumn from '../components/PostColumn'
import SquareButton from '../components/buttons/SquareButton'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Helpers
import fontSize from '../helpers/fontSize'

function CaseStudies() {

  const smallScreen = useMediaQuery({ query: '(max-width: 600px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 1023px)' })

  const [isContentVisible, setContentVisible] = useState(false)

  const history = useHistory()

  return (
    <MainContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
      <VisibilitySensor onChange={(isVisible) => setContentVisible(isVisible)} active={!isContentVisible} partialVisibility={true}>
        <Animated animationIn="fadeIn" animationInDuration={2000} isVisible={isContentVisible}>
          <HeadingContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
            <SectionHeading
              title='Featured Projects'
              subtitle='See how we have helped businesses in digital transformation' />
          </HeadingContainer>
        </Animated>
      </VisibilitySensor>
      <PostColumn landingPage={true} />
      <VisibilitySensor onChange={(isVisible) => setContentVisible(isVisible)} active={!isContentVisible} partialVisibility={true}>
        <Animated animationIn="fadeIn" animationInDuration={2000} isVisible={isContentVisible}>
          <ActionButtonContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
            <SquareButton
              action={() => history.push('/projects')}
              text='See other projects'
              textSize={smallScreen ? '16px' : mediumScreen ? '18px' : fontSize.bodyLg}
              textColor='#131A91'
              hoverTextColor='white'
              backgroundColor='transparent'
              hoverBgColor='#131A91'
              width={smallScreen ? '225px' : mediumScreen ? '275px' : '325px'}
              border='1px solid #131A91' />
          </ActionButtonContainer>
        </Animated>
      </VisibilitySensor>
    </MainContainer>
  )

}

export default CaseStudies

const MainContainer = styled.div`
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px 0 30px 0' : mediumScreen ? '30px 0 30px 0' : '50px 0 50px 0'}
`
const HeadingContainer = styled.div`
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px 0 0 0' : mediumScreen ? '0 10vw' : '0'}
`
const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px 0 0 0' : mediumScreen ? '30px 0 0 0' : '50px 0 0 0'}
`