import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import { useMediaQuery } from 'react-responsive'
import { Animated } from "react-animated-css";
import VisibilitySensor from 'react-visibility-sensor';

import HeadMeta from '../components/HeadMeta'
import SectionHeading from '../components/headings/SectionHeading'

import CloudxierLogoSquare from '../assets/images/logo/Cloudxier-logofull-colored-05.png'

import fontSize from '../helpers/fontSize'
import { device } from '../helpers/device'

function EnviromentalPolicy() {
  const smallScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 1023px)' })

  const [isContent1Visible, setContent1Visible] = useState(false)
  const [isContent2Visible, setContent2Visible] = useState(false)
  const [isContent3Visible, setContent3Visible] = useState(false)

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])

  return (
    <MainContainer>
      <HeadMeta
        title='Cloudxier - Enviromental Policy'
        description="Cloudxier enviromental policy"
        siteName="Cloudxier"
        url='https://www.cloudxier.com/enviromental-policy/'
        image={CloudxierLogoSquare}
      />
      <Container style={{ padding: smallScreen ? '0 5vw 30px 5vw' : mediumScreen ? '0 5vw 50px 5vw' : '0 5vw 50px 5vw' }}>
        <VisibilitySensor onChange={(isVisible) => setContent1Visible(isVisible)} active={!isContent1Visible} partialVisibility={true}>
          <Animated animationIn="fadeInDown" animationInDuration={2000} isVisible={isContent1Visible}>
            <SectionHeading
              title='Enviromental Policy Statement'
              subtitle=''
            />
            <BodyText>
              <i>Updated as per May 30, 2024</i>
            </BodyText>
            <BodyText>
              This policy applies to the Cloudxier - PT Cendekia Edukasi Citra Tritunggal including its employees, associates, facilitators, communities, and third parties who undertake activity for and on behalf of Cloudxier - PT Cendekia Edukasi Citra Tritunggal. It applies to the goods and services we procure, our direct operations and the services we provide to our customers and partners. Cloudxier - PT Cendekia Edukasi Citra Tritunggal operates within a suite of interdependent policies and their procedures, which are implemented collectively in order to deliver the objectives of the Environmental Policy.
            </BodyText>
          </Animated>
        </VisibilitySensor>
        <VisibilitySensor onChange={(isVisible) => setContent2Visible(isVisible)} active={!isContent2Visible} partialVisibility={true}>
          <Animated animationIn="fadeInLeft" animationInDuration={2000} isVisible={isContent2Visible}>
            <Heading>
              Purpose
            </Heading>
            <BodyText>
              To provide an effective framework for Cloudxier - PT Cendekia Edukasi Citra Tritunggal’s commitment to protecting and where possible enhancing the environment by reducing the environmental impacts, preventing pollution, mitigating and adapting to climate change and a low carbon future
            </BodyText>
          </Animated>
        </VisibilitySensor>
        <VisibilitySensor onChange={(isVisible) => setContent3Visible(isVisible)} active={!isContent3Visible} partialVisibility={true}>
          <Animated animationIn="fadeInRight" animationInDuration={2000} isVisible={isContent3Visible}>
            <Heading>
              Policy
            </Heading>
            <BodyText>
              Cloudxier - PT Cendekia Edukasi Citra Tritunggal is a tech and software development company based in Jakarta, Indonesia. Offering end-to-end technology consulting services from Product ideation, Cloud development to DevOps engineering we help our clients drive growth.
            </BodyText>
            <BodyText>
              Cloudxier recognises that its activities impact the environment in a number of ways, principally through our use of:
            </BodyText>
            <BodyText>
              <ul>
                <li>Energy (our office building, business travel)</li>
                <li>Paper</li>
                <li>Integrated cloud system for our internal team</li>
                <li>Information Communication and Technology (ICT) tools</li>
              </ul>
            </BodyText>
            <BodyText>
              In recognising this, Cloudxier - PT Cendekia Edukasi Citra Tritunggal is committed in contributing to protect the environment and continually improving its environmental performance by ensuring:
            </BodyText>
            <BodyText>
              <ul>
                <li>Compliance with all relevant legislation and standards, and the expectations of our customers, partners and other key stakeholders</li>
                <li>Continually to encourage our employees, customers, partners and other key stakeholders to support our environmental commitments;</li>
                <li>Our systems and procedures prevent pollution;</li>
                <li>Continually minimize harmful emissions to air, land and water;</li>
                <li>Continually review how we can make improvements to our environmental performance;</li>
                <li>Continually to develop the ability to measure and reduce emissions by encouraging our employees, customers, partners and communities and other key stakeholders to commit to low-carbon commuting;</li>
              </ul>
            </BodyText>
            <BodyText>Our actions to protecting environment, specifically includes:</BodyText>
            <BodyText>
              <ul>
                <li>Reduce business travel miles through better journey planning;</li>
                <li>Reduce our employee travel by prioritizing virtual meetings over offline meetings;</li>
                <li>Reduce energy consumption across our office and company operations;</li>
                <li>Reduce paper consumption through employees’ awareness and increase use of electronic communication and cloud data storage;</li>
                <li>Ensure that all waste electrical & electronic equipments are reused or recycled where possible;</li>
                <li>Monitor water consumption and reduce leakage and eliminate unnecessary and excessive use;</li>
                <li>Reduce and minimize our use of Information Communication and Technology (ICT) tools.</li>
              </ul>
            </BodyText>
            <BodyText>
              To implement our environmental objectives Cloudxier - PT Cendekia Edukasi Citra Tritunggal recognises the need for and is committed to communicating these objectives to our employees, customers, partners and communities and other key stakeholders.
            </BodyText>
            <BodyText>
              This is achieved via our procurement processes, and our existing communication channels to employees including the business plan, newsletters, team briefings.
            </BodyText>
            <BodyText>
              This policy and the actions arising from it will be annually reviewed as part of the business planning process which involves Cloudxier - PT Cendekia Edukasi Citra Tritunggal’s leadership and management teams, with final approval via the Cloudxier - PT Cendekia Edukasi Citra Tritunggal Board of Directors and Board of Commissioners.
            </BodyText>
          </Animated>
        </VisibilitySensor>
      </Container>
    </MainContainer>
  )
}

export default EnviromentalPolicy;

const MainContainer = styled.div`
  overflow: hidden;
  padding-top: 80px;
  @media ${device.tablet} {
    padding-top: 90px;
  }
  @media ${device.laptop} {
    padding-top: 90px;
  } 
  @media ${device.laptopM} {
    padding-top: 150px;
  } 
  @media ${device.laptopL} {
    padding-top: 150px;
  }
`
const Heading = styled.div`
  font-size: ${fontSize.bodyLg};
  color: black;
  font-weight: 700;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 12px;
  @media ${device.tablet} {
    font-size: ${fontSize.heading1Sm};
    margin-top: 48px;
    margin-bottom: 24px;
  }
  @media ${device.laptop} {
    font-size: ${fontSize.heading3Lg};
    margin-top: 48px;
    margin-bottom: 24px;
  }
  @media ${device.laptopM} {
    font-size: ${fontSize.heading3Lg};
    margin-top: 48px;
    margin-bottom: 24px;
  }
  @media ${device.laptopL} {
    font-size: ${fontSize.heading2Lg};
    margin-top: 48px;
    margin-bottom: 24px;
  }
`
const SubHeading = styled.p`
  font-size: ${fontSize.heading3Sm};
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 8px;
  @media ${device.tablet} {
    font-size: ${fontSize.bodyLg};
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptop} {
    font-size: 23px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopM} {
    font-size: 23px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopL} {
    font-size: 26px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
`
const BodyText = styled.p`
  text-align:justify;
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 8px;
  @media ${device.tablet} {
    font-size: 18px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptop} {
    font-size: ${fontSize.bodyLg};
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopM} {
    font-size: ${fontSize.bodyLg};
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopL} {
    font-size: 22px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
`