import TYPE from '../actions/types'

let initialState = {
  blogs: [],
  totalBlog: 0,
  blog: {},
  relatedBlog: [],
  loading: false
}

export default function blogReducers (state = initialState, action) {
  let { type, payload } = action
  switch (type) {
    case TYPE.REQUEST_SET_BLOGS:
      return {...state, loading: true}
    case TYPE.SUCCESS_SET_BLOGS:
      return {...state, loading: false, blogs: payload && payload.response, totalBlog: payload && payload.totalBlog}
    case TYPE.REQUEST_SET_BLOG_DETAILS:
      return {...state, loading: true}
    case TYPE.SUCCESS_SET_BLOG_DETAILS:
      return {...state, loading: false, blog: payload && payload.response, relatedBlog: payload && payload.response && payload.response.relatedBlog}
    default:
      return state
  }
}