import React from 'react'

function AppointmentIcon({
    className,
    width = "64",
    height = "49",
    fill = "#8000CF",
}) {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 64 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M60.8637 4.45455H49.7273V0.556818C49.7273 0.250568 49.4767 0 49.1705 0H45.2728C44.9665 0 44.7159 0.250568 44.7159 0.556818V4.45455H34.4148V0.556818C34.4148 0.250568 34.1642 0 33.858 0H29.9602C29.654 0 29.4034 0.250568 29.4034 0.556818V4.45455H19.1023V0.556818C19.1023 0.250568 18.8517 0 18.5455 0H14.6477C14.3415 0 14.0909 0.250568 14.0909 0.556818V4.45455H2.95457C1.72261 4.45455 0.727295 5.44986 0.727295 6.68182V46.7727C0.727295 48.0047 1.72261 49 2.95457 49H60.8637C62.0956 49 63.0909 48.0047 63.0909 46.7727V6.68182C63.0909 5.44986 62.0956 4.45455 60.8637 4.45455ZM58.0796 43.9886H5.73866V9.46591H14.0909V13.3636C14.0909 13.6699 14.3415 13.9205 14.6477 13.9205H18.5455C18.8517 13.9205 19.1023 13.6699 19.1023 13.3636V9.46591H29.4034V13.3636C29.4034 13.6699 29.654 13.9205 29.9602 13.9205H33.858C34.1642 13.9205 34.4148 13.6699 34.4148 13.3636V9.46591H44.7159V13.3636C44.7159 13.6699 44.9665 13.9205 45.2728 13.9205H49.1705C49.4767 13.9205 49.7273 13.6699 49.7273 13.3636V9.46591H58.0796V43.9886ZM25.2273 23.3864H12.4205C12.1142 23.3864 11.8637 23.6369 11.8637 23.9432V27.2841C11.8637 27.5903 12.1142 27.8409 12.4205 27.8409H25.2273C25.5335 27.8409 25.7841 27.5903 25.7841 27.2841V23.9432C25.7841 23.6369 25.5335 23.3864 25.2273 23.3864ZM25.2273 32.8523H12.4205C12.1142 32.8523 11.8637 33.1028 11.8637 33.4091V36.75C11.8637 37.0563 12.1142 37.3068 12.4205 37.3068H25.2273C25.5335 37.3068 25.7841 37.0563 25.7841 36.75V33.4091C25.7841 33.1028 25.5335 32.8523 25.2273 32.8523ZM46.6787 20.5048L39.4679 30.5067L35.7929 25.4188C35.5841 25.1264 35.25 24.9594 34.8951 24.9594H31.0739C30.6215 24.9594 30.357 25.4744 30.6215 25.8433L38.5631 36.8544C38.6656 36.9967 38.8005 37.1126 38.9566 37.1925C39.1127 37.2724 39.2856 37.3141 39.461 37.3141C39.6363 37.3141 39.8092 37.2724 39.9653 37.1925C40.1214 37.1126 40.2563 36.9967 40.3588 36.8544L51.8432 20.9364C52.1077 20.5675 51.8432 20.0524 51.3908 20.0524H47.5696C47.2216 20.0455 46.8875 20.2195 46.6787 20.5048Z" fill={fill} />
        </svg>
    )
}

export default AppointmentIcon