import { useState } from 'react'

// Styled components
import styled from 'styled-components'

// Featured On Logo
import GoodFirmsLogo from '../../assets/images/featured_on_logo/goodfirms_logo.png'
import ClutchLogo from '../../assets/images/featured_on_logo/top_clutch.png'

// React responsive
import { useMediaQuery } from 'react-responsive'

function FeaturedOnLogo() {

  const smallScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 1023px)' })

  return (
    <MainContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
      <ImageContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
        <Images src={GoodFirmsLogo} alt={"goodfirms-logo"} />
      </ImageContainer>
      <ImageContainer smallScreen={smallScreen} mediumScreen={mediumScreen} onClick={() => window.open('https://clutch.co/profile/cloudxier#highlights')} >
        <Images src={ClutchLogo} alt={"clutch-logo"} style={{ cursor: "pointer" }} />
      </ImageContainer>
      {/* <iframe width="200" height="200" src="https://shareables.clutch.co/share/badges/1811828/50687?utm_source=clutch_top_company_badge&utm_medium=image_embed" title="Top Clutch It Services Company Ecommerce Indonesia"></iframe> */}
      <div style={{ margin: smallScreen ? "50px 0 30px 0" : mediumScreen ? "60px 0 30px 0" : "0" }} class="clutch-widget" data-url="https://widget.clutch.co/" data-widget-type="13" data-height="50" d data-nofollow="true" data-expandifr="true" data-clutchcompany-id="1811828"></div>
    </MainContainer>
  )

}

export default FeaturedOnLogo

const MainContainer = styled.div`
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '10px 0' : mediumScreen ? '30px 0' : '50px 0'};
  display: ${({ smallScreen, mediumScreen }) => smallScreen ? 'flex' : mediumScreen ? 'flex' : 'flex'};
  flex-direction: ${({ smallScreen, mediumScreen }) => smallScreen ? 'column' : mediumScreen ? 'column' : 'row'};
  justify-content: ${({ smallScreen, mediumScreen }) => smallScreen ? 'center' : mediumScreen ? 'center' : 'space-evenly'};
  align-items: center;
  width: 100%;
`
const ImageContainer = styled.div`
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? '250px' : mediumScreen ? '250px' : '25%'};;
  height: auto;
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '5px' : mediumScreen ? '10px' : '15px'};
`
const Images = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain
`
