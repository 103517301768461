import { useRef, useState } from 'react';
// React bootstrap
// Styled components
import styled from 'styled-components'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Components
import SectionHeading from '../components/headings/SectionHeading'

// Animated
import { Animated } from "react-animated-css";

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

// Helpers
import { device } from '../helpers/device'

// Image
import ChevronRight from '../assets/images/icons/chevron_right_dark.png'
import be_compassionate from '../assets/images/our_core_values/be_compassionate.png';
import courage_to_accept_challenges from '../assets/images/our_core_values/courage_to_accept_challenges.png';
import go_above_and_beyond from '../assets/images/our_core_values/go_above_and_beyond.png';
import make_it_happen from '../assets/images/our_core_values/make_it_happen.png';
import work_with_purpose from '../assets/images/our_core_values/work_with_purpose.png';

function OurCoreValues() {
    const valuesContainerRef = useRef(null)
    const smallScreen = useMediaQuery({ query: '(max-width: 767px)' })
    const mediumScreen = useMediaQuery({ query: '(max-width: 1279px)' })

    const [isContent1Visible, setContent1Visible] = useState(false)

    const coreValues = [
        {
            title: 'Go Above and Beyond',
            descriptions: [
                'Always strive for excellence',
                'Deliver high-quality results and go extra mile'
            ],
            image: go_above_and_beyond,
            alt: 'go_above_and_beyond'
        },
        {
            title: 'Make It Happen',
            descriptions: [
                'We deliver what matters the most, results-oriented',
                'Empower each other to be the best version of ourselves in every work we do'
            ],
            image: make_it_happen,
            alt: 'make_it_happen'
        },
        {
            title: 'Courage to Accept Challenges',
            descriptions: [
                'Have a strong problem-solving thinking',
                'Have courage to take challenges and put customers first'
            ],
            image: courage_to_accept_challenges,
            alt: 'courage_to_accept_challenges'
        },
        {
            title: 'Be Compassionate',
            descriptions: [
                'Open-minded and collaborate with compassion',
                'Always work wholeheartedly, considering the impact we do on others'
            ],
            image: be_compassionate,
            alt: 'be_compassionate'
        },
        {
            title: 'Work with Purpose',
            descriptions: [
                'Always support and being helpful to others',
                'Contribute to the world in a meaningful way'
            ],
            image: work_with_purpose,
            alt: 'work_with_purpose'
        },
    ]

    const slideLeft = () => {
        if (valuesContainerRef?.current) {
            valuesContainerRef.current.scrollLeft -= smallScreen ? 150 : mediumScreen ? 200 : 250
        }
    }

    const slideRight = () => {
        if (valuesContainerRef?.current) {
            valuesContainerRef.current.scrollLeft += smallScreen ? 150 : mediumScreen ? 200 : 250
        }
    }

    return (
        <MainContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
            <VisibilitySensor onChange={(isVisible) => setContent1Visible(isVisible)} active={!isContent1Visible} partialVisibility={true}>
                <Animated animationIn="slideInDown" animationInDuration={2000} isVisible={isContent1Visible}>
                    <SectionHeading
                        title='Our Core Values'
                        subtitle=''
                    />
                    <ContentContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
                        <SlideButton onClick={slideLeft} ><SlideButtonIcon src={ChevronRight} smallScreen={smallScreen} mediumScreen={mediumScreen} alt='left' style={{ transform: "rotate(180deg)" }} /></SlideButton>
                        <ValuesContainer ref={valuesContainerRef} smallScreen={smallScreen} mediumScreen={mediumScreen}>
                            {coreValues.map((value, i) => (
                                <ValueItem smallScreen={smallScreen} mediumScreen={mediumScreen}>
                                    <Image src={value.image} alt={value.alt} smallScreen={smallScreen} mediumScreen={mediumScreen} />
                                    <TitleText smallScreen={smallScreen} mediumScreen={mediumScreen}>
                                        {value.title}
                                    </TitleText>
                                    <List smallScreen={smallScreen} mediumScreen={mediumScreen}>
                                        {value.descriptions.map((item, i) => (
                                            <ListItem smallScreen={smallScreen} mediumScreen={mediumScreen}>{item}</ListItem>
                                        ))}
                                    </List>
                                </ValueItem>
                            ))}
                        </ValuesContainer>
                        <SlideButton onClick={slideRight}><SlideButtonIcon src={ChevronRight} smallScreen={smallScreen} mediumScreen={mediumScreen} alt='right' /></SlideButton>
                    </ContentContainer>
                </Animated>
            </VisibilitySensor>
        </MainContainer>
    )

}

export default OurCoreValues

const MainContainer = styled.div`
    padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px 0 30px 0' : mediumScreen ? '30px 0 30px 0' : '50px 0 50px 0'};
    background: #F3F3F3;
`
const ContentContainer = styled.div`
    display: flex;
    align-items:center;
    padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '10px 10px' : mediumScreen ? '22px 8px' : '25px 72px 25px 72px'};
`
const ValuesContainer = styled.div`
    display: flex;
    overflow-y: auto;
    gap: ${({ smallScreen, mediumScreen }) => smallScreen ? '20px' : mediumScreen ? '30px' : '40px'};
    padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '0 14px' : mediumScreen ? '0 20px' : '0 26px'};
    scroll-behavior: smooth;
    margin: ${({ smallScreen, mediumScreen }) => smallScreen ? '0 12px' : mediumScreen ? '0 14px' : '0 16px'};
`

const ValueItem = styled.div`

`
const Image = styled.img`
    background: white;
    border-radius: ${({ smallScreen, mediumScreen }) => smallScreen ? '20px' : mediumScreen ? '30px' : '40px'};
    height: ${({ smallScreen, mediumScreen }) => smallScreen ? '150px' : mediumScreen ? '200px' : '270px'};
    width: ${({ smallScreen, mediumScreen }) => smallScreen ? '150px' : mediumScreen ? '200px' : '270px'};
    object-fit: cover;
    margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '20px' : mediumScreen ? '22px' : '24px'};
`

const TitleText = styled.p`
    font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '20px' : mediumScreen ? '25px' : '30px'};
    line-height: ${({ smallScreen, mediumScreen }) => smallScreen ? '25px' : mediumScreen ? '30px' : '36px'};
    color: #4C39FF;
    font-weight: 700;
    margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '12px' : mediumScreen ? '14px' : '16px'};
    width: ${({ smallScreen, mediumScreen }) => smallScreen ? '160px' : mediumScreen ? '180px' : '350px'};
    text-align: ${({ smallScreen, mediumScreen }) => smallScreen ? 'center' : mediumScreen ? 'center' : 'left'};
`

const List = styled.ul`
    padding: 0;
    padding-left: ${({ smallScreen, mediumScreen }) => smallScreen ? '18px' : mediumScreen ? '20px' : '22px'};
`

const ListItem = styled.li`
    font-size:  ${({ smallScreen, mediumScreen }) => smallScreen ? '16px' : mediumScreen ? '20px' : '24px'};
    line-height: ${({ smallScreen, mediumScreen }) => smallScreen ? '20px' : mediumScreen ? '30px' : '40px'};
    margin-bottom: 4px;
`

const SlideButton = styled.button`
    border: none;
    background-color: transparent;
    height: min-content;
    padding: 8px;
`
const SlideButtonIcon = styled.img`
    object-fit: cover;
    height: ${({ smallScreen, mediumScreen }) => smallScreen ? '24px' : mediumScreen ? '28px' : '32px'};
`