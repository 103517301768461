import { useState } from 'react'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Bootstrap
import Container from 'react-bootstrap/Container'

// Styled components
import styled from 'styled-components'

// Animated
import { Animated } from "react-animated-css";

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

// Components
import TechStackLogo from './banners/TechStackLogo'

// Helpers
import fontSize from '../helpers/fontSize'

function HomeTechStacks() {

  const smallScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 1200px)' })

  const [isVisibleColumn, setVisibleColumn] = useState(false)

  return (
    <VisibilitySensor onChange={(isVisible) => setVisibleColumn(isVisible)} active={!isVisibleColumn} partialVisibility={true}>
      <Container>
        <MainContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
          <Animated animationIn="fadeInUp" animationInDuration={2000} isVisible={isVisibleColumn}>
            <Heading smallScreen={smallScreen} mediumScreen={mediumScreen}>
              Technology Stacks
            </Heading>
            <TechStackLogo />
          </Animated>
        </MainContainer>
      </Container>
    </VisibilitySensor>
  )

}

export default HomeTechStacks

const MainContainer = styled.div`
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px 5vw 30px 5vw' : mediumScreen ? '30px 5vw 30px 5vw' : '50px 0'}
`
const Heading = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '20px' : mediumScreen ? '25px' : fontSize.heading3Lg};
  color: black;
  font-weight: 700;
  text-align: center
`