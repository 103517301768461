// React
import { useState, useEffect } from 'react'

// Styled components
import styled from 'styled-components'

// React bootstrap
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

// Bootstrap
import Container from 'react-bootstrap/Container'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Animated
import { Animated } from "react-animated-css";

// React router dom
import { useHistory } from 'react-router-dom'

function HeaderDropdown({ servicesMenuSelected, isOpen, list, backgroundColor, textColor, stopScrollAnimation, windowScrollPercentage, closeDropdown, isExternalUrl }) {

  const mediumScreen = useMediaQuery({ query: '(max-width: 1599px)' })

  const history = useHistory()

  const [selectedItem, setSelectedItem] = useState('')

  function triggerClick(item) {
    if (isExternalUrl) {
      // Open external url
      window.open(item.path, '_blank')
    } else {
      // Change to new route
      history.push(item.path)
    }
    // Close the dropdown once route loaded
    closeDropdown()
    // Scroll loaded window to top
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  useEffect(() => {
    if (!isOpen) {
      setSelectedItem('')
    }
  }, [isOpen])

  if (isOpen) {
    return (
      <MainContainer backgroundColor={backgroundColor} stopScrollAnimation={stopScrollAnimation} windowScrollPercentage={windowScrollPercentage}>
        <Animated animationIn={servicesMenuSelected ? "fadeInLeft" : "fadeInUp"} animationInDuration={2000} isVisible={isOpen}>
          {servicesMenuSelected ? (
            <Container style={{ padding: "0" }}>
              <Row style={{ display: "flex", justifyContent: "space-evenly", paddingRight: "35%" }}>
                {
                  list.map((item, index) => {
                    return (
                      <Col
                        key={index}
                        md={6}
                        lg={6}
                        style={{
                          display: "flex",
                          gap: "15px",
                          width: "200px",
                        }}
                      >
                        {item.icon && (
                          <IconContainer>
                            {item.icon}
                          </IconContainer>
                        )}
                        <ItemContainer servicesMenuSelected={servicesMenuSelected}>
                          <Item
                            servicesMenuSelected={servicesMenuSelected}
                            isSelected={selectedItem === item.title ? true : false}
                            onMouseOver={() => setSelectedItem(item.title)}
                            onClick={() => triggerClick(item)}
                            textColor={textColor}
                          >
                            {item.title}
                          </Item>
                          {item.description && (
                            <ItemDescription
                              isSelected={selectedItem === item.title ? true : false}
                              onMouseOver={() => setSelectedItem(item.title)}
                              onClick={() => triggerClick(item)}
                              textColor={textColor}
                            >
                              {item.description}
                            </ItemDescription>
                          )}
                        </ItemContainer>
                      </Col>
                    )
                  })
                }
              </Row>
            </Container>
          ) : (
            <Row style={{ display: "flex", justifyContent: "space-evenly" }}>
              {
                list.map((item, index) => {
                  return (
                    <Col
                      key={index}
                      md={3}
                      lg={3}
                      style={{
                        display: "flex",
                        gap: "15px",
                        width: "200px",
                      }}
                    >
                      {item.icon && (
                        <IconContainer>
                          {item.icon}
                        </IconContainer>
                      )}
                      <ItemContainer servicesMenuSelected={servicesMenuSelected}>
                        <Item
                          servicesMenuSelected={servicesMenuSelected}
                          isSelected={selectedItem === item.title ? true : false}
                          onMouseOver={() => setSelectedItem(item.title)}
                          onClick={() => triggerClick(item)}
                          textColor={textColor}
                        >
                          {item.title}
                        </Item>
                        {item.description && (
                          <ItemDescription
                            isSelected={selectedItem === item.title ? true : false}
                            onMouseOver={() => setSelectedItem(item.title)}
                            onClick={() => triggerClick(item)}
                            textColor={textColor}
                          >
                            {item.description}
                          </ItemDescription>
                        )}
                      </ItemContainer>
                    </Col>
                  )
                })
              }
            </Row>
          )}
        </Animated>
      </MainContainer>
    )
  } else {
    return null
  }

}

export default HeaderDropdown

const MainContainer = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor ? backgroundColor : 'white'};
  position: absolute;
  padding: 30px;
  border-top: 1px solid #999;
  // border-bottom: 1px solid #999;
  top: 85px;
  left: 0;
  width: 100%;
  z-index: 2;
  box-shadow: 0px 1px 3px #999;
  transition: opacity 1s;
  transition-delay: .5s;
  animation: ${({ stopScrollAnimation }) => stopScrollAnimation()};
  animation-play-state: paused;
  animation-delay: ${({ windowScrollPercentage }) => `calc(${windowScrollPercentage} * -30s)`};
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`
const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const Item = styled.div`
  color: ${({ textColor }) => textColor ? textColor : 'black'};
  font-weight: ${({ isSelected }) => isSelected ? 'bold' : 'normal'};
  font-size: 16px;
  text-align: left;
  cursor: pointer
`
const ItemDescription = styled.div`
  color: ${({ textColor }) => textColor ? textColor : 'black'};
  padding-top: 8px;
  font-size: 14px;
  text-align: left;
  cursor: pointer
`
const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ servicesMenuSelected }) => servicesMenuSelected ? '24px 0' : '12px 0'};
`