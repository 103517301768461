import { useEffect, useState } from 'react'

// React responsive
import { useMediaQuery } from 'react-responsive'

// React router dom
import { useHistory } from 'react-router-dom'

// Styled components
import styled from 'styled-components'

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

// Animated
import { Animated } from "react-animated-css";

// Images
import ArrowRightIcon from '../assets/images/icons/arrow_right_icon_light.png'

// Helpers
import fontSize from '../helpers/fontSize'

// React-redux
import { connect } from 'react-redux'

const mapStateToProps = state => ({
  projects: state.projects.projects
})

function PostColumn({ projects, landingPage }) {

  const smallScreen = useMediaQuery({ query: '(max-width: 600px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 1023px)' })

  const history = useHistory()

  const [isPostVisible, setPostVisible] = useState([])

  // Listen on post visibility on load
  useEffect(() => {
    let postVisibility = []
    projects.forEach((post, index) => {
      postVisibility.push(false)
    })
    setPostVisible(postVisibility)
  }, [])

  function handlePostVisible(isVisible, index) {
    let newPostVisible = [...isPostVisible]
    newPostVisible[index] = isVisible
    setPostVisible(newPostVisible)
  }

  function checkOddNumber(number) {
    return number % 2
  }

  return (
    <MainContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
      {
        projects.map((item, index) => {
          if (landingPage ? index < 5 : index < 1) {
            return (
              <VisibilitySensor key={index} onChange={(isVisible) => handlePostVisible(isVisible, index)} partialVisibility={true} active={!isPostVisible[index]}>
                <Animated animationIn={checkOddNumber(index) ? "fadeInRight" : "fadeInLeft"} animationInDuration={2000} isVisible={isPostVisible[index]}>
                  <Post smallScreen={smallScreen} mediumScreen={mediumScreen}>
                    {
                      smallScreen || mediumScreen ?
                        <ImageContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
                          <Image src={item.image} smallScreen={smallScreen} mediumScreen={mediumScreen} />
                        </ImageContainer>
                        :
                        null
                    }
                    {
                      !smallScreen && !mediumScreen && checkOddNumber(index) ?
                        <ImageContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
                          <Image src={item.image} smallScreen={smallScreen} mediumScreen={mediumScreen} />
                        </ImageContainer>
                        :
                        null
                    }
                    <Description smallScreen={smallScreen} mediumScreen={mediumScreen}>
                      <InnerContainer smallScreen={smallScreen} mediumScreen={mediumScreen} isPostVisible={isPostVisible} postIndex={index}>
                        <Title smallScreen={smallScreen} mediumScreen={mediumScreen} onClick={() => history.push(`/projects/details?id=${item.id}`)}>
                          {item.title}
                        </Title>
                        <ActionButton>
                          <ActionText smallScreen={smallScreen} mediumScreen={mediumScreen} onClick={() => history.push(`/projects/details?id=${item.id}`)}>
                            See more details
                          </ActionText>
                          <ArrowIcon src={ArrowRightIcon} smallScreen={smallScreen} mediumScreen={mediumScreen} onClick={() => history.push(`/projects/details?id=${item.id}`)} />
                        </ActionButton>
                      </InnerContainer>
                    </Description>
                    {
                      smallScreen || mediumScreen ?
                        null
                        :
                        !checkOddNumber(index) ?
                          <ImageContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
                            <Image src={item.image} smallScreen={smallScreen} mediumScreen={mediumScreen} />
                          </ImageContainer>
                          :
                          null
                    }
                  </Post>
                </Animated>
              </VisibilitySensor>
            )
          }
        })
      }
    </MainContainer>
  )

}

export default connect(mapStateToProps, null)(PostColumn)

const MainContainer = styled.div`
  padding-top: ${({ smallScreen, mediumScreen }) => smallScreen ? '0' : mediumScreen ? '0' : '50px'}
`
const Post = styled.div`
  display: ${({ smallScreen, mediumScreen }) => smallScreen ? 'block' : mediumScreen ? 'block' : 'flex'};
  width: 100%
`
const Description = styled.div`
  background-color: #131A91;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${({ smallScreen, mediumScreen }) => smallScreen ? 'flex-start' : mediumScreen ? 'flex-start' : 'center'};
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? '100%' : mediumScreen ? '100%' : '50%'};
  height: ${({ smallScreen, mediumScreen }) => smallScreen ? '200px' : mediumScreen ? '200px' : 'auto'};
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '0 10vw' : mediumScreen ? '0 10vw' : '70px 0'};
`
const InnerContainer = styled.div`
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? '100%' : mediumScreen ? '100%' : '50%'};
  opacity: ${({ isPostVisible, postIndex }) => isPostVisible[postIndex] ? 1 : 0};
  transition: opacity 1s;
`
const Title = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '25px' : mediumScreen ? '30px' : fontSize.heading2Lg};
  color: white;
  font-weight: 500;
  margin-bottom: ${({ smallScreen, mediumScreen }) => smallScreen ? '30px' : mediumScreen ? '40px' : '60px'};
  cursor: pointer;
  user-select: none
`
const ActionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
const ActionText = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '16px' : mediumScreen ? '18px' : fontSize.bodyLg};
  color: white;
  margin-right: 20px;
  cursor: pointer;
  user-select: none
`
const ArrowIcon = styled.img`
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? '20px' : mediumScreen ? '25px' : '30px'};
  height: auto;
  cursor: pointer
`
const ImageContainer = styled.div`
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? '100%' : mediumScreen ? '100%' : '50%'};
  height: auto
`
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover
`

