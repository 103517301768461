import { useState, useEffect } from 'react'

// Bootstrap
import Container from 'react-bootstrap/Container'

// React responsive
import { useMediaQuery } from 'react-responsive'

// Helpers
import fontSize from '../../helpers/fontSize'

// Components
import SquareButton from '../buttons/SquareButton'

// Images
import MainBannerImage from '../../assets/images/landing_page/main_banner/Cloudxier-mainbanner.png'

function HomeMainBanner() {
  const smallScreen = useMediaQuery({ query: '(max-width: 600px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 1200px)' })

  const [animateBannerTitle, setAnimateBannerTitle] = useState(false)

  // Start banner title animation on page load
  useEffect(() => {
    setAnimateBannerTitle(true)
  }, [])

  return (
    <div>
      <div style={styles.homeMainBannerContainer}>
        <div style={{
          ...styles.homeMainBannerTextContainer,
          opacity: animateBannerTitle ? 1 : 0,
          marginTop: animateBannerTitle ? 0 : '-50vh'
        }}>
          <div style={styles.homeMainBannerTextFlexContainer}>
            <Container style={{ padding: smallScreen ? '0 5vw' : mediumScreen ? '0 5vw' : 0 }}>
              <div
                style={{
                  ...styles.homeMainBannerTitle,
                  fontSize: smallScreen ? '35px' : mediumScreen ? '40px' : fontSize.heading1Lg,
                  marginBottom: smallScreen ? '30px' : mediumScreen ? '30px' : '60px'
                }}>
                Your Partner in Digital Transformation
              </div>
              <div
                style={{
                  fontSize: smallScreen ? '20px' : mediumScreen ? '24px' : fontSize.heading4Lg,
                  marginBottom: smallScreen ? '30px' : mediumScreen ? '30px' : '60px'
                }}>
                Empowering businesses with innovative digital solutions for seamless integration and sustainable growth.
              </div>
              <div style={styles.homeMainBannerActionButton}>
                <SquareButton
                  action={() => window.open('/contact-us', '_self')}
                  textColor='#4C39FF'
                  hoverTextColor='white'
                  text='Discuss with us'
                  textSize={smallScreen ? '18px' : mediumScreen ? '19px' : fontSize.bodyLg}
                  backgroundColor='white'
                  hoverBgColor='#4C39FF'
                  width={smallScreen ? '165px' : mediumScreen ? '185px' : '200px'}
                />
              </div>
            </Container>
          </div>
        </div>
        <div style={styles.bannerOverlay} />
        <img src='https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/5286a7805264fe17a3fc561ff421062ef93c456ff13116cb1846ff7c52db4b3487dc5ce0d87a8cb71a5b3c3f20bc8221246bbd34ca2d9d1eb1df1761ef617203ae48621d818d83ecb334d6bec5d33e9c74d72d2975906c722ae835f06d5f927d39631d98d18d80c520c1ee9b33817cacf6a013af8d30d0208f95/rez-file-1719291925650.png' style={styles.homeMainBanner} alt='main-banner' />
      </div>
    </div>
  )

}

export default HomeMainBanner

const styles = {
  container: {
    padding: '0 20px'
  },
  homeMainBannerContainer: {
    width: '100%',
    height: '100vh'
  },
  homeMainBanner: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  bannerOverlay: {
    backgroundColor: 'rgba(0,0,0,0.3)',
    width: '100%',
    height: '100vh',
    position: 'absolute',
    zIndex: 1
  },
  homeMainBannerTextContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    textAlign: 'center',
    color: 'white',
    zIndex: 2,
    transition: 'opacity 2s, margin 1s'
  },
  homeMainBannerTextFlexContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  },
  homeMainBannerTitle: {
    fontWeight: '500',
  },
  homeMainBannerActionButton: {
    color: 'blue',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center'
  }
}